import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { QuickReply } from "../../../../../../../types/Prisma";
import React from "react";

interface QuickReplyDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedQuickReply: QuickReply | null;
}

export function QuickReplyDetailsModal({ isOpen, onClose, selectedQuickReply }: QuickReplyDetailsModalProps): React.ReactElement {
  return(
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resposta Rápida</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              <Text as='b' size="sm">
                Título:
              </Text>
              <Text size='sm'>
                {selectedQuickReply?.title}
              </Text>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              <Text as='b' size="sm" mt={4}>Texto:</Text>
              <Text size='sm'>{selectedQuickReply?.text}</Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
