import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
} from "@chakra-ui/react";
import { colors } from "../../../../constants/colors";

import { MdWarning } from "react-icons/md";

const SectionNumberQualityRatingNotice = () => {
  return (
    <Card bgColor={colors.tulip} color={colors.white}>
      <CardBody>
        <Flex direction={{ base: "column", md: "row" }} gap={4}>
          <Box
            flex="2"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MdWarning size={38} color={colors.yellow} />
          </Box>
          <Box 
            display="flex"
            alignItems="center"
          >
            <Stat display={"flex"} justifyContent="center">
              <StatLabel
                color={colors.white}
                textTransform="uppercase"
                fontSize={14}
              >
                A qualidade do seu número junto à Meta está baixa. Recomendamos
                que espere alguns dias até que a qualidade do seu número aumente
                para garantir que sua conta não seja bloqueada pela Meta por 30
                dias.
              </StatLabel>
            </Stat>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default SectionNumberQualityRatingNotice;
