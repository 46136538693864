import { Flex, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { colors } from "../../../../constants/colors";

interface UrlButtonFieldProps {
  buttonTitleFormRegister: UseFormRegisterReturn<any>;
  buttonUrlFormRegister: UseFormRegisterReturn<any>;
  isTitleInvalid: boolean;
  isUrlInvalid: boolean;
  invalidTitleMessage?: string;
  invalidUrlMessage?: string;
}

function UrlButtonField({
  buttonTitleFormRegister,
  buttonUrlFormRegister,
  isTitleInvalid,
  isUrlInvalid,
  invalidTitleMessage,
  invalidUrlMessage,
}: UrlButtonFieldProps) {
  return (
    <Flex gap={2}>
      <FormControl w="250px">
        <FormLabel>Botão</FormLabel>
        <Input
          placeholder="Título do botão"
          {...buttonTitleFormRegister}
          isInvalid={isTitleInvalid}
        />
        <Text color={colors.danger} fontSize="xs">
          {invalidTitleMessage}
        </Text>
      </FormControl>
      <FormControl w="300px">
        <FormLabel>URL</FormLabel>
        <Input
          placeholder="URL do botão"
          {...buttonUrlFormRegister}
          isInvalid={isUrlInvalid}
        />
        <Text color={colors.danger} fontSize="xs">
          {invalidUrlMessage}
        </Text>
      </FormControl>
    </Flex>
  );
}

export default UrlButtonField;
