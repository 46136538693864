import React, { useState } from 'react';
import { Box, Input, HStack, Text } from '@chakra-ui/react';
import WhatsappTemplatePreview from '../../../../../components/WhatsappTemplatePreview';
import { ListMessageTemplateItem } from '../../../../../services/message-templates.service';
import { CommunicationChannelEnum } from '../../../../../types/CommunicationChannelEnum';

interface TemplateSelectorProps {
  templates: ListMessageTemplateItem[];
  selectedTemplate: ListMessageTemplateItem | null;
  onClickTemplate: (template: ListMessageTemplateItem) => void;
  communicationChannel: CommunicationChannelEnum;
  showSearch?: boolean;
}

const TemplateSelector = ({
  templates,
  selectedTemplate,
  onClickTemplate,
  communicationChannel,
  showSearch = true,
}: TemplateSelectorProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  function handleChangeSearchQuery(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
  }

  const filteredTemplates =
    templates.filter((template) => {
      return template.name.includes(searchQuery) && template.communicationChannel === communicationChannel;
    });

  return (
    <Box width='-webkit-fill-available'>
      {showSearch && (
        <Input
          placeholder="Pesquisar por nome"
          onChange={handleChangeSearchQuery}
          value={searchQuery}
          marginBottom={4}
        />
      )}
      <HStack spacing={2} overflowX="scroll">
        {filteredTemplates?.map((template) => {
          const isSelected = selectedTemplate?.id === template.id;
          return (
            <Box
              key={template.id}
              onClick={() => onClickTemplate(template)}
              cursor="pointer"
              borderWidth={isSelected ? "2px" : "0"}
              borderRadius="md"
              borderColor={isSelected ? "blue.500" : "gray.200"}
            >
              <Text fontWeight={"bold"}>{template.name}</Text>
              <WhatsappTemplatePreview
                message={template.templateText}
                footer={template.footerText}
                buttons={template.messageTemplateButtons}
                fileUrl={template.mediaUrl}
                height={"60vh"}
              />
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};

export default TemplateSelector;