import { Card, CardBody, CardHeader, Heading, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import CustomECharts from "../../../../components/CustomECharts";
import { apiRoutes } from "../../../../constants/api-routes";
import { colors } from "../../../../constants/colors";
import { StatisticsService } from "../../../../services/statistics.service";

type ChartType = "marketing" | "automation" | "all";

const SectionReviKPIsChart = () => {
  const [chartType, setChartType] = useState<ChartType>("marketing");
  const { data = [] } = useQuery(
    apiRoutes.getReviRevenueSummaryByMonth(),
    async () => {
      const { data } = await StatisticsService.getReviRevenueSummaryByMonth();
      return data;
    },
    {
      staleTime: 1000 * 60 * 60,
      select(data) {
        return data.map((item) => ({
          ...item,
          total_orders_value_from_marketing: Math.round(item.total_orders_value_from_marketing / 100),
          total_orders_value_from_automation: Math.round(item.total_orders_value_from_automation / 100),
        }));
      },
    },
  );

  function getSelectedLegend(chartType: ChartType) {
    return {
      Marketing: chartType === "marketing" || chartType === "all",
      "Mensagens enviadas de marketing":
        chartType === "marketing" || chartType === "all",
      Automações: chartType === "automation" || chartType === "all",
      "Mensagens enviadas de automação":
        chartType === "automation" || chartType === "all",
    };
  }

  return (
    <Card>
      <CardHeader display="flex" justifyContent={"space-between"}>
        <Heading size="md">Receita influenciada Revi</Heading>
        <Select
          width='300px'
          defaultValue={chartType}
          onChange={(e) => setChartType(e.target.value as ChartType)}>
          <option value="marketing">Marketing</option>
          <option value="automation">Automações</option>
          <option value="all">Marketing & Automações</option>
        </Select>
      </CardHeader>
      <CardBody>
        <CustomECharts
          chartWidth={"100%"}
          chartHeight="450px"
          option={{
            dataset: {
              dimensions: [
                "month",
                "total_orders_value_from_marketing",
                "total_orders_value_from_automation",
                "total_messages_sent_by_marketing",
                "total_messages_sent_by_automation",
              ],
              source: data,
            },
            color: [
              colors.primaryMedium,
              colors.greenMedium,
              colors.primaryLight,
              colors.green,
            ],
            legend: {
              selected: getSelectedLegend(chartType),
            },
            tooltip: {},
            xAxis: {
              type: "category",
            },
            yAxis: [
              {
                name: "Total em Vendas (R$)",
                nameLocation: "middle",
                nameGap: 55,
                splitLine: { show: false },
              },
              {
                name: "Mensagens enviadas",
                nameLocation: "middle",
                splitLine: { show: false },
                nameGap: 55,
              },
            ],
            series: [
              {
                type: "bar",
                name: "Marketing",
                stack: "month",
                yAxisIndex: 0,
              },
              {
                type: "bar",
                name: "Automações",
                stack: "month",
                yAxisIndex: 0,
              },
              {
                type: "line",
                name: "Mensagens enviadas de marketing",
                yAxisIndex: 1,
              },
              {
                type: "line",
                name: "Mensagens enviadas de automação",
                yAxisIndex: 1,
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
};

export default SectionReviKPIsChart;
