import {
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { apiRoutes } from "../../../../../constants/api-routes";
import { colors } from "../../../../../constants/colors";
import { TagsService } from "../../../../../services/tags.service";
import { useCrudTagModal } from "../../../../../hooks/useCrudTagModal";
import { TagWithCount } from "../../../../../types/Tag";

const TableTagSettings = () => {
  const { data: tags } = useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    }
  );
  const { openEditModal, openDeleteAlert } = useCrudTagModal();

  const tagDeletionAlert = (tag: TagWithCount) => {
    if (tag.isAssociatedWithFlowNode && tag.isUsedInFilter) {
      return 'Você não pode deletar uma tag associada a um fluxo e que está sendo usada em um filtro';
    }

    if (tag.isUsedInFilter) {
      return 'Você não pode deletar uma tag que está sendo usada em um filtro';
    }

    return tag.isAssociatedWithFlowNode ? 'Você não pode deletar uma tag associada a um fluxo' : 'Deletar tag';
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Tag</Th>
            <Th>Número de clientes</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tags?.map((tag) => {
            return (
              <Tr key={tag.id}>
                <Td fontWeight={"bold"}>{tag.name}</Td>
                <Td>{tag._count.customerTags}</Td>
                <Td>
                  <Tooltip label="Editar tag">
                    <IconButton
                      aria-label="Editar tag"
                      icon={<FaEdit color={colors.primary} />}
                      variant="ghost"
                      onClick={() => openEditModal(tag.id)}
                    />
                  </Tooltip>
                  <Tooltip label={
                    tagDeletionAlert(tag)
                  }>
                    <IconButton
                      isDisabled={tag.isAssociatedWithFlowNode || tag.isUsedInFilter}
                      aria-label="Deletar tag"
                      icon={<FaTrashAlt color={colors.danger} />}
                      variant="ghost"
                      onClick={() => {
                        openDeleteAlert(tag.id);
                      }}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableTagSettings;
