import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlineAreaChart } from "react-icons/ai";
import { BiMessageAltCheck, BiSolidMessageX } from "react-icons/bi";
import { BsFillEyeFill, BsPeopleFill } from "react-icons/bs";
import { FaRegCopy, FaUsers } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { HiCursorClick } from "react-icons/hi";
import { MdAttachMoney, MdOutlineFilterAlt } from "react-icons/md";
import { PiArrowsClockwiseBold } from "react-icons/pi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbBase from "../../../../components/BreadcrumbBase";
import CardStatistic from "../../../../components/CardStatistic";
import CustomECharts from "../../../../components/CustomECharts";
import LoadingScreen from "../../../../components/LoadingScreen";
import TableFilters from "../../../../components/TableFilters";
import WhatsappTemplatePreview from "../../../../components/WhatsappTemplatePreview";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { colors } from "../../../../constants/colors";
import {
  Button as ITemplateButton,
  MessageTemplatesService,
} from "../../../../services/message-templates.service";
import { StatisticsService } from "../../../../services/statistics.service";
import { WhatsappCampaignsService } from "../../../../services/whatsapp-campaigns.service";
import { ButtonType } from "../../../../types/ButtonTypeEnum";
import { ChartUtils, FunnelMetric } from "../../../../utils/chart.utils";
import { CustomerFilterUtils } from "../../../../utils/customer-filter.utils";
import { MoneyUtils } from "../../../../utils/money.utils";
import { NumberUtils } from "../../../../utils/number.utils";

type TimeUnit = "hour" | "day";

const WhatsappCampaignDetailsPage = () => {
  const { campaignId } = useParams();
  const [chartTimeUnit, setChartTimeUnit] = useState<TimeUnit>("day");
  const [funnelMetric, setFunnelMetric] =
    useState<FunnelMetric>("totalOrdersValue");
  const {
    isOpen: isDrawerOpen,
    onClose: onCloseDrawer,
    onOpen: onOpenDrawer,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const {
    data: campaignPerformanceData = [],
    isLoading: isLoadingCampaignPerformanceData,
  } = useQuery(
    [
      "statistics-service",
      "get-chart-campaigns-performance",
      campaignId,
      chartTimeUnit,
    ],
    async () => {
      const { data } = await StatisticsService.getChartCampaignPerformance(
        campaignId || "",
        chartTimeUnit
      );
      return data;
    },
    {}
  );

  const { data: campaignSalesData, isLoading: isLoadingCampaignSalesData } =
    useQuery(
      ["statistics-service", "campaign-sales", campaignId],
      async () => {
        const { data } = await StatisticsService.getChartCampaignSales(
          campaignId || ""
        );
        return data;
      },
      {}
    );

  const { data: details, isLoading: isLoadingDetails } = useQuery(
    ["whatsapp-campaigns", "get-campaign-details", campaignId],
    async () => {
      const { data } =
        await WhatsappCampaignsService.getWhatsappCampaignDetails(
          campaignId || ""
        );
      return data;
    }
  );

  const {
    data: currentTemplate,
    isLoading: isLoadingMessageTemplate,
    isError,
  } = useQuery(
    apiRoutes.showMessageTemplate(details?.template_id ?? ""),
    async () => {
      const { data } = await MessageTemplatesService.showMessageTemplate(
        details?.template_id ?? ""
      );
      return data;
    },
    { enabled: !!details }
  );

  function copyToClipboard(value: string) {
    navigator.clipboard.writeText(value);
    toast({
      title: "Filtros copiados para a área de transferência",
    });
  }

  function getButtons(): ITemplateButton[] | undefined {
    let res = currentTemplate?.messageTemplateButtons;
    if (!res && currentTemplate?.ctaLink) {
      res = [
        {
          text: "",
          type: ButtonType.URL,
          url: currentTemplate.ctaLink,
        },
      ];
    }

    return res;
  }

  const transformedFilterCriteria = details?.filter_criteria
    ? CustomerFilterUtils.parseFilterCriteria(details.filter_criteria)
    : [];

  const isLoadingScreen =
    isLoadingMessageTemplate ||
    isLoadingCampaignPerformanceData ||
    isLoadingCampaignSalesData ||
    isLoadingDetails;

  return (
    <LoadingScreen isLoading={isLoadingScreen}>
      <Box>
        <Box paddingX={"100px"} paddingY="20px">
          <BreadcrumbBase
            items={[
              {
                path: appPaths.campaigns.whatsapp.index(),
                name: "Campanhas",
              },
              {
                path: appPaths.campaigns.whatsapp.details(campaignId || ""),
                name: details?.template_name || "",
              },
            ]}
          />
          <Flex justify="space-between">
            <Heading>Engajamento da campanha</Heading>
            <Button leftIcon={<MdOutlineFilterAlt />} onClick={onOpenDrawer}>
              Ver segmentação
            </Button>
          </Flex>
          <Flex gap={3} alignItems="baseline">
            <Text color={colors.darkGrey}></Text>
          </Flex>
          <CustomECharts
            chartWidth={"100%"}
            chartHeight="450px"
            marginTop={"50px"}
            topRightComponent={
              <Select
                defaultValue={chartTimeUnit}
                onChange={(e) => setChartTimeUnit(e.target.value as TimeUnit)}>
                <option value="hour">Por hora</option>
                <option value="day">Por dia</option>
              </Select>
            }
            option={{
              legend: {},
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 40,
                },
              ],
              tooltip: {},
              color: [
                colors.primaryLight,
                colors.green,
                colors.blueTurquose,
                colors.purple,
              ],
              dataset: {
                dimensions: [
                  "datetime",
                  "read_count",
                  "clicked_count",
                  "replied_count",
                  "total_orders_value",
                ],
                source: campaignPerformanceData,
              },
              xAxis: {
                type: "category",
              },
              yAxis: [
                {
                  name: "Mensagens",
                  nameLocation: "middle",
                  splitLine: { show: false },
                  nameGap: 55,
                },
                {
                  name: "Total em Vendas (R$)",
                  nameLocation: "middle",
                  nameGap: 55,
                  splitLine: { show: false },
                },
              ],
              series: [
                { type: "bar", name: "Lidas", yAxisIndex: 0 },
                { type: "bar", name: "Cliques", yAxisIndex: 0 },
                { type: "bar", name: "Respostas", yAxisIndex: 0 },
                {
                  type: "line",
                  name: "Total em vendas",
                  yAxisIndex: 1,
                  tooltip: {
                    trigger: "item",
                    formatter: function (param: any) {
                      return `<strong>Total em vendas:</strong>  ${MoneyUtils.formatCurrency(
                        param.value.total_orders_value * 100
                      )} <br/><strong>Total de pedidos: </strong> ${
                        param.value.order_count
                      }`;
                    },
                  },
                },
              ],
            }}
          />
          <Flex justifyContent="space-between" gap={3} mt={5}>
            {[
              {
                icon: <BsPeopleFill />,
                title: "Disparos",
                value: details?.total_recipients || "",
                bgIconColor: colors.secondary,
                tooltip: "Quantidade de Disparos",
              },
              {
                icon: <BsFillEyeFill />,
                title: "Lidas",
                value: details?.read,
                bgIconColor: colors.primaryLight,
                tooltip: "Quantidade de mensagens lidas",
              },
              {
                icon: <HiCursorClick />,
                title: "Cliques",
                value: details?.clicks,
                bgIconColor: colors.green,
                tooltip: "Quantidade de cliques no link da campanha",
              },
              {
                icon: <BiMessageAltCheck />,
                title: "Respostas",
                value: details?.replies,
                bgIconColor: colors.blueTurquose,
                tooltip: "Quantidade de respostas recebidas",
              },
              {
                icon: <BiSolidMessageX />,
                title: "Bounce Rate",
                value: NumberUtils.getPercentValue(
                  details?.failed,
                  details?.total_recipients
                ),
                bgIconColor: colors.red,
                tooltip: "Percentual de mensagens que não foram entregues",
              },
            ].map(({ icon, title, value, bgIconColor, tooltip }) => (
              <CardStatistic
                value={value}
                key={title}
                icon={icon}
                title={title}
                bgIconColor={bgIconColor}
                tooltip={tooltip}
              />
            ))}
          </Flex>

          <Heading marginTop={"50px"}>
            Performance de vendas{" "}
            <Tooltip
              label={
                "Estimativa considerando compras realizadas até 7 dias após a campanha"
              }>
              <QuestionIcon boxSize={3} />
            </Tooltip>
          </Heading>
          <CustomECharts
            chartWidth={"100%"}
            chartHeight="450px"
            topRightComponent={
              <Select
                defaultValue={funnelMetric}
                onChange={(e) =>
                  setFunnelMetric(e.target.value as FunnelMetric)
                }>
                <option value="totalOrdersValue">Total em vendas</option>
                <option value="totalOrders">Total de pedidos</option>
                <option value="roi">ROI</option>
              </Select>
            }
            option={{
              title: {
                text: "ENGAJOU = CLICOU OU RESPONDEU",
                borderColor: "#d0d0d0",
                borderWidth: 1,
                textStyle: {
                  fontSize: 10,
                  color: "#d0d0d0",
                },
                left: "80%",
                top: "90%",
              },
              color: [colors.orange, colors.primaryLight, colors.green],
              series: [
                {
                  type: "funnel",
                  name: "Outer",
                  width: "80%",
                  label: {
                    show: true,
                    fontSize: 14,
                  },
                  labelLine: {
                    length: 15,
                    lineStyle: {
                      width: 2,
                      type: "solid",
                    },
                  },
                  itemStyle: {
                    borderColor: "#fff",
                    borderWidth: 2,
                  },
                  emphasis: {
                    disabled: true,
                  },
                  data: [
                    {
                      value: campaignSalesData?.received[funnelMetric],
                      name: `RECEBEU A\nMENSAGEM (${campaignSalesData?.received.count})`,
                    },
                    {
                      value: campaignSalesData?.read[funnelMetric],
                      name: `LEU A MENSAGEM (${campaignSalesData?.read.count})`,
                    },
                    {
                      value: campaignSalesData?.engaged[funnelMetric],
                      name: `ENGAJOU COM A MENSAGEM (${campaignSalesData?.engaged.count})`,
                    },
                  ],
                },
                {
                  type: "funnel",
                  name: "Inner",
                  width: "80%",
                  label: {
                    show: true,
                    position: "inside",
                    fontSize: 14,
                  },
                  labelLine: {
                    length: 20,
                    lineStyle: {
                      width: 2,
                      type: "solid",
                    },
                  },
                  itemStyle: {
                    borderColor: "#fff",
                    borderWidth: 2,
                  },
                  emphasis: {
                    label: {
                      fontSize: 20,
                    },
                  },
                  data: [
                    {
                      value: campaignSalesData?.received[funnelMetric],
                      name: ChartUtils.getFormattedFunnelValue(
                        campaignSalesData?.received[funnelMetric],
                        funnelMetric
                      ),
                    },
                    {
                      value: campaignSalesData?.read[funnelMetric],
                      name: ` ${ChartUtils.getFormattedFunnelValue(
                        campaignSalesData?.read[funnelMetric],
                        funnelMetric
                      )} `, // blank space added to have different color on chart
                    },
                    {
                      value: campaignSalesData?.engaged[funnelMetric],
                      name: `  ${ChartUtils.getFormattedFunnelValue(
                        campaignSalesData?.engaged[funnelMetric],
                        funnelMetric
                      )}  `, // blank space added to have different color on chart
                    },
                  ],
                },
              ],
            }}
          />
          <Flex justifyContent="space-between" gap={3} mt={5}>
            {[
              {
                icon: <MdAttachMoney />,
                title: "Total em compras",
                value: ChartUtils.getFormattedFunnelValue(
                  campaignSalesData?.received.totalOrdersValue,
                  "totalOrdersValue"
                ),
                bgIconColor: colors.greenMedium,
                tooltip:
                  "Total em Compras de Clientes que Receberam a Mensagem",
              },
              {
                icon: <GiPayMoney />,
                title: "Custo da campanha",
                value: MoneyUtils.formatCurrency(
                  campaignSalesData?.campaignCost || 0
                ),
                bgIconColor: colors.danger,
                tooltip: "Total de Mensagens Entregues * Custo por Mensagem",
              },
              {
                icon: <AiOutlineAreaChart />,
                title: "ROI",
                value: ChartUtils.getFormattedFunnelValue(
                  campaignSalesData?.received.roi,
                  "roi"
                ),
                bgIconColor: colors.primaryMedium,
                tooltip:
                  "Total em Vendas / (Total de Mensagens Entregues * Custo por Mensagem)",
              },
              {
                icon: <PiArrowsClockwiseBold />,
                title: "Engajamento",
                value: `${(
                  (100 * (campaignSalesData?.engaged.count || 0)) /
                  (campaignSalesData?.received.count || 1)
                ).toFixed(1)}%`,
                bgIconColor: colors.green,
                tooltip:
                  "Engajamento = (Cliques + Respostas) / Total de Mensagens Entregues",
              },
            ].map(({ icon, title, value, bgIconColor, tooltip }) => (
              <CardStatistic
                value={value}
                key={title}
                icon={icon}
                title={title}
                bgIconColor={bgIconColor}
                tooltip={tooltip}
              />
            ))}
          </Flex>
        </Box>
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          onClose={onCloseDrawer}
          size={"xl"}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Detalhes da campanha</DrawerHeader>

            <DrawerBody>
              <Grid
                height="auto"
                templateColumns="2fr 2fr"
                paddingTop="50px"
                paddingBottom="50px"
                paddingX="2px"
                alignItems="start"
                gap={4}>
                <Grid>
                  <GridItem>
                    <Text fontWeight="bold">Segmentação utilizada</Text>
                  </GridItem>
                  <GridItem>
                    <Box mt={4}>
                      <Flex justifyContent="space-between">
                        <Flex gap={2}>
                          <Button
                            size="xs"
                            leftIcon={<FaUsers />}
                            onClick={() => {
                              navigate({
                                pathname: appPaths.customers.index(),
                                search: details?.filter_criteria.replace(
                                  /excludedTemplateIds=.+?(?=&|$)/g,
                                  ""
                                ),
                              });
                            }}>
                            Aplicar segmentação
                          </Button>
                          <Button
                            size="xs"
                            leftIcon={<FaRegCopy />}
                            onClick={() => {
                              copyToClipboard(
                                transformedFilterCriteria
                                  .map(
                                    (filter) =>
                                      `${filter.title}: ${filter.value}`
                                  )
                                  .join(";\n")
                              );
                            }}>
                            Copiar filtros
                          </Button>
                        </Flex>
                      </Flex>
                      <TableFilters
                        filterCriteria={details?.filter_criteria || null}
                      />
                    </Box>
                  </GridItem>
                </Grid>
                <GridItem position={"sticky"} top={0} alignSelf="start">
                  <WhatsappTemplatePreview
                    message={currentTemplate?.templateText}
                    fileUrl={currentTemplate?.mediaUrl}
                    buttons={getButtons()}
                    footer={currentTemplate?.footerText}
                    messageTemplateCards={currentTemplate?.messageTemplateCards}
                  />
                </GridItem>
              </Grid>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </LoadingScreen>
  );
};

export default WhatsappCampaignDetailsPage;
