import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import campaignCreationSlice from "./campaignCreationSlice";
import globalSlice from "./globalSlice";
import inboxSlice from "./inboxSlice";

const reducer = combineReducers({
  auth: authSlice,
  global: globalSlice,
  inbox: inboxSlice,
  campaignCreation: campaignCreationSlice
});

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
