import {
  FormControl,
  Input,
  Switch
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { FiShoppingCart } from "react-icons/fi";
import { useMutation } from "react-query";
import FormLabel from "../../../../../components/FormLabel";
import InputRangeNumber from "../../../../../components/InputRangeNumber";
import { SelectOption } from "../../../../../components/InputSelect";
import InputSelectAsync from "../../../../../components/InputSelectAsync";
import { queryStringDelimiter } from "../../../../../constants/query-string-delimiter";
import { useCustomerSearchParams } from "../../../../../hooks/useCustomerSearchParams";
import {
  ListProductsDto,
  ProductsService
} from "../../../../../services/products.service";
import AccordionItemLayout from "../AccordionItemLayout";

interface SectionProductsProps {
  useFormReturn: UseFormReturn<any>;
  updateSelectedValues: (args: any) => void;
}

const SectionProducts = ({
  useFormReturn,
  updateSelectedValues,
}: SectionProductsProps) => {
  const { control, register, setValue } = useFormReturn;
  const { selectedProductIds, excludedProductIds, isLastProductPurchased } =
    useCustomerSearchParams();
  const [defaultProductOptions, setDefaultProductOptions] = useState<
    SelectOption[]
  >([]);

  const fetchProductsMutation = useMutation(
    async (listProductsDto: ListProductsDto) => {
      const { data } = await ProductsService.listProducts(listProductsDto);
      return data;
    },
    {
      onSuccess: (data) => {
        const options = data.map((product) => ({
          value: product.id,
          label: product.name,
        }));
        setDefaultProductOptions(options);
      },
    }
  );

  useEffect(() => {
    fetchProductsMutation.mutate({});
  }, [])

  async function updateSelectProducts() {
    let productIds = [
      ...selectedProductIds.split(queryStringDelimiter),
      ...excludedProductIds.split(queryStringDelimiter),
    ];

    const products = await fetchProductsMutation.mutateAsync({
      productIds,
    });

    updateSelectedValues({
      selectedValues: selectedProductIds,
      sourceData: products,
      valueToSet: "selectedProductIds",
      optionValue: "id",
      optionLabel: "name",
    });
    updateSelectedValues({
      selectedValues: excludedProductIds,
      sourceData: products,
      valueToSet: "excludedProductIds",
      optionValue: "id",
      optionLabel: "name",
    });
  };

  useEffect(() => {
    if (selectedProductIds.length > 0 || excludedProductIds.length > 0) {
      updateSelectProducts();
    }
    setValue("isLastProductPurchased", isLastProductPurchased === "true");
  }, [excludedProductIds, isLastProductPurchased, selectedProductIds, setValue, updateSelectedValues]);

  async function loadProductOptions(
    inputValue: string
  ): Promise<SelectOption[]> {
    const products = await fetchProductsMutation.mutateAsync({
      search: inputValue,
    });
    return products.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }

  return (
    <AccordionItemLayout title="Produtos" icon={<FiShoppingCart size="18px" />}>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Mostrar clientes que compraram o nome do produto comprado contém a palavra">
          Nome do produto comprado contém
        </FormLabel>
        <Input
          size={"sm"}
          bg="white"
          {...register("productNameContains")}
          placeholder="Palavra chave"
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Mostrar clientes que compraram um dos produtos">
          Comprou um dos produtos
        </FormLabel>
        <Controller
          name="selectedProductIds"
          control={control}
          render={({ field }) => (
            <InputSelectAsync
              defaultOptions={defaultProductOptions}
              isMulti
              cacheOptions={true}
              loadOptions={loadProductOptions}
              placeholder="Selecionar produtos"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
              onMenuClose={() => fetchProductsMutation.mutate({})}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel size="sm" tooltip="Considerar apenas última compra">
          Apenas última compra?
        </FormLabel>
        <Controller
          control={control}
          name="isLastProductPurchased"
          render={({ field: { onChange, onBlur, value } }) => (
            <Switch
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              onBlur={onBlur}
              isChecked={value}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Quantidade comprada dos produtos selecionados">
          Quantidade comprada
        </FormLabel>
        <Controller
          name="productQuantity"
          control={control}
          defaultValue={{ minValue: 0, maxValue: 0 }}
          render={({ field }) => (
            <InputRangeNumber
              size={"sm"}
              rightAddon="UN"
              onChangeMinValue={(value) =>
                field.onChange({ ...field.value, minValue: value })
              }
              onChangeMaxValue={(value) =>
                field.onChange({ ...field.value, maxValue: value })
              }
              minValue={field.value.minValue}
              maxValue={field.value.maxValue}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Dias desde a última compra dos produtos selecionados">
          Dias desde a última compra do produto
        </FormLabel>
        <Controller
          name="daysSinceLastProductPurchase"
          control={control}
          defaultValue={{ minValue: 0, maxValue: 0 }}
          render={({ field }) => (
            <InputRangeNumber
              size={"sm"}
              rightAddon="Dias"
              onChangeMinValue={(value) =>
                field.onChange({ ...field.value, minValue: value })
              }
              onChangeMaxValue={(value) =>
                field.onChange({ ...field.value, maxValue: value })
              }
              minValue={field.value.minValue}
              maxValue={field.value.maxValue}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Ocultar clientes que compraram um dos produtos">
          Não comprou um dos produtos
        </FormLabel>
        <Controller
          name="excludedProductIds"
          control={control}
          render={({ field }) => (
            <InputSelectAsync
              defaultOptions={defaultProductOptions}
              isMulti
              cacheOptions={true}
              loadOptions={loadProductOptions}
              placeholder="Selecionar produtos"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
              onMenuClose={() => fetchProductsMutation.mutate({})}
            />
          )}
        />
      </FormControl>
    </AccordionItemLayout>
  );
};

export default SectionProducts;
