import { MessageTemplateTypeEnum } from "../types/MessageTemplateType";
import { TemplateParametersEnum } from "../types/TemplateParametersEnum";

const templateParameters: Record<string, string[]> = {
  [MessageTemplateTypeEnum.ABANDONED_CART]: [TemplateParametersEnum.CUSTOMER_NAME],
  [MessageTemplateTypeEnum.NEW_ORDER]: [TemplateParametersEnum.CUSTOMER_NAME],
  [MessageTemplateTypeEnum.TRACKING_CODE]: [
    TemplateParametersEnum.CUSTOMER_NAME,
    TemplateParametersEnum.TRACKING_CODE,
  ],
  [MessageTemplateTypeEnum.ORDER_PAYMENT_CONFIRMATION]: [
    TemplateParametersEnum.CUSTOMER_NAME,
  ],
  [MessageTemplateTypeEnum.WELCOME_REGISTRATION]: [
    TemplateParametersEnum.CUSTOMER_NAME,
  ],
};

export const TemplateParametersUtils = { templateParameters };
