import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { FileUtils } from "../utils/file-utils";

const getSignedUrl = (
  fileKey: string
): Promise<AxiosResponse<{ url: string }>> => {
  return request.post(apiRoutes.getSignedUrl(), { fileKey });
};

const fetchFileByFileKey = async (fileKey: string): Promise<File> => {
  const {
    data: { url },
  } = await getSignedUrl(fileKey);
  return FileUtils.fetchFile(url);
};

export const FilesService = {
  getSignedUrl,
  fetchFileByFileKey,
};
