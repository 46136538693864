import { DrawerBody, Flex, FormControl, FormLabel, Text, useToast } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { SaveCustomerResponseNodeData } from "../../../../../../../types/ReactFlowNode";
import * as yup from "yup";
import InputSelect from "../../../../../../../components/InputSelect";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CompanyDefinedField, CompanyDefinedFieldDataTypeEnum, CompanyDefinedFieldTableEnum } from "../../../../../../../types/CompanyDefinedField";
import { useState } from "react";
import { CompaniesService, CreateCompanyDefinedFieldDto } from "../../../../../../../services/companies.service";
import { apiRoutes } from "../../../../../../../constants/api-routes";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  companyDefinedField: yup
    .object({
      label: yup.string().required("O nome do campo é obrigatório"),
      value: yup.string().required("O valor do campo é obrigatório"),
    })
    .required("Campo customizado é obrigatório"),
});

export interface SaveCustomerResponseNodeEditorProps {
  data: SaveCustomerResponseNodeData
  formId: string;
  onSaveNode: (data: any) => void;
}

const SaveCustomerResponseNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: SaveCustomerResponseNodeEditorProps) => {
  const [companyDefinedFieldOptions, setCompanyDefinedFieldOptions] = useState<{
    value: string;
    label: string;
  }[]>([]);

  const { handleSubmit, control, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();
  const toast = useToast();

  const { companyDefinedFieldId } = data;

  useQuery(apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS), async () => {
    const { data } = await CompaniesService.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS);
    return data;
  },
  {
    onSuccess: (data) => {
      const companyDefinedFieldOptions = data.map((companyDefinedField: CompanyDefinedField) => ({
        value: companyDefinedField.id,
        label: companyDefinedField.name,
      }));
      setCompanyDefinedFieldOptions(companyDefinedFieldOptions);

      const companyDefinedField = companyDefinedFieldOptions.find((companyDefinedField) => companyDefinedField.value === companyDefinedFieldId);
      if (companyDefinedField) {
        setValue("companyDefinedField", companyDefinedField);
      }
    },
  });


  const createCompanyDefinedFieldMutation = useMutation(
    async (data: CreateCompanyDefinedFieldDto) => {
      const response = await CompaniesService.createCompanyDefinedField(data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const newCompanyDefinedFieldOption = { value: data.id, label: data.name };
        setCompanyDefinedFieldOptions((prev) => [...prev, newCompanyDefinedFieldOption]);
        setValue("companyDefinedField", newCompanyDefinedFieldOption);
      },
      onError: (error) => {
        toast({
          title: "Erro ao criar campo customizado",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  );

  const handleCreateCompanyDefinedField = (name: string) => {
    createCompanyDefinedFieldMutation.mutateAsync({
      name: name,
      dataType: CompanyDefinedFieldDataTypeEnum.STRING,
      table: CompanyDefinedFieldTableEnum.CUSTOMERS,
    });
  }

  const handleFormSubmit = (data: any) => {
    const companyDefinedFieldId = data.companyDefinedField.value;
    const newData = {
      companyDefinedFieldId,
    };

    onSaveNode(newData);

    queryClient.invalidateQueries(
      apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS)
    );
  }

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(handleFormSubmit)} id={formId}>
        <Flex direction="column" gap={3}>
          <FormControl>
            <FormLabel>Nome do campo customizado</FormLabel>
            <Controller
              name="companyDefinedField"
              control={control}
              render={({ field }) => (
                <InputSelect
                  onCreateOption={handleCreateCompanyDefinedField}
                  placeholder="Selecione ou crie um campo"
                  options={companyDefinedFieldOptions}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
              />
              {errors.companyDefinedField && (
                <Text color="red" fontSize="xs">{errors.companyDefinedField.message}</Text>
              )}
          </FormControl>
          <Flex mb={4} alignItems="start">
            <AiOutlineInfoCircle style={{ marginTop: '4px' }} />
            <Text ml={2}>
              Salva a próxima resposta do cliente na tabela de clientes
            </Text>
          </Flex>
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default SaveCustomerResponseNodeEditor;
