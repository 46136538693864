import {
  FormControl,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { IoPersonOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import FormLabel from "../../../../../components/FormLabel";
import InputSelect, { SelectOption } from "../../../../../components/InputSelect";
import { useCustomerSearchParams } from "../../../../../hooks/useCustomerSearchParams";
import { CustomersService } from "../../../../../services/customers.service";
import AccordionItemLayout from "../AccordionItemLayout";

interface SectionCustomersProps {
  useFormReturn: UseFormReturn<any>;
  updateSelectedValues: (args: any) => void;
}

const SectionCustomers = ({
  useFormReturn,
  updateSelectedValues,
}: SectionCustomersProps) => {
  const { control } = useFormReturn;
  const { selectedStates } = useCustomerSearchParams();
  const [defaultStateOptions, setDefaultStateOptions] = useState<
    SelectOption[]
  >([]);

  const { data: statesData } = useQuery(
    "fetchStates",
    async () => {
      const { data } = await CustomersService.listCustomerStates();
      return data.states.map((state) => ({
        value: state,
        label: state,
      }));
    },
    {
      onSuccess: (data) => {
        setDefaultStateOptions(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    async function updateSelectStates() {
      const states = statesData || [];
      updateSelectedValues({
        selectedValues: selectedStates,
        sourceData: states,
        valueToSet: "selectedStates",
        optionValue: "value",
        optionLabel: "label",
      });
    }

    if (statesData) {
      updateSelectStates();
    }
  }, [selectedStates, statesData, updateSelectedValues]);

  return (
    <AccordionItemLayout title="Clientes" icon={<IoPersonOutline size="18px" />}>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Mostrar clientes que são de um dos estados">
          É de um dos estados
        </FormLabel>
        <Controller
          name="selectedStates"
          control={control}
          render={({ field }) => {
            return (
              <InputSelect
                options={defaultStateOptions}
                isMulti
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            );
          }}
        />
      </FormControl>
    </AccordionItemLayout>
  );
};

export default SectionCustomers;
