import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "react-query";
import FormLabel from "../../../components/FormLabel";
import { AuthService, UpdatePasswordDto } from "../../../services/auth.service";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../constants/colors";

const schema = yup
  .object({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required().min(8, "Mínimo de 8 caracteres"),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], "As senhas devem ser iguais"),
  })
  .required();

const AccountSettingsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const toast = useToast();
  const updatePassword = useMutation(
    (updatePasswordDto: UpdatePasswordDto) =>
      AuthService.updatePassword(updatePasswordDto),
    {
      onSuccess: (res) => {
        toast({
          title: "Senha atualizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await updatePassword.mutateAsync(data);
  }

  return (
    <Box padding="20px">
      <Container>
        <Heading size="md" mb={5}>
          Alterar senha
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack gap={3}>
            <FormControl>
              <FormLabel>Senha atual</FormLabel>
              <Input type="password" {...register("currentPassword")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.currentPassword?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Senha nova</FormLabel>
              <Input type="password" {...register("newPassword")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.newPassword?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Confirmar senha nova</FormLabel>
              <Input type="password" {...register("confirmNewPassword")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.confirmNewPassword?.message}
              </Text>
            </FormControl>
            <Flex justify={"flex-end"} width='100%'>
              <Button
                width="30%"
                isLoading={false}
                color={colors.white}
                bgColor={colors.primary}
                type="submit">
                Salvar
              </Button>
            </Flex>
          </VStack>
        </form>
      </Container>
    </Box>
  );
};

export default AccountSettingsPage;
