import { CompanyDefinedFieldTableEnum } from '../types/CompanyDefinedField';
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";

const listCompanyDefinedFields = async (table: CompanyDefinedFieldTableEnum) => {
  return request.get(apiRoutes.listCompanyDefinedFields(table));
};

const deleteCompanyDefinedField = (companyDefinedFieldId: string) => {
  return request.delete(
    apiRoutes.deleteCompanyDefinedField(companyDefinedFieldId)
  );
};

export const CompanyDefinedFieldsService = {
  listCompanyDefinedFields,
  deleteCompanyDefinedField,
}

