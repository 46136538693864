import {
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  ThemingProps,
} from "@chakra-ui/react";

export interface InputNumberProps {
  size?: ThemingProps<"Input">["size"];
  value?: number;
  onChange?: (value: number) => void;
  leftAddon?: string;
  rightAddon?: string;
  maxWidth?: any;
  name?: string;
  defaultValue?: number;
  displayZero?: boolean;
}

const InputNumber = ({
  size,
  value,
  onChange,
  leftAddon,
  rightAddon,
  maxWidth,
  name,
  defaultValue,
  displayZero = false,
}: InputNumberProps) => {
  return (
    <InputGroup
      size={size}
      display="flex"
      justifyContent={"center"}
      maxWidth={maxWidth}>
      {leftAddon && <InputLeftAddon children={leftAddon} />}
      <NumberInput
        name={name}
        size={size}
        width="100%"
        bgColor="white"
        min={0}
        value={onChange ? (
          value ? value : displayZero ? 0 : ""
        ) : undefined}
        defaultValue={defaultValue}
      >
        <NumberInputField
          padding={"2px"}
          onChange={(e: any) => onChange ? onChange(Number(e.target.value)) : null}
        />
      </NumberInput>
      {rightAddon && <InputRightAddon children={rightAddon} />}
    </InputGroup>
  );
};

export default InputNumber;
