import { colors } from "../constants/colors";

export const scrollbarStyles = (width: string="0") => ({
  "&::-webkit-scrollbar": {
    width,
  },
  "&::-webkit-scrollbar-track": {
    background: colors.white,
  },
  "&::-webkit-scrollbar-thumb": {
    background: colors.darkGrey,
    borderRadius: "24px",
  },
});
