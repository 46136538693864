import { MessageTemplate } from "../types/MessageTemplate";
import {
  TemplateParametersEnum,
  TemplateParametersList,
} from "../types/TemplateParametersEnum";

const TemplateParametersRegexpString =
  "(" +
  TemplateParametersList.map((el) => {
    return el
      .replaceAll("[", "\\[")
      .replaceAll("]", "\\]")
      .replaceAll(" ", "\\s");
  }).join("|") +
  ")";

const TemplateParametersRegexp = new RegExp(
  TemplateParametersRegexpString,
  "g"
);

function getAllParametersInText(
  templateText: string
): string[] {
  const parametersRegex = /\[.*?\]/g;
  const parametersArray = templateText.match(parametersRegex) as string[];

  return [...new Set(parametersArray)];
}

function getDefaultParametersInText(
  templateText: string
): TemplateParametersEnum[] {
  const parametersArray = templateText.match(
    TemplateParametersRegexp
  ) as string[];

  return [...new Set(parametersArray)] as TemplateParametersEnum[];
}

function getCustomParametersInText(templateText: string) {
  const parametersArray = getAllParametersInText(templateText);
  return [...new Set(parametersArray)].filter((el: any) => {
    return !TemplateParametersList.filter(param => param !== TemplateParametersEnum.CUSTOM_TEXT).includes(el as TemplateParametersEnum);
  });
}

function isValidTemplateArgs(
  templateArgs: Record<string, string>,
  templateText?: string,
): boolean {
  if (!templateText) {
    return false;
  }
  const customParameters = getCustomParametersInText(templateText);
  const customParametersCount = customParameters.filter(Boolean).length;
  const templateArgsCount = Object.values(templateArgs).filter(Boolean).length;

  return customParametersCount === templateArgsCount;
}

export const MessageTemplateUtils = {
  getDefaultParametersInText,
  getCustomParametersInText,
  getAllParametersInText,
  isValidTemplateArgs,
};
