import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  Table,
  TableContainer,
  Tbody,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ImageDropzone from "../../../../components/ImageDropzone";
import LoadingScreen from "../../../../components/LoadingScreen";
import WhatsappTemplatePreview from "../../../../components/WhatsappTemplatePreview";
import { apiRoutes } from "../../../../constants/api-routes";
import useFileDropzone from "../../../../hooks/useFileDropzone";
import {
  Button as TemplateButton,
  MessageTemplatesService,
  UpdateMessageTemplateDto,
} from "../../../../services/message-templates.service";
import { ButtonType } from "../../../../types/ButtonTypeEnum";
import {
  MessageTemplate,
  MessageTemplateWithIncludes,
} from "../../../../types/MessageTemplate";
import MessageTemplateStatusEnum from "../../../../types/MessageTemplateStatusEnum";
import { useNavigate } from "react-router-dom";
import { UrlUtils } from "../../../../utils/url.utils";
import Row from "./Row";

const TableMessageTemplates = ({
  status,
}: {
  status?: MessageTemplateStatusEnum;
}) => {
  const {
    isOpen: isEditTemplateDrawerOpen,
    onOpen: onOpenEditTemplateDrawer,
    onClose: onCloseEditTemplateDrawer,
  } = useDisclosure();
  const [selectedTemplate, setSelectedTemplate] =
    useState<MessageTemplateWithIncludes | null>(null);
  const { dropzone, preview, file, clear: clearFile } = useFileDropzone();
  const queryClient = useQueryClient();
  const [ctaLink, setCtaLink] = useState<string>(
    selectedTemplate?.ctaLink ?? ""
  );
  const toast = useToast();
  const { data: templates, isLoading: isLoadingTemplates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return status
          ? data.filter(
              (template) =>
                template.status === status ||
                (status === MessageTemplateStatusEnum.deleted &&
                  template.isDeleted)
            )
          : data;
      },
    }
  );

  const updateMessageTemplate = useMutation(
    (updateMessageTemplateDto: UpdateMessageTemplateDto) =>
      MessageTemplatesService.updateMessageTemplate(updateMessageTemplateDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiRoutes.listMessageTemplates());
        handleCloseEditTemplateDrawer();
        toast({
          title: "Template atualizado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const navigate = useNavigate();

  function handleCloseEditTemplateDrawer() {
    setSelectedTemplate(null);
    setCtaLink("");
    clearFile();
    onCloseEditTemplateDrawer();
  }

  function handleClickedEdit(template: MessageTemplate) {
    setSelectedTemplate(template);
    setCtaLink(template?.ctaLink || "");
    onOpenEditTemplateDrawer();
  }

  function handleCloneClick(template: MessageTemplate) {
    navigate(`novo?baseTemplateId=${template.id}`);
  }

  function validateCtaLink() {
    const showErrorMessage = (errMessage: string) =>
      toast({
        title: errMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    if (ctaLink === "") {
      showErrorMessage("URL é obrigatória");
      return false;
    }
    if (!UrlUtils.isValidUrl(ctaLink)) {
      showErrorMessage("URL inválida. (Ex: https://www.google.com)");
      return false;
    }
    return true;
  }

  async function handleClickSave() {
    if (selectedTemplate) {
      if (
        !["", null].includes(selectedTemplate.ctaLink) &&
        !validateCtaLink()
      ) {
        return;
      }
      await updateMessageTemplate.mutateAsync({
        id: selectedTemplate.id,
        ctaLink: ctaLink,
        file,
      });
    }
  }

  function getButtons(): TemplateButton[] | undefined {
    let res = selectedTemplate?.messageTemplateButtons;

    if (!res && selectedTemplate?.ctaLink) {
      res = [
        {
          text: "",
          type: ButtonType.URL,
          url: selectedTemplate.ctaLink,
        },
      ];
    }

    return res;
  }

  function getRows(): JSX.Element[] {
    return templates
      ? templates.map((template, index) => {
          return (
            <Row
              key={template.id}
              template={template}
              onClickEditTemplate={handleClickedEdit}
              onClickCloneTemplate={handleCloneClick}
            />
          );
        })
      : [];
  }

  return (
    <LoadingScreen isLoading={isLoadingTemplates}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Corpo da mensagem</Th>
              <Th>Tipo de Template</Th>
              <Th>Status</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>{getRows()}</Tbody>
        </Table>
      </TableContainer>
      <Drawer
        size="xl"
        isOpen={isEditTemplateDrawerOpen}
        placement="right"
        onClose={handleCloseEditTemplateDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Editar template</DrawerHeader>

          <DrawerBody>
            <Grid
              height="auto"
              templateColumns="2fr 2fr"
              paddingTop="50px"
              paddingBottom="50px"
              paddingX="2px"
              alignItems="start"
              gap={4}>
              <GridItem>
                {selectedTemplate && (
                  <VStack gap={1}>
                    <FormControl>
                      <FormLabel>Template</FormLabel>
                      <Input
                        type="text"
                        value={selectedTemplate.name}
                        disabled
                      />
                    </FormControl>

                    {selectedTemplate.mediaUrl && (
                      <FormControl>
                        <FormLabel>Mídia</FormLabel>
                        {selectedTemplate.mediaType === "image" ? (
                          <ImageDropzone
                            dropzone={dropzone}
                            preview={
                              preview
                                ? (preview as string)
                                : selectedTemplate.mediaUrl
                            }
                          />
                        ) : (
                          <Link
                            href={selectedTemplate.mediaUrl}
                            target="_blank">
                            Ver arquivo
                          </Link>
                        )}
                      </FormControl>
                    )}
                    <FormControl>
                      <FormLabel>Mensagem</FormLabel>
                      <Textarea
                        value={selectedTemplate?.templateText}
                        disabled
                        minH={"200px"}
                      />
                    </FormControl>
                    {selectedTemplate?.ctaLink && (
                      <FormControl>
                        <FormLabel>Link do botão</FormLabel>
                        <Input
                          type="text"
                          value={ctaLink}
                          onChange={(e) => setCtaLink(e.target.value)}
                        />
                      </FormControl>
                    )}
                  </VStack>
                )}
              </GridItem>
              <GridItem position={"sticky"} top={0} alignSelf="start">
                <WhatsappTemplatePreview
                  message={selectedTemplate?.templateText}
                  fileUrl={selectedTemplate?.mediaUrl}
                  file={file}
                  buttons={getButtons()}
                  footer={selectedTemplate?.footerText}
                  messageTemplateCards={selectedTemplate?.messageTemplateCards}
                />
              </GridItem>
            </Grid>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={handleCloseEditTemplateDrawer}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleClickSave}
              isLoading={updateMessageTemplate.isLoading}>
              Salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </LoadingScreen>
  );
};

export default TableMessageTemplates;
