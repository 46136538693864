import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { FaMagic } from "react-icons/fa";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { RecommendationsService } from "../../../../services/recommendations.service";
import { Recommendation } from "../../../../types/Recommendation";
import CardRecommendation from "./CardRecommendation";

const SectionRecommendations = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useBoolean(false);
  const {
    data: recommendations = [],
    isFetching: isFetchingRecommendations,
    isFetched: isFetchedRecommendations,
    refetch: refetchRecommendations,
  } = useQuery(apiRoutes.listCompanyRecommendations(), async () => {
    const { data } = await RecommendationsService.listCompanyRecommendations();
    return data;
  }, {
    enabled: false,
    onSettled: () => {
      setShowSpinner.off();
    }
  });

  function handleClickRecommendation(recommendation: Recommendation) {
    if (recommendation.type === "create_campaign") {
      navigate(
        `${appPaths.customers.index()}?${recommendation.data.filterCriteria}`
      );
    } else if (recommendation.type === "create_automation") {
      navigate(appPaths.automations.backgroundAutomations.createAutomation());
    }
  }

  function handleClickFechRecommendations() {
    setShowSpinner.on();
    setTimeout(() => {
      refetchRecommendations();
    }, 5000);
  }

  return (
    <Card height="100%">
      <CardHeader>
        <Heading size="md">Nossas recomendações</Heading>
      </CardHeader>
      <CardBody display="flex" flexDir={"column"}>
        <Box maxHeight="400px" overflow="scroll">
          {showSpinner || isFetchingRecommendations ? (
            <Center height="100%">
              <Spinner />
            </Center>
          ) : !isFetchedRecommendations ? (
            <Flex flexDir='column' justifyContent='center' alignItems='center' gap={4}>
              <Text textAlign={'center'}>Utilize a inteligência da Revi para gerar recomendações personalizadas para a sua empresa</Text>
              <Box>
                <Button leftIcon={<FaMagic />} onClick={handleClickFechRecommendations}>Gerar recomendações</Button>
              </Box>
            </Flex>
          ) : recommendations.length === 0 ? (
            <Box>Não há nenhuma recomendação :(. Nossa <strong>Inteligência Artificial</strong> ainda está aprendendo sobre os seus dados</Box>
          ) : (
            recommendations.map((recommendation, index) => (
              <CardRecommendation
                recommendation={recommendation}
                onClickRecommendation={handleClickRecommendation}
              />
            ))
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export default SectionRecommendations;
