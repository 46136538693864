import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {patchEmojiData} from "./utils";
import {EmojiMartData} from "@emoji-mart/data";

export interface EmojiDataSetContextData {
  data: MutableRefObject<EmojiMartData | undefined>;
  isPatched: boolean;
  locale: string;
}

export const EmojiDataSetContext = createContext<EmojiDataSetContextData>(
  {} as EmojiDataSetContextData
);

interface EmojiDataSetProviderProps {
  locale?: string;
}

const EmojiDataSetProvider = ({
  locale = "en",
  children,
}: PropsWithChildren<EmojiDataSetProviderProps>): JSX.Element => {
  const dataRef = useRef<EmojiMartData>();
  const [isPatched, setIsPatched] = useState<boolean>(false);

  const patch = useCallback(async () => {
    dataRef.current = await patchEmojiData(locale);
    setIsPatched(true);
  }, [locale]);

  useEffect(() => {
    patch();
  }, [patch]);

  return (
    <EmojiDataSetContext.Provider value={{data: dataRef, isPatched, locale}}>
      {children}
    </EmojiDataSetContext.Provider>
  );
};

export default EmojiDataSetProvider;
