import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Link,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaShopify } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { apiRoutes } from "../../../constants/api-routes";
import { BlingService } from "../../../services/bling.service";
import { ShopifyService } from "../../../services/shopify.service";

const IntegrationSettingsPage = () => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: blingAuthUrl } = useQuery(
    apiRoutes.getBlingAuthUrl(),
    async () => {
      const { data } = await BlingService.getBlingAuthUrl();
      return data.url;
    }
  );

  const syncShopifyOrders = useMutation(
    () => ShopifyService.syncShopifyOrders(),
    {
      onSuccess: () => {
        toast({
          title: "Sincronização com Shopify realizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    const authStatus = searchParams.get("authStatus");
    if (authStatus === "success") {
      toast({
        title: "Autenticação realizada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      searchParams.delete("authStatus");
      setSearchParams(searchParams);
    } else if (authStatus === "error") {
      toast({
        title: "Erro ao autenticar",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      searchParams.delete("authStatus");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, toast]);

  return (
    <Box padding="20px">
      <Container display="flex" flexDirection={"column"} gap={3}>
        <Heading size="md">Shopify</Heading>
        <Button
          onClick={() => syncShopifyOrders.mutateAsync()}
          isLoading={syncShopifyOrders.isLoading}
          leftIcon={<FaShopify />}
          width="fit-content">
          Sincronizar pedidos da Shopify
        </Button>
        <Divider />
        <Heading size="md">Bling</Heading>
        <Button
          as='a'
          href={blingAuthUrl}
          width="fit-content">
          Conectar com bling
        </Button>
      </Container>
    </Box>
  );
};

export default IntegrationSettingsPage;
