import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CustomECharts from '../../../../components/CustomECharts';
import { apiRoutes } from '../../../../constants/api-routes';
import { StatisticsService } from '../../../../services/statistics.service';

const colorByWeekDay: Record<string, string> = {
  sunday: "#A3C1E9",
  monday: "#FFB07C",
  tuesday: "#98D6A5",
  wednesday: "#FF9999",
  thursday: "#C1A4E9",
  friday: "#A0E6EF",
  saturday: "#E5E39A",
};

const SectionsOrderByHourChart = () => {
  const { t } = useTranslation();
  const { data: ordersCountByWeekDayAndHour = [] } = useQuery(
    apiRoutes.getOrdersCountByWeekDayAndHour(),
    async () => {
      const { data } = await StatisticsService.getOrdersCountByWeekDayAndHour();
      return data;
    }
  );

  const ordersByWeekDayAndHour = ordersCountByWeekDayAndHour.map(
    (item: any) => [String(item.hour), item.weekDay, item.count]
  );
  const maxOrderCountByWeekdayAndHour = (
    ordersCountByWeekDayAndHour
  ).reduce((acc: number, item: any) => Math.max(acc, item.count), 1);

  function normalizeValue(value: number, maxValue: number = 50): number {
    return (value / maxOrderCountByWeekdayAndHour) * maxValue;
  }

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Seus horários com mais pedidos</Heading>
      </CardHeader>
      <CardBody>
        <CustomECharts
          option={{
            legend: {},
            tooltip: {
              position: "top",
              formatter: (params: any) => {
                const [hour, weekDay, count] = params.value;
                return `<strong>${t(
                  `weekdays.long.${weekDay}`
                )} às ${hour}:00</strong> - ${count} pedidos`;
              },
            },
            xAxis: {
              type: "category",
              data: [
                ...new Set(
                  ordersCountByWeekDayAndHour.map(
                    (item: any) => item.hour
                  )
                ),
              ],
              splitLine: {
                show: true,
              },
              axisLine: {
                show: false,
              },
              boundaryGap: false,
            },
            yAxis: {
              type: "category",
              axisLabel: {
                formatter: (value: string) => t(`weekdays.long.${value}`),
              },
              data: [
                "saturday",
                "friday",
                "thursday",
                "wednesday",
                "tuesday",
                "monday",
                "sunday",
              ],
              splitLine: {
                show: true,
              },
              axisLine: {
                show: false,
              },
              boundaryGap: false,
            },
            series: [
              {
                name: "Total de pedidos",
                type: "scatter",
                data: ordersByWeekDayAndHour,
                itemStyle: {
                  color: function (curr: any) {
                    return colorByWeekDay[curr.value[1] as string];
                  },
                },
                symbolSize: function (params: any) {
                  return normalizeValue(params[2]);
                },
                animationDelay: function (idx: any) {
                  return idx * 5;
                },
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  )
}

export default SectionsOrderByHourChart