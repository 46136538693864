import { FormControl, FormLabel, Input, Stack, Text, useDisclosure, useId, useToast } from "@chakra-ui/react";
import { createContext, ReactNode, useContext, ReactElement, useState, useEffect } from "react";
import CrudModal from "../components/CrudModal";
import useGetCrudText from "./useGetCrudText";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { colors } from "../constants/colors";
import { useMutation, useQueryClient } from "react-query";
import { apiRoutes } from "../constants/api-routes";
import { Tag } from "../types/Prisma";
import AlertDialogBase from "../components/AlertDialog";
import { CreateTagDto, TagsService, UpdateTagDto } from "../services/tags.service";

interface CrudTagModalContextData {
  openCreateModal: () => void;
  openEditModal: (tag: string) => void;
  openDeleteAlert: (tagId: string) => void;
}

interface CrudTagModalProps {
  children?: ReactNode;
}

const CrudTagModalContext = createContext<CrudTagModalContextData | undefined>(undefined);

const schema = yup.object({
  name: yup.string().required("O nome é obrigatório"),
});

export function CrudTagModalProvider({ children }: CrudTagModalProps): ReactElement {
  const {
    onOpen,
    onClose,
    isOpen,
  } = useDisclosure();
  const [tagId, setTagId] = useState<string | null>(null);
  const { crudActionText, crudTitle } = useGetCrudText(!tagId);
  const formId = useId();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();

  const openCreateModal = () => {
    setTagId(null);
    onOpen();
  };

  const openEditModal = (tagId: string) => {
    setTagId(tagId);
    onOpen();
  };

  const openDeleteAlert = (tagId: string) => {
    setTagId(tagId);
    onOpenAlert();
  };

  const handleOnCloseAlert = () => {
    resetState();
    onCloseAlert();
  }

  async function handleClickDelete(optionId: string) {
    deleteTag.mutate(optionId)
    resetState();
  }

  const createTag = useMutation(
    (tagData: CreateTagDto) =>
      TagsService.createTag(tagData),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(apiRoutes.listTags());
      }
    }
  )

  const updateTag = useMutation(
    (updateTagData: UpdateTagDto) =>
      TagsService.updateTag(updateTagData),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(apiRoutes.listTags());
      }
    }
  )

  const deleteTag = useMutation(
    (tagId: string) => TagsService.deleteTag(tagId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiRoutes.listTags());

        toast({
          title: "Tag deletada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      }
    }
  )

  useEffect(() => {
    if (!tagId) return;

    const cachedTag = queryClient.getQueryData<Tag[]>(apiRoutes.listTags());
    const currentTag = cachedTag?.find((tag) => tag.id === tagId);

    if (!currentTag) return;

    setValue("name", currentTag.name);
  }, [tagId, queryClient, setValue]);

  async function onSubmit(submitData: any) {
    try {
      if (!tagId) {
        await createTag.mutateAsync(submitData);
      } else {
        await updateTag.mutateAsync({ id: tagId, ...submitData });
      }
      toast({
        title: "Tag salva com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      resetState();
    } catch {
      toast({
        title: "Ocorreu um erro ao salvar a tag",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  };

  const handleCloseModal = () => {
    resetState();
  }

  const resetState =() => {
    onClose();
    onCloseAlert();
    setTagId(null);
    setValue("name", "");
  }

  return (
    <CrudTagModalContext.Provider value={{
      openCreateModal,
      openEditModal,
      openDeleteAlert,
    }}>
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={() => {
          handleOnCloseAlert();
        }}
        title="Deletar tag"
        onConfirm={() => {
          handleClickDelete(tagId!);
        }}>
        Tem certeza que deseja deletar esta resposta rápida?
      </AlertDialogBase>
      <CrudModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        actionButtonText={crudActionText}
        title={`${crudTitle} tag`}
        isLoading={false}
        formId={formId}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack spacing={2}>
            <FormControl isRequired>
              <FormLabel>Nome</FormLabel>
              <Input
                placeholder="Escreva o nome"
                {...register("name")}
                isInvalid={!!errors?.title?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.title?.message as string}
              </Text>
            </FormControl>
          </Stack>
        </form>
      </CrudModal>
      {children}
    </CrudTagModalContext.Provider>
  );
}

export function useCrudTagModal(): CrudTagModalContextData {
  const context = useContext(CrudTagModalContext);

  if (context === undefined) {
    throw new Error("useCrudTagModal must be used within a CrudTagModalProvider");
  }

  return context;
}
