import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiRoutes } from "../../../constants/api-routes";
import { FlowsService } from "../../../services/flows.service";
import { ReportsService } from "../../../services/reports.service";
import { format } from "date-fns";
import { colors } from "../../../constants/colors";
import { AiOutlineEdit, AiOutlineMessage } from "react-icons/ai";
import { TbTableExport } from "react-icons/tb";
import { appPaths } from "../../../constants/app-paths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomMenu from "../../../components/CustomMenu";
import { FaTrash } from "react-icons/fa";
import AlertDialogBase from "../../../components/AlertDialog";
import useDownloadFile from "../../../hooks/useDownloadFile";


const MessagesFlowPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { downloadExcelReport } = useDownloadFile();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [flowIdToDelete, setFlowIdToDelete] = useState<string | null>(null);

  const { data: flows } = useQuery(apiRoutes.listFlows(), async () => {
    const { data } = await FlowsService.listFlows();
    return data;
  });

  const createFlowMutation = useMutation(
    async () => {
      const { data } = await FlowsService.createFlow({
        title: "Sem título",
      });
      return data;
    },
    {
      onSuccess: (data) => {
        navigate(appPaths.automations.messageFlows.editMessageFlow(data.id));
      },
    }
  );

  const deleteFlowMutation = useMutation(
    async (flowId: string) => FlowsService.deleteFlow(flowId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiRoutes.listFlows());
        toast({
          title: "",
          description: "Fluxo deletado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsDeleteModalOpen(false);
      },
    }
  );

  async function handleExportFlowClicksReport(flowId: string) {
    const toastIdRef = toast({
      description: (
        <Box display="flex" alignItems="center">
          <Spinner size="sm" mr={2} />
          Gerando relatório
        </Box>
      ),
      status: "info",
      duration: null,
      isClosable: true,
    });

    try {
      const { data } = await ReportsService.exportFlowClicksReport(flowId);
      if (data) {
        downloadExcelReport(data, "report.xlsx")
        toast({
          title: "",
          description: "Relatório gerado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {}
    if (toastIdRef) {
      toast.close(toastIdRef);
    }
  }

  function buildMenu(flowId: string) {
    const itemsMenu = [
      {
        label: "Editar",
        icon: <AiOutlineEdit size={20} />,
        onClick: () => navigate(appPaths.automations.messageFlows.editMessageFlow(flowId)),
      },
      {
        label: "Exportar Relatório",
        icon: <TbTableExport size={20} />,
        onClick: () => handleExportFlowClicksReport(flowId),
      },
      {
        label: "Deletar",
        icon: <FaTrash size={20} color={colors.danger} />,
        onClick: () => {
          setFlowIdToDelete(flowId);
          setIsDeleteModalOpen(true);
        },
      },
    ];
    return <CustomMenu items={itemsMenu} />;
  }

  const confirmDelete = () => {
    if (flowIdToDelete) {
      deleteFlowMutation.mutate(flowIdToDelete);
    }
  };

  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="flex-end">
        <Button variant="primary" onClick={() => createFlowMutation.mutateAsync()}>
          + Criar Fluxo
        </Button>
      </Flex>
      <Flex gap={3} flexWrap="wrap">
        {flows?.map((flow) => (
          <Card key={flow.id} width="380px">
            <CardHeader>
              <Flex justifyContent={"space-between"} alignItems="center">
                <Heading size="sm" textTransform="uppercase">
                  {flow.title}
                </Heading>
                {flow.isActive ? (
                  <Badge colorScheme={"green"}>Ativo</Badge>
                ) : (
                  <Badge colorScheme={"red"}>Inativo</Badge>
                )}
              </Flex>
              <Text color={colors.fontlightGrey} fontSize="xs">
                Última edição:{" "}
                {format(new Date(flow.updatedAt), "dd/MM/yyyy HH:mm")}
              </Text>
            </CardHeader>

            <CardBody>
              <Stack>
                {flow.flowTriggers?.map((trigger) => (
                  <Flex
                    key={trigger.id}
                    padding={1}
                    gap={2}
                    alignItems="center"
                    fontSize={"sm"}
                    bgColor={"#efefef"}
                    borderRadius="15px"
                  >
                    <Flex gap={2} fontWeight="bold" alignItems="center">
                      <AiOutlineMessage />
                      <Text>{t(`enums.FlowTriggerType.${trigger.type}`)}</Text>
                    </Flex>
                    <Text>{trigger.text}</Text>
                  </Flex>
                ))}
              </Stack>
              <Divider margin={3} />
              <Flex flexDir={"row"} justify={"space-between"}>
                <Box>
                  <Text fontWeight="bold">
                    {flow.flowTriggers?.reduce((prev, curr) => prev + curr.invocationCount, 0)}
                  </Text>
                  <Text>Execuções</Text>
                </Box>

                <Flex flexDir={"column"} justifyContent={"center"}>
                  {buildMenu(flow.id)}
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        ))}
      </Flex>
      <AlertDialogBase
        title="Confirmar Deleção"
        isOpen={isDeleteModalOpen}
        onConfirm={confirmDelete}
        onClose={() => setIsDeleteModalOpen(false)}
        isConfirmLoading={deleteFlowMutation.isLoading}
      >
        Você tem certeza que deseja deletar esse fluxo? Essa ação não pode ser desfeita.
      </AlertDialogBase>
    </Box>
  );
};

export default MessagesFlowPage;
