import { Card, CardBody, CardHeader, Heading, Select } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateCommunicationChannel } from '../../../../state/campaignCreationSlice';
import { RootState } from '../../../../state/store';
import { CommunicationChannelEnum } from '../../../../types/CommunicationChannelEnum'

const SectionCommunicationChannel = () => {
  const { communicationChannel } = useSelector(
    (state: RootState) => state.campaignCreation
  );
  const dispatch = useDispatch();

  function handleChangeCommunicationChannel(event: React.ChangeEvent<HTMLSelectElement>) {
    dispatch(updateCommunicationChannel(event.target.value as CommunicationChannelEnum));
  }

  return (
    <Card>
      <CardHeader paddingBottom={0}>
        <Heading size="md">Canal de envio</Heading>
      </CardHeader>
      <CardBody>
        <Select value={communicationChannel as string} onChange={handleChangeCommunicationChannel}>
          <option value={CommunicationChannelEnum.WHATSAPP}>
            WhatsApp
          </option>
          <option value={CommunicationChannelEnum.SMS}>
            SMS
          </option>
        </Select>
      </CardBody>
    </Card>
  )
}

export default SectionCommunicationChannel