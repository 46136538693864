import {ChakraProvider, extendTheme, useToast} from "@chakra-ui/react";
import {useEffect} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {colors} from "./constants/colors";
import {CrudAutoSortingModalProvider} from "./hooks/useCrudAutoSortingModal";
import {SocketProvider} from "./hooks/useSocket";
import Routes from "./routes";
import {checkAuthentication} from "./state/authSlice";
import {hideToast} from "./state/globalSlice";
import {RootState} from "./state/store";
import "./i18n";
import EmojiDataSetProvider from "./contexts/EmojiDataSetContext";

const DEFAULT_EMOJI_DATA_SET_LOCALE = "pt";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
const customTheme = extendTheme({
  colors: {
    primary: {
      main: colors.primary,
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: colors.primary,
      },
    },
    Button: {
      variants: {
        primary: {
          backgroundColor: colors.primary,
          color: colors.white,
        },
      },
    },
  },
});
function App() {
  const dispatch = useDispatch();
  const showToastState = useSelector(
    (state: RootState) => state.global.showToast
  );
  const toastOptions = useSelector(
    (state: RootState) => state.global.toastOptions
  );
  const toast = useToast();

  useEffect(() => {
    if (showToastState && toastOptions) {
      toast(toastOptions);
      dispatch(hideToast());
    }
  }, [showToastState, toastOptions, toast, dispatch]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      dispatch(checkAuthentication());
    });
    dispatch(checkAuthentication());

    return () => {
      window.removeEventListener("storage", (event) => {});
    };
  }, [dispatch]);

  return (
    <div className="App">
      <ChakraProvider theme={customTheme}>
        <SocketProvider>
          <QueryClientProvider client={queryClient}>
            <CrudAutoSortingModalProvider>
              <EmojiDataSetProvider locale={DEFAULT_EMOJI_DATA_SET_LOCALE}>
                <Router>
                  <Routes />
                </Router>
              </EmojiDataSetProvider>
            </CrudAutoSortingModalProvider>
          </QueryClientProvider>
        </SocketProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
