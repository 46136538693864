import { AxiosResponse } from 'axios';
import { request } from '../constants/request';
import { User } from '../types/Prisma';
import { apiRoutes } from '../constants/api-routes';

const listCompanyAgents = async(): Promise<AxiosResponse<User[]>> => {
  return request.get(apiRoutes.listCompanyAgents());
}

export const UsersService = {
  listCompanyAgents,
}
