import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";

export interface NumberInputProps {
  field: any;
  error?: string;
  placeholder?: string;
  customBorderBottom?: string;
  customColor?: string;
  customWidth?: string;
  onChange?: (e: any) => void;
  textAddon?: { side: 'left' | 'right', text: string } | undefined;
}

const NumberInput = ({
  field,
  error,
  placeholder,
  customWidth,
  textAddon,
}: NumberInputProps) => {
  return (
    <InputGroup width={customWidth || '100%'}>
      {textAddon && textAddon.side === 'left' && textAddon.text !== null && (
        <InputLeftElement
          pointerEvents={'none'}
          style={{height: 'auto'}}
          children={<span style={{ fontSize: '14px', marginRight: '5px' }}>{textAddon.text}</span>}
        />
      )}
      <Input
        {...field}
        style={{
          fontSize: 14,
          padding: textAddon?.side === 'left' ? '0 0 0 32px' : '0',
          margin: 0,
          height: 'fit-content',
          lineHeight: '19px',
          borderColor: error ? '#ff6567' : '',
        }}
        value={field.value['value']}
        placeholder={placeholder}
        variant='flushed'
        type={'number'}
        onChange={(e) => (
          field.onChange({ value: e.target.value.replace(/\D/g, '') })
        )}
      />
      {textAddon && textAddon.side === 'right' && textAddon.text !== null && (
        <InputRightElement
          pointerEvents={'none'}
          style={{height: 'auto'}}
          children={<span style={{ fontSize: '14px', marginRight: '5px' }}>{textAddon.text}</span>}
        />
      )}
    </InputGroup>
  );
}

export default NumberInput;
