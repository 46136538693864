import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaExchangeAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { apiRoutes } from "../../../../../../constants/api-routes";
import { ConversationCategoriesService } from "../../../../../../services/conversation-categories.service";
import { RootState } from "../../../../../../state/store";
import { MoveConversationToCategoryNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";

const MoveConversationToCategoryNode = (
  props: NodeProps<MoveConversationToCategoryNodeData>
) => {
  const { t } = useTranslation();
  const { data: conversationCategories } = useQuery(
    apiRoutes.listConversationCategories(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategories();
      return data;
    },
    {
      staleTime: 1000 * 15,
    }
  );
  const targetConversationCategory = conversationCategories?.find(
    (category) => category.id === props.data.targetConversationCategoryId
  );

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<FaExchangeAlt />}
      color="#9c00cc">
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content">
        {targetConversationCategory?.name || "Geral"}
      </Box>
    </NodeLayout>
  );
};

export default MoveConversationToCategoryNode;
