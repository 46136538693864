import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React, { useState } from "react";
import { FaUsers } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../../../components/ButtonIcon";
import { Th } from "../../../../components/CustomTable";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import { colors } from "../../../../constants/colors";
import {
  GetTopCampaignsOrderBy,
  StatisticsService,
} from "../../../../services/statistics.service";
import { MoneyUtils } from "../../../../utils/money.utils";

const SectionTopCampaigns = () => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] =
    useState<GetTopCampaignsOrderBy>("total_orders_value");
  const { data = [] } = useQuery(
    apiRoutes.getTopCampaigns(orderBy),
    async () => {
      const { data } = await StatisticsService.getTopCampaigns(orderBy);
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );

  function handleChangeOrderBy(orderBy: GetTopCampaignsOrderBy) {
    setOrderBy(orderBy);
  }

  function handleClickCampaignResults(campaignId: string) {
    navigate({
      pathname: appPaths.campaigns.whatsapp.details(campaignId),
    });
  }

  return (
    <Card>
      <CardHeader display="flex" justifyContent={"space-between"} alignItems='center'>
        <Heading size="md">Suas top 10 campanhas na Revi</Heading>
        <FormControl display="flex" width="fit-content" alignItems="center">
          <FormLabel>Ordenar por</FormLabel>
          <Select
            value={orderBy}
            width="200px"
            onChange={(e) =>
              handleChangeOrderBy(e.target.value as GetTopCampaignsOrderBy)
            }>
            {[
              {
                name: "Receita gerada",
                value: "total_orders_value",
              },
              {
                name: "ROI",
                value: "roi",
              },
            ].map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </CardHeader>
      <CardBody>
        <TableContainer>
          <Table variant="simple" size='sm'>
            <Thead>
              <Tr>
                <Th>Data de envio</Th>
                <Th>Template</Th>
                <Th>Envios</Th>
                <Th>Receita gerada</Th>
                <Th>ROI</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map(
                ({
                  whatsapp_campaign_id,
                  filter_criteria,
                  executed_at,
                  message_template_name,
                  total_orders_value,
                  total_messages_sent,
                  campaign_cost,
                  roi,
                }) => (
                  <Tr key={whatsapp_campaign_id}>
                    <Td>{format(new Date(executed_at), "dd/MM/yyyy HH:mm")}</Td>
                    <Td>{message_template_name}</Td>
                    <Td>{total_messages_sent}</Td>
                    <Td>{MoneyUtils.formatCurrency(total_orders_value)}</Td>
                    <Td>{roi}x</Td>
                    <Td>
                      <ButtonIcon
                        tooltipLabel="Ver resultados"
                        icon={
                          <MdOutlineRemoveRedEye color={colors.primaryLight} size={20} />
                        }
                        onClick={() => handleClickCampaignResults(whatsapp_campaign_id)}
                      />
                      <ButtonIcon
                        tooltipLabel="Aplicar segmentação"
                        icon={<FaUsers color={colors.primaryLight} size={20} />}
                        disabled={!filter_criteria}
                        onClick={() => {
                          navigate({
                            pathname: appPaths.customers.index(),
                            search: filter_criteria.replace(
                              /excludedTemplateIds=.+?(?=&|$)/g,
                              ""
                            ),
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default SectionTopCampaigns;
