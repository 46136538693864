import {
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useQuery } from "react-query";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { apiRoutes } from "../../../../../constants/api-routes";
import { colors } from "../../../../../constants/colors";
import { useCrudAutoSortingOptionModal } from "../../../../../hooks/useCrudAutoSortingModal";
import { AutoSortingOptionsService } from "../../../../../services/auto-sorting-options.service";

const TableSimple = () => {
  const { data: autoSortingOptions } = useQuery(
    apiRoutes.listAutoSortingOptions(),
    async () => {
      const { data } = await AutoSortingOptionsService.listAutoSortingOptions();
      return data;
    }
  );
  const { openEditModal, handleClickDelete, handleChangeIsActive } =
    useCrudAutoSortingOptionModal();

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Opção de atendimento</Th>
            <Th>Mensagem automática</Th>
            <Th>Arquivo</Th>
            <Th>Mostrar opção?</Th>
            <Th width='100px'>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {autoSortingOptions?.map((option, index) => {
            return (
              <Tr key={option.id}>
                <Td>{option.conversationCategory.name}</Td>
                <Td>
                  <Text noOfLines={1} maxW={"300px"} display="block">
                    {option.firstMessage}
                  </Text>
                </Td>
                <Td>
                  <Text noOfLines={1} maxW={"300px"} display="block">
                    {option.file?.name}
                  </Text>
                </Td>
                <Td>
                  <Switch
                    isChecked={option.isActive}
                    onChange={async (event) => {
                      await handleChangeIsActive(
                        option.id,
                        event.target.checked
                      );
                    }}
                  />
                </Td>
                <Td
                  display="flex"
                  align="center"
                  justifyContent={"space-around"}>
                  <ButtonIcon
                    icon={<FaEdit fontSize="20px" color={colors.primary} />}
                    onClick={() => openEditModal(option.id)}
                  />
                  <ButtonIcon
                    icon={<FaTrash fontSize="20px" color={colors.danger} />}
                    onClick={() => handleClickDelete(option.id)}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableSimple;
