import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface AccordionItemLayoutProps {
  icon: ReactNode;
  children: ReactNode;
  title: string;
}

const AccordionItemLayout = ({
  icon,
  children,
  title,
}: AccordionItemLayoutProps) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton
          paddingLeft={0}
          fontWeight={"bold"}
          height="50px"
          gap={2}>
          {icon}
          <Box as="span" flex="1" textAlign="left">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel
        pb={4}
        bgColor="white"
        display={"flex"}
        flexDir="column"
        gap={5}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionItemLayout;
