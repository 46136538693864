import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../../constants/app-paths";
import { CommunicationChannelEnum } from "../../../../types/CommunicationChannelEnum";

interface HeaderProps {
  title: string;
  icon?: React.ReactNode;
}

const Header = ({ title, icon }: HeaderProps) => {
  const navigate = useNavigate();
  const defaultValue = window.location.pathname.includes("whatsapp")
    ? CommunicationChannelEnum.WHATSAPP
    : CommunicationChannelEnum.SMS;
  return (
    <Box>
      <Heading mb={5} display='flex' alignItems='center' gap={3}>{title} {icon}</Heading>
      <Flex justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={3}>
          <Text fontWeight={"bold"}>Canal de comunicação:</Text>
          <Select
            defaultValue={defaultValue}
            width="fit-content"
            onChange={(event: any) => {
              const value = event.target.value as CommunicationChannelEnum;
              const route = appPaths.campaigns[value].index();
              navigate({
                pathname: route,
              });
            }}>
            <option value={CommunicationChannelEnum.WHATSAPP}>WhatsApp</option>
            <option value={CommunicationChannelEnum.SMS}>Sms</option>
          </Select>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
