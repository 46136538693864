import { Flex, Link } from "@chakra-ui/react";
import ButtonIcon from "../ButtonIcon";
import { FaRegTimesCircle } from "react-icons/fa";
import { noop } from "lodash";

const FILE_NAME_MAX_LENGTH = 45;

interface FileInputItemProps {
  file: File; 
  maxFileNameLength?: number;
  onClick?: () => void;
  onRemove?: () => void;
}

const FileInputItem = ({
  file,
  maxFileNameLength = FILE_NAME_MAX_LENGTH, 
  onClick = noop, 
  onRemove = noop
}: FileInputItemProps) => {
  const displayName = file.name.length > maxFileNameLength ? `${file.name.slice(0, maxFileNameLength)}...` : file.name;

  return (
    <Flex gap={1} alignItems='center'>
      <Link
        onClick={onClick}>
        {displayName}
      </Link>
      <ButtonIcon
        icon={<FaRegTimesCircle color="red" />}
        onClick={onRemove}
      />
    </Flex>
  )
}

export default FileInputItem;