import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import RFMHistoricalChart from "../../components/RFMHistoricalChart";
import { colors } from "../../constants/colors";
import { RootState } from "../../state/store";
import SectionCustomerKPIs from "./components/SectionCustomerKPIs";
import SectionsOrderByHourChart from "./components/SectionOrdersByHourChart";
import SectionRecommendations from "./components/SectionRecommendations";
import SectionReviKPIsChart from "./components/SectionReviKPIsChart";
import SectionReviRevenueSummary from "./components/SectionReviRevenueSummary";
import SectionRFM from "./components/SectionRFM";
import SectionTopCampaigns from "./components/SectionTopCampaigns";

const HomePage = () => {
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const [maxRfmRecency, setMaxRfmRecency] = useState(365);

  function handleChangeMaxRecency(maxRfmRecency: number) {
    setMaxRfmRecency(maxRfmRecency);
  }

  return (
    <Grid
      gap={2}
      templateAreas={`
        "header header header"
        "revenue-stats revenue-stats revenue-stats"
        "rfm-chart rfm-chart recommendations"
        "rfm-history-chart rfm-history-chart rfm-history-chart"
        "revi-kpis revi-kpis revi-kpis"
        "top-campaigns top-campaigns top-campaigns"
        "orders-by-hour-chart orders-by-hour-chart orders-by-hour-chart"
      `}
      gridTemplateRows={"auto auto 1fr auto"}
      gridTemplateColumns={"1fr 1fr 1fr"}>
      <GridItem
        area="header"
        alignItems={"center"}
        bgColor={"#8BD9F7"}
        display="flex"
        gap={4}>
        <Image
          src="/logo512.png"
          width="150px"
          height="150px"
          objectFit={"cover"}
        />
        <Flex alignItems={"center"} justifyContent="space-between" flex={1}>
          <Flex flexDir="column" alignItems={"center"}>
            <Heading textTransform={"uppercase"}>
              hey {currentUser?.name?.split(" ")[0] || ""}
            </Heading>
            <Text>Gosta de números? Nós também!</Text>
          </Flex>
          <Flex flexDir="column" gap={2}>
            <Text fontWeight={"bold"}>Não sabe por onde começar?</Text>
            <Button
              mr={4}
              bgColor={colors.primary}
              color={colors.white}
              as="a"
              href="https://wa.me/+5511935025879?text=Olá Juliana, gostaria de ajuda com estratégias na Revi"
              target={"_blank"}>
              Falar com um especialista
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem area="revenue-stats" display="flex">
        <SectionReviRevenueSummary />
        <SectionCustomerKPIs />
      </GridItem>
      <GridItem area="rfm-chart">
        <SectionRFM
          maxRecency={maxRfmRecency}
          onChangeMaxRecency={handleChangeMaxRecency}
        />
      </GridItem>
      <GridItem area="recommendations">
        <SectionRecommendations />
      </GridItem>
      <GridItem area="rfm-history-chart">
        <Card>
          <CardHeader display="flex" justifyContent={"space-between"}>
            <Heading size="md">Evolução das categorias RFM</Heading>
          </CardHeader>
          <CardBody>
            <RFMHistoricalChart maxRecency={maxRfmRecency} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem area="revi-kpis">
        <SectionReviKPIsChart />
      </GridItem>
      <GridItem area="top-campaigns">
        <SectionTopCampaigns />
      </GridItem>
      <GridItem area="orders-by-hour-chart">
        <SectionsOrderByHourChart />
      </GridItem>
    </Grid>
  );
};

export default HomePage;
