import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const useFileDropzone = () => {
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      const fileReader = new FileReader();

      fileReader.onload = function () {
        setPreview(fileReader.result);
      };

      fileReader.readAsDataURL(acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    },
    [file]
  );

  const dropzone = useDropzone({
    onDrop,
  });
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);

  function clear() {
    setFile(null);
    setPreview(null);
  }

  return {
    file,
    dropzone,
    preview,
    clear,
  };
};

export default useFileDropzone;
