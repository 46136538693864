import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { useEffect, useReducer } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ListMessageTemplateItem } from "../../../../../services/message-templates.service";
import {
  resetTemplateArgs,
  updateSelectedMessageTemplate,
  updateTemplateArg,
} from "../../../../../state/campaignCreationSlice";
import { RootState } from "../../../../../state/store";
import { CompanyDefinedField } from "../../../../../types/CompanyDefinedField";
import TemplateParameterEditor from "./TemplateParameterEditor";
import TemplateSelector from "./TemplateSelector";

interface SelectionState {
  status: "selecting" | "selected" | "notSelected";
}

const initialSelectionState: SelectionState = {
  status: "notSelected",
};

enum SelectionActionTypes {
  NOT_SELECTED = "NOT_SELECTED",
  SELECTING = "SELECTING",
  SELECTED = "SELECTED",
}

interface SelectionAction {
  type: SelectionActionTypes;
}

const selectionReducer = (
  state: SelectionState,
  action: SelectionAction
): SelectionState => {
  switch (action.type) {
    case SelectionActionTypes.SELECTING:
      return { ...state, status: "selecting" };
    case SelectionActionTypes.SELECTED:
      return { ...state, status: "selected" };
    case SelectionActionTypes.NOT_SELECTED:
      return { ...state, status: "notSelected" };
    default:
      return state;
  }
};

interface SectionSelectTemplateProps {
  templates: ListMessageTemplateItem[];
  companyDefinedFields: CompanyDefinedField[];
}

const SectionSelectTemplate = ({
  templates,
  companyDefinedFields,
}: SectionSelectTemplateProps) => {
  const [selectionState, dispatchSelectionState] = useReducer(
    selectionReducer,
    initialSelectionState
  );
  const { selectedTemplate, templateArgs, communicationChannel } = useSelector(
    (state: RootState) => state.campaignCreation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTemplate) {
      dispatchSelectionState({
        type: SelectionActionTypes.SELECTED,
      });
    }
  }, [selectedTemplate]);

  function clearSelectedTemplate() {
    dispatch(updateSelectedMessageTemplate(null));
    dispatch(resetTemplateArgs);
  }

  function handleClickTemplate(template: ListMessageTemplateItem) {
    dispatchSelectionState({
      type: SelectionActionTypes.SELECTED,
    });
    dispatch(updateSelectedMessageTemplate(template));
  }

  function handleClickEditTemplate() {
    dispatchSelectionState({
      type: SelectionActionTypes.SELECTING,
    });
    clearSelectedTemplate();
  }

  function handleClickSelectTemplate() {
    dispatchSelectionState({
      type: SelectionActionTypes.SELECTING,
    });
  }

  function handleChangeTemplateArgs(args: Record<string, string>) {
    dispatch(updateTemplateArg(args));
  }

  return (
    <Card>
      <CardHeader display="flex" justifyContent="space-between">
        <Heading size="md">Mensagem</Heading>
        {selectionState.status === "selected" ? (
          <Flex alignItems={"center"} gap={2}>
            <Text>{selectedTemplate?.name}</Text>
            <IconButton
              aria-label="Alterar template selecionado"
              variant={"ghost"}
              onClick={handleClickEditTemplate}
              icon={<FaEdit fontSize={16} />}
            />
          </Flex>
        ) : selectionState.status === "notSelected" ? (
          <Button onClick={handleClickSelectTemplate}>
            Selecionar template
          </Button>
        ) : null}
      </CardHeader>
      <CardBody paddingTop={0}>
        {selectionState.status === "selecting" ? (
          <TemplateSelector
            templates={templates}
            onClickTemplate={handleClickTemplate}
            selectedTemplate={selectedTemplate}
            communicationChannel={communicationChannel}
          />
        ) : selectedTemplate && selectionState.status === "selected" ? (
          <TemplateParameterEditor
            onChangeTemplateArgs={handleChangeTemplateArgs}
            template={selectedTemplate!}
            templateArgs={templateArgs}
            companyDefinedFields={companyDefinedFields}
          />
        ) : null}
      </CardBody>
    </Card>
  );
};

export default SectionSelectTemplate;
