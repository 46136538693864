import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  ButtonGroup,
  FocusLock,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaRegCopy, FaTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import ReactInputMask from "react-input-mask";
import { useMutation, useQueryClient } from "react-query";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { colors } from "../../../../../constants/colors";
import {
  MessagesService,
  SendMessageTemplateByPhoneDto,
} from "../../../../../services/messages.service";
import {
  MessageTemplate,
  MessageTemplateStatus,
} from "../../../../../types/MessageTemplate";
import { MessageTemplateUtils } from "../../../../../utils/message-templates.utils";
import { StringUtils } from "../../../../../utils/string.utils";

import MessageTemplateStatusEnum from "../../../../../types/MessageTemplateStatusEnum";
import { MessageTemplatesService } from "../../../../../services/message-templates.service";
import { apiRoutes } from "../../../../../constants/api-routes";
import { MessageTemplateTypeTextEnum } from "../../../../../types/MessageTemplateType";

interface RowProps {
  template: MessageTemplate;
  onClickEditTemplate: (template: MessageTemplate) => void;
  onClickCloneTemplate: (template: MessageTemplate) => void;
}

const MESSAGE_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  NONE: null,
} as const;

type MessageStatusType = (typeof MESSAGE_STATUS)[keyof typeof MESSAGE_STATUS];

const Row = ({ template, onClickEditTemplate, onClickCloneTemplate }: RowProps) => {
  const { t } = useTranslation();
  const isDeleting =
    template.status !== MessageTemplateStatusEnum.deleted && template.isDeleted;

  const {
    isOpen: isTestTemplatePopoverOpen,
    onOpen: onOpenTestTemplatePopover,
    onClose: onCloseTestTemplatePopover,
  } = useDisclosure();
  const {
    isOpen: isMessageTemplateDeleteConfirmationOpen,
    onOpen: onOpenMessageTemplateDeleteConfirmation,
    onClose: onCloseMessageTemplateDeleteConfirmation,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const cancelMessageTemplateDeleteRef = React.useRef<HTMLButtonElement | null>(
    null
  );
  const [recipientName, setRecipientName] = useState<string>("");
  const [recipientPhoneNumberId, setRecipientPhoneNumberId] =
    useState<string>("");
  const [messageStatus, setMessageStatus] = useState<MessageStatusType>(
    MESSAGE_STATUS.NONE
  );
  const sendMessageTemplateByPhone = useMutation(
    (sendMessageTemplateByPhoneDto: SendMessageTemplateByPhoneDto) =>
      MessagesService.sendMessageTemplateByPhone(sendMessageTemplateByPhoneDto),
    {
      onSuccess: (res: any) => {
        setMessageStatus(MESSAGE_STATUS.SUCCESS);
      },
      onError: (error: any) => {
        setMessageStatus(MESSAGE_STATUS.ERROR);
      },
    }
  );

  const deleteMessageTemplate = useMutation(
    (templateId: string) =>
      MessageTemplatesService.deleteMessageTemplate(templateId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(apiRoutes.listMessageTemplates());
        onCloseMessageTemplateDeleteConfirmation();
        toast({
          title: "Template excluído com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (err: any) => {
        toast({
          title: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function handleClickedDeleteTemplate() {
    await deleteMessageTemplate.mutateAsync(template.id);
  }

  function getColorScheme(status: MessageTemplateStatus): string {
    const statusData: Record<MessageTemplateStatus, string> = {
      approved: colors.status.approved,
      pending: colors.status.pending,
      rejected: colors.status.rejected,
      deleted: colors.status.deleted,
      disabled: colors.status.disabled,
      paused: colors.status.paused,
    };
    return statusData[isDeleting ? MessageTemplateStatusEnum.pending : status];
  }

  const closeTestTemplatePopover = useCallback(() => {
    onCloseTestTemplatePopover();
    setRecipientName("");
    setRecipientPhoneNumberId("");
    setMessageStatus(null);
  }, [onCloseTestTemplatePopover]);

  async function handleSubmit() {
    const parameters = MessageTemplateUtils.getAllParametersInText(
      template.templateText
    );

    const templateArgs: Record<string, string> = {};
    parameters.forEach((parameter) => {
      let formatted = parameter.replace(/[\[|\]]/g, "");
      formatted = StringUtils.toCamelCase(formatted);
      templateArgs[formatted] = parameter;
    });

    await sendMessageTemplateByPhone.mutateAsync({
      recipientName,
      recipientPhoneNumberId,
      templateName: template.name,
      templateArgs,
    });
  }

  useEffect(() => {
    if (messageStatus === MESSAGE_STATUS.SUCCESS) {
      const oneSecondInMilliseconds = 1000;
      const timeoutId = setTimeout(
        () => closeTestTemplatePopover(),
        oneSecondInMilliseconds
      );

      return () => clearTimeout(timeoutId);
    }
  }, [messageStatus, closeTestTemplatePopover]);

  function getMenuButtons(): JSX.Element[] {
    return isDeleting || template.status === MessageTemplateStatusEnum.deleted
      ? []
      : [
          <ButtonIcon
            icon={<FaEdit fontSize="20px" color={colors.darkGrey} />}
            onClick={() => onClickEditTemplate(template)}
          />,
          <ButtonIcon
            icon={<FaRegCopy fontSize="20px" color={colors.darkGrey} />}
            onClick={() => onClickCloneTemplate(template)}
          />,
          <Popover
            isOpen={isTestTemplatePopoverOpen}
            onOpen={onOpenTestTemplatePopover}
            onClose={closeTestTemplatePopover}
            placement="right"
            closeOnBlur={true}
          >
            <PopoverTrigger>
              <IconButton
                aria-label="Send message"
                icon={<FiSend fontSize="20px" color={colors.darkGrey} />}
                backgroundColor={"transparent"}
              />
            </PopoverTrigger>
            <PopoverContent p={5}>
              <FocusLock persistentFocus={false}>
                <PopoverArrow />
                <PopoverCloseButton />
                <Stack>
                  <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input
                      value={recipientName}
                      onChange={(e) => setRecipientName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Telefone</FormLabel>
                    <InputGroup>
                      <InputLeftAddon children="+55" />
                      <Input
                        type="tel"
                        placeholder="(11) 00000-0000"
                        as={ReactInputMask}
                        mask="(99)99999-9999"
                        value={recipientPhoneNumberId}
                        onChange={(e) =>
                          setRecipientPhoneNumberId(e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormControl>
                  {messageStatus === MESSAGE_STATUS.SUCCESS ? (
                    <Text color="green.500">Mensagem enviada com sucesso</Text>
                  ) : messageStatus === MESSAGE_STATUS.ERROR ? (
                    <Text color="red.500">Erro ao enviar mensagem</Text>
                  ) : null}
                  <ButtonGroup display="flex" justifyContent="flex-end">
                    <Button
                      variant="outline"
                      onClick={closeTestTemplatePopover}
                    >
                      Cancelar
                    </Button>
                    <Button
                      colorScheme="teal"
                      onClick={handleSubmit}
                      isLoading={sendMessageTemplateByPhone.isLoading}
                      isDisabled={!recipientName || !recipientPhoneNumberId}
                    >
                      Enviar
                    </Button>
                  </ButtonGroup>
                </Stack>
              </FocusLock>
            </PopoverContent>
          </Popover>,

          <ButtonIcon
            icon={<FaTrashAlt fontSize="20px" color={colors.danger} />}
            onClick={onOpenMessageTemplateDeleteConfirmation}
          ></ButtonIcon>,
        ];
  }

  function getStatusLabel(): string {
    return isDeleting
      ? "Deletando..."
      : t(`enums.MessageTemplateStatus.${template.status}`);
  }

  return (
    <Tr
      key={template.id}
      color={
        isDeleting || template.status === MessageTemplateStatusEnum.deleted
          ? colors.middleGrey
          : colors.black
      }
    >
      <Td fontWeight="bold">{template.name}</Td>
      <Td>
        <Text display="block" whiteSpace={"pre-wrap"}>
          {template.templateText}
        </Text>
      </Td>
      <Td>
        <Text noOfLines={1} maxW={"300px"} display="block">
          {MessageTemplateTypeTextEnum[template.type]}
        </Text>
      </Td>
      <Td>
        <Text noOfLines={1} maxW={"300px"} display="block">
          <Badge colorScheme={getColorScheme(template.status)}>
            {getStatusLabel()}
          </Badge>
        </Text>
      </Td>
      <Td>
        {getMenuButtons()}
        <AlertDialog
          isOpen={isMessageTemplateDeleteConfirmationOpen}
          leastDestructiveRef={cancelMessageTemplateDeleteRef}
          onClose={onCloseMessageTemplateDeleteConfirmation}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirmação de Exclusão
              </AlertDialogHeader>

              <AlertDialogBody>
                {`Você tem certeza de que deseja excluír o template de mensagem
                ${template.name}?\n`}
                <p>Atenção: Esta ação é irreversível</p>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelMessageTemplateDeleteRef}
                  onClick={onCloseMessageTemplateDeleteConfirmation}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  onClick={handleClickedDeleteTemplate}
                  ml={3}
                  isLoading={deleteMessageTemplate.isLoading}
                >
                  Confirmar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Td>
    </Tr>
  );
};

export default Row;
