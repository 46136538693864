import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Divider, Link, Flex } from "@chakra-ui/react";
import { colors } from "../../constants/colors";

function UrlButtonPreview({ text }: { text: string }) {
  return (
    <Box bgColor={"#FFFFFF"} borderBottomRadius="5px">
      <Divider />
      <Flex
        flexDir="column"
        justifyContent={"center"}
        alignItems="center"
        padding={2}
      >
        <Link color={colors.hyperlink}>
          <ExternalLinkIcon mx="3px" color={colors.hyperlink} />
          {text}
        </Link>
      </Flex>
    </Box>
  );
}
export default UrlButtonPreview;
