function convertJsonToFormData(object: any, form?: FormData, namespace?: string): FormData {
  const formData = form || new FormData();
  for (let property in object) {
      if (object[property] === undefined || object[property] === '') {
          continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
          formData.append(formKey, object[property].toISOString());
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
        convertJsonToFormData(object[property], formData, formKey);
      } else {
          formData.append(formKey, object[property]);
      }
  }
  return formData;
}

export const FormDataUtils = {
  convertJsonToFormData: (json: any) => convertJsonToFormData(json),
};
