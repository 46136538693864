import { DrawerBody, Flex, FormControl, Link, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaRegTimesCircle } from "react-icons/fa";
import * as yup from "yup";
import ButtonIcon from "../../../../../../../components/ButtonIcon";
import { colors } from "../../../../../../../constants/colors";
import useDownloadFile from "../../../../../../../hooks/useDownloadFile";
import { SendWhatsappMediaNodeData } from "../../../../../../../types/ReactFlowNode";
import { FileValidationUtils } from "../../../../../../../utils/file-validation.utils";

const schema = yup
  .object({
    file: yup
      .mixed()
      .test("fileSize", "Arquivo muito grande", (files) => {
        const file = files[0];
        if (!file) {
          return true;
        }
        return FileValidationUtils.isValidFileSize(file);
      })
      .test("fileType", "Tipo de arquivo inválido", (files) => {
        const file = files[0];
        if (!file) {
          return true;
        }
        return FileValidationUtils.isValidFileType(file);
      })
      .transform((current, original) => {
        return original[0];
      }),
  })
  .required();

export interface SendWhatsappMediaNodeEditorProps {
  data: SendWhatsappMediaNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const SendWhatsappMediaNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: SendWhatsappMediaNodeEditorProps) => {
  const { downloadWhatsappMedia } = useDownloadFile();
  const [fileKey, setFileKey] = useState<string | null>(data.fileKey || null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  } as any);
  const { ref: fileFormRef, ...fileRegister } = register("file");
  const fileRef = useRef<any>(null);

  useEffect(() => {
    if (data.file && fileRef?.current?.files) {
      setValue("file", [data.file]);
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(data.file);
      fileRef.current.files = dataTransfer.files;
    }
  }, [data.file]);

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(onSaveNode)} id={formId}>
        <Flex flexDir={"column"} gap={3}>
          <FormControl>
            {fileKey ? (
              <Flex align="center" gap={3}>
                <Link
                  onClick={() =>
                    downloadWhatsappMedia({
                      fileKey,
                      mediaName: data.fileName!,
                    })
                  }>
                  {data.fileName}
                </Link>
                <ButtonIcon
                  icon={<FaRegTimesCircle color="red" />}
                  onClick={() => {
                    setFileKey(null);
                  }}
                />
              </Flex>
            ) : (
              <input
                {...fileRegister}
                type="file"
                accept={FileValidationUtils.supportedFileTypes.join(", ")}
                ref={(e) => {
                  fileFormRef(e);
                  // @ts-ignore
                  fileRef.current = e;
                }}
              />
            )}
            <Text color={colors.danger} fontSize="xs">
              {errors.file && <span>{errors.file.message}</span>}
            </Text>
          </FormControl>
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default SendWhatsappMediaNodeEditor;
