import { Button, Flex, Text } from "@chakra-ui/react"
import {  useMemo } from "react";
import { colors } from "../../../../constants/colors";

export interface InboxToolbarAction  {
  name: string;
  icon: React.ReactElement;
  label: string;
  onClick?: (name: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled?: boolean | (() => boolean);
  isLoading?: boolean;
}

export interface InboxToolbarProps {
  statusMessage?: string
  actions?: InboxToolbarAction[];
  selectedCount?: number;
  totalCount?: number;
}

const InboxToolbar = ({statusMessage, actions = [], selectedCount = 0, totalCount = 0}: InboxToolbarProps) => {
  const selectLabel = useMemo(() => {
    if (totalCount === 0 || selectedCount === 0) return undefined;
    if (selectedCount === totalCount) return 'Todas selecionadas';
    return `${selectedCount} selecionadas`;
  }, [selectedCount, totalCount])

  return (
    <Flex
      height={35}
      px={3}
      alignItems={"center"}
      flexDirection={'row'}
      borderWidth={"1px"}
      borderStyle="solid"
      borderColor={colors.lightGrey}
    >
      <Flex flex={1}>
        <Text fontWeight={'semibold'} fontSize={10}>
          {selectLabel || statusMessage || ''}
        </Text>
      </Flex>
      <Flex 
        gap={2}
        justify={"flex-end"}
      >
        {
          actions?.map((action: InboxToolbarAction) => (
            <Button 
              key={action.name}
              isDisabled={typeof action.isDisabled === 'function' ? action.isDisabled() : action.isDisabled}
              size="xs"
              fontSize={"xs"}
              px={2}
              py={1}
              bgColor={'transparent'}
              color="black"
              leftIcon={action.icon}
              onClick={(event) => action.onClick?.(action.name, event)}
              isLoading={action.isLoading}
            >
              {action.label}
            </Button>
          ))
        }
      </Flex>
    </Flex>
  )
}

export default InboxToolbar