import {
  Badge,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { PiMagnifyingGlassPlus } from "react-icons/pi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../../../../components/LoadingScreen";
import { apiRoutes } from "../../../../../constants/api-routes";
import {
  appPaths,
  getCustomersPageQueryParams,
} from "../../../../../constants/app-paths";
import { colors } from "../../../../../constants/colors";
import { MixpanelService } from "../../../../../services/mixpanel.service";
import { EngagementActionsEnum } from "../../../../../types/EngagementActionsEnum";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdFreeCancellation } from "react-icons/md";
import ButtonIcon from "../../../../../components/ButtonIcon";
import AlertDialogBase from "../../../../../components/AlertDialog";
import { useState } from "react";
import { SmsCampaignStatus } from "../../../../../types/Prisma";
import { SmsCampaignsService } from "../../../../../services/sms-campaigns.service";
import { SmsCampaignStatsData } from "../../../../../types/SmsCampaignStatsData";
import { useTranslation } from "react-i18next";

const TableSmsCampaigns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const toast = useToast();
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  );
  const {
    data: smsCampaigns,
    isLoading: isLoadingSmsCampaigns,
    refetch: refetchSmsCampaigns,
  } = useQuery(
    apiRoutes.listSmsCampaigns(),
    async () => {
      const res = await SmsCampaignsService.listSmsCampaigns();
      return res.data;
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: true,
    }
  );

  const cancelSmsCampaign = useMutation(
    (campaignId: string) => SmsCampaignsService.cancelSmsCampaign(campaignId),
    {
      onSuccess: () => {
        toast({
          title: "Campanha cancelada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetchSmsCampaigns();
      },
    }
  );

  function getRealCampaignStatus(
    campaign: SmsCampaignStatsData
  ): SmsCampaignStatus {
    if (campaign.status === "scheduled" && !campaign.scheduled_execution_time) {
      return "in_progress";
    }

    return campaign.status;
  }

  function getColorScheme(status: SmsCampaignStatus): string {
    const statusData: Record<
      SmsCampaignStatus,
      string
    > = {
      in_progress: colors.status.inProgress,
      completed: colors.status.completed,
      scheduled: colors.status.scheduled,
      failed: colors.status.failed,
      canceled: colors.status.canceled,
    };
    return statusData[status];
  }

  return (
    <LoadingScreen isLoading={isLoadingSmsCampaigns}>
      <TableContainer overflowX="visible">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Data de envio</Th>
              <Th>Template</Th>
              <Th># destinatários</Th>
              <Th># cliques</Th>
              <Th>Status</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {smsCampaigns?.map((smsCampaign, index) => {
              return (
                <Tr
                  key={smsCampaign.id}
                  fontWeight={
                    getRealCampaignStatus(smsCampaign) === "scheduled"
                      ? "semibold"
                      : "light"
                  }>
                  <Td>
                    {format(
                      new Date(
                        smsCampaign.scheduled_execution_time ||
                          smsCampaign.created_at
                      ),
                      "dd/MM/yyyy HH:mm"
                    )}
                  </Td>
                  <Td>
                    <Tooltip label={smsCampaign.template_name}>
                      <Text noOfLines={1} maxW={"180px"} display="block">
                        {smsCampaign.template_name}
                      </Text>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Text noOfLines={1} maxW={"100px"} display="block">
                      {smsCampaign.total_recipients}
                    </Text>
                  </Td>
                  <Td>
                    <Text noOfLines={1} maxW={"100px"} display="block">
                      {smsCampaign.clicks}
                    </Text>
                  </Td>
                  <Td
                    display={"flex"}
                    width={"100px"}
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems="center">
                    <Badge
                      colorScheme={getColorScheme(
                        getRealCampaignStatus(smsCampaign)
                      )}>
                      {t(
                        `enums.SmsCampaignStatus.${getRealCampaignStatus(
                          smsCampaign
                        )}`
                      )}
                    </Badge>
                  </Td>
                  <Td>
                    {getRealCampaignStatus(smsCampaign) === "scheduled" ? (
                      <ButtonIcon
                        tooltipLabel="Cancelar agendamento"
                        icon={
                          <MdFreeCancellation
                            color={colors.darkGrey}
                            size={20}
                          />
                        }
                        onClick={() => {
                          setSelectedCampaignId(smsCampaign.id);
                          onOpenAlert();
                        }}
                      />
                    ) : null}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={() => {
          setSelectedCampaignId(null);
          onCloseAlert();
        }}
        title="Confirmar cancelamento"
        onConfirm={() => {
          cancelSmsCampaign.mutateAsync(selectedCampaignId!);
          onCloseAlert();
        }}>
        Tem certeza que deseja cancelar o agendamento desta campanha?
      </AlertDialogBase>
    </LoadingScreen>
  );
};

export default TableSmsCampaigns;
