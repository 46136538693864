import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
  useId,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import AlertDialogBase from "../components/AlertDialog";
import CrudModal from "../components/CrudModal";
import { apiRoutes } from "../constants/api-routes";
import { colors } from "../constants/colors";
import {
  CompaniesService,
  CreateCompanyDefinedFieldDto,
} from "../services/companies.service";
import { CompanyDefinedFieldsService } from "../services/company-defined-fields.service";
import { MixpanelService } from "../services/mixpanel.service";
import { CompanyDefinedField } from "../types/CompanyDefinedField";
import {
  CompanyDefinedFieldDataTypeEnum,
  CompanyDefinedFieldTableEnum,
} from "../types/CompanyDefinedField";
import useGetCrudText from "./useGetCrudText";

interface CrudCompanyDefinedFieldModalContextData {
  openCreateModal: () => void;
  openEditModal: (companyDefinedFieldId: string) => void;
  handleClickDelete: (companyDefinedFieldId: string) => void;
}

interface CrudCompanyDefinedFieldProps {
  children?: ReactNode;
}

const CrudCompanyDefinedFieldModalContext = createContext(
  {} as CrudCompanyDefinedFieldModalContextData
);

const schema = yup
  .object({
    table: yup.string().default("customers"),
    name: yup.string().required(),
    dataType: yup
      .string()
      .oneOf(Object.values(["string", "number", "boolean", "date"]))
      .required(),
  })
  .required();

export function CrudCompanyDefinedFieldModalProvider({
  children,
}: CrudCompanyDefinedFieldProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [companyDefinedFieldId, setCompanyDefinedFieldId] = useState<
    string | null
  >(null);
  const { crudActionText, crudTitle } = useGetCrudText(!companyDefinedFieldId);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const formId = useId();
  const toast = useToast();
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const createCompanyDefinedField = useMutation(
    (newCompanyDefinedField: CreateCompanyDefinedFieldDto) =>
      CompaniesService.createCompanyDefinedField(newCompanyDefinedField),
    {
      onSuccess: (res) => {
        MixpanelService.track("create-company-defined-field");
        queryClient.setQueryData(
          apiRoutes.listCompanyDefinedFields(
            CompanyDefinedFieldTableEnum.CUSTOMERS
          ),
          (old: any) => [...old, res.data]
        );
        resetState();
      },
    }
  );

  const deleteCompanyDefinedField = useMutation(
    (companyDefinedFieldId: string) =>
      CompanyDefinedFieldsService.deleteCompanyDefinedField(
        companyDefinedFieldId
      ),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          apiRoutes.listCompanyDefinedFields(
            CompanyDefinedFieldTableEnum.CUSTOMERS
          ),
          (old: any) =>
            old.filter(
              (option: CompanyDefinedField) => option.id !== res.data.id
            )
        );
      },
    }
  );

  async function onSubmit(data: any) {
    try {
      if (!companyDefinedFieldId) {
        await createCompanyDefinedField.mutateAsync(data);
      }
    } catch (err) {}
  }

  function openEditModal(companyDefinedFieldId: string) {
    setCompanyDefinedFieldId(companyDefinedFieldId);
    onOpen();
  }

  function handleCloseModal() {
    resetState();
  }

  function openCreateModal() {
    setCompanyDefinedFieldId(null);
    onOpen();
  }

  function resetState() {
    onClose();
    setCompanyDefinedFieldId(null);

    setValue("dataType", null);
    setValue("name", "");
  }

  async function handleClickDelete(companyDefinedFieldId: string) {
    deleteCompanyDefinedField.mutate(companyDefinedFieldId);
    onClose();
  }

  return (
    <CrudCompanyDefinedFieldModalContext.Provider
      value={{
        openCreateModal,
        openEditModal,
        handleClickDelete: (companyDefinedFieldId: string) => {
          setCompanyDefinedFieldId(companyDefinedFieldId);
          onOpenAlert();
        },
      }}>
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={onCloseAlert}
        title="Deletar resposta automática"
        onConfirm={() => {
          handleClickDelete(companyDefinedFieldId!);
          onCloseAlert();
        }}>
        Tem certeza que deseja deletar esta opção?
      </AlertDialogBase>
      <CrudModal
        isLoading={createCompanyDefinedField.isLoading}
        actionButtonText={crudActionText}
        isOpen={isOpen}
        onClose={handleCloseModal}
        title={`${crudTitle} resposta automática`}
        onClickDelete={onOpenAlert}
        canDelete={Boolean(companyDefinedFieldId)}
        formId={formId}>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack spacing={2}>
            <FormControl isRequired>
              <FormLabel>Nome do campo</FormLabel>
              <Input
                placeholder="Escreva o nome do campo"
                {...register("name")}
                isInvalid={errors.name?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Tipo de dado</FormLabel>
              <Select
                placeholder="Selecione uma condição"
                {...register("dataType")}>
                {[
                  {
                    value: CompanyDefinedFieldDataTypeEnum.STRING,
                    label: "Texto",
                  },
                  {
                    value: CompanyDefinedFieldDataTypeEnum.NUMBER,
                    label: "Número",
                  },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.dataType?.message}
              </Text>
            </FormControl>
          </Stack>
        </form>
      </CrudModal>
      {children}
    </CrudCompanyDefinedFieldModalContext.Provider>
  );
}

export function useCrudCompanyDefinedFieldModal(): CrudCompanyDefinedFieldModalContextData {
  const context = useContext(CrudCompanyDefinedFieldModalContext);
  if (!context) {
    throw new Error(
      "useCrudCompanyDefinedFieldModal must be used within a CrudCompanyDefinedFieldModal"
    );
  }
  return context;
}
