import { Button, Tooltip } from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { hoverButtonStyles } from "../../styles/hover.styles";

interface ButtonIconProps {
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  tooltipLabel?: string;
}

const ButtonIcon = forwardRef(
  (
    {
      icon, 
      onClick, 
      disabled, 
      tooltipLabel
    }: ButtonIconProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Button
        ref={ref}
        onClick={onClick}
        isDisabled={disabled}
        bgColor="transparent"
        height="fit-content"
        padding={0}
        _hover={hoverButtonStyles}
      >
        {tooltipLabel ? (
          <Tooltip label={tooltipLabel}>
            <span>{icon}</span>
          </Tooltip>
        ) : (
          icon
        )}
      </Button>
    );
  }
);
ButtonIcon.displayName = "ButtonIcon";

export default ButtonIcon;
