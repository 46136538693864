import { Box, Flex, Skeleton, Spinner, Tooltip } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { IoIosAlert } from "react-icons/io";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MessageItem from "../../../../components/MessageItem";
import { colors } from "../../../../constants/colors";
import { MessagesService } from "../../../../services/messages.service";
import {
  addMessagesToConversation,
  getMessagesByConversationId,
} from "../../../../state/inboxSlice";
import { AppDispatch, RootState } from "../../../../state/store";
import {
  MessageCard,
  MessageWithCardsIncludes,
} from "../../../../types/Message";

interface ContainerMessagesProps {
  conversationId: string;
}

const ContainerMessages = ({ conversationId }: ContainerMessagesProps) => {
  const chatRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const messages = useSelector((state: RootState) =>
    getMessagesByConversationId(state, conversationId)
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const {
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isFetching,
    hasPreviousPage,
    data,
  } = useInfiniteQuery(
    ["messages", conversationId],
    async ({ pageParam = 1 }) => {
      const { data } = await MessagesService.listMessagesByConversation(
        conversationId,
        pageParam
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === 0) return undefined;
        return pages.length + 1;
      },
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length - 1];
        dispatch(
          addMessagesToConversation({
            conversationId,
            messages: lastPage,
          })
        );
      },
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    handleScroll();
  }, [messages]);

  // Scroll to bottom when new message is sent
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages[0]?.tempId]);

  const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } =
      chatRef.current as HTMLDivElement;
    const isScrolledToTop =
      Math.abs(clientHeight - scrollHeight - scrollTop) <= 1;

    if (isScrolledToTop && hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
    }
  };

  return (
    <Flex
      ref={chatRef}
      onScroll={handleScroll}
      minWidth="100%"
      height="100%"
      overflow={"auto"}
      flexDirection="column-reverse"
    >
      <div ref={messagesEndRef} />
      {isFetching && messages.length === 0 ? (
        <Box>
          {Array.from({ length: 10 }).map((_, index) => (
            <Flex
              margin={3}
              key={index}
              justifyContent={index % 3 === 0 ? "flex-end" : "flex-start"}
            >
              <Skeleton
                height={index % 4 === 0 ? "64px" : "40px"}
                width={index % 2 !== 0 ? "300px" : "150px"}
                borderRadius={"15px"}
              />
            </Flex>
          ))}
        </Box>
      ) : (
        messages.map((message, index) => {
          return (
            <Flex
              marginTop={1}
              marginBottom={1}
              marginLeft={2}
              marginRight={2}
              justifyContent={message.fromSystem ? "flex-end" : "flex-start"}
              key={message.id || message.tempId}
              alignItems="center"
            >
              <MessageItem
                createdAt={message.createdAt!}
                bgColor={
                  message.status === "failed"
                    ? "#FED6D7"
                    : message.fromSystem
                    ? colors.blueLight
                    : colors.lightGrey
                }
                textColor={colors.black}
                status={message.status}
                isFromSystem={message.fromSystem}
                text={message.text}
                mediaType={message.mediaType}
                mediaId={message.mediaId}
                fileKey={message.fileKey}
                mediaUrl={message.mediaUrl}
                messageTemplateId={message.messageTemplateId}
                uploadProgress={message.uploadProgress}
              />
              {message.status === "failed" && (
                <Tooltip label={"Falha no envio da mensagem"}>
                  <span>
                    <IoIosAlert color={colors.darkGrey} size={32} />
                  </span>
                </Tooltip>
              )}
            </Flex>
          );
        })
      )}
      {isFetchingNextPage && (
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          padding="20px"
        >
          <Spinner />
        </Box>
      )}
    </Flex>
  );
};

export default ContainerMessages;
