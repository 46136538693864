import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Th } from "../../../../../components/CustomTable";
import ReportOverviewData from "../../../../../types/ReportOverviewData";

interface CardAverageTimeToFirstReplyProps {
  overviewInfo: ReportOverviewData;
}

export default function CardAverageTimeToFirstReply({
  overviewInfo,
}: CardAverageTimeToFirstReplyProps) {
  return (
    <Card>
      <CardHeader>
        <Center>
          <b>Tempo Médio Para Primeira Resposta</b>
        </Center>
      </CardHeader>
      <CardBody>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Tempo médio por atendimento</Th>
                <Th>Quantidade</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{"< 5m"}</Td>
                <Td>
                  {overviewInfo.averageFirstResponseTimePeriods.lessThan5m}
                </Td>
              </Tr>
              <Tr>
                <Td>{"5m - 15m"}</Td>
                <Td>
                  {overviewInfo.averageFirstResponseTimePeriods.between5mAnd15m}
                </Td>
              </Tr>
              <Tr>
                <Td>{"15m - 30m"}</Td>
                <Td>
                  {
                    overviewInfo.averageFirstResponseTimePeriods
                      .between15mAnd30m
                  }
                </Td>
              </Tr>
              <Tr>
                <Td>{"30m - 1h"}</Td>
                <Td>
                  {overviewInfo.averageFirstResponseTimePeriods.between30mAnd1h}
                </Td>
              </Tr>
              <Tr>
                <Td>{"1h - 3h"}</Td>
                <Td>
                  {overviewInfo.averageFirstResponseTimePeriods.between1hAnd3h}
                </Td>
              </Tr>
              <Tr>
                <Td>{"3h - 8h"}</Td>
                <Td>
                  {overviewInfo.averageFirstResponseTimePeriods.between3hAnd8h}
                </Td>
              </Tr>
              <Tr>
                <Td>{"> 8h"}</Td>
                <Td>
                  {overviewInfo.averageFirstResponseTimePeriods.moreThan8h}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}
