import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { MixpanelService } from "./mixpanel.service";

interface LoginParams {
  email: string;
  password: string;
}

const login = async ({
  email,
  password,
}: LoginParams): Promise<AxiosResponse<{ access_token: string }>> => {
  return request.post(apiRoutes.login(), { username: email, password });
};

const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

const logout = () => {
  localStorage.removeItem("access_token");
  MixpanelService.reset();
  window.dispatchEvent(new Event("storage"));
};

export interface UpdatePasswordDto {
  currentPassword: string;
  newPassword: string;
}

const updatePassword = (updatePasswordDto: UpdatePasswordDto) => {
  return request.post(apiRoutes.updatePassword(), updatePasswordDto);
};

export const AuthService = {
  login,
  getAccessToken,
  logout,
  updatePassword,
};
