import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import {
  MessageTemplatesService, RegenerateMessageTemplateDto
} from "../../../../services/message-templates.service";

interface RegenerateMessageTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRegenerateMessageTemplate: (content: string) => void;
  promptThreadId: string | null;
}

const RegenerateMessageTemplateModal = ({
  onClose,
  isOpen,
  onRegenerateMessageTemplate,
  promptThreadId,
}: RegenerateMessageTemplateModalProps) => {
  const [regeneratePrompt, setRegeneratePrompt] = useState<string>("");
  const regenerateMessageTemplate = useMutation(
    (regenerateMessageTemplateDto: RegenerateMessageTemplateDto) =>
      MessageTemplatesService.regenerateMessageTemplate(
        regenerateMessageTemplateDto
      )
  );

  async function handleClickRegenerate() {
    const { data } = await regenerateMessageTemplate.mutateAsync({
      promptText: regeneratePrompt,
      promptThreadId: promptThreadId!,
    });
    onRegenerateMessageTemplate(data.content);
    handleClose();
  }

  function resetState() {
    setRegeneratePrompt("");
  }

  function handleClose() {
    resetState();
    onClose();
  }

  function handleClickOption(option: string) {
    setRegeneratePrompt((prevPrompt) => {
      if (prevPrompt) {
        return `${prevPrompt}; ${option.toLocaleLowerCase()}`;
      }
      return option;
    });
  }
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Regenerar template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel>
                Descreva as mudança que deseja fazer separadas por ponto e vírgula ( ; )
              </FormLabel>
              <Textarea
                placeholder="Ex: Deixe o tom da mensagem mais formal; aumente o tamanho da mensagem"
                value={regeneratePrompt}
                onChange={(e) => setRegeneratePrompt(e.target.value)}
              />
            </FormControl>
          </Stack>
          <Box mt={3}>
            <Text>Sugestões</Text>
            <Flex
              mt={1}
              gap={2}
              alignItems="center"
              flexWrap={"wrap"}
              maxW="100%">
              {[
                {
                  text: "Reduza o número de emojis",
                  value:
                    "Reduza o número de emojis, deixe pelo menos 1 emoji",
                },
                {
                  text: "Encurte a mensagem",
                  value: "Deixe a mensagem mais curta",
                },
                {
                  text: "Aumente o tamanho da mensagem",
                  value: "Adicione mais informação para a mensagem",
                },
                {
                  text: "Deixe o tom da mensagem mais formal",
                  value: "Deixe o tom da mensagem mais formal",
                },
                {
                  text: "Deixe o tom da mensagem mais informal",
                  value: "Deixe o tom da mensagem mais informal",
                },
                {
                  text: "Melhore o espaçamento do texto",
                  value: "Melhore o espaçamento do texto",
                },
                {
                  text: "Deixe o tom da mensagem mais pessoal",
                  value: "Deixe o tom da mensagem mais pessoal",
                },
              ].map((option) => (
                <Button
                  key={option.value}
                  color="gray.500"
                  variant="outline"
                  size={"xs"}
                  borderRadius={"2xl"}
                  onClick={() => handleClickOption(option.value)}>
                  {option.text}
                </Button>
              ))}
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            isLoading={regenerateMessageTemplate.isLoading}
            onClick={handleClickRegenerate}
            isDisabled={!regeneratePrompt}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegenerateMessageTemplateModal;
