import { TableCellProps, Td as TdChakra, Text, Tooltip } from "@chakra-ui/react";

interface TdProps extends TableCellProps {
  children: React.ReactNode;
  textForTooltip?: string;
  minWidth?: string;
}

export function TextTd({ children, textForTooltip, minWidth = '8rem', ...rest }: TdProps) {
  return (
    <TdChakra whiteSpace={"nowrap"} overflow={"hidden"} minWidth={minWidth} {...rest}>
      {textForTooltip ? (
        <Tooltip label={textForTooltip} placement={"bottom-start"}>
          <Text overflow={"hidden"} textOverflow={"ellipsis"}>{children}</Text>
        </Tooltip>
      ) : (
        <Text overflow={"hidden"} textOverflow={"ellipsis"}>{children}</Text>
      )}
    </TdChakra>
  );
}
