import {
  IconButton,
  MenuButton,
  Menu,
  MenuList,
  MenuDivider,
  MenuItem,
} from "@chakra-ui/react";
import { AiOutlineMore } from "react-icons/ai";
import { CustomMenuItem } from "./type/CustomMenuItem";

interface CustomMenuProps {
  items: CustomMenuItem[];
}

const MessageItem = ({ items }: CustomMenuProps) => {
  function buildMenuItems() {
    const res = items.map((item) =>
      item.isDivider ? (
        <MenuDivider />
      ) : (
        <MenuItem icon={item.icon} onClick={item.onClick}>
          {item.label}
        </MenuItem>
      )
    );

    return res;
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Menu"
        icon={<AiOutlineMore size={22} />}
        variant="none"
      />
      <MenuList>{buildMenuItems()}</MenuList>
    </Menu>
  );
};

export default MessageItem;
