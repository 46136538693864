function camelToSnake(camelStr: string) {
  return camelStr.replace(/[A-Z]/g, (match) => '_' + match.toLowerCase());
}

function snakeToCamel(snakeStr: string) {
  return snakeStr.replace(/(_\w)/g, (match) =>
    match.toUpperCase().replace('_', ''),
  );
}

function camelToParameter(camelStr: string) {
  const formattedString = camelToSnake(camelStr).replace(/_/g, ' ');
  return `[${formattedString}]`;
}

function isNumeric(value: string) {
  return /^-?\d+\.?\d+$/.test(value);
}

function toCamelCase(key: string) {
  return key.toLowerCase().replace(/[_|\s]([a-zA-Z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
}

export const StringUtils = {
  camelToSnake,
  snakeToCamel,
  camelToParameter,
  isNumeric,
  toCamelCase,
};
