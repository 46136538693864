import { IoMdArchive } from "react-icons/io";
import InboxToolbar, { InboxToolbarAction, InboxToolbarProps } from ".";
import { useMutation } from "react-query";
import { ConversationsService } from "../../../../services/conversations.service";
import { Conversation } from "../../../../types/Conversation";
import { useToast } from "@chakra-ui/react";

interface OpenConversationToolbarProps extends Omit<InboxToolbarProps, 'actions'> {
  selectedConversationsIds?: string[]
  onConversationsTicketsClosed?: (data: Conversation[]) => void
}

const OpenConversationToolbar = ({
  statusMessage,
  selectedConversationsIds = [], 
  totalCount, 
  selectedCount = 0,
  onConversationsTicketsClosed
}: OpenConversationToolbarProps) => {
  const toast = useToast();

  const closeManyConversationsTicketsMutation = useMutation(
    async (conversationIds: string[]): Promise<Conversation[]> => {  
      const {data} = await ConversationsService.closeAllTicketsFromManyConversations(conversationIds);
      return data;
    },
    {
      onSuccess: (data: Conversation[]) => {
        onConversationsTicketsClosed?.(data);
        const successCount = data.length;
        const plural = successCount > 1 ? 's' : '';
        toast({
          title: `${successCount} conversa${plural} selecionada${plural} movida${plural} para "finalizado"`,
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const handleCloseSelectedConversationsTickets = () => {
    closeManyConversationsTicketsMutation.mutate(selectedConversationsIds);
  }

  const actions: InboxToolbarAction[] = [
    {
      name: "finish",
      label: "Finalizar selecionadas",
      icon: <IoMdArchive size={12} />,
      onClick: () => handleCloseSelectedConversationsTickets(),
      isDisabled: () => selectedCount === 0,
      isLoading: closeManyConversationsTicketsMutation.isLoading,
    },
  ]
  
  return (
    <InboxToolbar statusMessage={statusMessage} actions={actions} selectedCount={selectedCount} totalCount={totalCount} />
  )
}

export default OpenConversationToolbar;