import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image, Text,
  useRadioGroup,
  VStack
} from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import innactiveCustomerIcon from "../../assets/images/inactive-customer-icon.png";
import newCustomerIcon from "../../assets/images/new-customer-icon.png";
import productLaunchIcon from "../../assets/images/product-launch-icon.png";
import promotionIcon from "../../assets/images/promotion-icon.png";
import InputNumber from "../../components/InputNumber";
import RadioOptionWrapper from "../../components/RadioOptionWrapper";
import {
  AudienceRecommendation,
  AudienceRecommendationTypeEnum
} from "../../types/AudienceRecommendation";
import { CustomerFiltersEnum } from "../../types/CustomerFiltersEnum";
import { AudienceRecommendationClassSlug } from "../../types/Prisma";

const audienceImagePathByGoal: Record<AudienceRecommendationTypeEnum, string> = {
  [AudienceRecommendationTypeEnum.NEW_CUSTOMERS]: newCustomerIcon,
  [AudienceRecommendationTypeEnum.INACTIVE_CUSTOMERS]: innactiveCustomerIcon,
  [AudienceRecommendationTypeEnum.PRODUCT_LAUNCH]: productLaunchIcon,
  [AudienceRecommendationTypeEnum.PROMOTION]: promotionIcon,
};

interface AudienceFiltersFormProps {
  handleSubmitAudienceFilters: (e: FormEvent<HTMLFormElement>) => void;
  id: string;
  audienceRecommendations: AudienceRecommendation[];
}

export function AudienceFiltersForm({
  handleSubmitAudienceFilters,
  audienceRecommendations,
  id,
}: AudienceFiltersFormProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAudienceClass, setSelectedAudienceClass] =
    useState<AudienceRecommendationClassSlug | null>(null);
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: "audienceType",
    onChange: handleChangeAudienceType,
  });

  function handleChangeAudienceType(audienceType: string) {
    const selectedAudience = audienceRecommendations.find(
      (audience) => audience.type === audienceType
    );
    setSelectedAudienceClass(selectedAudience?.audienceClass || null);
  }

  function getLabelByRecommendationType(type: AudienceRecommendationTypeEnum) {
    const labels: Record<AudienceRecommendationTypeEnum, string> = {
      [AudienceRecommendationTypeEnum.NEW_CUSTOMERS]: "Fidelizar clientes novos",
      [AudienceRecommendationTypeEnum.INACTIVE_CUSTOMERS]:
        "Recuperar clientes inativos",
      [AudienceRecommendationTypeEnum.PRODUCT_LAUNCH]: "Lançamento",
      [AudienceRecommendationTypeEnum.PROMOTION]: "Promoção",
    };
    return labels[type] || "";
  }

  const audienceOptions: {
    value: string;
    label: string;
    imageSrc: string;
  }[] =
    audienceRecommendations.map((audience) => ({
      value: audience.type,
      label: getLabelByRecommendationType(audience.type),
      imageSrc: audienceImagePathByGoal[audience.type],
    }));

  const group = getRootProps();
  return (
    <form id={id} onSubmit={handleSubmitAudienceFilters}>
      <VStack pt="2" gap={5}>
        <FormControl>
          <FormLabel fontSize={"sm"}>
            <span style={{ fontSize: "15px" }}>
              Recomendações da Revi (
              <i>
                <b>Inteligência Artificial</b>
              </i>
              )
            </span>
          </FormLabel>
            <Flex {...group} flexDir='row' gap={2} overflowX='scroll'>
                {[
                  {
                    value: "none",
                    label: "Nenhuma",
                    imageSrc: "",
                  },
                  ...audienceOptions,
                ].map((option, index) => {
                  const value = option.value;
                  const radio = getRadioProps({ value });
                  return (
                    <RadioOptionWrapper key={value} radioProps={radio}>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="center"
                        justifyContent={index === 0 ? "center" : ""}
                        padding={2}
                        height="140px"
                        width="100px"
                        gap={2}>
                        {index !== 0 && (
                          <Image
                            src={option.imageSrc}
                            alt={option.value}
                            boxSize="50px"
                          />
                        )}
                        <Box>
                          <Text fontSize="sm" textAlign="center">
                            {option.label}
                          </Text>
                        </Box>
                      </Box>
                    </RadioOptionWrapper>
                  );
                })}
            </Flex>
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontSize={"sm"}>
            Ocultar clientes que <u>receberam campanha</u> nos últimos
          </FormLabel>
          <InputNumber
            name={CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN}
            size="sm"
            rightAddon="dias"
            defaultValue={
              searchParams.get(CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN)
                ? Number(
                    searchParams.get(
                      CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN
                    )
                  )
                : 14
            }
          />
        </FormControl>
        {selectedAudienceClass !== "new" && (
          <FormControl>
            <FormLabel fontSize={"sm"}>
              Ocultar clientes que <u>compraram</u> nos últimos
            </FormLabel>
            <InputNumber
              name={CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE}
              size="sm"
              rightAddon="dias"
              defaultValue={
                searchParams.get(
                  CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE
                )
                  ? Number(
                      searchParams.get(
                        CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE
                      )
                    )
                  : 21
              }
            />
          </FormControl>
        )}
      </VStack>
    </form>
  );
}
