import { Box, Button, Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import TableTagSettings from "./components/TableTagSettings";
import { useCrudTagModal } from "../../../hooks/useCrudTagModal";

const TagSettingsPage = () => {
  const { openCreateModal } = useCrudTagModal();

  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="space-between" paddingBottom={"16px"}>
        <Heading>Tags</Heading>
        <Button
          variant='primary'
          onClick={openCreateModal}
          minWidth={100}
        >
          + tag
        </Button>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableTagSettings />
    </Box>
  );
};

export default TagSettingsPage;
