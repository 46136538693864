import {
  Box,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { MessageTemplate } from "../../types/MessageTemplate";

interface DirectDeliveryFormProps {
  selectedTemplate: MessageTemplate;
  templateParams: string[];
  templateArgs: { [key: string]: string | undefined };
  handleChangeTemplateParameter: (parameter: string, value: string) => void;
}

export function DirectDeliveryForm({
  selectedTemplate,
  templateParams,
  templateArgs,
  handleChangeTemplateParameter,
}: DirectDeliveryFormProps) {
  function handleChange(param: string, e: any) {
    let value = e.target.value;
    if (value.includes("\n")) {
      value = value.replace(/[\r\n]+/gm, " ");
    }

    value = value.replace(/^\s+/, "");

    handleChangeTemplateParameter(param, value);
  }

  return (
    <Box>
      <Card>
        <CardHeader pb="1">
          <Text fontWeight={"bold"}>{selectedTemplate.name}</Text>
        </CardHeader>
        <CardBody pt="1">{selectedTemplate.templateText}</CardBody>
      </Card>
      <VStack pt="2">
        {templateParams.map((param) => (
          <FormControl isRequired>
            <FormLabel>
              {param.replaceAll("[", "").replaceAll("]", "")}
            </FormLabel>
            {param.includes("texto personalizado") ? (
              <Textarea
                onChange={(e) => handleChange(param, e)}
                value={templateArgs[param] || ""}
                required
              />
            ) : (
              <Input
                onChange={(e) => handleChange(param, e)}
                value={templateArgs[param] || ""}
                required
              />
            )}
          </FormControl>
        ))}
      </VStack>
    </Box>
  );
}
