import { Card, CardBody, CardHeader, Center } from "@chakra-ui/react";
import CustomECharts from "../../../../../components/CustomECharts";

interface CardTicketsPerCategoryChartProps {
  categories: string[];
  values: number[];
}

export default function CardTicketsPerCategoryChart({
  categories,
  values,
}: CardTicketsPerCategoryChartProps) {
  return (
    <Card variant={"outline"}>
      <CardHeader>
        <Center>
          <b>Atendimentos Por Categoria</b>
        </Center>
      </CardHeader>
      <CardBody>
        <CustomECharts
          chartWidth={"100%"}
          chartHeight="450px"
          marginTop={"0px"}
          option={{
            xAxis: {
              type: "category",
              data: categories,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: values,
                type: "bar",
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
}
