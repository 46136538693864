function formatName(name: string) {
  return name.toLowerCase().replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

function getFirstName(name?: string): string | undefined {
  return name?.split(' ')[0]?.replace(/[^A-zÀ-ú]/g, '') || '';
}

export const NameUtils = {
  formatName,
  getFirstName,
};
