import { AxiosResponse } from 'axios';
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { Tag, TagWithCount } from '../types/Tag';

export interface CreateTagDto {
  name: string;
}

export interface UpdateTagDto {
  id: string;
  name: string;
}

const listTags = async (): Promise<AxiosResponse<TagWithCount[]>> => {
  return request.get(apiRoutes.listTags());
};

const createTag = async (tag: Pick<Tag, 'name'>): Promise<AxiosResponse<Tag>> => {
  return request.post(apiRoutes.createTag(), tag);
};

const updateTag = async (tag: UpdateTagDto) => {
  const { id, ...updateData } = tag;
  return request.put(apiRoutes.updateTag(id), updateData);
}

const deleteTag = async (tagId: string): Promise<AxiosResponse<void>> => {
  return request.delete(apiRoutes.deleteTag(tagId));
}

export const TagsService = {
  listTags,
  createTag,
  updateTag,
  deleteTag,
}
