import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { colors } from "../../constants/colors";

interface SiderProps {
  topElement?: React.ReactNode;
  width?: string;
  activeTab: string;
  items: {
    title: string;
    icon?: React.ReactNode;
    rightElement: React.ReactNode;
    onClick: () => void;
    contextMenuItems?: {
      title: string;
      onClick: () => void;
    }[];
    children: {
      title: string;
      icon?: React.ReactNode;
      rightElement: React.ReactNode;
      onClick: () => void;
      contextMenuItems?: {
        title: string;
        onClick: () => void;
      }[];
    }[];
  }[];
}

const SiderItem = ({
  activeTab,
  title,
  icon,
  onClick,
  children,
  rightElement,
  contextMenuItems,
}: SiderProps["items"][0] & {
  activeTab: string;
}) => {
  const hasChildren = children?.length > 0;
  const isActive = activeTab === title;
  const contextMenu = useDisclosure();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const hoverStyles = {
    cursor: "pointer",
    bgColor: "#FFFFFF",
  };

  function handleContextMenu(event: React.MouseEvent) {
    event.preventDefault();
    setPosition({ x: event.pageX, y: event.pageY });
    contextMenu.onOpen();
  }
  return (
    <div>
      {!!contextMenuItems && contextMenu.isOpen && (
        <Menu isOpen={true} onClose={() => contextMenu.onClose()}>
          <MenuButton as={Box} position='absolute'
            left={`${position.x}px`}
            top={`${position.y}px`}
          />
          <MenuList>
            {contextMenuItems?.map(({ title, onClick }) => (
              <MenuItem key={title} onClick={onClick}>
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
      <Stack
        onClick={onClick}
        paddingY={1}
        paddingLeft={2}
        borderRadius={5}
        onContextMenu={handleContextMenu}
        _hover={!hasChildren ? hoverStyles : {}}
        bgColor={isActive ? "#FFFFFF" : "transparent"}
      >
        {!hasChildren ? (
          <Flex alignItems="center" justifyContent={"space-between"}>
            <Flex alignItems="center" gap={3}>
              {icon}
              {title}
            </Flex>
            <Box marginRight={2}>{rightElement}</Box>
          </Flex>
        ) : (
          <Flex alignItems="center" gap={3} justifyContent={"space-between"}>
            <Text textTransform={"uppercase"} fontWeight="bold">
              {title}
            </Text>
            <Box marginRight={2}>{rightElement}</Box>
          </Flex>
        )}
        {hasChildren &&
          children.map((child) => (
            <SiderItem
              key={child.title}
              activeTab={activeTab}
              title={child.title}
              icon={child.icon}
              onClick={child.onClick}
              rightElement={child.rightElement}
              contextMenuItems={child.contextMenuItems}
              children={[]}
            />
          ))}
      </Stack>
    </div>
  );
};

const Sider = ({ items, activeTab, width, topElement }: SiderProps) => {
  return (
    <Stack
      minWidth={width}
      maxWidth={width}
      width={width}
      height="100vh"
      paddingTop={topElement ? "0" : "20px"}
      paddingX={"20px"}
      paddingBottom={"20px"}
      bgColor={colors.lightGrey}
      overflowY="auto"
    >
      {topElement}
      {items.map(
        ({
          title,
          icon,
          onClick,
          children,
          rightElement,
          contextMenuItems,
        }) => (
          <SiderItem
            key={title}
            title={title}
            icon={icon}
            onClick={onClick}
            children={children}
            rightElement={rightElement}
            activeTab={activeTab}
            contextMenuItems={contextMenuItems}
          />
        )
      )}
    </Stack>
  );
};

export default Sider;
