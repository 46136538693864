import { Component, useState } from "react";
import PropTypes from "prop-types";
import MediaRecorder from "opus-media-recorder";

// opus-media-recorder options
const workerOptions = {
  encoderWorkerFactory: function () {
    return new Worker(
      process.env.PUBLIC_URL + "/opus-media-recorder/encoderWorker.umd.js"
    );
  },
  OggOpusEncoderWasmPath:
    process.env.PUBLIC_URL + "/opus-media-recorder/OggOpusEncoder.wasm",
  WebMOpusEncoderWasmPath:
    process.env.PUBLIC_URL + "/opus-media-recorder/WebMOpusEncoder.wasm",
};

interface useOpusMediaRecorderParams {
  mimeType?: string;
}

type MediaRecorderState =
  | "notInitialized"
  | "recording"
  | "inactive"
  | "paused"
  | "recording";

const useOpusMediaRecorder = ({
  mimeType = "audio/ogg; codecs=opus",
}: useOpusMediaRecorderParams) => {
  const [recorderState, setRecorderState] =
    useState<MediaRecorderState>("notInitialized");
  const [recorder, setRecorder] = useState<any>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [timer, setTimer] = useState<any>(null);

  async function startRecording() {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const options = { mimeType };
    const newRecorder = new MediaRecorder(stream, options, workerOptions);
    setRecorderState("inactive");
    setRecorder(newRecorder);
    setMediaStream(stream);
    const timer = setInterval(() => {
      setRecordingDuration((prevState) => prevState + 1);
    }, 1000);
    setTimer(timer);

    newRecorder.start();
    newRecorder.addEventListener("dataavailable", (e: any) => {
      console.log("Recording stopped, data available");
      handleDataAvailable(e);
    });
    newRecorder.addEventListener("start", (e: any) => {
      console.log("start");
      setRecorderState("recording");
    });
    newRecorder.addEventListener("stop", (e: any) => {
      console.log("stop");
      setRecorderState("inactive");
    });
    newRecorder.addEventListener("pause", (e: any) => {
      console.log("pause");
      setRecorderState("paused");
    });
    newRecorder.addEventListener("resume", (e: any) => {
      console.log("resume");
      setRecorderState("recording");
    });
    newRecorder.addEventListener("error", (e: any) => {
      console.log("error");
    });
  }

  function stopRecording() {
    console.log("stop recording called");
    setRecorderState("inactive");
    if (recorder) {
      recorder.stop();
      resetMediaStream();
    }
    if (timer) {
      clearInterval(timer);
    }
  }

  function pauseRecording() {
    console.log("pause recording called");
    if (recorder) {
      recorder.pause();
    }
  }

  function handleDataAvailable(e: any) {
    const mediaUrl = window.URL.createObjectURL(e.data);
    setAudioUrl(mediaUrl);
  }

  function resetMediaStream() {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  function resetRecorder() {
    if (recorderState === "recording" && recorder.state === "recording") {
      stopRecording();
      setTimeout(resetRecorder, 100);
      return;
    }
    setRecordingDuration(0);
    resetMediaStream();
    setAudioUrl(null);
    setRecorder(null);
    setMediaStream(null);
    setRecorderState("notInitialized");
  }

  return {
    recorderState,
    startRecording,
    stopRecording,
    pauseRecording,
    audioUrl,
    resetRecorder,
    recordingDuration,
  };
};

export default useOpusMediaRecorder;
