import {
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { apiRoutes } from "../../../../constants/api-routes";
import { appPaths } from "../../../../constants/app-paths";
import {
  AutomationFormValues,
  AutomationsService,
  UpdateAutomationDto,
} from "../../../../services/automations.service";
import { Automation } from "../../../../types/Automation";
import { CronUtils } from "../../../../utils/cron.utils";
import AbandonedCartAutomationForm from "../components/AbandonedCartAutomationForm";
import AutomationForm from "../components/AutomationForm";

export interface AbandonedCartAutomationFormValues {
  name: string;
  isActive: boolean;
  automationType: { slug: string };
  messageTemplateId: string;
}

export interface UpdateAbandonedCartAutomationDto {
  name: string;
  isActive: boolean;
  messageTemplateId: string;
  templateArgs?: Record<string, string | null | undefined>;
}

const EditBackgroundAutomationPage = () => {
  const { automationId } = useParams();
  const [automationValues, setAutomationValues] = useState<AutomationFormValues | null>(null);
  const [abandonedCartAutomationValues, setAbandonedCartAutomationValues] = useState<AbandonedCartAutomationFormValues | null>(null);

  function mapAbandonedCartAutomation(automation: Automation): AbandonedCartAutomationFormValues {
    return {
      name: automation.name,
      isActive: automation.isActive,
      automationType: automation.automationType,
      messageTemplateId: automation.messageTemplateId,
    };
  }


  function mapAutomation(automation: Automation): AutomationFormValues | null {
    const cronValues = CronUtils.getValuesFromCronExpression(automation.cronExpression);
    if (!cronValues) return null;

    return {
      name: automation.name,
      automationType: automation.automationType,
      isActive: automation.isActive,
      messageTemplateId: automation.messageTemplateId,
      filterId: automation.filterId!,
      dailyMessageLimitOnWhatsapp: automation.dailyMessageLimitOnWhatsapp,
      isAutomationRepetitionAllowed: automation.isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign: automation.minDaysSinceLastCampaign,
      ...cronValues,
    };
  }

  function processData(data: any) {
    if (data.templateArgs) {
      setTemplateArgs(data.templateArgs);
    }

    if (data.automationType.slug === 'abandoned_cart') {
      return setAbandonedCartAutomationValues(mapAbandonedCartAutomation(data));
    }

    return setAutomationValues(mapAutomation(data));
  }

  useQuery(
    apiRoutes.showAutomation(automationId!),
    async () => {
      const { data } = await AutomationsService.showAutomation(automationId!);
      return data;
    },
    {
      onSuccess: (data) => {
        processData(data);
      },
    }
  )

  const navigate = useNavigate();
  const toast = useToast();

  const updateAutomation = useMutation(
    (createAutomationDto: UpdateAutomationDto) =>
      AutomationsService.updateAutomation(automationId!, createAutomationDto),
    {
      onSuccess: (res) => {
        toast({
          title: "Automação atualizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      }
    }
  );

  const updateAbandonedCartAutomation = useMutation(
    (updateAbandonedCartAutomationDto: UpdateAbandonedCartAutomationDto) =>
      AutomationsService.updateAbandonedCartAutomation(automationId!, updateAbandonedCartAutomationDto),
    {
      onSuccess: (res) => {
        toast({
          title: "Automação atualizada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      }
    }
  )

  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});

  async function onSubmitAbandonedCartAutomation(data: any) {
    const { name, isActive, messageTemplateId } = data;
    await updateAbandonedCartAutomation.mutateAsync({
      name,
      isActive,
      messageTemplateId,
      templateArgs,
    });
  }

  async function onSubmit(data: any) {
    const {
      name,
      isActive,
      messageTemplateId,
      filterId,
      dailyMessageLimitOnWhatsapp,
      frequency,
      minutesOfTheDay,
      daysOfWeek,
      daysOfMonth,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
    } = data;
    const hours = Math.floor(Number(minutesOfTheDay) / 60);
    const minutes = Number(minutesOfTheDay) % 60;

    await updateAutomation.mutateAsync({
      name,
      daysOfMonth: daysOfMonth?.filter(Boolean).map(Number) || [],
      daysOfWeek: daysOfWeek?.filter(Boolean).map(Number) || [],
      filterId,
      frequency,
      isActive,
      messageTemplateId,
      dailyMessageLimitOnWhatsapp,
      hours,
      minutes,
      templateArgs,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
    });
  }

  return (
    <>
      {automationValues && (
        <AutomationForm
          onSubmit={onSubmit}
          templateArgs={templateArgs}
          setTemplateArgs={setTemplateArgs}
          automation={automationValues}
        />
      )}

      {abandonedCartAutomationValues && (
        <AbandonedCartAutomationForm
          onSubmit={onSubmitAbandonedCartAutomation}
          templateArgs={templateArgs}
          setTemplateArgs={setTemplateArgs}
          automation={abandonedCartAutomationValues}
        />
      )}
    </>
  );
};

export default EditBackgroundAutomationPage;
