import { Select } from "@chakra-ui/react";

export interface SelectInputProps {
  field: any;
  options: { value: string; label: string }[];
  error?: string;
  borderBottom?: string;
  color?: string;
  width?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
}

const SelectInput = ({
  field,
  options,
  error,
  borderBottom,
  color,
  width,
  placeholder,
  onChange,
}: SelectInputProps) => {
  if (typeof field.value === 'object') {
    field.value = field.value.value;
  }

  return (
    <Select
      value={field.value || ""}
      style={{
        fontSize: 14,
        width: width ? width : '-webkit-fill-available',
        display: 'inline-block',
        height: 'fit-content',
        color: color ? color : '',
        borderColor: error ? '#ff6567' : '',
      }}
      {...field}
      onChange={onChange ? (e) => onChange(e) : field.onChange}
      variant='flushed'
      placeholder={placeholder ? placeholder : null}
    >
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}

export default SelectInput;
