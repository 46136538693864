import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Select,
  Text,
  useBoolean,
  VStack
} from "@chakra-ui/react";
import { useEffect } from "react";
import { CiCircleRemove } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import InputNumber from "../../../../../components/InputNumber";
import { colors } from "../../../../../constants/colors";
import { ListMessageTemplateItem } from "../../../../../services/message-templates.service";
import {
  addVariant,
  cancelExperiment,
  isExperiment,
  removeVariant,
  updateDurationInMinutes,
  updateTestSizePercentage,
  updateVariantTemplateArgs,
  updateWinningMetric
} from "../../../../../state/campaignCreationSlice";
import { RootState } from "../../../../../state/store";
import { WinningMetric } from "../../../../../types/CampaignExperiment";
import { CompanyDefinedField } from "../../../../../types/CompanyDefinedField";
import TemplateParameterEditor from "../SectionSelectTemplate/TemplateParameterEditor";
import TemplateSelector from "../SectionSelectTemplate/TemplateSelector";

interface SectionABTestingProps {
  templates: ListMessageTemplateItem[];
  companyDefinedFields: CompanyDefinedField[];
}

const SectionABTesting = ({
  templates,
  companyDefinedFields,
}: SectionABTestingProps) => {
  const {
    variants,
    testSizePercentage,
    winningMetric,
    durationInMinutes,
    communicationChannel,
    selectedTemplate,
  } = useSelector((state: RootState) => state.campaignCreation);
  const [isConfiguring, setIsConfiguring] = useBoolean();
  const dispatch = useDispatch();
  const isCampaignExperiment = useSelector(isExperiment);

  useEffect(() => {
    if (isCampaignExperiment) {
      setIsConfiguring.on();
    }
  }, []);

  function handleClickConfigure() {
    setIsConfiguring.on();
  }

  function handleClickAddVariant(templateId: string) {
    const templateText = templates.find((t) => t.id === templateId)?.templateText || "";
    if (!templateText) {
      return;
    }
    dispatch(
      addVariant({
        templateId,
        templateArgs: {},
        templateText,
      })
    );
  }

  function handleChangeTemplateArgs(
    index: number,
    templateArgs: Record<string, string>
  ) {
    dispatch(
      updateVariantTemplateArgs({
        index,
        templateArgs,
      })
    );
  }

  function handleChangeTestSizePercentage(value: number) {
    dispatch(updateTestSizePercentage(value));
  }

  function handleChangeDurationInMinutes(value: number) {
    dispatch(updateDurationInMinutes(value));
  }

  function handleChangeWinningMetric(value: WinningMetric) {
    dispatch(updateWinningMetric(value));
  }

  function handleClickRemoveVariant(index: number) {
    dispatch(removeVariant(index));
  }

  function handleClickCancel() {
    dispatch(cancelExperiment());
    setIsConfiguring.off();
  }

  return (
    <Card>
      <CardHeader
        paddingBottom={0}
        display="flex"
        justifyContent={"space-between"}>
        <Heading size="md">
          Teste A/B{" "}
          <Text as="span" color={colors.darkGrey} fontSize="sm">
            [opcional]
          </Text>
        </Heading>
        <Flex alignItems="center">
          {isConfiguring ? (
            <Button onClick={handleClickCancel} colorScheme="red">
              Cancelar
            </Button>
          ) : (
            <Button
              onClick={handleClickConfigure}
              isDisabled={!selectedTemplate}>
              Configurar
            </Button>
          )}
        </Flex>
      </CardHeader>

      <CardBody>
        {isConfiguring ? (
          <VStack gap={5} alignItems="flex-start">
            <Flex gap={3} width="100%">
            <FormControl flex={3}>
                <FormLabel>Métrica de comparação</FormLabel>
                <Select
                  defaultValue={winningMetric}
                  onChange={(e) =>
                    handleChangeWinningMetric(e.target.value as WinningMetric)
                  }>
                  {[{
                    value: WinningMetric.ENGAGEMENT_RATE,
                    label: "Taxa de engajamento (cliques ou respostas)"
                  }, {
                    value: WinningMetric.READ_RATE,
                    label: "Taxa de leitura"
                    }].map(({ value, label }) => (
                      <option key={value} value={value}>{label}</option>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl flex={1}>
                <FormLabel>Percentual para testes</FormLabel>
                <InputNumber
                  rightAddon="%"
                  value={testSizePercentage}
                  displayZero={true}
                  onChange={handleChangeTestSizePercentage}
                />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel>Duração do teste</FormLabel>
                <Select
                  defaultValue={String(durationInMinutes)}
                  onChange={(e) =>
                    handleChangeDurationInMinutes(Number(e.target.value))
                  }>
                  {
                    [
                      {
                        value: "60",
                        label: "1 hora"
                      },
                      {
                        value: "120",
                        label: "2 horas"
                      },
                      {
                        value: "180",
                        label: "3 horas"
                      }].map(({ value, label }) => (
                        <option key={value} value={value}>{label}</option>
                      )
                    )
                  }
                </Select>
              </FormControl>
            </Flex>
            {variants.length <= 0 && (
              <TemplateSelector
                templates={templates}
                onClickTemplate={(template) =>
                  handleClickAddVariant(template.id)
                }
                selectedTemplate={null}
                communicationChannel={communicationChannel}
                showSearch={false}
              />
            )}
            {variants.map(({ templateArgs, templateId }, index) => {
              const template = templates.find((t) => t.id === templateId);
              return (
                <Flex alignItems={"center"} gap={2}>
                  <IconButton
                    borderRadius="full"
                    aria-label="Remover variante"
                    variant={"ghost"}
                    icon={
                      <CiCircleRemove size={"100%"} color={colors.danger} />
                    }
                    onClick={() => handleClickRemoveVariant(index)}
                  />
                  <TemplateParameterEditor
                    onChangeTemplateArgs={(args) => {
                      handleChangeTemplateArgs(index, args);
                    }}
                    template={template!}
                    templateArgs={templateArgs || {}}
                    companyDefinedFields={companyDefinedFields}
                  />
                </Flex>
              );
            })}
          </VStack>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default SectionABTesting;
