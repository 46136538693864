import { Checkbox } from "@chakra-ui/react";
import { useController } from "react-hook-form";

interface InputCheckboxProps {
  options: {
    label: string;
    value: string;
  }[];
  control: any;
  name: string;
  itemWidth?: string;
}

const InputCheckbox = ({ options, control, name, itemWidth }: InputCheckboxProps) => {
  const { field } = useController({
    control,
    name,
  });

  return (
    <div>
      {options.map(({ label, value: option }, index) => (
        <Checkbox
          isChecked={field.value.includes(option)}
          onChange={(e) => {
            const valueCopy = [...field.value];

            if (e.target.checked) {
              valueCopy.push(option);
            } else {
              const index = valueCopy.indexOf(option);
              if (index > -1) valueCopy.splice(index, 1);
            }

            field.onChange(valueCopy);
          }}

          key={option}
          value={option}
          width={itemWidth}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );
};

export default InputCheckbox;
