import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { Product } from "../types/Product";

export interface ListProductsDto {
  search?: string;
  productIds?: string[];
}
const listProducts = async (
  listProductsDto: ListProductsDto
): Promise<AxiosResponse<Product[]>> => {
  return request.post(apiRoutes.listProducts(), listProductsDto);
};

export const ProductsService = {
  listProducts,
};
