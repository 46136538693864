import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaShopify } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { apiRoutes } from "../../../constants/api-routes";
import { colors } from "../../../constants/colors";
import { CompaniesService } from "../../../services/companies.service";
import { FlowsService } from "../../../services/flows.service";
import { ShopifyService } from "../../../services/shopify.service";
import { Company } from "../../../types/Company";

const schema = yup
  .object({
    // firstContactMessage: yup.string(),
    // name: yup.string(),
    // phoneNumber: yup.string(),
    // isAutomaticSortingActive: yup.boolean(),
  })
  .required();

const GeneralSettingsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useBoolean();
  const toast = useToast();
  const [company, setCompany] = useState<Company | null>(null);
  const { data: flows } = useQuery(apiRoutes.listFlows(), async () => {
    const { data } = await FlowsService.listFlows();
    return data;
  });

  useEffect(() => {
    CompaniesService.getCompanyDetails().then(({ data: company }) => {
      setCompany(company);
      setValue("firstContactMessage", company.firstContactMessage);
      setValue("name", company.name);
      setValue("phoneNumber", company.phoneNumber);
      setValue("isAutomaticSortingActive", company.isAutomaticSortingActive);
      setValue("initialContactFlowId", company.initialContactFlowId);
    });
  }, [JSON.stringify(flows)]);

  async function onSubmit(data: any) {
    setIsLoading.on();
    CompaniesService.updateCompany({
      ...data,
      initialContactFlowId: data.initialContactFlowId || null,
    })
      .then((res) => {
        toast({
          title: "Opção salva com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading.off();
      });
  }

  return (
    <Box padding="20px">
      <Container>
        <Heading size="md" mb={5}>
          Dados cadastrais
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel>Nome da empresa</FormLabel>
              <Input
                placeholder="Nome"
                {...register("name")}
                isInvalid={errors.name?.message}
                disabled
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Número whatsapp cadastrado</FormLabel>
              <Input
                {...register("phoneNumber")}
                isInvalid={errors.name?.message}
                disabled
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            <Divider />
            <Heading size="md">Triagem</Heading>
            <FormControl>
              <FormLabel>Mensagem inicial</FormLabel>
              <Textarea
                placeholder="Digite aqui a mensagem inicial"
                {...register("firstContactMessage")}
                isInvalid={errors.firstContactMessage?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.firstContactMessage?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Fluxo após mensagem inicial</FormLabel>
              <Select
                placeholder="Selecione um fluxo"
                {...register("initialContactFlowId")}>
                {flows?.map((flow) => (
                  <option key={flow.id} value={flow.id}>
                    {flow.title}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.initialContactFlowId?.message}
              </Text>
            </FormControl>            
            <Flex justify={"flex-end"}>
              <Button
                width="30%"
                isLoading={isLoading}
                color={colors.white}
                bgColor={colors.primary}
                type="submit">
                Salvar
              </Button>
            </Flex>
          </Stack>
        </form>
      </Container>
    </Box>
  );
};

export default GeneralSettingsPage;
