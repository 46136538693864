export enum WinningMetric {
  ENGAGEMENT_RATE= 'engagement_rate',
  READ_RATE= 'read_rate'
};

export enum ExperimentStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}