import {
  DrawerBody,
  Flex,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as yup from "yup";
import { TagsService } from "../../../../../../../services/tags.service";
import { AddTagToCustomerNodeData } from "../../../../../../../types/ReactFlowNode";
import { apiRoutes } from "../../../../../../../constants/api-routes";
import { colors } from "../../../../../../../constants/colors";
import InputSelect from "../../../../../../../components/InputSelect";
import { useState } from "react";

const schema = yup
  .object({
    tag: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
  })
  .required();

export interface AddTagToCustomerNodeEditorProps {
  data: AddTagToCustomerNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const AddTagToCustomerNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: AddTagToCustomerNodeEditorProps) => {
  const [tagOptions, setTagOptions] = useState<{
    value: string;
    label: string;
  }[]
  >([]);

  const { tagId } = data;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useQuery(apiRoutes.listTags(), async () => {
    const { data } = await TagsService.listTags();
    return data;
  },
  {
    onSuccess: (data) => {
      const currentTag = data.find((tag) => tag.id === tagId);
      if (currentTag) {
        setValue("tag", {
          value: currentTag.id,
          label: currentTag.name,
        });
      }

      setTagOptions(
        data.map((tag) => ({
          value: tag.id,
          label: tag.name,
        }))
      );
    }
  });

  const queryClient = useQueryClient();

  const createTagMutation = useMutation(
    async (tag: { name: string }) => {
      const { data } = await TagsService.createTag(tag);
      return data;
    },
    {
      onSuccess: (data) => {
        const newTagOption = {
          value: data.id,
          label: data.name,
        };
        setTagOptions((prev) => [...prev, newTagOption]);
        setValue("tag", newTagOption);
      },
    }
  );

  async function handleCreateTag(category: string) {
    await createTagMutation.mutate({ name: category });
  }

  const handleFormSubmit = (data: any) => {
    const tagId = data.tag.value;
    onSaveNode({ ...data, tagId });
    queryClient.invalidateQueries(apiRoutes.listTags());
  }

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(handleFormSubmit)} id={formId}>
        <Flex flexDir={"column"} gap={3}>
          <FormControl>
            <FormLabel>Nova Tag</FormLabel>
            <Controller
              name="tag"
              control={control}
              render={({ field }) => (
                <InputSelect
                  onCreateOption={handleCreateTag}
                  placeholder="Selectionar tag"
                  options={tagOptions}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.tag?.message}
            </Text>
          </FormControl>
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default AddTagToCustomerNodeEditor;
