import { Card, CardBody, Center, Flex } from "@chakra-ui/react";
import { colors } from "../../../../../constants/colors";

interface InfoCardProps {
  title: string;
  value: string | number;
  bgColor?: string;
  titleFontColor?: string;
  valueFontColor?: string;
}

export default function InfoCard({
  title,
  value,
  bgColor = colors.white,
  titleFontColor = colors.darkGrey,
  valueFontColor = colors.black,
}: InfoCardProps) {
  return (
    <Card
      variant="outline"
      style={{ width: 260, height: 125, backgroundColor: bgColor }}
    >
      <CardBody style={{ padding: "0px", margin: 0 }}>
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{ height: 60 }}
        >
          <b
            style={{
              color: titleFontColor,
              display: "-webkit-box",
              textAlign: "center",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </b>
        </Flex>
        <Center>
          <span
            style={{
              color: valueFontColor,
              fontSize: 35,
              fontWeight: "normal",
            }}
          >
            {value}
          </span>
        </Center>
      </CardBody>
    </Card>
  );
}
