import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

interface FilePreviewProps {
  file: File;
}

const FilePreview = ({ file }: FilePreviewProps) => {
  const isImage = file?.type.startsWith("image/");
  const isPdf = file?.type === "application/pdf";

  return isImage ? (
    <Image
      src={URL.createObjectURL(file)}
      alt={file.name}
      maxH="150px"
      mb={2}
    />
  ) : isPdf ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
      borderRadius="md"
      p={2}
      w="100%"
      h="150px"
      mb={2}>
      <Text fontSize="lg" fontWeight="bold" color="gray.500">
        PDF
      </Text>
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.100"
      borderRadius="md"
      p={2}
      w="100%"
      h="150px"
      mb={2}>
      <Text fontSize="lg" fontWeight="bold" color="gray.500">
        {file.name.split(".").pop()?.toUpperCase()}
      </Text>
    </Box>
  );
};

export default FilePreview;
