import { Box, Image, Text } from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";

interface ImageDropzoneProps {
  dropzone: any;
  preview: string;
  maxW?: string;
  maxH?: string;
}

const ImageDropzone = ({
  dropzone,
  preview,
  maxW='200px',
  maxH='200px',
}: ImageDropzoneProps) => {
  return (
    <Box {...dropzone.getRootProps()}>
      <Box position="relative" display="inline-block" maxW={maxW} maxH={maxH}>
        <Image
          src={preview}
          alt="Image preview"
          width="100%"
          height="100%"
          maxW={maxW}
          maxH={maxH}
          style={{ transition: "all 0.2s ease-in-out" }}
          _hover={{
            transform: "scale(1.05)",
            cursor: "pointer",
            opacity: "0.8",
          }}
        />
        <Box
          position="absolute"
          top="0"
          right="0"
          p="2"
          cursor="pointer"
          _hover={{ bg: "rgba(255, 255, 255, 0.5)" }}>
          <FaEdit />
        </Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgba(0,0,0,0.4)"
          opacity="0"
          _hover={{ opacity: "1" }}>
          <Text color="white" fontSize="lg">
            Arraste e solte para alterar
          </Text>
        </Box>
      </Box>
      <input {...dropzone.getInputProps()} />
    </Box>
  );
};

export default ImageDropzone;
