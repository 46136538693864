import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import { CustomerFilterUtils } from "../../utils/customer-filter.utils";

interface TableFiltersProps {
  filterCriteria: string | null;
}

const TableFilters = ({ filterCriteria }: TableFiltersProps) => {
  const transformedFilterCriteria = filterCriteria
    ? CustomerFilterUtils.parseFilterCriteria(filterCriteria)
    : [];

  return (
    <TableContainer mt={2}>
      <Table variant="striped" size={"sm"}>
        <Thead>
          <Tr>
            <Th>Filtro</Th>
            <Th>Valor</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transformedFilterCriteria.map((filter, index) => (
            <Tr key={index}>
              <Td>{filter.title}</Td>
              <Td>{filter.value}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableFilters;
