import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { BiMessageRoundedError } from "react-icons/bi";
import { BsChatLeft } from "react-icons/bs";
import { CgTemplate } from "react-icons/cg";
import { FaCogs, FaUsers } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineCampaign } from "react-icons/md";
import { RiNodeTree } from "react-icons/ri";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRoutes } from "../../constants/api-routes";
import { appPaths } from "../../constants/app-paths";
import { colors } from "../../constants/colors";
import { CompaniesService } from "../../services/companies.service";
import { logout } from "../../state/authSlice";
import { hoverButtonStyles } from "../../styles/hover.styles";
import { FaHome } from "react-icons/fa";

interface SidebarOption {
  title: string;
  icon: ReactNode;
  path: string;
}

export const NAVBAR_HEIGHT = "40px";

const SIDEBAR_OPTIONS: SidebarOption[] = [
  {
    title: "Home",
    icon: <FaHome size="24px" />    ,
    path: appPaths.home(),
  },
  {
    title: "Conversas",
    icon: <BsChatLeft size="24px" />,
    path: appPaths.conversations(),
  },
  {
    title: "Clientes",
    icon: <FaUsers size="24px" />,
    path: appPaths.customers.index(),
  },
  {
    title: "Campanhas",
    icon: <MdOutlineCampaign size="24px" />,
    path: appPaths.campaigns.whatsapp.index(),
  },
  {
    title: "Templates",
    icon: <CgTemplate size="24px" />,
    path: appPaths.messageTemplates.index(),
  },
  {
    title: "Automações",
    icon: <RiNodeTree size="24px" />,
    path: appPaths.automations.messageFlows.index(),
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, refetch } = useQuery(
    apiRoutes.getCompanyMessageBalance(),
    async () => {
      const { data } = await CompaniesService.getCompanyMessageBalance();
      return data;
    }
  );

  function handleClickOption(path: string) {
    navigate(path, { replace: true });
  }

  function isActivePath(path: string) {
    return location.pathname.split("/")[1] === path.split("/")[1];
  }

  function handleClickLogo() {
    navigate(appPaths.home(), { replace: true });
  }

  return (
    <Flex
      width="auto"
      bgColor={colors.primary}
      height="100%"
      flexDir={"column"}
      justify="space-between">
      <Box>
        <Flex justify="center" align="center" height={NAVBAR_HEIGHT} mb={3} onClick={handleClickLogo}>
          <Image src="/logo192.png" height="80%" />
        </Flex>
        <Stack width="100%">
          {SIDEBAR_OPTIONS.map(({ title, icon, path }, index) => {
            return (
              <Tooltip label={title} aria-label="A tooltip" key={index}>
                <Flex
                  onClick={() => handleClickOption(path)}
                  padding={2}
                  justify="center"
                  align="center"
                  cursor={"pointer"}
                  _hover={hoverButtonStyles}
                  bgColor={isActivePath(path) ? colors.white : colors.primary}
                  color={isActivePath(path) ? colors.primary : colors.white}>
                  {icon}
                </Flex>
              </Tooltip>
            );
          })}
        </Stack>
      </Box>
      <Flex
        padding={2}
        justify="center"
        align="center"
        cursor={"pointer"}
        _hover={{ ...hoverButtonStyles, opacity: 1 }}
        flexDir="column">
        {data && (
          <Popover isLazy>
            <Tooltip label={"Limites de mensagens"} aria-label="A tooltip">
              <Box display="inline-block" onClick={() => refetch()}>
                <PopoverTrigger>
                  <Flex
                    padding={2}
                    justify="center"
                    align="center"
                    cursor={"pointer"}
                    _hover={hoverButtonStyles}
                    bgColor={colors.primary}
                    color={colors.white}>
                    <BiMessageRoundedError size="24px" />
                  </Flex>
                </PopoverTrigger>
              </Box>
            </Tooltip>

            <PopoverContent>
              <PopoverHeader fontWeight="semibold">
                Seus limites de mensagens
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <VStack gap={2} fontSize='sm'>
                  <Box width="100%">
                    <Flex justify={"space-between"}>
                      <Text>Mensagens restantes hoje</Text>
                      <Text>
                        {data?.daily.total - data?.daily.sent}
                      </Text>
                    </Flex>
                  </Box>
                  <Box width="100%">
                    <Flex justify={"space-between"}>
                        <Text>Mensagens enviadas no mês</Text>
                      <Text>
                        {data?.monthly.billable}/{data?.monthly.total}
                      </Text>
                    </Flex>
                    <Progress
                      value={(data.monthly.billable / data.monthly.total) * 100}
                      size="sm"
                      colorScheme="pink"
                      borderRadius={"10px"}
                    />
                    <Text color="gray.500" fontStyle="italic">
                      Atualizados a cada 24h
                    </Text>
                  </Box>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}

        <Menu>
          <MenuButton bgColor={colors.primary} color={colors.white} padding={2}>
            <FiSettings size="24px" />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => handleClickOption(appPaths.settings.general())}
              display="flex"
              justifyContent={"space-between"}>
              Configurações <FaCogs />
            </MenuItem>
            <MenuItem
              onClick={() => dispatch(logout())}
              display="flex"
              justifyContent={"space-between"}>
              Sair <FiLogOut />
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
