import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { Filter } from "../types/Filter";
import { FilterType } from "../types/FilterType";

const deleteFilter = async (filterId: string) => {
  return request.delete(apiRoutes.deleteFilter(filterId));
};

export interface CreateFilterDto {
  name: string;
  criteria: string;
  type: FilterType;
}

const createFilter = async (data: CreateFilterDto) => {
  return request.post(apiRoutes.createFilter(), data);
};

const listFilters = async (): Promise<AxiosResponse<Filter[]>> => {
  return request.get(apiRoutes.listFilters());
};

export interface UpdateFilterDto {
  filterId: string;
  criteria: string;
}
const updateFilter = async (
  updateFilterDto: UpdateFilterDto,
) => {
  const { filterId, ...updateData } = updateFilterDto;
  return request.put(
    apiRoutes.updateFilter(updateFilterDto.filterId),
    updateData,
  );
};


export const FiltersService = {
  deleteFilter,
  createFilter,
  listFilters,
  updateFilter,
};
