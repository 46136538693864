import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import reactStringReplace from "react-string-replace";
import { Button } from "../../services/message-templates.service";
import { ButtonType } from "../../types/ButtonTypeEnum";
import { SimplifiedTemplateMessageCard } from "../../types/MessageTemplate";
import { FileUtils } from "../../utils/file-utils";
import UrlButtonPreview from "./UrlButtonPreview";
import OfferCodeButtonPreview from "./OfferCodeButtonPreview";
import Carousel from "../Carousel";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export interface WhatsappMessageItemMessageCard
  extends Pick<SimplifiedTemplateMessageCard, "buttons" | "body"> {
  mediaUrl?: string;
  file?: File | null;
}

export interface WhatsappMessageItemProps {
  message?: string | null;
  title?: string;
  file?: File | null;
  buttons?: Button[];
  footer?: string | null;
  fileUrl?: string | null;
  limitedOfferText?: string;
  limitedOfferExpirationDate?: string;
  messageTemplateCards?: WhatsappMessageItemMessageCard[];
  isCard?: boolean;
}

const whatsappImageWidth = 1125 / 4;
const WhatsappMessageItem = ({
  message,
  limitedOfferText,
  limitedOfferExpirationDate,
  file,
  title,
  buttons,
  footer,
  fileUrl,
  messageTemplateCards,
  isCard,
}: WhatsappMessageItemProps) => {
  const hasCards = (messageTemplateCards || []).length > 0;
  const [filePreview, setFilePreview] = useState<undefined | null | File>(null);
  const buttonType = buttons?.[0]?.type || null;
  const replyButtonFlexParams = {
    card: {
      flexDir: "column" as "column",
      width: "100%",
      gap: 0,
      marginTop: 0,
    },
    normal: { flexDir: "row" as "row", width: "100%", gap: 1, marginTop: 1 },
  };

  useEffect(() => {
    if (fileUrl) {
      FileUtils.fetchFile(fileUrl).then((file) => {
        setFilePreview(file);
      });
    }
  }, [fileUrl]);

  useEffect(() => {
    if (file) {
      setFilePreview(file);
    } else if (!fileUrl) {
      setFilePreview(null);
    }
  }, [file, fileUrl, filePreview]);

  useEffect(() => {
    setFilePreview(null);
  }, [fileUrl]);

  function getLimitedOfferExpirationDate(): string {
    if (!limitedOfferExpirationDate) return "";

    const dt = parseISO(limitedOfferExpirationDate);
    const formattedDate = format(dt, "d 'de' MMMM 'de' yyyy", { locale: ptBR });

    return formattedDate;
  }

  return (
    <Box width={whatsappImageWidth + 8} maxWidth={"100%"} fontSize={"15px"}>
      <Box
        bgColor={"#FFFFFF"}
        padding={1}
        borderTopRadius="5px"
        borderBottomRadius={(buttons?.length || 0) === 0 ? "5px" : "0px"}
      >
        {!filePreview ? null : filePreview.type.includes("pdf") ? (
          <Flex
            alignItems={"center"}
            bgColor={"#F5F6F6"}
            paddingX={4}
            paddingY={3}
            fontSize={12}
            gap={2}
            borderRadius="5px"
          >
            <Image src="/icons/pdf.png" width={"26px"} height={"30px"} />
            {filePreview.name}
          </Flex>
        ) : filePreview.type.startsWith("video") ? (
          <video
            style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "5px" }}
            controls
          >
            <source
              src={URL.createObjectURL(filePreview)}
              type={filePreview.type}
            />
          </video>
        ) : isCard ? (
          <Image
            src={URL.createObjectURL(filePreview)}
            alt={filePreview.name}
            width={"100%"}
            height={"150px"}
            borderRadius="5px"
            objectFit={"cover"}
          />
        ) : (
          <Image
            src={URL.createObjectURL(filePreview)}
            alt={filePreview.name}
            width={"100%"}
            borderRadius="5px"
            objectFit={"contain"}
          />
        )}
        {limitedOfferText && limitedOfferText.length > 0 && (
          <Box
            bg="gray.800"
            color="white"
            p={1}
            borderBottomRadius="lg"
            borderTopRadius={file || fileUrl ? "0px" : "lg"}
            boxShadow="md"
            maxWidth="sm"
            mx="auto"
          >
            <Flex align="center" mb={1} ml={2}>
              <Box
                as="span"
                display="inline-block"
                bg="gray.700"
                p={3}
                borderRadius="full"
                mr={3}
              >
                <Text fontSize="md" color="white">
                  🏷️
                </Text>
              </Box>
              <Box>
                <Text fontWeight="bold">{limitedOfferText}</Text>
                {limitedOfferExpirationDate &&
                  limitedOfferExpirationDate.length > 0 && (
                    <Text
                      fontSize="sm"
                      maxWidth={215}
                      color="white.400"
                      isTruncated
                    >
                      A oferta termina no dia {getLimitedOfferExpirationDate()}
                    </Text>
                  )}
                {buttons && buttons?.[0].offerCode && (
                  <Text fontSize="sm" color="gray.400">
                    Código: <Text as="span">{buttons?.[0].offerCode}</Text>
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        )}
        <Box padding={1}>
          <Text as="span" fontWeight={"bold"}>
            {hasCards && title}
          </Text>
          <Text whiteSpace={"pre-line"}>
            {reactStringReplace(
              message || undefined,
              /\*(.*?)\*/g,
              (match, i) => (
                <Text as="span" fontWeight={"bold"} key={i}>
                  {match}
                </Text>
              )
            )}
          </Text>

          <Text fontSize={13} marginTop={2} color={"#8696a0"}>
            {footer}
          </Text>
        </Box>
      </Box>
      {buttonType === ButtonType.URL ? (
        <UrlButtonPreview text={buttons![0].text} />
      ) : buttonType === ButtonType.QUICK_REPLY ? (
        <Flex
          flexWrap={"wrap"}
          {...(hasCards
            ? replyButtonFlexParams.card
            : replyButtonFlexParams.normal)}
        >
          {buttons?.map((button, index) => (
            <Flex
              bgColor={"#FFFFFF"}
              flex={1}
              padding={2}
              borderRadius={"5px"}
              minWidth="48%"
              justifyContent={"center"}
              alignItems="center"
              color={"#009de2"}
            >
              {button.text}
            </Flex>
          ))}
        </Flex>
      ) : buttonType === ButtonType.COPY_CODE ? (
        <>
          <OfferCodeButtonPreview />
          <UrlButtonPreview text={buttons![1].text} />
        </>
      ) : null}
      {(messageTemplateCards || []).length > 0 && (
        <Carousel
          items={messageTemplateCards || []}
          itemWidthInPx={300}
          isScrollable={true}
          renderItem={(card) => (
            <WhatsappMessageItem
              isCard={true}
              file={card.file}
              fileUrl={card.mediaUrl}
              buttons={card.buttons as Button[]}
              message={card.body}
            />
          )}
        />
      )}
    </Box>
  );
};

export default WhatsappMessageItem;
