import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { FaEdit } from "react-icons/fa";
import { useMutation } from "react-query";
import { ConversationsService, CreateConversationDto } from "../../../../services/conversations.service";
import { useSearchParams } from "react-router-dom";

const PopoverNewConversation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const popover = useDisclosure();
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const toast = useToast();
  const createConversation = useMutation(
    async (createConversationDto: CreateConversationDto) => {
      const { data } = await ConversationsService.createConverstation(createConversationDto);
      return data;
    },
    {
      onSuccess: (data) => {
        toast({
          title: "Envie uma mensagem para iniciar a conversa",
          status: "info",
          duration: 3000,
          isClosable: true,
        })
        popover.onClose();
        setSearchParams({
          conversationId: data.id,
        });
      },
      onError: (err: any) => {
        setErrorMessage(err.response.data.message);
      },
    }
  );

  useEffect(() => {
    setRecipientPhoneNumber("");
    setErrorMessage(null);
  }, [popover.isOpen]);

  function handleCreateConversation() {
    createConversation.mutateAsync({
      recipientPhoneNumberId: `+55${recipientPhoneNumber}`,
    });
  }

  return (
    <Popover
      isOpen={popover.isOpen}
      onOpen={popover.onOpen}
      onClose={popover.onClose}
      placement="right"
      closeOnBlur={false}>
      <PopoverTrigger>
        <IconButton
          aria-label="Nova Conversa"
          size="sm"
          icon={<FaEdit fontSize={16} />}
        />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverHeader fontWeight="semibold">Nova conversa</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack gap={2}>
            <FormControl isInvalid={!!errorMessage}>
              <InputGroup>
                <InputLeftAddon children="+55" />
                <Input
                  type="tel"
                  placeholder="(11) 00000-0000"
                  as={InputMask}
                  mask="(99)99999-9999"
                  value={recipientPhoneNumber}
                  onChange={(e) => setRecipientPhoneNumber(e.target.value)}
                />
              </InputGroup>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Button variant="outline" onClick={popover.onClose}>
                Cancelar
              </Button>
              <Button
                onClick={handleCreateConversation}
                isLoading={createConversation.isLoading}>
                Avançar
              </Button>
            </ButtonGroup>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverNewConversation;
