import { Card, CardBody, CardHeader, FormControl, FormLabel, Heading, Select } from '@chakra-ui/react'
import RFMChart from '../../../../components/RFMChart'
import { maxRfmRecencyOptions } from '../../../CustomersPage/components/FilterSidebar/SectionRFM';

interface SectionRFMProps {
  maxRecency: number
  onChangeMaxRecency: (maxRecency: number) => void
}

const SectionRFM = ({
  maxRecency,
  onChangeMaxRecency,
}: SectionRFMProps) => {
  return (
    <Card>
      <CardHeader display='flex' justifyContent={'space-between'}>
        <Heading size="md">Sua matriz RFM</Heading>
        <FormControl display='flex' width='fit-content' alignItems='center'>
          <FormLabel>Período máximo</FormLabel>
          <Select
            value={maxRecency}
            width='200px'
            onChange={(e) => onChangeMaxRecency(Number(e.target.value))}
          >
            {maxRfmRecencyOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </CardHeader>
      <CardBody>
        <RFMChart maxRecency={maxRecency} />
      </CardBody>
    </Card>
  )
}

export default SectionRFM