function formatCurrency(value: number, maximumFractionDigits=2): string {
  return (value / 100).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits,
  });
}

export const MoneyUtils = {
  formatCurrency,
};
