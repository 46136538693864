import { useEffect } from "react";

type OutsideClickHandler = () => void;

const useOutsideClickDetector = (
  ref: React.RefObject<HTMLElement>,
  onOutsideClick: OutsideClickHandler
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

export default useOutsideClickDetector;