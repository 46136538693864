export enum RFMGroupsEnum {
  CHAMPIONS = 'champions',
  LOYAL_CUSTOMERS = 'loyal_customers',
  POTENTIAL_LOYALISTS = 'potential_loyalists',
  RECENT_CUSTOMERS = 'recent_customers',
  PROMISING = 'promising',
  NEED_ATTENTION = 'need_attention',
  ABOUT_TO_SLEEP = 'about_to_sleep',
  CANNOT_LOSE_THEM = 'cannot_lose_them',
  AT_RISK = 'at_risk',
  HIBERNATING = 'hibernating',
  LOST_CUSTOMERS = 'lost_customers',
}
