import { Box, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GiChoice } from "react-icons/gi";
import { useQuery } from "react-query";
import { Handle, NodeProps, Position } from "reactflow";
import { apiRoutes } from "../../../../../../constants/api-routes";
import { TagsService } from "../../../../../../services/tags.service";
import { ConditionsCheckNodeData, FlowNodeCondition } from "../../../../../../types/ReactFlowNode";
import { MoneyUtils } from "../../../../../../utils/money.utils";
import NodeLayout from "../NodeLayout";

const ConditionTypeEnum = {
  "default": "Caso nenhuma condição seja atendida",
  "total_orders": "Total de pedidos",
  "total_purchases": "Total em compras",
  "days_since_last_purchase": "Última compra",
  "has_tag": "Possui tag",
}

export const ConditionsOperatorEnumByType = {
  "total_orders": {
    "GREATER_THAN": "é maior que ",
    "LESS_THAN": "é menor que ",
    "EQUALS": "igual a",
  },
  "total_purchases": {
    "GREATER_THAN": "é maior que ",
    "LESS_THAN": "é menor que ",
    "EQUALS": "igual a",
  },
  "days_since_last_purchase": {
    "GREATER_THAN": "nos últimos ",
    "LESS_THAN": "antes dos últimos ",
    "EQUALS": "",
  },
  "has_tag": {
    "GREATER_THAN": "",
    "LESS_THAN": "",
    "EQUALS": "",
  },
  "default": {
    "GREATER_THAN": "",
    "LESS_THAN": "",
    "EQUALS": "",
  },
}

const ConditionsCheckNode = (props: NodeProps<ConditionsCheckNodeData>) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<{ id: string; name: string }[]>([]);

  useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    },
    {
      onSuccess: (data) => {
        setTags(
          data.map((tag) => ({
            id: tag.id,
            name: tag.name,
          }))
        );
      },
    }
  );

  const sortedFlowNodeConditionBlocks = props.data.flowNodeConditionBlocks?.sort((a, b) => {
    if (a.flowNodeConditions.some((condition) => condition.type === "default")) {
      return 1;
    }
    if (b.flowNodeConditions.some((condition) => condition.type === "default")) {
      return -1;
    }
    return a.priority - b.priority;
  });

  const getValue = (condition: FlowNodeCondition) => {
    switch (condition.type) {
      case "total_purchases":
        return MoneyUtils.formatCurrency(condition.value.value)
      case "days_since_last_purchase":
        return `${condition.value.value} dias`;
      case "has_tag":
        return tags.find((tag) => tag.id === condition.value.value)?.name || "";
      case "default":
        return "";
      default:
        return String(condition.value.value);
    }
  }

  const getHeight = (index: number) => {
    const marginHeight = 5;
    const boxPaddingHeight = 24;
    let totalHeight = 69;
    let previousBoxesHeight = 0;


    for (let i = 0; i < index; i++) {
      const boxHeight = (props.data.flowNodeConditionBlocks![i].flowNodeConditions.length * 18) + boxPaddingHeight;
      previousBoxesHeight += boxHeight;
    }

    const currentBoxHeight = (props.data.flowNodeConditionBlocks![index].flowNodeConditions.length * 18) + boxPaddingHeight || 42;
    totalHeight += currentBoxHeight / 2;
    totalHeight += index * marginHeight;

    return totalHeight + previousBoxesHeight;
  }

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<GiChoice />}
      color="#797279"
      width={"fit-content"}
    >
      <Flex flexDir="column" gap={"5px"} mt="20px">
        { sortedFlowNodeConditionBlocks?.map((conditionBlock, index) => (
          <Box key={conditionBlock.id}>
            <Handle
              id={conditionBlock.id}
              type="source"
              position={Position.Right}
              style={{
                top: getHeight(index),
                bottom: "auto",
                height: 10,
                width: 10,
              }}
            />
            <Flex
              bgColor="#EAF4FF"
              width="100%"
              borderRadius={"5px"}
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Box fontSize={12} textOverflow={'ellipsis'} overflow={'hidden'}>
                {conditionBlock.flowNodeConditions.map((condition) => (
                  <Text key={condition.id}>
                    <b>{ConditionTypeEnum[condition.type]}</b>&nbsp;
                    {ConditionsOperatorEnumByType[condition.type][condition.comparisonOperator]}
                    <b>{getValue(condition)}</b>
                  </Text>
                  ))
                }
              </Box>
            </Flex>
          </Box>
        ))}
      </Flex>
    </NodeLayout>
  );
};

export default ConditionsCheckNode;
