import { FormControl, Switch, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { MdOutlineCampaign } from "react-icons/md";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import AlertDialogBase from "../../../../../components/AlertDialog";
import FormLabel from "../../../../../components/FormLabel";
import InputNumber from "../../../../../components/InputNumber";
import InputSelect from "../../../../../components/InputSelect";
import { apiRoutes } from "../../../../../constants/api-routes";
import { useCustomerSearchParams } from "../../../../../hooks/useCustomerSearchParams";
import { MessageTemplatesService } from "../../../../../services/message-templates.service";
import { RootState } from "../../../../../state/store";
import { CustomerFiltersEnum } from "../../../../../types/CustomerFiltersEnum";
import AccordionItemLayout from "../AccordionItemLayout";
import { selectEngamentActionOptions } from "../selectEngamentActionOptions";

interface SectionCampaignProps {
  useFormReturn: UseFormReturn<any>;
  updateSelectedValues: (args: any) => void;
}

const SectionCampaigns = ({
  useFormReturn,
  updateSelectedValues,
}: SectionCampaignProps) => {
  const { control, watch, setValue } = useFormReturn;
  const { selectedTemplate } = useSelector(
    (state: RootState) => state.campaignCreation
  );
  const {
    isRemarketing,
    selectedEngagementTemplateIds,
    selectedEngagementActionTypes,
    isScheduledCampaignsVisible,
    excludedTemplateIds,
  } = useCustomerSearchParams();
  const isCreatingCampaign = !!selectedTemplate;
  const { data: templates = [] } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    }
  );
  const watchSelectedEngagementTemplateId: string[] = watch(
    CustomerFiltersEnum.SELECTED_ENGAGEMENT_TEMPLATE_IDS
  );
  const removeExcludedTemplateIdsAlert = useDisclosure();

  useEffect(() => {
    setValue("isRemarketing", isRemarketing === "true");
    setValue(
      "isScheduledCampaignsVisible",
      isScheduledCampaignsVisible === "true"
    );
    updateSelectedValues({
      selectedValues: selectedEngagementActionTypes,
      sourceData: selectEngamentActionOptions,
      valueToSet: "selectedEngagementActionTypes",
      optionValue: "value",
      optionLabel: "label",
    });
    updateSelectedValues({
      selectedValues: selectedEngagementTemplateIds,
      sourceData: templates,
      valueToSet: "selectedEngagementTemplateIds",
      optionValue: "id",
      optionLabel: "name",
    });
    updateSelectedValues({
      selectedValues: excludedTemplateIds,
      sourceData: templates,
      valueToSet: "excludedTemplateIds",
      optionValue: "id",
      optionLabel: "name",
    });
  }, [
    isRemarketing,
    isScheduledCampaignsVisible,
    selectedEngagementActionTypes,
    selectedEngagementTemplateIds,
    setValue,
    templates,
    updateSelectedValues,
    excludedTemplateIds,
  ]);

  return (
    <AccordionItemLayout
      title="Campanhas"
      icon={<MdOutlineCampaign size="20px" />}>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Ocultar clientes que receberam qualquer campanhas nos últimos X dias">
          Ocultar quem recebeu campanha nos últimos
        </FormLabel>
        <Controller
          name="minDaysSinceLastCampaign"
          control={control}
          render={({ field }) => {
            return (
              <InputNumber
                value={field.value}
                onChange={(value) => field.onChange(value)}
                size="sm"
                rightAddon="dias"
              />
            );
          }}
        />
      </FormControl>
      {isCreatingCampaign && (
        <FormControl>
          <FormLabel
            size="sm"
            tooltip="Mostrar clientes que possuem campanhas agendadas">
            Mostrar clientes com campanhas agendadas
          </FormLabel>
          <Controller
            control={control}
            name="isScheduledCampaignsVisible"
            render={({ field: { onChange, onBlur, value } }) => (
              <Switch
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                onBlur={onBlur}
                isChecked={value}
              />
            )}
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Clientes que receberam o template selecionado">
          Template enviado
        </FormLabel>
        <Controller
          name="selectedEngagementTemplateIds"
          control={control}
          render={({ field }) => (
            <InputSelect
              placeholder="Selecione..."
              options={templates.map((template) => ({
                value: template.id,
                label: template.name,
              }))}
              isMulti
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                if (value.length === 0) {
                  setValue("selectedEngagementActionTypes", []);
                }
              }}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Clientes que realizaram uma ação específica para o template selecionado">
          Ação realizada
        </FormLabel>
        <Controller
          name="selectedEngagementActionTypes"
          control={control}
          render={({ field }) => (
            <InputSelect
              placeholder="Qualquer"
              options={selectEngamentActionOptions.map((option) => ({
                value: option.value,
                label: option.label,
              }))}
              isMulti
              disabled={watchSelectedEngagementTemplateId.length === 0}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Clientes que receberam mensagens nas últimas 23h não tem custo de disparo">
          Podem receber remarketing
        </FormLabel>
        <Controller
          control={control}
          name="isRemarketing"
          render={({ field: { onChange, onBlur, value } }) => (
            <Switch
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              onBlur={onBlur}
              isChecked={value}
            />
          )}
        />
      </FormControl>
      {isCreatingCampaign && (
        <FormControl>
          <FormLabel
            size="sm"
            tooltip="Ocultar clientes que receberam o template selecionado">
            Ocultar clientes que reberam o template
          </FormLabel>
          <Controller
            name="excludedTemplateIds"
            control={control}
            render={({ field }) => (
              <InputSelect
                placeholder="Selecione..."
                options={templates.map((template) => ({
                  value: template.id,
                  label: template.name,
                }))}
                isMulti
                value={field.value}
                onChange={(value) => {
                  if (value.length === 0) {
                    removeExcludedTemplateIdsAlert.onOpen();
                    setValue("excludedTemplateIds", []);
                  } else {
                    field.onChange(value);
                  }
                }}
              />
            )}
          />
        </FormControl>
      )}
      <AlertDialogBase
        isOpen={removeExcludedTemplateIdsAlert.isOpen}
        onClose={removeExcludedTemplateIdsAlert.onClose}
        title="Tem certeza disso?"
        onConfirm={() => {
          setValue("excludedTemplateIds", []);
          removeExcludedTemplateIdsAlert.onClose();
        }}>
        Ao remover o filtro "Ocultar clientes que reberam o template" você
        poderá impactar clientes que já receberam essa mensagem
      </AlertDialogBase>
    </AccordionItemLayout>
  );
};

export default SectionCampaigns;
