import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  useBoolean,
} from "@chakra-ui/react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { colors } from "../../../../constants/colors";
import { setScheduledExecutionTime } from "../../../../state/campaignCreationSlice";
import { RootState } from "../../../../state/store";

const SectionScheduling = () => {
  const [isScheduling, setIsScheduling] = useBoolean();
  const { scheduledExecutionTime } = useSelector(
    (state: RootState) => state.campaignCreation
  );
  const dispatcher = useDispatch();

  function handleChangeScheduledExecutionTime(date: Date | null) {
    dispatcher(setScheduledExecutionTime(date ? date.toISOString() : null));
  }

  function handleClickSendNow() {
    dispatcher(setScheduledExecutionTime(null));
    setIsScheduling.off();
  }
  function handleClickSchedule() {
    dispatcher(setScheduledExecutionTime(new Date().toISOString()));
    setIsScheduling.on();
  }

  return (
    <Card>
      <CardHeader
        paddingBottom={0}
        display="flex"
        justifyContent={"space-between"}>
        <Heading size="md">Horário de envio</Heading>
        <Flex alignItems="center">
          <Button
            borderRadius={0}
            color={!isScheduling ? colors.white : colors.primary}
            bgColor={!isScheduling ? colors.primary : colors.white}
            borderColor={!isScheduling ? colors.white : colors.primary}
            borderStyle={!isScheduling ? 'none' : 'solid'}
            borderWidth='1px'
            onClick={handleClickSendNow}>
            Enviar agora
          </Button>
          <Button
            borderRadius={0}
            color={isScheduling ? colors.white : colors.primary}
            bgColor={isScheduling ? colors.primary : colors.white}
            borderColor={isScheduling ? colors.white : colors.primary}
            borderStyle={isScheduling ? 'none' : 'solid'}
            borderWidth='1px'
            onClick={handleClickSchedule}>
            Agendar para
          </Button>
        </Flex>
      </CardHeader>
      <CardBody display="flex" justifyContent={"space-between"}>
        <div />
        <div>
          {isScheduling && (
            <ReactDatePicker
              selected={
                scheduledExecutionTime ? new Date(scheduledExecutionTime) : null
              }
              onChange={handleChangeScheduledExecutionTime}
              minDate={new Date()}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              isClearable
              timeIntervals={10}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionScheduling;
