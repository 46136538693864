import { Box, Button, DrawerBody, Flex, Text } from "@chakra-ui/react";
import { useFieldArray, useForm } from "react-hook-form";
import { ConditionsCheckNodeData, FlowNodeCondition, FlowNodeConditionBlock } from "../../../../../../../types/ReactFlowNode";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import ConditionBlock from "./components/ConditionBlock";
import { useQuery } from "react-query";
import { apiRoutes } from "../../../../../../../constants/api-routes";
import { TagsService } from "../../../../../../../services/tags.service";

const flowNodeConditionsSchema = yup.object({
  id: yup.string().uuid().required(),
  type: yup.string().required(),
  value: yup.mixed().required().test('not-empty', "Obrigatório *", (value) => value !== null && value !== "" && value !== {}),
  comparisonOperator: yup.string().required(),
  flowNodeConditionBlockId: yup.string().uuid().required(),
});

const flowNodeConditionBlocksSchema = yup.object({
  id: yup.string().uuid().required(),
  conditionalJoinType: yup.string().required(),
  priority: yup.number().required().integer(),
  targetFlowNodeId: yup.string().nullable(),
  flowNodeConditions: yup.array().of(flowNodeConditionsSchema).min(1, "É necessário ao menos uma condição").required(),
});

const schema = yup.object({
  flowNodeConditionBlocks: yup.array().of(flowNodeConditionBlocksSchema).min(2, "É necessário ao menos uma condição").required(),
});

export interface ConditionsCheckNodeEditorProps {
  data: ConditionsCheckNodeData
  formId: string;
  onSaveNode: (data: any) => void;
}

const ConditionsCheckNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: ConditionsCheckNodeEditorProps) => {
  const [tagOptions, setTagOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const formatData = (data: FlowNodeConditionBlock[]) => {
    return data.map((block) => ({
      ...block,
      flowNodeConditions: block.flowNodeConditions.map((condition) => {
        if (condition.type !== "total_purchases") {
          return condition
        }
        return {
          ...condition,
          value: { value: Number(condition.value.value) / 100 },
        }
      }),
    }));
  }

  useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    },
    {
      onSuccess: (data) => {
        setTagOptions(
          data.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))
        );
      },
    }
  );

  const flowNodeConditionBlocks = formatData(data.flowNodeConditionBlocks);
  const { handleSubmit, control, formState: { errors } } = useForm<{ flowNodeConditionBlocks: FlowNodeConditionBlock[] }>({
    resolver: yupResolver(schema),
    defaultValues: {
      flowNodeConditionBlocks: flowNodeConditionBlocks || [],
    }
  });
  const initialRender = useRef(true);
  const [oneConditionMinimumError, setOneConditionMinimumError] = useState(false);

  const getFormattedValue = (condition: FlowNodeCondition) => {
    if (condition.type === "total_purchases") {
      return {"value": condition.value.value * 100} // make it in cents
    }

    if (typeof condition.value === "object") { return condition.value }
    return {"value": condition.value}
  }


  const handleFormSubmit = (data: any) => {
    const formattedData = {
      ...data,
      flowNodeConditionBlocks: data.flowNodeConditionBlocks.map((block: FlowNodeConditionBlock) => ({
        ...block,
        flowNodeConditions: block.flowNodeConditions.map((condition: FlowNodeCondition) => ({
          ...condition,
          value: getFormattedValue(condition),
        })),
      })),
    }

    onSaveNode(formattedData);
  }

  const { fields: blocks, append: appendBlock, remove: removeBlock } = useFieldArray({
    control,
    name: "flowNodeConditionBlocks",
  });

  useEffect(() => {
    if (blocks.length === 1 || (
      blocks.some((block) => block.flowNodeConditions.length === 0)
    ))
    {
      setOneConditionMinimumError(true);
    } else if (errors.flowNodeConditionBlocks === undefined) {
      setOneConditionMinimumError(false);
    }
  }, [blocks, errors]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (blocks.length === 0) {
        appendBlock({
          id: uuidv4(),
          conditionalJoinType: 'AND',
          priority: 1,
          flowNodeConditions: [],
        });
      }
    }
  }, [appendBlock, blocks.length]);

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(handleFormSubmit)} id={formId}>
        <Flex direction="column" gap={3}>
          {blocks.length === 1 && (
            <Button mb={5} onClick={() => appendBlock({ id: uuidv4(), conditionalJoinType: 'AND', priority: blocks.length + 1, flowNodeConditions: [] })}>
              + Criar condições
            </Button>
          )}
          {oneConditionMinimumError && (
            <Text color="red.500" fontSize={'sm'}>
              É necessário pelo menos uma condição
            </Text>
          )}
          {blocks.map((block, blockIndex) => {
            if (!block.flowNodeConditions.some((condition) => condition.type === "default")) {
              return (
                <>
                  <ConditionBlock
                    key={block.id}
                    control={control}
                    block={block}
                    blockIndex={blockIndex}
                    removeBlock={removeBlock}
                    tagOptions={tagOptions}
                    errors={errors}
                  />

                  <Box mt={3} mb={3} display={"flex"} justifyContent={"center"} fontSize={'xl'} fontWeight={"bold"}>
                    <Text>Se não</Text>
                  </Box>

                  {blockIndex + 2 < blocks.length ? (
                    null
                  ) : <Button mb={5} onClick={() => appendBlock({ id: uuidv4(), conditionalJoinType: 'AND', priority: blocks.length + 1, flowNodeConditions: [] })}>
                      + Checar outras condições
                    </Button>
                  }
                </>
              );
            }
            return null;
          })}
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default ConditionsCheckNodeEditor;
