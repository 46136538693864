import { FormControl, FormLabel, Input, Stack, Text, Textarea, useDisclosure, useId, useToast } from "@chakra-ui/react";
import { createContext, ReactNode, useContext, ReactElement, useState, useEffect } from "react";
import CrudModal from "../components/CrudModal";
import useGetCrudText from "./useGetCrudText";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { colors } from "../constants/colors";
import { useMutation, useQueryClient } from "react-query";
import { CreateQuickReplyDto, QuickRepliesService, UpdateQuickReplyDto } from "../services/quick-replies.service"
import { apiRoutes } from "../constants/api-routes";
import { QuickReply } from "../types/Prisma";
import AlertDialogBase from "../components/AlertDialog";

interface CrudQuickReplyModalContextData {
  openCreateModal: () => void;
  openEditModal: (quickReply: string) => void;
  openDeleteAlert: (quickReplyId: string) => void;
}

interface CrudQuickReplyModalProps {
  children?: ReactNode;
}

const CrudQuickReplyModalContext = createContext<CrudQuickReplyModalContextData | undefined>(undefined);

const schema = yup.object({
  title: yup.string().required("O título é obrigatório"),
  text: yup.string().required("O texto é obrigatório"),
});

export function CrudQuickReplyModalProvider({ children }: CrudQuickReplyModalProps): ReactElement {
  const {
    onOpen,
    onClose,
    isOpen,
  } = useDisclosure();
  const [quickReplyId, setQuickReplyId] = useState<string | null>(null);
  const { crudActionText, crudTitle } = useGetCrudText(!quickReplyId);
  const formId = useId();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();

  const openCreateModal = () => {
    setQuickReplyId(null);
    onOpen();
  };

  const openEditModal = (quickReplyId: string) => {
    setQuickReplyId(quickReplyId);
    onOpen();
  };

  const openDeleteAlert = (quickReplyId: string) => {
    setQuickReplyId(quickReplyId);
    onOpenAlert();
  };

  const handleOnCloseAlert = () => {
    resetState();
    onCloseAlert();
  }

  async function handleClickDelete(optionId: string) {
    try {
      deleteQuickReply.mutate(optionId)

      toast({
        title: "Resposta rápida deletada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      })

      resetState();
    } catch {
      toast({
        title: "Ocorreu um erro ao deletar a resposta rápida",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const createQuickReply = useMutation(
    (quickReplyData: CreateQuickReplyDto) =>
      QuickRepliesService.createQuickReply(quickReplyData),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(apiRoutes.listQuickReplies(), (old: any) => {
          return [...old, response.data];
        });
      }
    }
  )

  const updateQuickReply = useMutation(
    (updateQuickReplyData: UpdateQuickReplyDto) =>
      QuickRepliesService.updateQuickReply(updateQuickReplyData),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(apiRoutes.listQuickReplies(), (old: any) => {
          return old.map((quickReply: QuickReply) => {
            return quickReply.id === response.data.id ? response.data : quickReply;
          });
        });
      }
    }
  )

  const deleteQuickReply = useMutation(
    (quickReplyId: string) => QuickRepliesService.deleteQuickReply(quickReplyId),
    {
      onSuccess: () => {
        queryClient.setQueryData(apiRoutes.listQuickReplies(), (old: any) => {
          return old.filter((quickReply: QuickReply) => quickReply.id !== quickReplyId);
        });
      }
    }
  )

  useEffect(() => {
    if (!quickReplyId) return;

    const cachedQuickReply = queryClient.getQueryData<QuickReply[]>(apiRoutes.listQuickReplies());
    const currentQuickReply = cachedQuickReply?.find((quickReply) => quickReply.id === quickReplyId);

    if (!currentQuickReply) return;

    setValue("title", currentQuickReply.title);
    setValue("text", currentQuickReply.text);
  }, [quickReplyId, queryClient, setValue]);

  async function onSubmit(submitData: any) {
    try {
      if (!quickReplyId) {
        await createQuickReply.mutateAsync(submitData);
      } else {
        await updateQuickReply.mutateAsync({ id: quickReplyId, ...submitData });
      }
      toast({
        title: "Resposta rápida salva com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      resetState();
    } catch {
      toast({
        title: "Ocorreu um erro ao salvar a resposta rápida",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  };

  const handleCloseModal = () => {
    resetState();
  }

  const resetState =() => {
    onClose();
    onCloseAlert();
    setQuickReplyId(null);
    setValue("title", "");
    setValue("text", "");
  }

  return (
    <CrudQuickReplyModalContext.Provider value={{
      openCreateModal,
      openEditModal,
      openDeleteAlert,
    }}>
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={() => {
          handleOnCloseAlert();
        }}
        title="Deletar resposta automática"
        onConfirm={() => {
          handleClickDelete(quickReplyId!);
        }}>
        Tem certeza que deseja deletar esta resposta rápida?
      </AlertDialogBase>
      <CrudModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        actionButtonText={crudActionText}
        title={`${crudTitle} resposta rápida`}
        isLoading={false}
        formId={formId}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack spacing={2}>
            <FormControl isRequired>
              <FormLabel>Título</FormLabel>
              <Input
                placeholder="Escreva o título"
                {...register("title")}
                isInvalid={!!errors?.title?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.title?.message as string}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Resposta</FormLabel>
              <Textarea
                placeholder="Escreva a resposta"
                {...register("text")}
                isInvalid={!!errors?.text?.message}
                minHeight={"8rem"}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.text?.message as string}
              </Text>
            </FormControl>
          </Stack>
        </form>
      </CrudModal>
      {children}
    </CrudQuickReplyModalContext.Provider>
  );
}

export function useCrudQuickReplyModal(): CrudQuickReplyModalContextData {
  const context = useContext(CrudQuickReplyModalContext);

  if (context === undefined) {
    throw new Error("useCrudQuickReplyModal must be used within a CrudQuickReplyModalProvider");
  }

  return context;
}
