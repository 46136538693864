import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { ReportBufferData } from "../types/ReportBufferData";
import ReportOverviewData from "../types/ReportOverviewData";

const exportFlowClicksReport = async (
  flowId: string
): Promise<AxiosResponse<ReportBufferData>> => {
  return request.get(apiRoutes.exportFlowClicksReport(flowId));
};

const getOverview = async (
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportOverviewData>> => {
  return request.get(apiRoutes.getReportOverview(startDate, endDate));
};

const exportCampaignSalesReport = async (
  startDate: string,
  endDate: string
): Promise<AxiosResponse<ReportBufferData>> => {
  return request.get(apiRoutes.exportCampaignSalesReport(startDate, endDate), {
    timeout: 120000
  });
};

export const ReportsService = {
  exportFlowClicksReport,
  getOverview,
  exportCampaignSalesReport
};
