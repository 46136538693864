import { Switch } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

interface InputSwitchControlledProps {
  control: any;
  name: string;
  onChange?: (value: boolean) => void;
}

const InputSwitchControlled = ({
  control,
  name,
  onChange,
}: InputSwitchControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Switch
          {...field}
          isChecked={field.value}
          onChange={(e) => {
            field.onChange(e.target.checked);
            onChange?.(e.target.checked);
          }}
        />
      )}
    />
  );
};

export default InputSwitchControlled;
