import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoRocketOutline } from "react-icons/io5";
import { Handle, NodeProps, Position } from "reactflow";
import { TriggerNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";

const TriggerNode = (props: NodeProps<TriggerNodeData>) => {
  const { t } = useTranslation();

  return (
    <NodeLayout
      id={props.id}
      title="Triggers"
      icon={<IoRocketOutline />}
      color="#666666"
      targetHandle={false}
    >
      <Flex flexDir="column" gap={"5px"} mt="20px">
        {props.data.flowTriggers?.map((flowTrigger, index) => (
          <Box key={flowTrigger.id}>
            <Handle
              id={flowTrigger.id}
              type="source"
              position={Position.Right}
              style={{
                top: "auto",
                bottom: 30 + (props.data.flowTriggers!.length - index - 1) * 45,
                height: 10,
                width: 10,
              }}
            />
            <Flex
              bgColor={flowTrigger.isDefault ? "#EAF4FF" : "#F3F3F3"}
              width="100%"
              borderRadius={"5px"}
              height="40px"
              justifyContent="center"
              alignItems={"center"}
            >
              {t(`enums.FlowTriggerType.${flowTrigger.type}`)} -{" "}
              {flowTrigger.text}
            </Flex>
          </Box>
        ))}
      </Flex>
    </NodeLayout>
  );
};

export default TriggerNode;
