import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../../constants/colors";
import { useCrudAutoReplyModal } from "../../../hooks/useCrudAutoReplyModal";
import TableAutoReplies from "./components/TableAutoReplies";

const AutoRepliesPage = () => {
  const { openCreateModal } = useCrudAutoReplyModal();
  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="flex-end">
        <Button
          variant='primary'
          onClick={openCreateModal}>
          + criar resposta automática
        </Button>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableAutoReplies />
    </Box>
  );
};

export default AutoRepliesPage;
