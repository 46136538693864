import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { colors } from "../../../../../../constants/colors";

export interface TemplateNodeProps {
  id: string;
  title: string;
  children?: React.ReactNode;
  color?: string;
  icon?: React.ReactElement;
  targetHandle?: boolean;
  outHandle?: boolean;
  width?: string;
}

const NodeLayout = ({
  id,
  title,
  children,
  color,
  icon,
  targetHandle = true,
  outHandle = true,
  width = '250px',
}: TemplateNodeProps) => {
  const { getNode } = useReactFlow();
  const node = getNode(id);

  const shouldHideOutHandle =
    node?.type === 'trigger' ||
    !outHandle ||
    node?.type === 'conditions_check' ||
    (node?.data.buttons && node?.data.buttons?.length > 0  && !node?.data.buttons[0].url);

  return (
    <Box
      bgColor={colors.white}
      shadow="md"
      borderRadius="lg"
      width={width}
      height="auto"
      padding={"20px"}
      borderTop={`5px solid ${color}`}
      fontSize="sm"
      border={node!.selected ? "2px solid #3182CE" : undefined}>
      {targetHandle && (
        <Handle
          id={id}
          type="target"
          position={Position.Left}
          isConnectable={true}
          style={{
            top: 20,
            height: 10,
            width: 10,
          }}
        />
      )}
      {
        !shouldHideOutHandle && (
          <Handle
            id={id}
            type="source"
            position={Position.Right}
            isConnectable={true}
            style={{
              top: 20,
              height: 10,
              width: 10,
            }}
          />
        )
      }
      <Flex alignItems="center" gap={2} mb={3}>
        <Avatar bg={color} size="xs" icon={icon} />
        <Text fontSize="md">{title}</Text>
      </Flex>
      {children}
    </Box>
  );
};

export default NodeLayout;
