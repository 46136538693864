import { Grid, GridItem, Show, useMediaQuery } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import Sidebar from "../../components/Sidebar";
import { screenSizes } from "../../constants/screen-sizes";

interface AppLayoutProps {
  children: ReactNode;
  background?: string;
}

const AppLayout = ({ children, background }: AppLayoutProps) => {
  const isMobile = useMediaQuery(screenSizes.mobile)[0];

  return (
    <Grid
      height={"100vh"}
      templateColumns={isMobile ? "auto" : "50px auto"}
      templateAreas={isMobile ? "'page'" : "'sidebar page'"}
      bg={background || "white"}
    >
      <Show above="lg">
        <GridItem area="sidebar">
          <Sidebar />
        </GridItem>
      </Show>
      <GridItem area="page" maxH={"100vh"} height={"100vh"} overflow="auto">
        {children}
      </GridItem>
    </Grid>
  );
};

export default AppLayout;
