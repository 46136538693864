import { Card, CardBody, CardHeader, Center } from "@chakra-ui/react";
import CustomECharts from "../../../../../components/CustomECharts";
import { colors } from "../../../../../constants/colors";

interface CardOpenedVersusFinalizedTicketsProps {
  dates: string[];
  open: number[];
  closed: number[];
}

export default function CardOpenedVersusFinalizedTickets({
  dates,
  open,
  closed,
}: CardOpenedVersusFinalizedTicketsProps) {
  return (
    <Card variant="outline">
      <CardHeader>
        <Center>
          <b>Atendimentos Abertos vs Finalizados</b>
        </Center>
      </CardHeader>
      <CardBody>
        <CustomECharts
          chartWidth={"100%"}
          chartHeight="450px"
          marginTop={"0px"}
          option={{
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {},
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: dates,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "Abertos",
                type: "bar",
                itemStyle: {
                  color: "#F0CB06",
                },
                emphasis: {
                  focus: "series",
                },
                data: open,
              },
              {
                name: "Finalizados",
                itemStyle: {
                  color: colors.greenMedium,
                },
                type: "bar",
                emphasis: {
                  focus: "series",
                },
                data: closed,
              },
            ],
          }}
        />
      </CardBody>
    </Card>
  );
}
