import {TableColumnHeaderProps, Text, Th as ThChakra, Tooltip} from "@chakra-ui/react";
import { colors } from "../../../constants/colors";

interface ThProps extends TableColumnHeaderProps {
  children: React.ReactNode;
  textForTooltip?: string;
  minWidth?: string;
}

export function Th({ children, textForTooltip, minWidth = "fit-content", ...rest }: ThProps) {
  return (
    <ThChakra fontWeight={"bold"} color={colors.black} minWidth={minWidth} {...rest}>
      {textForTooltip ? (
        <Tooltip label={textForTooltip} placement={"top"}>
          {children}
        </Tooltip>
      ) : (
        <Text>{children}</Text>
      )}
    </ThChakra>
  );
}
