import {
  ListConversationsDetailedParams,
  ListConversationsParams,
  ListConversationSummaryParams,
} from "../services/conversations.service";
import { ListCustomersParams } from "../services/customers.service";
import { DownloadMediaDto } from "../services/messages.service";
import { GetTopCampaignsOrderBy } from "../services/statistics.service";
import { CompanyDefinedFieldTableEnum } from "../types/CompanyDefinedField";
import { MessageStatus } from "../types/Message";
import { UrlUtils } from "../utils/url.utils";

export const apiRoutes = {
  // auth
  login: () => "/auth/login",
  updatePassword: () => "/auth/update-password",

  // bling
  getBlingAuthUrl: () => "/bling/auth",

  // conversations
  listConversations: (listConversationParams: ListConversationsParams) => {
    const queryParams: { [key: string]: string | undefined } =
      listConversationParams as any;
    const queryString = UrlUtils.convertObjectToQueryString(queryParams);
    return `/conversations?${queryString}`;
  },
  listConversationsDetailed: (
    listConversationsDetailedParams: ListConversationsDetailedParams
  ) => {
    const queryParams: { [key: string]: string | undefined } =
      listConversationsDetailedParams as any;
    const queryString = UrlUtils.convertObjectToQueryString(queryParams);
    return `/conversations/detailed?${queryString}`;
  },
  showConversation: (conversationId: string) =>
    `/conversations/${conversationId}`,
  updateConversation: (conversationId: string) =>
    `/conversations/${conversationId}`,
  createConversation: () => `/conversations`,
  readConversation: (conversationId: string) =>
    `/conversations/${conversationId}/read`,
  closeAllConversationTickets: (conversationId: string) =>
    `/conversations/${conversationId}/close-all-tickets`,
  closeAllTicketsFromManyConversations: (conversationIds: string[]) =>
    `/conversations/close-all-tickets?conversationIds=${conversationIds.join( ",")}`,
  listConversationsCustomers: () => "/conversations/customers",

  getConversationIdsWithOpenTickets: (params: ListConversationSummaryParams) =>
    `/conversations/open-tickets?daysSinceLastMessage=${params.daysSinceLastMessage}`,
  getConversationIdsWithUnreadMessages: (
    params: ListConversationSummaryParams
  ) =>
    `/conversations/unread-messages?daysSinceLastMessage=${params.daysSinceLastMessage}`,
  listConversationSummary: (params: ListConversationSummaryParams) =>
    `/conversations/summary?daysSinceLastMessage=${params.daysSinceLastMessage}`,
  markConversationAs: (conversationId: string, state: MessageStatus) =>
    `/conversations/${conversationId}/mark/${state}`,

  // conversation tickets
  updateConversationTicket: (conversationTicketId: string) =>
    `/conversation-tickets/${conversationTicketId}`,

  // messages
  listMessagesByConversation: (conversationId: string, page: number) =>
    `/conversations/${conversationId}/messages?page=${page}`,
  sendMessage: () => "/messages/send",
  sendMessageTemplate: () => "/messages/send-template",
  sendMessageTemplateByPhone: () => "/messages/send-template-by-phone",

  // conversation categories
  listConversationCategories: () => "/conversation-categories",
  listConversationCategoriesDetailed: () => "/conversation-categories/detailed",
  createConversationCategory: () => "/conversation-categories",
  updateConversationCategory: (conversationCategoryId: string) =>
    `/conversation-categories/${conversationCategoryId}`,
  deleteConversationCategory: (conversationCategoryId: string) =>
    `/conversation-categories/${conversationCategoryId}`,
  updateConversationCategoryPosValue: (conversationCategoryId: string) =>
    `/conversation-categories/${conversationCategoryId}/update-pos`,

  // whatsapp
  downloadMedia: ({ mediaId, mediaName, fileKey }: DownloadMediaDto) =>
    `/whatsapp/file?${mediaId ? `fileId=${mediaId}` : ""}${
      mediaName ? `&fileName=${mediaName}` : ""
    }${fileKey ? `&fileKey=${fileKey}` : ""}`,
  // whatsapp
  sendMedia: () => `/whatsapp/file`,

  // statistics
  getChartCampaignPerformance: (campaignId: string, timeUnit: "hour" | "day") =>
    `/statistics/charts/campaign-performance?campaignId=${campaignId}&timeUnit=${timeUnit}`,
  getChartCampaignSales: (campaignId: string) =>
    `/statistics/charts/campaign-sales?campaignId=${campaignId}`,
  getChartAutomationSales: (
    automationId: string,
    startDate: Date,
    endDate: Date
  ) =>
    `/statistics/charts/automation-sales?automationId=${automationId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
  getChartAutomationPerformance: (
    automationId: string,
    startDate: Date,
    endDate: Date
  ) =>
    `/statistics/charts/automation-performance?automationId=${automationId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
  getOrderAggByCustomerStatistics: (
    startOrdersCreatedAt?: string,
    endOrdersCreatedAt?: string
  ) =>
    `/statistics/orders-agg-by-customer?${
      startOrdersCreatedAt
        ? `startOrdersCreatedAt=${startOrdersCreatedAt}&`
        : ""
    }${endOrdersCreatedAt ? `endOrdersCreatedAt=${endOrdersCreatedAt}&` : ""}`,
  getTotalWhatsappCampaigns: (startDate: Date, endDate: Date) =>
    `statistics/total-whatsapp-campaigns?startDate=${startDate}&endDate=${endDate}`,
  getTotalMesssagesSent: (startDate: Date, endDate: Date) =>
    `statistics/total-messsages-sent?startDate=${startDate}&endDate=${endDate}`,
  getMessagesBounceRate: (startDate: Date, endDate: Date) =>
    `statistics/messages-bounce-rate?startDate=${startDate}&endDate=${endDate}`,
  getMessagesEngagementRate: (startDate: Date, endDate: Date) =>
    `statistics/messages-engagement-rate?startDate=${startDate}&endDate=${endDate}`,
  getReviRevenueSummary: () => `statistics/revenue/revi/summary`,
  getReviRevenueSummaryByMonth: () =>
    `statistics/revenue/revi/summary/by-month`,
  getCustomersKpiSummary: (startDate: Date, endDate: Date) =>
    `statistics/customers/kpi/summary?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
  getRFMAnalysis: (maxRecency: number) =>
    `statistics/customers/rfm/summary?maxRecency=${maxRecency}`,
  getRFMAnalysisHistory: (numberOfPreviousMonths: number, maxRecency: number) =>
    `statistics/customers/rfm/summary/history?numberOfPreviousMonths=${numberOfPreviousMonths}&maxRecency=${maxRecency}`,
  getOrdersCountByWeekDayAndHour: () =>
    `/statistics/orders/summary/agg-by-week-day-and-hour`,
  getTopCampaigns: (orderBy: GetTopCampaignsOrderBy, limit: number = 10) =>
    `/statistics/campaigns/top?orderBy=${orderBy}&limit=${limit}`,

  // auto-sorting-option
  listAutoSortingOptions: () => `/automatic-sorting-options`,
  createAutoSortingOption: () => `/automatic-sorting-options`,
  updateAutoSortingOption: (autoSortingOptionId: string) =>
    `/automatic-sorting-options/${autoSortingOptionId}`,
  deleteAutoSortingOption: (autoSortingOptionId: string) =>
    `/automatic-sorting-options/${autoSortingOptionId}`,

  // auto-replies
  listAutoReplies: () => `/automatic-replies`,
  createAutoReply: () => `/automatic-replies`,
  updateAutoReply: (autoReplyId: string) => `/automatic-replies/${autoReplyId}`,
  deleteAutoReply: (autoReplyId: string) => `/automatic-replies/${autoReplyId}`,

  // companies
  getCompanyDetails: () => `/companies/details`,
  updateCompany: (companyId: string) => `/companies/${companyId}`,
  getCompanyMessageBalance: () => `/companies/message-balance`,
  showPhoneQualityRating: () =>
    `companies/phone-quality-rating`,

  // company-recommendations
  listCompanyRecommendations: () => `/recommendations`,

  // company-defined-fields
  listCompanyDefinedFields: (table: CompanyDefinedFieldTableEnum) =>
    `/company-defined-fields?table=${table}`,
  createCompanyDefinedField: () => `/company-defined-fields`,
  deleteCompanyDefinedField: (companyDefinedFieldId: string) =>
    `/company-defined-fields/${companyDefinedFieldId}`,

  // message-templates
  showMessageTemplate: (id: string) => `/message-templates/${id}`,
  listMessageTemplates: () => `/message-templates`,
  createMessageTemplate: () => `/message-templates`,
  generateMessageTemplate: () => `/message-templates/generate`,
  regenerateMessageTemplate: () => `/message-templates/re-generate`,
  updateMessageTemplate: (messageTemplateId: string) =>
    `/message-templates/${messageTemplateId}`,
  deleteMessageTemplate: (messageTemplateId: string) =>
    `/message-templates/${messageTemplateId}`,
  getMessageTemplateCreateDto: (id: string) => `/message-templates/get-create-dto/${id}`,

  // customers
  uploadCustomers: () => `/customers/upload-file`,
  listCustomers: (
    listCustomersParams: ListCustomersParams = {
      // default values to invalidateQueries on reactQuery
      selectedEngagementActionTypes: undefined,
      excludedTemplateIds: undefined,
      selectedTemplateId: undefined,
      page: undefined,
      perPage: undefined,
    }
  ) => {
    const queryParams: { [key: string]: string | undefined } =
      listCustomersParams as any;

    const queryString = UrlUtils.convertObjectToQueryString(queryParams);
    return `/customers?${queryString}`;
  },
  toggleOptOutCustomer: (customerId: string) =>
    `/customers/${customerId}/toggle/opt-out`,
  listCustomerTableHeaders: () => `/customers/table-headers`,
  listOptedOutCustomers: () => `/customers/opted-out`,
  listCustomerStates: () => `/customers/states`,

  // orders
  uploadOrders: () => `/orders/upload-file`,

  // shopify
  syncShopifyOrders: () => `/shopify/sync-orders`,

  // whatsapp-campaigns
  listWhatsappCampaigns: (page: number, perPage: number) =>
    `/whatsapp-campaigns/stats?page=${page}&perPage=${perPage}`,
  getWhatsappCampaignDetails: (campaignId: string) =>
    `/whatsapp-campaigns/${campaignId}/details`,
  sendOrScheduleWhatsappCampaign: () => "/whatsapp-campaigns/send-or-schedule",
  cancelWhatsappCampaign: (campaignId: string) =>
    `/whatsapp-campaigns/${campaignId}/cancel`,

  // campaign-experiments
  startOrScheduleCampaignExperiment: () =>
    "/campaign-experiments/start-or-schedule",

  // sms-campaigns
  sendOrScheduleSmsCampaign: () => "/sms-campaigns/send-or-schedule",
  listSmsCampaigns: () => `/sms-campaigns/stats`,
  cancelSmsCampaign: (campaignId: string) =>
    `/sms-campaigns/${campaignId}/cancel`,

  // message-template-suggestions
  listMessageTemplateSuggestions: () => `/message-template-suggestions`,

  // tags
  listTags: () => `/tags`,
  createTag: () => `/tags`,
  updateTag: (tagId: string) => `/tags/${tagId}`,
  deleteTag: (tagId: string) => `/tags/${tagId}`,

  // filters
  listFilters: () => `/filters`,
  createFilter: () => `/filters`,
  deleteFilter: (filterId: string) => `/filters/${filterId}`,
  updateFilter: (filterId: string) => `/filters/${filterId}`,

  // audience-recommendations
  listAudienceRecommendations: () => `/audience-recommendations`,

  // quick-replies
  listQuickReplies: () => `/quick-replies`,
  createQuickReply: () => `/quick-replies`,
  updateQuickReply: (quickReplyId: string) => `/quick-replies/${quickReplyId}`,
  deleteQuickReply: (quickReplyId: string) => `/quick-replies/${quickReplyId}`,

  // flows
  createFlow: () => `flows`,
  updateFlow: (flowId: string) => `flows/${flowId}`,
  listFlows: () => `flows`,
  showFlow: (flowId: string) => `flows/${flowId}`,
  deleteFlow: (flowId: string) => `flows/${flowId}`,

  // products
  listProducts: () => `products`,

  // automations
  showAutomation: (automationId: string) => `automations/${automationId}`,
  listAutomations: () => `automations`,
  createAutomation: () => `automations`,
  updateAutomation: (automationId: string) => `automations/${automationId}`,
  updateAbandonedCartAutomation: (automationId: string) =>
    `automations/${automationId}/abandoned-cart`,
  deleteAutomation: (automationId: string) => `automations/${automationId}`,
  toggleAutomation: (automationId: string) =>
    `automations/${automationId}/toggle`,

  // files
  getSignedUrl: () => `files/signed-url`,

  // reports
  exportFlowClicksReport: (flowId: string) =>
    `reports/flows/${flowId}/clicks/export`,
  getReportOverview: (startDate: string, endDate: string) =>
    `reports/overview?startDate=${startDate}&endDate=${endDate}`,
  exportCampaignSalesReport: (startDate: string, endDate: string) =>
    `reports/campaigns/sales/export?startDate=${startDate}&endDate=${endDate}`,

  // users
  listCompanyAgents: () => `users/company-agents`,
};
