import { Box, Button, Flex, Stack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRoutes } from "../../../constants/api-routes";
import { appPaths } from "../../../constants/app-paths";
import { colors } from "../../../constants/colors";
import { MAX_CAMPAIGN_RECIPIENTS } from "../../../constants/max-campaign-recipients";
import {
  CampaignExperimentsService,
  StartOrScheduleCampaignExperimentDto,
} from "../../../services/campaign-experiments.service";
import { CompaniesService } from "../../../services/companies.service";
import {
  ListMessageTemplateItem,
  MessageTemplatesService,
} from "../../../services/message-templates.service";
import { MixpanelService } from "../../../services/mixpanel.service";
import {
  SendOrScheduleSmsCampaignDto,
  SmsCampaignsService,
} from "../../../services/sms-campaigns.service";
import {
  SendOrScheduleWhatsappCampaignDto,
  WhatsappCampaignsService,
} from "../../../services/whatsapp-campaigns.service";
import {
  finishCampaignCreation,
  isExperiment,
  isValidCampaignCreationState,
} from "../../../state/campaignCreationSlice";
import { RootState } from "../../../state/store";
import { CompanyDefinedFieldTableEnum } from "../../../types/CompanyDefinedField";
import SectionABTesting from "./components/SectionABTesting";
import SectionCampaignRecipients from "./components/SectionCampaignRecipients";
import SectionCommunicationChannel from "./components/SectionCommunicationChannel";
import SectionScheduling from "./components/SectionScheduling";
import SectionSelectTemplate from "./components/SectionSelectTemplate";
import SectionNumberQualityRatingNotice from "./components/SectionNumberQualityRatingNotice";
import AlertDialogBase from "../../../components/AlertDialog";
import { useState } from "react";

const CreateCampaignPage = () => {
  const {
    selectedCustomerRows,
    scheduledExecutionTime,
    selectedTemplate,
    templateArgs,
    filterCriteria,
    communicationChannel,
    variants,
    winningMetric,
    testSizePercentage,
    durationInMinutes,
  } = useSelector((state: RootState) => state.campaignCreation);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCustomerIds = Object.keys(selectedCustomerRows).slice(
    0,
    MAX_CAMPAIGN_RECIPIENTS
  );
  const canSubmit = useSelector(isValidCampaignCreationState);
  const isCampaignExperiment = useSelector(isExperiment);
  const { data: phoneRating } = useQuery(
    apiRoutes.showPhoneQualityRating(),
    async () => {
      const { data } = await MessageTemplatesService.showPhoneQualityRating();
      return data;
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );
  const { data: templates = [] } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: ListMessageTemplateItem) => template.status === "approved"
        );
      },
    }
  );
  const { data: companyDefinedFields = [] } = useQuery(
    apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS),
    async () => {
      const { data } = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS
      );
      return data;
    }
  );
  const [
    isPhoneQualityRatingLowAlertOpen,
    setIsPhoneQualityRatingLowAlertOpen,
  ] = useState(false);

  const sendOrScheduleWhatsappCampaign = useMutation(
    (sendOrScheduleWhatsappCampaignDto: SendOrScheduleWhatsappCampaignDto) =>
      WhatsappCampaignsService.sendOrScheduleWhatsappCampaign(
        sendOrScheduleWhatsappCampaignDto
      ),
    {
      onSuccess: async () => {
        MixpanelService.track("send-multiple-messages");
        dispatch(finishCampaignCreation());
        toast({
          title: "Campanha criada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.campaigns.whatsapp.index());
      },
    }
  );

  const sendOrScheduleSmsCampaign = useMutation(
    (sendOrScheduleSmsCampaignDto: SendOrScheduleSmsCampaignDto) =>
      SmsCampaignsService.sendOrScheduleSmsCampaign(
        sendOrScheduleSmsCampaignDto
      ),
    {
      onSuccess: async () => {
        MixpanelService.track("send-multiple-messages");
        dispatch(finishCampaignCreation());
        toast({
          title: "Campanha criada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.campaigns.sms.index());
      },
    }
  );

  const startOrScheduleCampaignExperimentMutation = useMutation(
    (
      startOrScheduleCampaignExperimentDto: StartOrScheduleCampaignExperimentDto
    ) => {
      return CampaignExperimentsService.startOrScheduleCampaignExperiment(
        startOrScheduleCampaignExperimentDto
      );
    },
    {
      onSuccess: async () => {
        dispatch(finishCampaignCreation());
        toast({
          title: "Experimento criado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.campaigns.whatsapp.index());
      },
    }
  );

  async function handleClickSubmit() {
    const templateId = selectedTemplate?.id;

    if (!templateId || !selectedCustomerIds.length) return;

    if (communicationChannel === "sms") {
      return await sendOrScheduleSmsCampaign.mutateAsync({
        customerIds: selectedCustomerIds,
        templateId,
        templateArgs,
        filterCriteria: filterCriteria || undefined,
        scheduledExecutionTime: scheduledExecutionTime || null,
      });
    }

    if (isCampaignExperiment) {
      return await startOrScheduleCampaignExperimentMutation.mutateAsync({
        customerIds: selectedCustomerIds,
        filterCriteria: filterCriteria || undefined,
        scheduledExecutionTime: scheduledExecutionTime || null,
        name: "Experimento",
        variants: [
          {
            templateId,
            templateArgs,
          },
          ...variants,
        ],
        winningMetric,
        testSizePercentage,
        durationInMinutes,
      });
    }
    if (phoneRating?.rating === "low") {
      setIsPhoneQualityRatingLowAlertOpen(true);
      return;
    }

    handleSendOrScheduleWhatsappCampaign();
  }

  async function handleSendOrScheduleWhatsappCampaign() {
    const templateId = selectedTemplate?.id!;
    return await sendOrScheduleWhatsappCampaign.mutateAsync({
      customerIds: selectedCustomerIds,
      templateId,
      templateArgs,
      filterCriteria: filterCriteria || undefined,
      scheduledExecutionTime: scheduledExecutionTime || null,
    });
  }

  function handleClickCancel() {
    dispatch(finishCampaignCreation());
    navigate(appPaths.customers.index());
  }

  return (
    <Box paddingTop="50px" paddingBottom="50px" paddingX="100px">
      <AlertDialogBase
        isOpen={isPhoneQualityRatingLowAlertOpen}
        onClose={() => setIsPhoneQualityRatingLowAlertOpen(false)}
        title="Tem certeza de que gostaria de enviar esta campanha?"
        onConfirm={() => {
          setIsPhoneQualityRatingLowAlertOpen(false);
          handleSendOrScheduleWhatsappCampaign();
        }}
      >
        A qualidade do seu número está baixa. Recomendamos que
        espere alguns dias até que a qualidade do seu número aumente para
        garantir que sua conta não seja bloqueada pela Meta (antigo Facebook) por 30 dias.
      </AlertDialogBase>
      <Stack spacing={5}>
        {phoneRating?.rating === "low" ? (
          <SectionNumberQualityRatingNotice />
        ) : (
          <></>
        )}
        <SectionCommunicationChannel />
        <SectionSelectTemplate
          templates={templates}
          companyDefinedFields={companyDefinedFields}
        />
        {communicationChannel === "whatsapp" && (
          <SectionABTesting
            templates={templates}
            companyDefinedFields={companyDefinedFields}
          />
        )}
        <SectionCampaignRecipients />
        <SectionScheduling />
        <Flex justifyContent={"space-between"}>
          <Button onClick={handleClickCancel}>Cancelar</Button>
          <Button
            color={colors.white}
            bgColor={colors.primary}
            onClick={handleClickSubmit}
            isDisabled={!canSubmit}
            isLoading={
              sendOrScheduleWhatsappCampaign.isLoading ||
              sendOrScheduleSmsCampaign.isLoading ||
              startOrScheduleCampaignExperimentMutation.isLoading
            }
          >
            Confirmar
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
};

export default CreateCampaignPage;
