import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import CustomECharts from "../../../../../components/CustomECharts";
import { colors } from "../../../../../constants/colors";

interface CardConversationsInfoProps {
  dates: string[];
  customer: number[];
  company: number[];
  totalPerCompany: number;
  totalPerCustomer: number;
}

export default function CardConversationsInfo({
  dates,
  customer,
  company,
  totalPerCompany,
  totalPerCustomer,
}: CardConversationsInfoProps) {

  function getCardTicketsChart() {
    return (
      <div style={{ width: "40%" }}>
        <CardHeader>
          <Center>
            <b>Conversas Iniciadas Por Cliente vs Empresa</b>
          </Center>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td>Pelo Cliente</Td>
                  <Td>{totalPerCustomer}</Td>
                </Tr>
                <Tr>
                  <Td>Pela Empresa</Td>
                  <Td>{totalPerCompany}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </div>
    );
  }
  function getCardInboudVsOutboundTickets() {
    return (
      <div style={{ width: "60%" }}>
        <CardHeader>
          <Center>
            <b>Conversas Iniciadas Pelo Cliente vs Empresa</b>
          </Center>
        </CardHeader>
        <CardBody>
          <CustomECharts
            chartWidth={"100%"}
            chartHeight="450px"
            marginTop={"0px"}
            option={{
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
              },
              legend: {},
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
              },
              xAxis: [
                {
                  type: "category",
                  data: dates,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: "Pelo Cliente",
                  type: "bar",
                  itemStyle: { color: "#9494FD" },
                  emphasis: {
                    focus: "series",
                  },
                  data: customer,
                },
                {
                  name: "Pela Empresa",
                  type: "bar",
                  itemStyle: { color: colors.danger },
                  emphasis: {
                    focus: "series",
                  },
                  data: company,
                },
              ],
            }}
          />
        </CardBody>
      </div>
    );
  }

  return (
    <Card variant="outline">
      <CardHeader>
        <Center>
          <b>Conversas</b>
        </Center>
      </CardHeader>
      <CardBody>
        <Flex flexDirection={"row"} gap={5}>
          {getCardInboudVsOutboundTickets()}
          {getCardTicketsChart()}
        </Flex>
      </CardBody>
    </Card>
  );
}
