import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { apiRoutes } from "../../constants/api-routes";
import { appPaths } from "../../constants/app-paths";
import { colors } from "../../constants/colors";
import { StatisticsService } from "../../services/statistics.service";
import { RFMGroupsEnum } from "../../types/RFMGroupsEnum";
import { RFMStats } from "../../types/RFMSummary";
import { MoneyUtils } from "../../utils/money.utils";

const CustomGridItem = ({
  area,
  bgColor,
  rfmGroup,
  stats,
  iconColor,
  totalCustomerCount,
  onClickRfmGroup,
}: {
  area: string;
  bgColor: string;
  totalCustomerCount: number;
  rfmGroup: RFMGroupsEnum;
  stats: RFMStats;
  iconColor: string;
  onClickRfmGroup: (rfmGroup: RFMGroupsEnum) => void;
}) => {
  const { t } = useTranslation();
  const customerPercentage = (
    (stats.customerCount / totalCustomerCount) *
    100
  ).toFixed(2);

  return (
    <GridItem
      gridArea={area}
      bgColor={bgColor}
      p={3}
      style={{ transition: "all 0.2s ease-in-out" }}
      _hover={{
        transform: "scale(1.05)",
        cursor: "pointer",
      }}
      onClick={() => onClickRfmGroup(rfmGroup)}>
      <Tooltip
  hasArrow
  placement="right-start"
  label={
    <Box>
      <Text fontWeight="bold">{t(`enums.RFMGroup.${rfmGroup}`)}</Text>
      <Divider my={2} />
      
      <Text>
        Clientes: {stats.customerCount} ({customerPercentage}%)
      </Text>

      <Box mt={2}>
        <Text fontWeight="medium">Recência</Text>
        <Text>• Mín: {stats.minRecency} dias</Text>
        <Text>• Máx: {stats.maxRecency} dias</Text>
        <Text>• Média: {stats.avgRecency} dias</Text>
      </Box>

      <Box mt={2}>
        <Text fontWeight="medium">Frequência</Text>
        <Text>• Mín: {stats.minFrequency} compras</Text>
        <Text>• Máx: {stats.maxFrequency} compras</Text>
        <Text>• Média: {stats.avgFrequency} compras</Text>
      </Box>

      <Box mt={2}>
        <Text fontWeight="medium">Valor gasto</Text>
        <Text>• Mín: {MoneyUtils.formatCurrency(stats.minMonetary)}</Text>
        <Text>• Máx: {MoneyUtils.formatCurrency(stats.maxMonetary)}</Text>
        <Text>• Média: {MoneyUtils.formatCurrency(stats.avgMonetary)}</Text>
      </Box>
    </Box>
  }
  whiteSpace="pre-line"
>

        <Flex
          flexDir="column"
          alignItems={"flex-end"}
          width="100%"
          height="100%">
          <Text>{t(`enums.RFMGroup.${rfmGroup}`)}</Text>
          <Flex alignItems={"center"} gap={2} fontWeight="bold">
            <FaRegUser color={iconColor} />
            {stats.customerCount}
          </Flex>
          {stats.customerCount > 0 && <Text>{customerPercentage}%</Text>}
        </Flex>
      </Tooltip>
    </GridItem>
  );
};

interface RFMChartProps {
  maxRecency: number;
}
const RFMChart = ({
  maxRecency
}: RFMChartProps) => {
  const navigate = useNavigate();
  const { data, isFetching } = useQuery(
    apiRoutes.getRFMAnalysis(
      maxRecency
    ),
    async () => {
      const { data } = await StatisticsService.getRFMAnalysis(maxRecency);
      return data;
    },
    {
      staleTime: 1000 * 60 * 60,
    }
  );

  function handleClickRfmGroup(rfmGroup: RFMGroupsEnum) {
    navigate(`${appPaths.customers.index()}?rfmGroup=${rfmGroup}&rfmMaxRecency=${maxRecency}`);
  }

  const totalCustomerCount = Object.values(data || {}).reduce(
    (acc, curr) => acc + (curr.customerCount || 0),
    0
  );

  const gridItems = data ? [
    {
      bgColor: colors.rfm[RFMGroupsEnum.CHAMPIONS].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.CHAMPIONS].primary,
      rfmGroup: RFMGroupsEnum.CHAMPIONS,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.LOYAL_CUSTOMERS].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.LOYAL_CUSTOMERS].primary,
      rfmGroup: RFMGroupsEnum.LOYAL_CUSTOMERS,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.POTENTIAL_LOYALISTS].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.POTENTIAL_LOYALISTS].primary,
      rfmGroup: RFMGroupsEnum.POTENTIAL_LOYALISTS,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.RECENT_CUSTOMERS].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.RECENT_CUSTOMERS].primary,
      rfmGroup: RFMGroupsEnum.RECENT_CUSTOMERS,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.PROMISING].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.PROMISING].primary,
      rfmGroup: RFMGroupsEnum.PROMISING,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.NEED_ATTENTION].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.NEED_ATTENTION].primary,
      rfmGroup: RFMGroupsEnum.NEED_ATTENTION,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.ABOUT_TO_SLEEP].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.ABOUT_TO_SLEEP].primary,
      rfmGroup: RFMGroupsEnum.ABOUT_TO_SLEEP,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.CANNOT_LOSE_THEM].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.CANNOT_LOSE_THEM].primary,
      rfmGroup: RFMGroupsEnum.CANNOT_LOSE_THEM,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.AT_RISK].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.AT_RISK].primary,
      rfmGroup: RFMGroupsEnum.AT_RISK,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.HIBERNATING].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.HIBERNATING].primary,
      rfmGroup: RFMGroupsEnum.HIBERNATING,
    },
    {
      bgColor: colors.rfm[RFMGroupsEnum.LOST_CUSTOMERS].secondary,
      iconColor: colors.rfm[RFMGroupsEnum.LOST_CUSTOMERS].primary,
      rfmGroup: RFMGroupsEnum.LOST_CUSTOMERS,
    },
  ].map((gridOption) => ({
    ...gridOption,
    stats: data[gridOption.rfmGroup],
    totalCustomerCount,
    area: gridOption.rfmGroup,
  })) : [];

  return isFetching ? (
    <Center width="100%" height="100%">
      <Spinner />
    </Center>
  ) : (
    <Grid
      width={"100%"}
      height={"100%"}
      templateColumns={"repeat(5, 1fr)"}
      templateRows={"repeat(5, 1fr)"}
      templateAreas={`
        "${RFMGroupsEnum.CANNOT_LOSE_THEM} ${RFMGroupsEnum.CANNOT_LOSE_THEM} ${RFMGroupsEnum.LOYAL_CUSTOMERS} ${RFMGroupsEnum.LOYAL_CUSTOMERS}  ${RFMGroupsEnum.CHAMPIONS}"
        "${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.LOYAL_CUSTOMERS} ${RFMGroupsEnum.LOYAL_CUSTOMERS}  ${RFMGroupsEnum.CHAMPIONS}"
        "${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.AT_RISK} ${RFMGroupsEnum.NEED_ATTENTION} ${RFMGroupsEnum.POTENTIAL_LOYALISTS}  ${RFMGroupsEnum.POTENTIAL_LOYALISTS}"
        "${RFMGroupsEnum.LOST_CUSTOMERS} ${RFMGroupsEnum.HIBERNATING} ${RFMGroupsEnum.ABOUT_TO_SLEEP} ${RFMGroupsEnum.POTENTIAL_LOYALISTS}  ${RFMGroupsEnum.POTENTIAL_LOYALISTS}"
        "${RFMGroupsEnum.LOST_CUSTOMERS} ${RFMGroupsEnum.HIBERNATING} ${RFMGroupsEnum.ABOUT_TO_SLEEP} ${RFMGroupsEnum.PROMISING}  ${RFMGroupsEnum.RECENT_CUSTOMERS}"
      `}>
      {gridItems.map(
        ({ area, bgColor, iconColor, rfmGroup, stats }) => (
          <CustomGridItem
            key={area}
            area={area}
            totalCustomerCount={totalCustomerCount}
            bgColor={bgColor}
            iconColor={iconColor}
            rfmGroup={rfmGroup}
            stats={stats}
            onClickRfmGroup={handleClickRfmGroup}
          />
        )
      )}
    </Grid>
  );
};

export default RFMChart;
