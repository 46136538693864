import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { MdOutlineCampaign } from "react-icons/md";
import { RiNodeTree } from "react-icons/ri";
import { colors } from "../../../../constants/colors";
import {
  Recommendation,
  RecommendationType,
} from "../../../../types/Recommendation";

const recommendationIconByType: Record<RecommendationType, ReactNode> = {
  create_automation: <RiNodeTree size="24px" />,
  create_campaign: <MdOutlineCampaign size="24px" />,
};

const ctaByType: Record<RecommendationType, string> = {
  create_automation: "Criar Automação",
  create_campaign: "Criar Campanha",
};

interface CardRecommendationProps {
  recommendation: Recommendation;
  onClickRecommendation: (recommendation: Recommendation) => void;
}

const CardRecommendation = ({
  recommendation,
  onClickRecommendation,
}: CardRecommendationProps) => {
  return (
    <Card
      direction={{ base: "column", md: "row" }}
      variant="outline"
      borderRadius="md"
      overflow="hidden"
      boxShadow="md"
      mb={4}
      p={4}
      _hover={{ boxShadow: "lg" }}>
      <Center
        marginRight={{ base: 0, md: "20px" }}
        marginBottom={{ base: "10px", md: 0 }}
        color="white"
        borderRadius="50%"
        bgColor={colors.primary}
        height="40px"
        width="40px"
        fontSize="16px"
        aria-hidden="true">
        {recommendationIconByType[recommendation.type]}
      </Center>
      <Stack spacing={3} flex="1">
        <CardBody p={0}>
          <Text
            fontSize="20px"
            dangerouslySetInnerHTML={{
              __html: recommendation.title,
            }}
          />
          <Text py={2} color="gray.600">
            {recommendation.description}
          </Text>
        </CardBody>
        <CardFooter p={0} pt={2}>
          <Button
            variant="solid"
            bgColor={colors.primary}
            color={colors.white}
            width={{ base: "100%", md: "auto" }}
            aria-label={recommendation.title}
            onClick={() => onClickRecommendation(recommendation)}>
            {ctaByType[recommendation.type]}
          </Button>
        </CardFooter>
      </Stack>
    </Card>
  );
};

export default CardRecommendation;
