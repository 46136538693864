import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";

export interface UpdateConversationTicketDto {
  conversationTicketId: string;
  agentId: string;
}

const updateConversationTicket = async (
  updateConversationTicketDto: UpdateConversationTicketDto
) => {
  const { conversationTicketId, ...data } = updateConversationTicketDto;
  return request.put(
    apiRoutes.updateConversationTicket(conversationTicketId),
    data
  );
}

export const ConversationTicketsService = {
  updateConversationTicket,
}
