import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import React from "react";
import { colors } from "../../constants/colors";

interface CrudModalProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  actionButtonText: string;
  children: React.ReactNode;
  formId: string;
  onClickDelete?: () => void;
  canDelete?: boolean;
}

const CrudModal = ({
  isLoading,
  isOpen,
  onClose,
  actionButtonText,
  title,
  children,
  formId,
  onClickDelete,
  canDelete,
}: CrudModalProps) => {
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter display={"flex"} justifyContent="space-between">
          {onClickDelete ? (
            <Button
              variant="ghost"
              onClick={onClickDelete}
              isDisabled={!canDelete}>
              Excluir
            </Button>
          ) : (
            <div></div>
          )}
          <Button
            isLoading={isLoading}
            variant='primary'
            type="submit"
            form={formId}>
            {actionButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CrudModal;
