import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import {
  ConversationCategoriesService,
  CreateConversationCategoryDto,
} from "../../../../services/conversation-categories.service";
import { ConversationCategoryDetailed } from "../../../../types/ConversationCategory";

const PopoverNewConversationCategory = () => {
  const popover = useDisclosure();
  const queryClient = useQueryClient();
  const [newConversationCategoryName, setNewConversationCategoryName] =
    useState("");
  const toast = useToast();
  const createConversationCategory = useMutation(
    async (createConversationCategoryDto: CreateConversationCategoryDto) =>
      ConversationCategoriesService.createConversationCategory(
        createConversationCategoryDto
      ),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          apiRoutes.listConversationCategoriesDetailed(),
          (oldData?: ConversationCategoryDetailed[]) => {
            if (!oldData) return [];
            const isNew = !oldData.some((category) => category.id === res.data.id)
            if (isNew) {
              return [
                ...oldData,
                {
                  id: res.data.id,
                  name: res.data.name,
                },
              ];
            }
            return oldData;
          }
        );
        toast({
          title: "Categoria criada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        popover.onClose();
        setNewConversationCategoryName("");
      },
    }
  );

  function handleCreateConversationCategory() {
    createConversationCategory.mutate({
      name: newConversationCategoryName,
    });
  }

  return (
    <Popover
      isOpen={popover.isOpen}
      onOpen={popover.onOpen}
      onClose={popover.onClose}
      placement="right"
      closeOnBlur={false}>
      <PopoverTrigger>
        <IconButton aria-label="Nova categoria" size="sm" icon={<AddIcon />} />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverHeader fontWeight="semibold">Nova categoria</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack gap={2}>
            <Input
              id="conversaton-category-name"
              onChange={(e) => setNewConversationCategoryName(e.target.value)}
              value={newConversationCategoryName}
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Button variant="outline" onClick={popover.onClose}>
                Cancelar
              </Button>
              <Button
                isDisabled={!newConversationCategoryName}
                bg='black'
                color='white'
                onClick={handleCreateConversationCategory}
                isLoading={createConversationCategory.isLoading}>
                Salvar
              </Button>
            </ButtonGroup>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverNewConversationCategory;
