import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../state/authSlice";
import { AppDispatch, RootState } from "../../state/store";

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; }>;
}

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const toast = useToast();
  const [isTermsOfUseAccepted, setIsTermsOfUseAccepted] = useState(true);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [isInstallable, setIsInstallable] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      const promptEvent = e as BeforeInstallPromptEvent;
      setDeferredPrompt(promptEvent);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    if (currentUser) {
      navigate(-1);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [currentUser, navigate]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  function onSubmit(data: any) {
    dispatch(
      login({
        email: data.email,
        password: data.password,
      })
    )
      .then(unwrapResult)
      .then(() => {
        toast({
          title: "Bem vindo",
          description: "Login realizado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Falha no login",
          description: "Email ou senha inválidos",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  }

  useEffect(() => {
    if (currentUser) {
      navigate(-1);
    }
  }, [currentUser, navigate]);

  return (
    <Box position="relative" height="100vh">
      <Container pt={10}>
        <Flex width="100%" justify={"center"} align="center">
          <Image
            src="/revi-logo-text.png"
            width="300px"
            height="200px"
            objectFit={"cover"}
          />
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <Input pr="4.5rem" placeholder="Email" {...register("email")} />
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Senha"
                {...register("password")}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Checkbox
              isChecked={isTermsOfUseAccepted}
              onChange={(e) => setIsTermsOfUseAccepted(e.target.checked)}>
              Eu aceito{" "}
              <Link
                href="https://revi-public.s3.amazonaws.com/Termos_de_Uso_Revi.pdf"
                target={"_blank"}>
                Termos & Condições de Uso
              </Link>
            </Checkbox>
            <Button type="submit" isDisabled={!isTermsOfUseAccepted}>
              Entrar
            </Button>
            {isInstallable && (
              <Button onClick={handleInstallClick}>
                Instalar o App
              </Button>
            )}
          </Stack>
        </form>
      </Container>
      <Box
        position={"absolute"}
        bottom={"10px"}
        left="0"
        right="0"
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        flexDir={"column"}>
        <Text>©2024 Revi. Todos os direitos reservados</Text>
        <Link
          href="https://revi-public.s3.amazonaws.com/Politica_de_Privacidade_Revi.pdf"
          target={"_blank"}>
          Política de privacidade
        </Link>
      </Box>
    </Box>
  );
};

export default LoginPage;
