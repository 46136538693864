import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  createContext,
  FormEvent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InputNumber from "../../components/InputNumber";
import Loading from "../../components/Loading";
import { apiRoutes } from "../../constants/api-routes";
import { colors } from "../../constants/colors";
import { AudienceRecommendationsService } from "../../services/audiece-recommendations.service";
import { MessageTemplatesService } from "../../services/message-templates.service";
import {
  MessagesService,
  SendMessageTemplateDto,
} from "../../services/messages.service";
import { MixpanelService } from "../../services/mixpanel.service";
import { AppDispatch } from "../../state/store";
import { Conversation } from "../../types/Conversation";
import {
  MessageTemplateWithIncludes,
  MessageTemplate,
  MessageTemplateType,
} from "../../types/MessageTemplate";
import { TemplateParametersEnum } from "../../types/TemplateParametersEnum";
import { MessageTemplateUtils } from "../../utils/message-templates.utils";
import { NameUtils } from "../../utils/name.utils";
import { UrlUtils } from "../../utils/url.utils";
import { AudienceFiltersForm } from "./AudienceFiltersForm";
import { DirectDeliveryForm } from "./DirectDeliveryForm";
import { TemplateSelection } from "./TemplateSelection";

export type DeliveryType = "direct" | "campaign";

interface SendTemplateModalContextData {
  openModal: ({
    conversation,
    onSelectTemplate,
    templateTypes,
    deliveryType,
  }: {
    conversation?: Conversation;
    onSelectTemplate?: (template: MessageTemplate) => void;
    templateTypes: MessageTemplateType[];
    deliveryType: DeliveryType;
  }) => void;
  handleClose: () => void;
}

interface SendTemplateModalProps {
  children?: ReactNode;
}

const SendTemplateModalContext = createContext(
  {} as SendTemplateModalContextData
);

export function SendTemplateModalProvider({
  children,
}: SendTemplateModalProps) {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const {
    data: templates = [],
    isLoading: isLoadingMessageTemplates,
    isError,
  } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: MessageTemplate) => template.status === "approved"
        );
      },
    }
  );

  const sendMessageTemplate = useMutation(
    (sendMessageTemplateDto: SendMessageTemplateDto) =>
      MessagesService.sendMessageTemplate(sendMessageTemplateDto),
    {
      onSuccess: () => {
        MixpanelService.track("send-message", {
          isTemplate: true,
        });
        handleClose();
      },
    }
  );

  const { data: audienceRecommendations = [] } = useQuery(
    apiRoutes.listAudienceRecommendations(),
    async () => {
      const { data } =
        await AudienceRecommendationsService.listAudienceRecommendations();
      return data;
    }
  );

  const [enabledTemplateTypes, setEnabledTemplateTypes] = useState<
    MessageTemplateType[]
  >([]);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [onSelectTemplateCb, setOnSelectTemplateCb] = useState<any>(null);
  const [selectedTemplate, setSelectedTemplate] =
    useState<MessageTemplateWithIncludes | null>(null);
  const [deliveryType, setDeliveryType] = useState<DeliveryType | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});
  const dispatch = useDispatch<AppDispatch>();
  const needsToSelectTemplate = !selectedTemplate;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const templateParams: string[] = selectedTemplate?.templateText
    ? MessageTemplateUtils.getAllParametersInText(
        selectedTemplate.templateText +
          selectedTemplate.messageTemplateCards
            ?.map((card) => card.body)
            .join("")
      )
    : [];
  const templateHasCustomerName = templateParams.includes(
    TemplateParametersEnum.CUSTOMER_NAME
  );

  const filteredTemplates = templates.filter((template) => {
    const isValidType = enabledTemplateTypes.includes(template.type);
    return template.name.includes(searchQuery) && isValidType;
  });

  useEffect(() => {
    if (needsToSelectTemplate) {
      setTemplateArgs({});
    }
  }, [needsToSelectTemplate]);

  useEffect(() => {
    if (templateHasCustomerName) {
      setTemplateArgs({
        ...templateArgs,
        "[nome do consumidor]": NameUtils.getFirstName(
          conversation?.recipientName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateHasCustomerName, conversation?.recipientName]);

  function handleChangeTemplateParameter(parameter: string, value: string) {
    setTemplateArgs({
      ...templateArgs,
      [parameter]: value,
    });
  }

  function openModal({
    conversation,
    onSelectTemplate,
    templateTypes,
    deliveryType,
  }: {
    conversation?: Conversation;
    onSelectTemplate?: (template: MessageTemplate) => void;
    templateTypes: MessageTemplateType[];
    deliveryType?: DeliveryType;
  }) {
    if (conversation) {
      setConversation(conversation);
    }
    if (onSelectTemplate) {
      setOnSelectTemplateCb(() => onSelectTemplate);
    }
    if (templateTypes) {
      setEnabledTemplateTypes(templateTypes);
    }
    if (deliveryType) {
      setDeliveryType(deliveryType);
    }
    onOpen();
  }

  function handleClose() {
    onClose();
    setSearchQuery("");
    setSelectedTemplate(null);
    setConversation(null);
  }

  function canSubmit(): boolean {
    const isParameterValuesValid =
      Object.values(templateArgs).filter(Boolean).length ===
      templateParams.length;
    return !needsToSelectTemplate && isParameterValuesValid;
  }

  function handleSelectTemplate(template: MessageTemplateWithIncludes) {
    setSelectedTemplate(template);
  }

  async function handleSubmit() {
    await sendMessageTemplate.mutateAsync({
      conversationId: conversation!.id,
      templateArgs,
      templateId: selectedTemplate!.id,
    });

    // update name in conversation if needed
    const firstName = templateArgs["[nome do consumidor]"]!;
    if (!conversation?.recipientName.includes(firstName)) {
      // TODO
    }
  }

  function handleSubmitAudienceFilters(e: any) {
    e.preventDefault();
    if (onSelectTemplateCb) {
      onSelectTemplateCb(selectedTemplate);
    }

    const audienceType = e.target.elements?.audienceType?.value;
    const audienceFilterCriteria = audienceRecommendations?.find(
      (audience) => audience.type === audienceType
    )?.filterCriteria;
    if (audienceFilterCriteria) {
      // override segmentation filters with audience filters
      const audienceFilters = UrlUtils.convertQueryStringToObject(
        audienceFilterCriteria
      );
      const inputValues: Record<string, string> = {};

      ["minDaysSinceLastPurchase", "minDaysSinceLastCampaign"].forEach(
        (key) => {
          if (e.target.elements[key]) {
            inputValues[key] = e.target.elements[key].value;
          }
        }
      );

      setSearchParams({
        ...inputValues,
        ...audienceFilters,
        excludedTemplateIds: selectedTemplate!.id,
      });
    } else {
      // update minDaysSinceLastPurchase and minDaysSinceLastCampaign
      searchParams.set(
        "minDaysSinceLastPurchase",
        e.target.elements.minDaysSinceLastPurchase.value
      );
      searchParams.set(
        "minDaysSinceLastCampaign",
        e.target.elements.minDaysSinceLastCampaign.value
      );
      searchParams.set("excludedTemplateIds", selectedTemplate!.id);
      setSearchParams(searchParams);
    }
    handleClose();
  }

  const modalTitle = needsToSelectTemplate
    ? "Selecione um template"
    : deliveryType === "direct"
    ? "Confira o template e envie"
    : "Segmentação inteligente";

  return (
    <SendTemplateModalContext.Provider
      value={{
        openModal,
        handleClose,
      }}
    >
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxH={"80vh"}>
          <ModalHeader color={colors.primaryMedium}>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY={"scroll"} overflowX="hidden">
            {needsToSelectTemplate ? (
              <Loading
                isLoading={isLoadingMessageTemplates}
                loadingMessage="Carregando templates..."
                isError={isError}
                errorMessage="Falha ao carregar templates"
              >
                <TemplateSelection
                  deliveryType={deliveryType}
                  filteredTemplates={filteredTemplates}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleSelectTemplate={handleSelectTemplate}
                />
              </Loading>
            ) : deliveryType === "direct" ? (
              <DirectDeliveryForm
                templateParams={templateParams}
                selectedTemplate={selectedTemplate!}
                templateArgs={templateArgs}
                handleChangeTemplateParameter={handleChangeTemplateParameter}
              />
            ) : (
              <AudienceFiltersForm
                handleSubmitAudienceFilters={handleSubmitAudienceFilters}
                audienceRecommendations={audienceRecommendations}
                id="audience-filters-form"
              />
            )}
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="space-between">
            <Button
              variant="ghost"
              onClick={() => {
                selectedTemplate ? setSelectedTemplate(null) : handleClose();
              }}
            >
              Voltar
            </Button>
            {deliveryType === "direct" ? (
              <Button
                variant="primary"
                isDisabled={!canSubmit()}
                onClick={handleSubmit}
                isLoading={sendMessageTemplate.isLoading}
              >
                Continuar
              </Button>
            ) : (
              <Button
                variant="primary"
                form="audience-filters-form"
                type="submit"
              >
                Continuar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {children}
    </SendTemplateModalContext.Provider>
  );
}

export function useSendTemplateModal(): SendTemplateModalContextData {
  const context = useContext(SendTemplateModalContext);
  if (!context) {
    throw new Error(
      "useSendTemplateModal must be used within a CrudAutoSortingModal"
    );
  }
  return context;
}
