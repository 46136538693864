import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Select,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../constants/app-paths";
import TableMessageTemplates from "./components/TableMessageTemplates";
import { useState } from "react";
import MessageTemplateStatusEnum from "../../types/MessageTemplateStatusEnum";

const MessageTemplatesPage = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<MessageTemplateStatusEnum | undefined>();

  return (
    <Box padding="20px">
      <Flex width="100%" direction={{ base: "column" }}>
        <Flex width="100%" justifyContent="space-between">
          <Heading mb={5}>Templates de mensagem</Heading>
          <Button
            variant="primary"
            onClick={() =>
              navigate(appPaths.messageTemplates.createMessageTemplate())
            }
          >
            + criar template
          </Button>
        </Flex>

        <Flex alignItems={"center"} gap={3}>
          <Text fontWeight={"bold"}>Situação:</Text>
          <Select
            defaultValue={"Todos"}
            width="fit-content"
            onChange={(event: any) => {
              setFilter(event.target.value);
            }}
          >
            <option value="">Todos</option>
            <option value={MessageTemplateStatusEnum.approved}>Aprovado</option>
            <option value={MessageTemplateStatusEnum.pending}>Pendente</option>
            <option value={MessageTemplateStatusEnum.deleted}>Deletado</option>
            <option value={MessageTemplateStatusEnum.paused}>Pausado</option>
          </Select>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableMessageTemplates status={filter} />
    </Box>
  );
};

export default MessageTemplatesPage;
