import { Card, CardBody, CardHeader, Flex, Heading } from '@chakra-ui/react';
import { subYears } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useQuery } from 'react-query';
import CardStatistic from '../../../../components/CardStatistic';
import { apiRoutes } from '../../../../constants/api-routes';
import { StatisticsService } from '../../../../services/statistics.service';
import { MoneyUtils } from '../../../../utils/money.utils';


const SectionCustomerKPIs = () => {
  const [startDate, setStartDate] = useState(subYears(new Date(), 3));
  const [endDate, setEndDate] = useState(new Date());
  const {
    data: customersKpiSummary,
    isFetching: isFetchingCustomersKpiSummary,
  } = useQuery(
    apiRoutes.getCustomersKpiSummary(startDate, endDate),
    async () => {
      const { data } = await StatisticsService.getCustomersKpiSummary(startDate, endDate);
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );

  return (
    <Card flex={1}>
      <CardHeader display='flex' justifyContent={'space-between'} alignItems='center'>
        <Heading size="md">KPIs</Heading>
        <Flex gap={3}>
          <Flex alignItems={'center'}>
            Início:
            <ReactDatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
            />
          </Flex>
          <Flex alignItems={'center'}>
            Fim:
            <ReactDatePicker
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
            />
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody display="flex" gap={4}>
        {[
          {
            title: "Ticket médio",
            value: MoneyUtils.formatCurrency(
              customersKpiSummary?.avgOrderValuePerCustomer || 0,
              0
            ),
            icon: "R$",
            tooltip: "Ticket médio por cliente",
          },
          {
            title: "LTV",
            value: MoneyUtils.formatCurrency(
              customersKpiSummary?.avgTotalOrdersValuePerCustomer || 0,
              0
            ),
            icon: "LTV",
            tooltip: "Total gasto por cliente",
          },
          {
            title: "Frequência",
            value: `${(
              customersKpiSummary?.avgTotalOrdersPerCustomer || 0
            ).toFixed(1)}x`,
            icon: "Freq",
            tooltip: "Frequência de compras por cliente",
          },
        ].map((stats) => (
          <CardStatistic
            icon={stats.icon}
            title={stats.title}
            value={stats.value}
            key={stats.title}
            size="sm"
            tooltip={stats.tooltip}
            isLoading={isFetchingCustomersKpiSummary}
          />
        ))}
      </CardBody>
    </Card>
  )
}

export default SectionCustomerKPIs