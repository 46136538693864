import {
  FormControl,
  Input,
  Select
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { AiOutlineTable } from "react-icons/ai";
import { useQuery } from "react-query";
import FormLabel from "../../../../../components/FormLabel";
import InputNumber from "../../../../../components/InputNumber";
import { apiRoutes } from "../../../../../constants/api-routes";
import { CompaniesService } from "../../../../../services/companies.service";
import { CompanyDefinedFieldTableEnum } from "../../../../../types/CompanyDefinedField";
import AccordionItemLayout from "../AccordionItemLayout";

interface SectionCustomFieldsProps {
  useFormReturn: UseFormReturn<any>;
}

const SectionCustomFields = ({ useFormReturn }: SectionCustomFieldsProps) => {
  const { register, control } = useFormReturn;
  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null);
  const { data: companyDefinedFields } = useQuery(
    apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS),
    async () => {
      const { data } = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS
      );
      return data;
    }
  );

  const selectedField = companyDefinedFields?.find(
    (field) => field.id === selectedFieldId
  );

  return (
    <AccordionItemLayout
      title="Colunas customizadas"
      icon={<AiOutlineTable size="18px" />}>
      <FormControl>
        <FormLabel size="sm">Nome da coluna</FormLabel>
        <Select
          size="sm"
          bg="white"
          placeholder="Selecione"
          {...register("customFieldId1")}
          onChange={(e) => {
            register("customFieldId1").onChange(e);
            setSelectedFieldId(e.target.value);
          }}>
          {companyDefinedFields?.map((field) => (
            <option key={field.id} value={field.id}>
              {field.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel size="sm">Valor</FormLabel>
        {selectedField?.dataType === "number" ? (
          <Controller
            name="customFieldValue1"
            control={control}
            render={({ field }) => {
              return (
                <InputNumber
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  size="sm"
                />
              );
            }}
          />
        ) : (
          <Input
            size={"sm"}
            bg="white"
            {...register("customFieldValue1")}
            placeholder="Digite um valor"
          />
        )}
      </FormControl>
    </AccordionItemLayout>
  );
};

export default SectionCustomFields;
