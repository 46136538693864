import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlinePermMedia } from "react-icons/md";
import { NodeProps } from "reactflow";
import WhatsappFilePreview from "../../../../../../components/WhatsappFilePreview";
import { FilesService } from "../../../../../../services/files.service";
import { SendWhatsappMediaNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";

const SendWhatsappMediaNode = (props: NodeProps<SendWhatsappMediaNodeData>) => {
  const { t } = useTranslation();
  const [filePreview, setFilePreview] = useState<File | null>(null);

  useEffect(() => {
    if (props.data.file) {
      setFilePreview(props.data.file);
    } else if (props.data.fileKey) {
      FilesService.fetchFileByFileKey(props.data.fileKey).then((file) => {
        setFilePreview(file);
      });
    }
  }, [props.data.file, props.data.fileKey]);

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<MdOutlinePermMedia />}
      color="#e80000">
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content">
        {(!filePreview && !!props.data?.fileKey) && (
          <Flex justifyContent="center" alignItems="center">
            <Spinner size="md" mr={2} />
          </Flex>
        )}
        {!!filePreview && <WhatsappFilePreview file={filePreview} />}
      </Box>
    </NodeLayout>
  );
};

export default SendWhatsappMediaNode;
