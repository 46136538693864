import {
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation} from "react-query";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../../constants/app-paths";
import {
  AutomationsService,
  CreateAutomationDto,
} from "../../../../services/automations.service";
import AutomationForm from "../components/AutomationForm";

const CreateBackgroundAutomationPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const createAutomation = useMutation(
    (createAutomationDto: CreateAutomationDto) =>
      AutomationsService.createAutomation(createAutomationDto),
    {
      onSuccess: (res) => {
        toast({
          title: "Automação criada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(appPaths.automations.backgroundAutomations.index());
      }
    }
  );

  const [templateArgs, setTemplateArgs] = useState<{
    [key: string]: string | undefined;
  }>({});

  async function onSubmit(data: any) {
    const {
      name,
      isActive,
      messageTemplateId,
      filterId,
      dailyMessageLimitOnWhatsapp,
      frequency,
      minutesOfTheDay,
      daysOfWeek,
      daysOfMonth,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
    } = data;
    const hours = Math.floor(Number(minutesOfTheDay) / 60);
    const minutes = Number(minutesOfTheDay) % 60;

    await createAutomation.mutateAsync({
      name,
      daysOfMonth: daysOfMonth.filter(Boolean).map(Number),
      daysOfWeek: daysOfWeek.filter(Boolean).map(Number),
      filterId,
      frequency,
      isActive,
      messageTemplateId,
      dailyMessageLimitOnWhatsapp,
      hours,
      minutes,
      templateArgs,
      isAutomationRepetitionAllowed,
      minDaysSinceLastCampaign,
    });
  }

  return (
    <AutomationForm
      onSubmit={onSubmit}
      templateArgs={templateArgs}
      setTemplateArgs={setTemplateArgs}
    />
  );
};

export default CreateBackgroundAutomationPage;
