import { Badge, Link, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { BiTestTube } from "react-icons/bi";
import { BsExclamationTriangle } from "react-icons/bs";
import { CiTrophy } from "react-icons/ci";
import { MdFreeCancellation, MdOutlineRemoveRedEye } from "react-icons/md";
import { PiMagnifyingGlassPlus } from "react-icons/pi";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { colors } from "../../../../../constants/colors";
import { EngagementActionsEnum } from "../../../../../types/EngagementActionsEnum";
import { WhatsappCampaignStatus } from "../../../../../types/Prisma";
import {
  CampaignType,
  WhatsappCampaignStatsData,
} from "../../../../../types/WhatsappCampaignsData";

export interface RowCampaignProps {
  whatsappCampaign: WhatsappCampaignStatsData;
  onClickEngagementAction: (
    templateId: string,
    action: EngagementActionsEnum
  ) => void;
  onClickCancelCampaign: (campaignId: string) => void;
  onClickCampaignResults: (campaignId: string) => void;
  onClickInterruptCampaign: () => void;
  bgColor?: string;
  paddingLeft?: string;
  campaignIcon?: React.ReactNode;
}

const RowCampaign = ({
  whatsappCampaign,
  onClickEngagementAction,
  onClickCancelCampaign,
  onClickCampaignResults,
  onClickInterruptCampaign,
  bgColor,
  paddingLeft,
  campaignIcon,
}: RowCampaignProps) => {
  const { t } = useTranslation();

  function getRealCampaignStatus(
    campaign: WhatsappCampaignStatsData
  ): WhatsappCampaignStatus {
    if (campaign.status === "scheduled" && !campaign.scheduled_execution_time) {
      return "in_progress";
    }

    return campaign.status;
  }

  function getColorScheme(status: WhatsappCampaignStatus): string {
    const statusData: Record<WhatsappCampaignStatus, string> = {
      in_progress: colors.status.inProgress,
      completed: colors.status.completed,
      scheduled: colors.status.scheduled,
      interrupted: colors.status.interrupted,
      failed: colors.status.failed,
      canceled: colors.status.canceled,
    };
    return statusData[status];
  }

  return (
    <Tr
      bgColor={bgColor}
      key={whatsappCampaign.id}
      fontWeight={
        getRealCampaignStatus(whatsappCampaign) === "scheduled"
          ? "semibold"
          : "light"
      }>
      <Td></Td>
      <Td paddingLeft={paddingLeft}>
        {format(
          new Date(
            whatsappCampaign.scheduled_execution_time ||
              whatsappCampaign.created_at
          ),
          "dd/MM/yyyy HH:mm"
        )}
      </Td>
      <Td>
        <Tooltip label={whatsappCampaign.template_name}>
          <Text display="inline-flex" alignItems="center">
            {whatsappCampaign.template_name}
            {campaignIcon}
          </Text>
        </Tooltip>
      </Td>

      <Td textAlign={"center"}>
        {whatsappCampaign.total_recipients}
      </Td>
      <Td>
        {getRealCampaignStatus(whatsappCampaign) !== "scheduled" && (
          <Link
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
            gap={3}
            color={colors.primaryLight}
            onClick={() =>
              onClickEngagementAction(
                whatsappCampaign.template_id,
                EngagementActionsEnum.RECEIVED
              )
            }>
            {whatsappCampaign.sent}
          </Link>
        )}
      </Td>
      <Td>
        {getRealCampaignStatus(whatsappCampaign) !== "scheduled" && (
          <Link
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
            gap={3}
            color={colors.primaryLight}
            onClick={() =>
              onClickEngagementAction(
                whatsappCampaign.template_id,
                EngagementActionsEnum.READ
              )
            }>
            {whatsappCampaign.read}
          </Link>
        )}
      </Td>
      <Td>
        {getRealCampaignStatus(whatsappCampaign) !== "scheduled" && (
          <Link
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
            gap={3}
            color={colors.primaryLight}
            onClick={() =>
              onClickEngagementAction(
                whatsappCampaign.template_id,
                EngagementActionsEnum.CLICKED
              )
            }>
            {whatsappCampaign.clicks}
          </Link>
        )}
      </Td>
      <Td>
        {getRealCampaignStatus(whatsappCampaign) !== "scheduled" && (
          <Link
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
            gap={3}
            color={colors.primaryLight}
            onClick={() =>
              onClickEngagementAction(
                whatsappCampaign.template_id,
                EngagementActionsEnum.REPLIED
              )
            }>
            {whatsappCampaign.replies}
          </Link>
        )}
      </Td>
      <Td textAlign={"center"}>
        <Text noOfLines={1} maxW={"300px"} display="block">
          {whatsappCampaign.failed}
        </Text>
      </Td>
      <Td
        display={"flex"}
        width={"100px"}
        flexDir="column"
        justifyContent={"center"}
        alignItems="center">
        <Badge
          colorScheme={getColorScheme(getRealCampaignStatus(whatsappCampaign))}>
          {t(
            `enums.WhatsappCampaignStatus.${getRealCampaignStatus(
              whatsappCampaign
            )}`
          )}
        </Badge>
      </Td>
      <Td>
        {getRealCampaignStatus(whatsappCampaign) === "scheduled" &&
          whatsappCampaign.campaign_experiment_id === null ?
          (
            <ButtonIcon
              tooltipLabel="Cancelar agendamento"
              icon={<MdFreeCancellation color={colors.darkGrey} size={20} />}
              onClick={() => {
                onClickCancelCampaign(whatsappCampaign.id);
              }}
            />
          ) : ["completed", "interrupted"].includes(
            getRealCampaignStatus(whatsappCampaign)
          ) ? (
          <ButtonIcon
            tooltipLabel="Ver resultados"
            icon={
              <MdOutlineRemoveRedEye color={colors.primaryLight} size={20} />
            }
            onClick={() => onClickCampaignResults(whatsappCampaign.id)}
          />
        ) : null}
        {getRealCampaignStatus(whatsappCampaign) === "interrupted" && (
          <ButtonIcon
            tooltipLabel="Ver detalhes"
            icon={
              <BsExclamationTriangle color={colors.primaryLight} size={20} />
            }
            onClick={onClickInterruptCampaign}
          />
        )}
      </Td>
    </Tr>
  );
};

export default RowCampaign;
