import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationTriangle } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import AlertDialogBase from "../../../../../components/AlertDialog";
import LoadingScreen from "../../../../../components/LoadingScreen";
import Pagination from "../../../../../components/Pagination";
import { apiRoutes } from "../../../../../constants/api-routes";
import {
  appPaths,
  getCustomersPageQueryParams,
} from "../../../../../constants/app-paths";
import { MixpanelService } from "../../../../../services/mixpanel.service";
import { WhatsappCampaignsService } from "../../../../../services/whatsapp-campaigns.service";
import { EngagementActionsEnum } from "../../../../../types/EngagementActionsEnum";
import { WhatsappCampaignStatsData } from "../../../../../types/WhatsappCampaignsData";
import RowCampaign from "./RowCampaign";
import RowExperiment from "./RowExperiment";

const TableWhatsappCampaigns = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const {
    isOpen: isInterruptedModalOpen,
    onClose: onCloseInterruptedModal,
    onOpen: onOpenInterruptedModal,
  } = useDisclosure();
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  );
  const {
    data: tableData,
    isLoading: isLoadingWhatsappCampaigns,
    refetch: refetchWhatsappCampaigns,
  } = useQuery(
    apiRoutes.listWhatsappCampaigns(currentPage, rowsPerPage),
    async () => {
      const res = await WhatsappCampaignsService.listWhatsappCampaigns(
        currentPage,
        rowsPerPage
      );
      return res.data;
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: true,
    }
  );

  const cancelWhatsappCampaign = useMutation(
    (campaignId: string) =>
      WhatsappCampaignsService.cancelWhatsappCampaign(campaignId),
    {
      onSuccess: () => {
        toast({
          title: "Campanha cancelada com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetchWhatsappCampaigns();
      },
    }
  );

  function handleClickEngagementAction(
    templateId: string,
    selectedEngagementActionTypes?: EngagementActionsEnum
  ) {
    MixpanelService.track("view-campaign-details", {
      selectedEngagementActionTypes: selectedEngagementActionTypes,
    });
    navigate({
      pathname: appPaths.customers.index(),
      search: getCustomersPageQueryParams({
        selectedEngagementTemplateIds: templateId,
        selectedEngagementActionTypes,
      }),
    });
  }

  function groupByCampaignExperimentId(data: WhatsappCampaignStatsData[]) {
    const groupedData = data.reduce((acc, item) => {
      const key = item.campaign_experiment_id || item.id;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item);

      return acc;
    }, {} as Record<string, WhatsappCampaignStatsData[]>);

    return Object.values(groupedData);
  }

  function handleClickCancelCampaign(campaignId: string) {
    setSelectedCampaignId(campaignId);
    onOpenAlert();
  }

  function handleClickCampaignResults(campaignId: string) {
    navigate({
      pathname: appPaths.campaigns.whatsapp.details(campaignId),
    });
  }
  function handleClickInterruptCampaign() {
    onOpenInterruptedModal();
  }

  const groupedCampaigns = groupByCampaignExperimentId(tableData?.data || []);

  return (
    <LoadingScreen isLoading={isLoadingWhatsappCampaigns}>
      <TableContainer overflowX="visible">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Data de envio</Th>
              <Th>Template</Th>
              <Th># destinatários</Th>
              <Th># enviadas</Th>
              <Th># lidas</Th>
              <Th># cliques</Th>
              <Th># respostas</Th>
              <Th># erros</Th>
              <Th>Status</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupedCampaigns.map((campaignsList, index) => {
              const isExperiment = campaignsList.some(
                (campaign) => !!campaign.campaign_experiment_id
              );
              return isExperiment ? (
                <RowExperiment
                  whatsappCampaigns={campaignsList}
                  onClickEngagementAction={handleClickEngagementAction}
                  onClickCancelCampaign={handleClickCancelCampaign}
                  onClickCampaignResults={handleClickCampaignResults}
                  onClickInterruptCampaign={handleClickInterruptCampaign}
                />
              ) : (
                campaignsList.map((whatsappCampaign, index) => (
                  <RowCampaign
                    whatsappCampaign={whatsappCampaign}
                    onClickEngagementAction={handleClickEngagementAction}
                    onClickCancelCampaign={handleClickCancelCampaign}
                    onClickCampaignResults={handleClickCampaignResults}
                    onClickInterruptCampaign={handleClickInterruptCampaign}
                  />
                ))
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        initialPage={currentPage}
        onChangePage={(page) => setCurrentPage(page)}
        rowsPerPage={rowsPerPage}
        totalRows={tableData?.meta.totalItems || 0}
        onChangeRowsPerPage={(rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
        }}
      />
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={() => {
          setSelectedCampaignId(null);
          onCloseAlert();
        }}
        title="Confirmar cancelamento"
        onConfirm={() => {
          cancelWhatsappCampaign.mutateAsync(selectedCampaignId!);
          onCloseAlert();
        }}>
        Tem certeza que deseja cancelar o agendamento desta campanha?
      </AlertDialogBase>
      <Modal isOpen={isInterruptedModalOpen} onClose={onCloseInterruptedModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={"flex"} alignItems="center" gap={2}>
            <BsExclamationTriangle size={20} />
            Campanha interrompida pela Meta
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontStyle={"italic"}>
              A campanha foi interrompida pela Meta devido ao baixo engajamento.
              Para continuar o envio para os clientes que não receberam a
              campanha, siga os passos abaixo:
            </Text>
            <OrderedList mt={2}>
              {[
                {
                  text: "Crie um novo template",
                },
                {
                  text: "Aguarde a aprovação do novo template",
                },
                {
                  text: "Clique na aba de clientes",
                },
                {
                  text: "Adicione os mesmos filtros do último envio",
                },
                {
                  text: "Oculte clientes que receberam uma campanha nos últimos 2 dias para evitar de impactar o mesmo cliente",
                  fontWeight: "bold",
                },
                {
                  text: "Envie a campanha",
                },
              ].map(({ text, fontWeight }) => (
                <ListItem fontWeight={fontWeight}>{text}</ListItem>
              ))}
            </OrderedList>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseInterruptedModal}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </LoadingScreen>
  );
};

export default TableWhatsappCampaigns;
