import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../constants/colors";
import { hoverCardStyles } from "../../styles/hover.styles";

interface SidebarSecondaryProps {
  title: string;
  options: {
    title: string;
    path: string;
  }[]
}

const SidebarSecondary = ({
  title,
  options
}: SidebarSecondaryProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  function isActivePath(path: string) {
    return location.pathname.includes(path);
  }
  return (
    <Flex
      flexDir="column"
      bgColor={'#e3ecff'}
      height="100%"
      borderLeft={"solid 1px #4a4a4a"}>
      <Box paddingY={5} paddingX={2}>
        <Heading
          size={"lg"}
          alignSelf="flex-start"
        >
          {title}
        </Heading>
      </Box>
      <Flex flexDir="column">
        {options.map((option) => (
          <Flex
            key={option.title}
            padding={4}
            bgColor={
              isActivePath(option.path) ? '#c8daff' : '#e3ecff'
            }
            _hover={hoverCardStyles}
            cursor={"pointer"}
            width="100%"
            onClick={() => navigate(option.path)}
            borderBottom={`solid 1px ${colors.white}`}
            gap={3}>
            <Text fontWeight={"light"}>
              {option.title}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default SidebarSecondary;
