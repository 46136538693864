import { UrlUtils } from "../utils/url.utils";
import { EngagementActionsEnum } from "../types/EngagementActionsEnum";
import { CustomerFiltersEnum } from "../types/CustomerFiltersEnum";

interface CustomersPageQueryParams
  extends Partial<
    Record<CustomerFiltersEnum, string | number>
  > {
  isSendTemplateModalOpen?: boolean;
  selectedEngagementActionTypes?: EngagementActionsEnum;
}

export function getCustomersPageQueryParams(
  queryParams: CustomersPageQueryParams
): string {
  return UrlUtils.convertObjectToQueryString(queryParams);
}

export const appPaths = {
  home: () => "/home",
  conversations: () => "/conversas",
  automations: {
    autoSorting: () => "/automacoes/triagem-inicial",
    autoReplies: () => "/automacoes/respostas-automaticas",
    messageFlows: {
      index: () => "/automacoes/fluxos-de-mensagem",
      editMessageFlow: (flowId: string) =>
        `/automacoes/fluxos-de-mensagem/${flowId}/editar`,
    },
    backgroundAutomations: {
      index: () => "/automacoes/envios-automatizados",
      // editAutomation: (automationId: string) => `/automacoes/envios-automatizados/${automationId}/editar`,
      createAutomation: () => `/automacoes/envios-automatizados/novo`,
      editAutomation: (automationId: string) => `/automacoes/envios-automatizados/${automationId}/editar`,
      details: (automationId: string) =>
        `/automacoes/envios-automatizados/${automationId}`,
    },
  },
  settings: {
    general: () => "/configuracoes/geral",
    customColumns: () => "/configuracoes/colunas-customizadas",
    accountSettings: () => "/configuracoes/conta",
    integrationSettings: () => "/configuracoes/integracoes",
    tags: () => "/configuracoes/tags",
    quickReplies: () => "/configuracoes/respostas-rapidas",
  },
  customers: {
    index: () => `/clientes`,
  },
  messageTemplates: {
    index: () => "/templates-de-mensagem",
    createMessageTemplate: () => "/templates-de-mensagem/novo",
  },
  campaigns: {
    create: () => "/campanhas/novo",
    whatsapp: {
      index: () => "/campanhas/whatsapp",
      details: (campaignId: string) => `/campanhas/whatsapp/${campaignId}`,
    },
    sms: {
      index: () => "/campanhas/sms",
      details: (campaignId: string) => `/campanhas/sms/${campaignId}`,
    },
  },
  reports: {
    index: () => "/reports/",
  },
};
