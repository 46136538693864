import { useTranslation } from "react-i18next";
import { NodeProps } from "reactflow";
import { EndWhatsappConversationNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";
import { FaDotCircle } from "react-icons/fa";

const EndWhatsappConversationNode = (
  props: NodeProps<EndWhatsappConversationNodeData>
) => {
  const { t } = useTranslation();
  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<FaDotCircle />}
      color="#000000"
      outHandle={false}
    ></NodeLayout>
  );
};

export default EndWhatsappConversationNode;
