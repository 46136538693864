import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../components/Header";
import TableSmsCampaigns from "./components/TableSmsCampaigns";
import { MdOutlineSms } from "react-icons/md";

const SmsCampaignsPage = () => {
  return (
    <Box padding="20px">
      <Header title="Campanhas de Sms" icon={<MdOutlineSms />} />
      <TableSmsCampaigns />
    </Box>
  );
};

export default SmsCampaignsPage;
