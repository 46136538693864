import { Box, useRadio } from "@chakra-ui/react";
import { ReactNode } from "react";

interface RadioOptionWrapperProps {
  radioProps: any;
  children: ReactNode;
}

const RadioOptionWrapper = ({
  radioProps,
  children,
}: RadioOptionWrapperProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} style={{width: 0}} />
      <Box
        {...checkbox}
        borderRadius="5px"
        borderWidth="1px"
        borderColor="gray.200"
        cursor="pointer"
        _checked={{
          bg: "gray.600",
          color: "white",
          fontWeight: "bold",
        }}>
        {children}
      </Box>
    </Box>
  );
}

export default RadioOptionWrapper;
