import { Flex, FormControl, Text } from "@chakra-ui/react";
import { addDays, format, parse } from "date-fns";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, UseFormReturn } from "react-hook-form";
import { TbZoomMoney } from "react-icons/tb";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import FormLabel from "../../../../../components/FormLabel";
import InputNumber from "../../../../../components/InputNumber";
import InputRangeNumber from "../../../../../components/InputRangeNumber";
import { apiRoutes } from "../../../../../constants/api-routes";
import { useCustomerSearchParams } from "../../../../../hooks/useCustomerSearchParams";
import { StatisticsService } from "../../../../../services/statistics.service";
import { RootState } from "../../../../../state/store";
import { CustomerFiltersEnum } from "../../../../../types/CustomerFiltersEnum";
import { MoneyUtils } from "../../../../../utils/money.utils";
import AccordionItemLayout from "../AccordionItemLayout";

interface SectionPurchasesProps {
  useFormReturn: UseFormReturn<any>;
}

const SectionPurchases = ({
  useFormReturn,
}: SectionPurchasesProps) => {
  const { refetchKey } = useSelector((state: RootState) => state.campaignCreation);
  const { startOrdersCreatedAt } = useCustomerSearchParams();
  const { control, watch, setValue } = useFormReturn;
  const watchMinDaysSinceLastPurchase = watch(
    CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE
  );
  const watchMaxDaysSinceLastPurchase = watch(
    CustomerFiltersEnum.MAX_DAYS_SINCE_LAST_PURCHASE
  );

  const { data: orderStatistics, refetch: refetchOrderStatistics } = useQuery(
    apiRoutes.getOrderAggByCustomerStatistics(startOrdersCreatedAt),
    async () => {
      const { data } = await StatisticsService.getOrderAggByCustomerStatistics(
        startOrdersCreatedAt,
        format(addDays(new Date(), 1), "yyyy-MM-dd")
      );

      return data;
    }
  );

  useEffect(() => {
    refetchOrderStatistics();
  }, [refetchKey, refetchOrderStatistics]);

  return (
    <AccordionItemLayout title="Compras" icon={<TbZoomMoney size="18px" />}>
      <FormControl>
        <FormLabel size="sm" tooltip="Pedidos criados no período">
          Período de vendas
        </FormLabel>
        <FormLabel size="xs" marginBottom="0xp">
          De
        </FormLabel>
        <Controller
          name="startOrdersCreatedAt"
          control={control}
          render={({ field }) => (
            <ReactDatePicker
              isClearable
              selected={
                field.value
                  ? parse(field.value, "yyyy-MM-dd", new Date())
                  : null
              }
              onSelect={(date: Date) => {
                if (!date) {
                  return field.onChange(null);
                }
                const formattedDate = format(date, "yyyy-MM-dd");
                field.onChange(formattedDate); // Save the formatted date as a string
              }}
              onChange={(date: Date) => {
                if (!date) {
                  return field.onChange(null);
                }
                const formattedDate = format(date, "yyyy-MM-dd");
                field.onChange(formattedDate); // Save the formatted date as a string
              }}
              dateFormat="dd/MM/yyyy"
            />
          )}
        />
        <FormLabel size="xs" marginBottom="0xp">
          Até
        </FormLabel>
        {!!watchMinDaysSinceLastPurchase || !!watchMaxDaysSinceLastPurchase ? (
          <Text fontSize={10}>
            Desmarque a opção "Exibir/Ocultar clientes que compraram nos
            últimos" para poder alterar
          </Text>
        ) : (
          <Controller
            name="endOrdersCreatedAt"
            control={control}
            render={({ field }) => (
              <ReactDatePicker
                isClearable
                selected={
                  field.value
                    ? parse(field.value, "yyyy-MM-dd", new Date())
                    : null
                }
                onSelect={(date: Date) => {
                  if (!date) {
                    return field.onChange(null);
                  }
                  const formattedDate = format(date, "yyyy-MM-dd");
                  field.onChange(formattedDate); // Save the formatted date as a string
                }}
                onChange={(date: Date) => {
                  if (!date) {
                    return field.onChange(null);
                  }
                  const formattedDate = format(date, "yyyy-MM-dd");
                  field.onChange(formattedDate); // Save the formatted date as a string
                }}
                dateFormat="dd/MM/yyyy"
              />
            )}
          />
        )}
      </FormControl>

      <FormControl>
        <FormLabel size="sm" tooltip="Total de pedidos do cliente no período">
          Total de Pedidos no Período
        </FormLabel>
        <Controller
          name="totalOrders"
          control={control}
          defaultValue={{ minValue: 0, maxValue: 0 }}
          render={({ field }) => (
            <InputRangeNumber
              size={"sm"}
              rightAddon="UN"
              onChangeMinValue={(value) =>
                field.onChange({ ...field.value, minValue: value })
              }
              onChangeMaxValue={(value) =>
                field.onChange({ ...field.value, maxValue: value })
              }
              minValue={field.value.minValue}
              maxValue={field.value.maxValue}
            />
          )}
        />
        <Flex justifyContent={"flex-end"}>
          <Text fontSize="xs">
            média no período:{" "}
            {(orderStatistics?.averageTotalOrders || 0).toFixed(0)}
          </Text>
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel size="sm" tooltip="Total em compras do cliente no período">
          Total em Compras no Período
        </FormLabel>
        <Controller
          name="totalPurchases"
          control={control}
          defaultValue={{ minValue: 0, maxValue: 0 }}
          render={({ field }) => (
            <InputRangeNumber
              leftAddon="R$"
              size={"sm"}
              onChangeMinValue={(value) =>
                field.onChange({ ...field.value, minValue: value })
              }
              onChangeMaxValue={(value) =>
                field.onChange({ ...field.value, maxValue: value })
              }
              minValue={field.value.minValue}
              maxValue={field.value.maxValue}
            />
          )}
        />
        <Flex justifyContent={"flex-end"}>
          <Text fontSize="xs">
            média no período:{" "}
            {MoneyUtils.formatCurrency(
              orderStatistics?.averageTotalPurchases || 0
            )}
          </Text>
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel size="sm" tooltip="Valor médio dos pedidos no período">
          Ticket Médio no Período
        </FormLabel>
        <Controller
          name="averageOrderValue"
          control={control}
          defaultValue={{ minValue: 0, maxValue: 0 }}
          render={({ field }) => (
            <InputRangeNumber
              leftAddon="R$"
              size={"sm"}
              onChangeMinValue={(value) =>
                field.onChange({ ...field.value, minValue: value })
              }
              onChangeMaxValue={(value) =>
                field.onChange({ ...field.value, maxValue: value })
              }
              minValue={field.value.minValue}
              maxValue={field.value.maxValue}
            />
          )}
        />
        <Flex justifyContent={"flex-end"}>
          <Text fontSize="xs">
            média no período:{" "}
            {MoneyUtils.formatCurrency(orderStatistics?.averageOrderValue || 0)}
          </Text>
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel size="sm" tooltip="Valor dos itens comprados no período">
          Preço Médio dos Items
        </FormLabel>
        <Controller
          name="averageItemValue"
          control={control}
          defaultValue={{ minValue: 0, maxValue: 0 }}
          render={({ field }) => (
            <InputRangeNumber
              size={"sm"}
              leftAddon="R$"
              onChangeMinValue={(value) =>
                field.onChange({ ...field.value, minValue: value })
              }
              onChangeMaxValue={(value) =>
                field.onChange({ ...field.value, maxValue: value })
              }
              minValue={field.value.minValue}
              maxValue={field.value.maxValue}
            />
          )}
        />
        <Flex justifyContent={"flex-end"}>
          <Text fontSize="xs">
            média no período:{" "}
            {MoneyUtils.formatCurrency(orderStatistics?.averageItemValue || 0)}
          </Text>
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Exibir clientes que compraram nos últimos X dias">
          Comprou nos últimos
        </FormLabel>
        <Controller
          name="maxDaysSinceLastPurchase"
          control={control}
          render={({ field }) => {
            return (
              <InputNumber
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  setValue("endOrdersCreatedAt", "");
                }}
                size="sm"
                rightAddon="dias"
              />
            );
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          size="sm"
          tooltip="Ocultar clientes que compraram nos últimos X dias">
          Não comprou nos últimos
        </FormLabel>
        <Controller
          name="minDaysSinceLastPurchase"
          control={control}
          render={({ field }) => {
            return (
              <InputNumber
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  setValue("endOrdersCreatedAt", "");
                }}
                size="sm"
                rightAddon="dias"
              />
            );
          }}
        />
      </FormControl>
    </AccordionItemLayout>
  );
};

export default SectionPurchases;
