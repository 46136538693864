import { keyframes } from "@emotion/react";

export const pulsing = keyframes`
0% {
  transform: scale(0.6);
}
70% {
  transform: scale(1);
}
100% {
  transform: scale(0.6);
}
`;
