import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaTag } from "react-icons/fa";
import { useQuery } from "react-query";
import { NodeProps } from "reactflow";
import { apiRoutes } from "../../../../../../constants/api-routes";
import { AddTagToCustomerNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";
import { TagsService } from "../../../../../../services/tags.service";

const AddTagToCustomerNode = (props: NodeProps<AddTagToCustomerNodeData>) => {
  const { t } = useTranslation();

  const { data: tags } = useQuery(
    apiRoutes.listTags(),
    async () => {
      const { data } = await TagsService.listTags();
      return data;
    },
    {
      staleTime: 1000 * 15,
    }
  );
  const targetTag = tags?.find((tag) => tag.id === props.data.tagId);

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<FaTag />}
      color="#ffaa00"
    >
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content"
      >
        {targetTag?.name || ""}
      </Box>
    </NodeLayout>
  );
};

export default AddTagToCustomerNode;
