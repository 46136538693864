import {
  Box,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useQuery } from "react-query";
import { colors } from "../../../constants/colors";
import { ReportsService } from "../../../services/reports.service";
import InfoCard from "./components/InfoCard";
import CardOpenedVersusFinalizedTickets from "./components/CardOpenedVersusFinalizedTickets";
import CardTicketsPerCategoryChart from "./components/CardTicketsPerCategoryChart";
import CardConversationsInfo from "./components/CardConversationsInfo";
import { generateBlankReportOverviewData } from "../../../types/ReportOverviewData";
import CardAverageTimeToFirstReply from "./components/CardAverageTimeToFirstReply";
import { apiRoutes } from "../../../constants/api-routes";

const ReportsOverviewPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  function setFirstDate() {
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);

    setStartDate(formatDate(lastWeek));
    setEndDate(formatDate(today));
  }

  const handleInicialDateChange = (e: ChangeEvent<HTMLInputElement>) =>
    setStartDate(e.target.value);
  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEndDate(e.target.value);

  const { data: overviewInfo, refetch: overviewInfoRefetch } = useQuery(
    apiRoutes.getReportOverview(startDate, endDate),
    async () => {
      if ([startDate, endDate].includes("")) {
        setFirstDate();
        return;
      }
      const { data } = await ReportsService.getOverview(startDate, endDate);
      return data;
    }
  );

  function buildDateFilterFields() {
    return (
      <Box>
        <HStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel>Data Inicial</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={handleInicialDateChange}
              placeholder="Selecione a data inicial"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Data Final</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              placeholder="Selecione a data final"
            />
          </FormControl>
        </HStack>
      </Box>
    );
  }

  function formatTicketTime(value: number): string {
    if (value < 60) {
      return `${value}seg`;
    } else if (value < 3600) {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      return seconds === 0 ? `${minutes}min` : `${minutes}min${seconds}seg`;
    } else {
      const hours = Math.floor(value / 3600);
      const remainingMinutes = Math.floor((value % 3600) / 60);
      const remainingSeconds = value % 60;

      let formattedTime = `${hours}h`;
      if (remainingMinutes > 0) {
        formattedTime += `${remainingMinutes}min`;
      }
      if (remainingSeconds > 0) {
        formattedTime += `${remainingSeconds}seg`;
      }
      return formattedTime;
    }
  }

  function getTopInfoSection() {
    return (
      <Flex wrap="wrap" direction="row" gap={5} justifyContent="space-between">
        {
          <InfoCard
            title="Novos Primeiros Atendimentos"
            value={overviewInfo?.newFirstCustomerTicketsCount || 0}
          />
        }
        {
          <InfoCard
            title="Atendimentos Em Aberto"
            value={overviewInfo?.openTickets || 0}
          />
        }
        {
          <InfoCard
            title="Atendimentos Finalizados"
            value={overviewInfo?.closedTickets || 0}
          />
        }
        {
          <InfoCard
            title="Tempo Médio Para Primeira Resposta Do Atendente"
            value={formatTicketTime(overviewInfo?.averageTimeToFirstAgent || 0)}
          />
        }
        {
          <InfoCard
            title="Tempo Médio Por Atendimento"
            value={formatTicketTime(overviewInfo?.averageTicketFinishTime || 0)}
          />
        }
      </Flex>
    );
  }

  function getFilterArea() {
    return (
      <Card variant="outline" style={{ width: "auto" }}>
        <CardBody>
          <Flex direction={"row"} gap={4} justifyContent={"start"}>
            {buildDateFilterFields()}
            <Flex direction="column" justifyContent={"end"}>
              <IconButton
                size="sm"
                aria-label="Pesquisar"
                style={{
                  backgroundColor: colors.blueLight,
                  width: 40,
                  height: 40,
                }}
                onClick={() => overviewInfoRefetch()}
                icon={<FaSearch fontSize={20} />}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  }

  function getCardTicketsPerCategoryChart() {
    const info = overviewInfo?.ticketsByCategory.reduce(
      (acc, ticket) => {
        acc.categories.push(ticket.categoryName);
        acc.values.push(ticket.ticketCount);
        return acc;
      },
      { categories: [] as string[], values: [] as number[] }
    ) || { categories: [] as string[], values: [] as number[] };

    return (
      <CardTicketsPerCategoryChart
        categories={info.categories}
        values={info.values}
      />
    );
  }

  function stringToDate(value: number | Date | string) {
    return new Date(value);
  }

  function getCardOpenedVersusFinalizedTickets() {
    const info = overviewInfo?.openVsClosedTicketsByDate.reduce(
      (acc, ticket) => {
        acc.dates.push(stringToDate(ticket.date).toLocaleDateString());
        acc.open.push(ticket.openTickets);
        acc.closed.push(ticket.closedTickets);
        return acc;
      },
      { dates: [] as string[], open: [] as number[], closed: [] as number[] }
    ) || {
      dates: [] as string[],
      open: [] as number[],
      closed: [] as number[],
    };
    return (
      <CardOpenedVersusFinalizedTickets
        dates={info.dates}
        open={info.open}
        closed={info.closed}
      />
    );
  }

  function getCardAverageTimeToFirstReply() {
    const overviewData = overviewInfo ?? generateBlankReportOverviewData();
    return <CardAverageTimeToFirstReply overviewInfo={overviewData} />;
  }

  function getCardConversationsInfo() {
    let data = { perCompany: 0, perCustomer: 0 };
    const info = overviewInfo?.ticketsStartedByClientVsCompanyByDate.reduce(
      (acc, ticket) => {
        acc.dates.push(stringToDate(ticket.date).toLocaleDateString());
        acc.customer.push(ticket.clientStarted);
        acc.company.push(ticket.companyStarted);
        data.perCompany += ticket.companyStarted;
        data.perCustomer += ticket.clientStarted;
        return acc;
      },
      {
        dates: [] as string[],
        customer: [] as number[],
        company: [] as number[],
      }
    ) || {
      dates: [] as string[],
      customer: [] as number[],
      company: [] as number[],
    };
    return (
      <CardConversationsInfo
        dates={info.dates}
        customer={info.customer}
        company={info.company}
        totalPerCompany={data.perCompany}
        totalPerCustomer={data.perCustomer}
      />
    );
  }

  return (
    <Box padding="20px">
      <Flex flexDirection={"column"} gap={5}>
        <Heading size="md">Analise de Atendimentos</Heading>
        {getFilterArea()}
        {getTopInfoSection()}
        {getCardOpenedVersusFinalizedTickets()}
        {getCardTicketsPerCategoryChart()}
        {getCardConversationsInfo()}
        {getCardAverageTimeToFirstReply()}
      </Flex>
    </Box>
  );
};

export default ReportsOverviewPage;
