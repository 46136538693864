import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FocusLock,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsPlus } from "react-icons/bs";
import { FaEdit, FaMagic, FaTrash } from "react-icons/fa";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { ImBold } from "react-icons/im";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import ButtonIcon from "../../../components/ButtonIcon";
import CustomEmojiPicker from "../../../components/CustomEmojiPicker";
import { WhatsappMessageItemMessageCard } from "../../../components/WhatsappMessageItem";
import WhatsappTemplatePreview from "../../../components/WhatsappTemplatePreview";
import { apiRoutes } from "../../../constants/api-routes";
import { appPaths } from "../../../constants/app-paths";
import { colors } from "../../../constants/colors";
import useFileValidation from "../../../hooks/useFileValidation";
import { MessageTemplateSuggestionsService } from "../../../services/message-template-suggestions.service";
import {
  CreateMessageTemplateDto,
  MessageTemplatesService,
} from "../../../services/message-templates.service";
import { MixpanelService } from "../../../services/mixpanel.service";
import { ButtonType } from "../../../types/ButtonTypeEnum";
import { CommunicationChannelEnum } from "../../../types/CommunicationChannelEnum";
import { GupshupTemplateType } from "../../../types/GupshupTemplateType";
import {
  MessageTemplateTypeEnum,
  MessageTemplateTypeTextEnum,
} from "../../../types/MessageTemplateType";
import { TemplateBodyTypeEnum } from "../../../types/TemplateBodyTypeEnum";
import { TemplateParametersList } from "../../../types/TemplateParametersEnum";
import { TemplateParametersUtils } from "../../../utils/template-parameters-by-type.utils";
import CarouselFormSection from "./components/CarouselFormSection";
import UrlButtonField from "./components/UrlButtonField";
import GenerateMessageTemplateModal from "./components/GenerateMessageTemplateModal";
import RegenerateMessageTemplateModal from "./components/RegenerateMessageTemplateModal";
import { FileUtils } from "../../../utils/file-utils";
import FileInputItem from "../../../components/FileInputItem/FileInputItem";

const offerCodeSize = 15;
const limitedOfferTextSize = 16;
const buttonTextSize = 25;

const emojisMatchRegExp = /^(?:[0-9]|[^\p{Emoji}])*$/u;
const msgThisFieldCannotHaveEmojis =
  "Devido a uma limitação da Meta API, emojis não são permitidos neste campo";

const buttonSchemaYupDefault = yup.object({
  type: yup.string().required().oneOf(["QUICK_REPLY", "URL", "COPY_CODE"]),
  text: yup.string().when("type", {
    is: (val: string) => val !== "COPY_CODE",
    then: yup
      .string()
      .required("Texto é obrigatório")
      .max(buttonTextSize, `Limite máx. de ${buttonTextSize} caracteres`)
      .matches(emojisMatchRegExp, msgThisFieldCannotHaveEmojis),
    otherwise: yup.string().nullable()
  }),
  url: yup
    .string()
    .url("URL inválida. (Ex: https://www.google.com)")
    .when("type", {
      is: "URL",
      then: yup
        .string()
        .url("URL inválida. (Ex: https://www.google.com)")
        .required("URL é obrigatória"),
      otherwise: yup.string().nullable()
    }),
  offerCode: yup.string().when("type", {
    is: "COPY_CODE",
    then: yup
      .string()
      .required("Código da promoção é obrigatório")
      .max(offerCodeSize, `Limite máx. de ${offerCodeSize} caracteres`)
      .matches(emojisMatchRegExp, msgThisFieldCannotHaveEmojis),
    otherwise: yup.string().nullable()
  }),
});

const buttonSchema = buttonSchemaYupDefault.when("templateBodyType", {
  is: (v: TemplateBodyTypeEnum) => v !== TemplateBodyTypeEnum.CAROUSEL,
  then: buttonSchemaYupDefault,
  otherwise: yup.object({ type: yup.string() }),
});

export const CARD_BODY_MAX_LENGTH = 140;

const cardSchema = yup.object({
  body: yup
    .string()
    .required("Corpo da mensagem é obrigatório")
    .max(
      CARD_BODY_MAX_LENGTH,
      `Limite máx. de ${CARD_BODY_MAX_LENGTH} caracteres`
    )
    .test(
      "max-new-lines",
      "O texto não pode conter mais de uma quebra de linha.",
      (value) => {
        if (!value) return true;
        return !value.match(/\n\s*\n/g);
      }
    ),
  buttons: yup.array().of(buttonSchemaYupDefault).max(2),
});

const schema = yup.object().shape({
  communicationChannel: yup
    .string()
    .oneOf([CommunicationChannelEnum.SMS, CommunicationChannelEnum.WHATSAPP])
    .required("Canal é obrigatório"),
  isLimitedOffer: yup.boolean().default(false),
  limitedOfferHasExpiration: yup.boolean().default(false),
  limitedOfferExpirationDate: yup.string().when("limitedOfferHasExpiration", {
    is: true,
    then: yup.string().required("Data de expiração é obrigatória").default(""),
  }),
  limitedOfferExpirationTime: yup.string().when("limitedOfferHasExpiration", {
    is: true,
    then: yup.string().required("Hora de expiração é obrigatória").default(""),
  }),
  templateBodyType: yup
    .string()
    .oneOf(Object.values(TemplateBodyTypeEnum))
    .default(TemplateBodyTypeEnum.NORMAL),
  elementName: yup
    .string()
    .matches(
      /^[a-z0-9\s]+$/,
      "Nome do template deve conter apenas letras minúsculas, números e espaços em branco"
    )
    .required("Nome do template é obrigatório"),
  type: yup.string().oneOf(Object.values(MessageTemplateTypeEnum)),
  templateType: yup.string().oneOf(Object.values(GupshupTemplateType)), // Substitua 'TEMPLATE_TYPE_1' e 'TEMPLATE_TYPE_2' pelos valores reais do enum GupshupTemplateType
  content: yup.string().when("templateBodyType", {
    is: CommunicationChannelEnum.SMS,
    then: yup
      .string()
      .required("O corpo da mensagem não pode ser deixado em branco")
      .matches(
        /^[a-zA-Z0-9 !@#$%^&*()[\],;:'"<>/\\?{}|_+-=]*$/,
        "Templates de SMS não devem conter acentos nem emojis"
      )
      .max(96, "Limite máx. de 96 caracteres"),
    otherwise: yup
      .string()
      .required("O corpo da mensagem não pode ser deixado em branco")
      .max(1028, "Limite máx. de 1028 caracteres"),
  }),
  footer: yup
    .string()
    .max(60, "Limite máx. de 60 caracteres")
    .matches(emojisMatchRegExp, msgThisFieldCannotHaveEmojis),
  buttons: yup.array().of(buttonSchema).max(10),
  cards: yup.array().of(cardSchema).max(10),
  limitedOfferText: yup
    .string()
    .max(
      limitedOfferTextSize,
      `Limite máx. de ${limitedOfferTextSize} caracteres`
    ),
  // exampleMedia: yup.string().optional(),
  messageTemplateSuggestionId: yup.string().optional(),
});

const CreateMessageTemplatePage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      templateType: GupshupTemplateType.TEXT,
      //   elementName: undefined,
      //   content: undefined,
      //   buttons: undefined,
      //   exampleMedia: undefined,
    } as any,
  });
  const {
    fields: buttonTextFields,
    append: appendButtonText,
    remove: removeButtonText,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "buttons", // unique name for your Field Array
  });
  const { fields: cardsField, append: appendCard } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "cards", // unique name for your Field Array
  });
  const {
    isOpen: isOpenNewParameter,
    onOpen: onOpenNewParameter,
    onClose: onCloseNewParameter,
  } = useDisclosure();
  const {
    isOpen: isOpenGenerateMessageTemplateModal,
    onOpen: onOpenGenerateMessageTemplateModal,
    onClose: onCloseGenerateMessageTemplateModal,
  } = useDisclosure();
  const {
    isOpen: isOpenRegenerateMessageTemplateModal,
    onOpen: onOpenRegenerateMessageTemplateModal,
    onClose: onCloseRegenerateMessageTemplateModal,
  } = useDisclosure();
  const { data: templateSuggestions } = useQuery(
    apiRoutes.listMessageTemplateSuggestions(),
    async () => {
      const { data } =
        await MessageTemplateSuggestionsService.listMessageTemplateSuggestions();
      return data;
    }
  );
  const watchTemplateType = watch("templateType");
  const watchTemplateBodyType = watch("templateBodyType");
  const watchChannel = watch("communicationChannel");
  const watchContent = watch("content");
  const watchCards = watch("cards");
  const watchIsLimitedOffer = watch("isLimitedOffer");
  const watchLimitedOfferText = watch("limitedOfferText");
  const watchLimitedOfferExpires = watch("limitedOfferHasExpiration");
  const watchLimitedOfferExpirationDate = watch("limitedOfferExpirationDate");
  const watchButtons = watch("buttons");
  const watchFooter = watch("footer");
  const [buttonType, setButtonType] = useState<ButtonType | null>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { validateFile, getAcceptedMessageTemplateFileTypes } =
    useFileValidation();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useBoolean(false);
  const contentRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref: contentRegisterRef, ...contentRegister } = register("content");
  const newParameterInputRef = useRef<any>(null);
  const [promptThreadId, setPromptThreadId] = useState<string | null>(null);
  const [selectedSuggestionId, setSelectedSuggestionId] = useState<string>("");
  const availableTemplateTypes = Object.entries(
    MessageTemplateTypeTextEnum
  ).map(([id, name]) => ({
    id,
    name,
  }));

  const selectedType = watch("type");
  const templateParametersList = useMemo(() => {
    if (TemplateParametersUtils.templateParameters[selectedType]) {
      return TemplateParametersUtils.templateParameters[selectedType];
    } else {
      return TemplateParametersList;
    }
  }, [selectedType]);
  const queryClient = useQueryClient();
  const createMessageTemplate = useMutation(
    (createMessageTemplateDto: CreateMessageTemplateDto) =>
      MessageTemplatesService.createMessageTemplate(createMessageTemplateDto),
    {
      onSuccess: (res) => {
        MixpanelService.track("create-message-template", {
          messageTemplateSuggestionId: res.data.messageTemplateSuggestionId,
        });
        toast({
          title: "Template criado com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        queryClient.invalidateQueries(
          apiRoutes.listMessageTemplateSuggestions()
        );
        navigate(appPaths.messageTemplates.index());
      },
    }
  );

  const [searchParams] = useSearchParams();
  const templateToCloneId = searchParams.get("baseTemplateId");

  const selectManyFilesFromUrls = async (urls: string[]) => {
    const files = (await Promise.all(urls.map(async (url) => {
      try {
        return await FileUtils.fetchFile(url)
      } catch (error) {
        console.error(`Erro ao carregar url ${url}`, error);
        return null;
      }
    }))).filter((file) => !!file) as File[];

    setSelectedFiles([...selectedFiles, ...files]);
  }

  useQuery(
    templateToCloneId!,
    async () => {
      if (!templateToCloneId) return null;
      const { data } = await MessageTemplatesService.getMessageTemplateCreateDto(templateToCloneId);
      return data;
    },
    {
      enabled: !!templateToCloneId,
      onSuccess: async (data) => {
        if (!data) return;
          let templateBodyType: TemplateBodyTypeEnum;
          if (data.templateType === GupshupTemplateType.CAROUSEL) 
            templateBodyType = TemplateBodyTypeEnum.CAROUSEL;
          else if (!!data.limitedOfferExpirationDate) 
            templateBodyType = TemplateBodyTypeEnum.LIMITED_OFFER;
          else 
            templateBodyType = TemplateBodyTypeEnum.NORMAL;

          setValue("elementName", `${data.elementName} copia`);
          setValue("content", data.content);
          setValue("footer", data.footer);
          setValue("buttons", data.buttons);
          setValue("cards", data.cards);
          setValue("type", data.type);
          setValue("templateType", data.templateType);
          setValue("templateBodyType", templateBodyType);
          setValue('messageTemplateSuggestionId', data.messageTemplateSuggestionId)
          setValue('communicationChannel', data.communicationChannel)
          
          if (templateBodyType === TemplateBodyTypeEnum.LIMITED_OFFER) {
            setValue("isLimitedOffer", true);
            setValue("limitedOfferText", data.limitedOfferText);
            setValue("limitedOfferHasExpiration", !!data.limitedOfferExpirationDate);
            setValue("limitedOfferExpirationDate", data.limitedOfferExpirationDate);
            setValue("limitedOfferExpirationTime", data.limitedOfferExpirationTime);
          }

          if(data.buttons?.[0]) setButtonType(data.buttons[0].type);
          
          const mediaUrls: string[] = []
          if (data?.mediaUrl) mediaUrls.push(data.mediaUrl);
          if (data?.cards?.length)
            data.cards?.map((card) => {
              if (card?.mediaUrl) mediaUrls.push(card.mediaUrl);
            })
          if (mediaUrls.length) await selectManyFilesFromUrls(mediaUrls);
      },
    }
  );

  useEffect(() => {
    setSelectedFiles([]);
  }, [watchTemplateType, templateToCloneId]);

  useEffect(() => {
    setValue("isLimitedOffer", false);
    setValue("limitedOfferHasExpiration", false);
    setValue("templateBodyType", TemplateBodyTypeEnum.NORMAL);
  }, [setValue]);

  function handleClickParameter(parameter: any) {
    const textarea = contentRef.current! as any;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      parameter +
      currentValue.substring(endPosition);

    setValue("content", newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + parameter.length + 1,
      startPosition + parameter.length + 1
    );
  }

  async function handleClickBold() {
    const textarea = contentRef.current! as any;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      "*" +
      currentValue.substring(startPosition, endPosition) +
      "*" +
      currentValue.substring(endPosition);

    setValue("content", newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + 1,
      startPosition + 1 + (endPosition - startPosition)
    );
  }

  function isExpirationTimeValid(expirationDateTime: string): boolean {
    const dateTime = new Date(expirationDateTime);
    return !isNaN(dateTime.getTime());
  }

  async function onSubmit(data: Omit<CreateMessageTemplateDto, "files">) {
    if (watchTemplateBodyType === TemplateBodyTypeEnum.CAROUSEL && data.cards) {
      data.templateType = GupshupTemplateType.CAROUSEL;
      if (selectedFiles.length !== data.cards.length) {
        toast({
          title: "É necessário uma imagem/vídeo para cada card",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setFileError.on();
        return;
      }
      const seenTexts = new Set<string>();

      for (let i = 0; i < data.cards.length; i++) {
        const card = data.cards[i];
        if (!card.buttons || card.buttons.length === 0) {
          toast({
            title: "É necessário ao menos um botão para cada card",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        let lastCardType = "";
        for (let index = 0; index < card.buttons.length; index++) {
          if (seenTexts.has(card.buttons[index].text)) {
            toast({
              title:
                "Não é possível repetir o texto de um botão, todos deverão ser únicos",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
          seenTexts.add(card.buttons[index].text);

          if (index === 0) {
            lastCardType = card.buttons[index].type;
            continue;
          }
          if (lastCardType !== card.buttons[index].type) {
            toast({
              title: "Todos os botões do card devem ser do mesmo tipo",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
          lastCardType = card.buttons[index].type;
        }
        if (i < data.cards.length - 1) {
          const nextCard = data.cards[i + 1];
          if (
            nextCard.buttons.length !== card.buttons.length ||
            !nextCard.buttons.every((btn) => btn.type === card.buttons[0].type)
          ) {
            toast({
              title:
                "Todos os cards devem ter a mesma quantidade e tipo de botões",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
        }
      }
    } else if (data.templateType !== GupshupTemplateType.TEXT) {
      if (!selectedFiles) {
        setFileError.on();
        return;
      }
    }
    if (
      data.isLimitedOffer &&
      data.limitedOfferHasExpiration &&
      !isExpirationTimeValid(
        data.limitedOfferExpirationDate + "T" + data.limitedOfferExpirationTime
      )
    ) {
      toast({
        title: "Data de expiração inválida",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    await createMessageTemplate.mutateAsync({
      ...data,
      files: selectedFiles || undefined,
    });
  }

  async function handleChangeFile(index: number, event: any) {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    const isValidFile = validateFile(file);
    if (!isValidFile) {
      event.target.value = null;
      return;
    }
    const files = [...selectedFiles];
    files[index] = file;
    setSelectedFiles(files);
  }

  async function handleRemoveSelectedFile(index: number) {
    if (selectedFiles.length <= index) return;
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
  }

  function handleAddNewParameter() {
    let newParameter = newParameterInputRef.current?.value;
    newParameter = `[${newParameter.replace(/(\[|\])/g, "")}]`;

    if (!newParameter) {
      return;
    }
    if (
      newParameter.normalize("NFD").replace(/[\u0300-\u036f]/g, "") !==
      newParameter
    ) {
      toast({
        title: "O parâmetro não pode conter acentos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (templateParametersList.includes(newParameter)) {
      toast({
        title: "Esse nome de parâmetro já existe",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    handleClickParameter(newParameter);
    handleCloseNewParameter();
  }

  function handleCloseNewParameter() {
    newParameterInputRef.current!.value = null;
    onCloseNewParameter();
  }

  function handleChangeTemplateSuggestion(templateSuggestionId: string) {
    const selectedTemplateSuggestion = templateSuggestions?.find((template) => {
      return template.id === templateSuggestionId;
    });

    if (selectedTemplateSuggestion) {
      MixpanelService.track("click-template-suggestion", {
        messageTemplateSuggestionName: selectedTemplateSuggestion.name,
      });
      setValue("messageTemplateSuggestionId", templateSuggestionId);
      setValue("content", selectedTemplateSuggestion.templateText);
      setSelectedSuggestionId(templateSuggestionId);
    } else {
      setValue("messageTemplateSuggestionId", "");
      setValue("content", "");
      setSelectedSuggestionId("");
    }
  }

  function handleGenerateTemplate(content: string, promptThreadId: string) {
    setValue("content", content);
    setPromptThreadId(promptThreadId);
  }

  function handleChangeLimitedOfferExpires(value: boolean) {
    if (!value) {
      setValue("limitedOfferExpirationDate", "");
      setValue("limitedOfferExpirationTime", "");
    }
    setValue("limitedOfferHasExpiration", value);
  }

  function handleRegenerateTemplate(content: string) {
    setValue("content", content);
  }

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const content = event.target.value;

    if (!content.trim()) {
      setValue("messageTemplateSuggestionId", "");
      setSelectedSuggestionId("");
    }
  };

  const addCopyCodeButtons = () => {
    appendButtonText({
      type: ButtonType.COPY_CODE,
      text: "",
      offerCode: "",
    });
    appendButtonText({
      type: ButtonType.URL,
      text: "",
      offerCode: "",
    });
  };

  const handleChangeIsLimitedOffer = (value: boolean) => {
    setValue("isLimitedOffer", value);
    removeButtonText();
    if (!value) {
      setButtonType(null);
      setValue("limitedOfferHasExpiration", false);
      setValue("limitedOfferText", "");
      return;
    }
    setButtonType(ButtonType.COPY_CODE);
    addCopyCodeButtons();

    if (value) {
      setTimeout(() => {
        document.getElementById("limitedOfferText")?.focus();
      }, 300);
    }
  };

  const handleChangeTemplateBodyType = (value: TemplateBodyTypeEnum) => {
    setSelectedFiles([]);
    removeButtonText();
    setValue("cards", []);
    setValue("templateBodyType", value);
    if (value !== TemplateBodyTypeEnum.NORMAL) {
      setValue("type", MessageTemplateTypeEnum.MARKETING);
    }
    if (value === TemplateBodyTypeEnum.LIMITED_OFFER) {
      handleChangeIsLimitedOffer(true);
      return;
    }
    handleChangeIsLimitedOffer(false);
  };

  const handleFileRemove = (index: number) => {
    setSelectedFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }

  const getTemplateMessageBody = () => {
    return (
      <Card>
        <CardBody>
          <FormControl>
            <Flex alignItems={"center"} justifyContent="space-between">
              <FormLabel>Corpo da Mensagem</FormLabel>
            </Flex>
            <Box>
              <Textarea
                isDisabled={!!promptThreadId}
                placeholder="Escreva o texto da mensagem"
                value={watchContent}
                {...contentRegister}
                ref={(e) => {
                  contentRegisterRef(e);
                  contentRef.current = e;
                }}
                onChange={(e) => {
                  contentRegister.onChange(e);
                  handleContentChange(e);
                }}
                isInvalid={!!errors.content}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.content?.message}
              </Text>
              {watchChannel === CommunicationChannelEnum.WHATSAPP && (
                <Flex
                  justifyContent={"space-between"}
                  width="100%"
                  mt={1}
                  position={"relative"}
                >
                  {!!promptThreadId ? (
                    <Flex gap={3}>
                      <Button
                        onClick={() => setPromptThreadId(null)}
                        leftIcon={<FaEdit />}
                      >
                        Editar
                      </Button>
                      <Button
                        onClick={onOpenRegenerateMessageTemplateModal}
                        leftIcon={<FaArrowRotateLeft />}
                      >
                        Regenerar
                      </Button>
                    </Flex>
                  ) : (
                    <Button
                      onClick={onOpenGenerateMessageTemplateModal}
                      leftIcon={<FaMagic />}
                    >
                      Gerador da Revi
                    </Button>
                  )}
                  <Box>
                    <ButtonIcon icon={<ImBold />} onClick={handleClickBold} />
                    <CustomEmojiPicker
                      onEmojiSelection={handleClickParameter}
                    />
                  </Box>
                </Flex>
              )}
            </Box>
          </FormControl>
          <Box mt={3}>
            <Text>Adicionar parâmetro</Text>
            <Flex gap={2} alignItems="center" flexWrap={"wrap"} maxW="100%">
              {templateParametersList.map((parameter) => (
                <Button
                  key={parameter}
                  fontSize="xs"
                  color="gray.500"
                  mt={2}
                  mb={2}
                  variant="outline"
                  onClick={() => handleClickParameter(parameter)}
                >
                  {parameter}
                </Button>
              ))}
              <Popover
                isOpen={isOpenNewParameter}
                onOpen={onOpenNewParameter}
                onClose={handleCloseNewParameter}
                initialFocusRef={newParameterInputRef}
                placement="right"
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <IconButton aria-label="Novo parâmetro" icon={<BsPlus />} />
                </PopoverTrigger>
                <PopoverContent p={5}>
                  <FocusLock persistentFocus={false}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <Stack spacing={4}>
                      <FormControl>
                        <FormLabel>Nome do parâmetro</FormLabel>
                        <Input
                          ref={newParameterInputRef}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleAddNewParameter();
                            }
                          }}
                          placeholder="Ex: codigo do cupom, vencimento"
                        />
                      </FormControl>
                      <ButtonGroup display="flex" justifyContent="flex-end">
                        <Button
                          variant="outline"
                          onClick={handleCloseNewParameter}
                        >
                          Cancelar
                        </Button>
                        <Button
                          bgColor={colors.lightGrey}
                          onClick={handleAddNewParameter}
                        >
                          Adicionar
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </FocusLock>
                </PopoverContent>
              </Popover>
            </Flex>
          </Box>
        </CardBody>
      </Card>
    );
  };

  return (
    <Grid
      height="auto"
      templateColumns="2fr 1fr"
      paddingTop="50px"
      paddingBottom="50px"
      paddingX="100px"
      alignItems="start"
      gap={4}
    >
      <GridItem>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>Canal de envio</FormLabel>
                  <Controller
                    name="communicationChannel"
                    control={control}
                    defaultValue={CommunicationChannelEnum.WHATSAPP}
                    render={({ field }) => (
                      <Select onChange={field.onChange} value={field.value}>
                        <option value={CommunicationChannelEnum.WHATSAPP}>
                          WhatsApp
                        </option>
                        <option value={CommunicationChannelEnum.SMS}>
                          SMS
                        </option>
                      </Select>
                    )}
                  />
                  <Text color={colors.danger} fontSize="xs">
                    {errors.communicationChannel?.message}
                  </Text>
                </FormControl>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>Nome do Template</FormLabel>
                  <Input
                    placeholder="Escreva o nome do template"
                    {...register("elementName")}
                    isInvalid={!!errors.elementName}
                  />
                  <Text color={colors.danger} fontSize="xs">
                    {errors.elementName?.message}
                  </Text>
                </FormControl>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>Tipo de Estrutura</FormLabel>
                  <Controller
                    name="templateBodyType"
                    control={control}
                    defaultValue={CommunicationChannelEnum.WHATSAPP}
                    render={({ field }) => (
                      <RadioGroup
                        onChange={handleChangeTemplateBodyType}
                        defaultValue={TemplateBodyTypeEnum.NORMAL}
                        value={field.value}
                      >
                        <HStack gap="6">
                          <Radio value={TemplateBodyTypeEnum.NORMAL}>
                            Normal
                          </Radio>
                          <Radio value={TemplateBodyTypeEnum.CAROUSEL}>
                            Carrossel
                          </Radio>
                          <Radio value={TemplateBodyTypeEnum.LIMITED_OFFER}>
                            Oferta Limitada
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    )}
                  />
                  <Text color={colors.danger} fontSize="xs">
                    {errors.templateBodyType?.message}
                  </Text>
                </FormControl>
              </CardBody>
            </Card>
            {watchIsLimitedOffer && (
              <Card>
                <CardBody>
                  <Box mb={4}>
                    <FormControl>
                      <FormLabel>Oferta Limitada</FormLabel>
                      <FormLabel fontSize={14}></FormLabel>
                      <Text color={colors.danger} fontSize="xs">
                        {errors.templateBodyType?.message}
                      </Text>
                    </FormControl>
                  </Box>
                  <Box mb={4}>
                    <FormControl>
                      <FormLabel fontSize={14}>
                        Texto da Oferta Limitada (curto)
                      </FormLabel>
                      <Input
                        key="limitedOfferText"
                        id="limitedOfferText"
                        autoFocus
                        maxLength={16}
                        placeholder="Texto curto para oferta limitada"
                        {...register(`limitedOfferText`)}
                        isInvalid={!!errors.limitedOfferText}
                      />
                      <Text fontSize="xs" color="gray.500" textAlign="right">
                        {watchLimitedOfferText?.length || 0}/
                        {limitedOfferTextSize} caracteres
                      </Text>
                    </FormControl>
                    <FormControl>
                      <Controller
                        name="limitedOfferHasExpiration"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            isChecked={field.value}
                            onChange={(e) =>
                              handleChangeLimitedOfferExpires(e.target.checked)
                            }
                          >
                            Oferta expira?{watchLimitedOfferExpires}
                          </Checkbox>
                        )}
                      />
                      <Text color={colors.danger} fontSize="xs">
                        {errors.templateBodyType?.message}
                      </Text>
                    </FormControl>
                    {watchLimitedOfferExpires && (
                      <HStack spacing={4} mt={4}>
                        <FormControl flex="2">
                          <FormLabel fontSize={14}>Data de Expiração</FormLabel>
                          <Input
                            type="date"
                            {...register("limitedOfferExpirationDate")}
                            isInvalid={!!errors.limitedOfferExpirationDate}
                          />
                          <Text color={colors.danger} fontSize="xs">
                            {errors.limitedOfferExpirationDate?.message}
                          </Text>
                        </FormControl>
                        <FormControl flex="1">
                          <FormLabel fontSize={14}>Hora de Expiração</FormLabel>
                          <Input
                            type="time"
                            {...register("limitedOfferExpirationTime")}
                            isInvalid={!!errors.limitedOfferExpirationTime}
                          />
                          <Text color={colors.danger} fontSize="xs">
                            {errors.limitedOfferExpirationTime?.message}
                          </Text>
                        </FormControl>
                      </HStack>
                    )}
                  </Box>
                </CardBody>
              </Card>
            )}
            {watchChannel === CommunicationChannelEnum.WHATSAPP &&
              watchTemplateBodyType === TemplateBodyTypeEnum.NORMAL && (
                <Card>
                  <CardBody>
                    <FormControl>
                      <FormLabel>Tipo de Template</FormLabel>
                      <Select {...register("type")}>
                        {availableTemplateTypes.map((templateType) => (
                          <option key={templateType.id} value={templateType.id}>
                            {templateType.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </CardBody>
                </Card>
              )}
            {watchTemplateBodyType === TemplateBodyTypeEnum.CAROUSEL ? (
              <>
                {getTemplateMessageBody()}
                <CarouselFormSection
                  fileError={fileError}
                  onFileRemove={handleFileRemove}
                  handleChangeFile={handleChangeFile}
                  handleRemoveSelectedFile={handleRemoveSelectedFile}
                  getAcceptedMessageTemplateFileTypes={
                    getAcceptedMessageTemplateFileTypes
                  }
                  files={selectedFiles}
                  appendCard={appendCard}
                  templateParametersList={templateParametersList}
                  cardsField={cardsField}
                  cards={watchCards}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                />
              </>
            ) : (
              <>
                {templateSuggestions && templateSuggestions.length > 0 && (
                  <Card>
                    <CardBody>
                      <FormControl>
                        <FormLabel>Sugestão de mensagem</FormLabel>
                        <Select
                          placeholder="Selecione uma mensagem"
                          value={selectedSuggestionId}
                          onChange={(e) => {
                            handleChangeTemplateSuggestion(e.target.value);
                          }}
                        >
                          {templateSuggestions.map((template) => (
                            <option
                              key={template.id}
                              value={template.id}
                              title={template.templateText}
                            >
                              {template.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </CardBody>
                  </Card>
                )}

                {watchChannel === CommunicationChannelEnum.WHATSAPP && (
                  <Card>
                    <CardBody>
                      <FormControl>
                        <FormLabel>Cabeçalho</FormLabel>
                        <Select {...register("templateType")}>
                          {[
                            {
                              id: GupshupTemplateType.TEXT,
                              name: "Nenhum",
                            },
                            {
                              id: GupshupTemplateType.DOCUMENT,
                              name: "Arquivo PDF",
                            },
                            {
                              id: GupshupTemplateType.IMAGE,
                              name: "Imagem",
                            },
                            {
                              id: GupshupTemplateType.VIDEO,
                              name: "Vídeo",
                            },
                          ].map((templateType) => (
                            <option
                              key={templateType.id}
                              value={templateType.id}
                            >
                              {templateType.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      {watchTemplateType !== GupshupTemplateType.TEXT && (
                        <FormControl>
                          <Flex flexDirection='column'>
                            <FormLabel>Arquivo</FormLabel>
                            <input
                              type="file"
                              onChange={(event) => handleChangeFile(0, event)}
                              accept={getAcceptedMessageTemplateFileTypes(
                                watchTemplateType
                              )}
                            />
                            {fileError && (
                              <Text color={colors.danger} fontSize="xs">
                                {"Arquivo é obrigatório"}
                              </Text>
                            )}
                            {
                              selectedFiles?.[0] && (
                                <FileInputItem file={selectedFiles[0]} onRemove={() => handleFileRemove(0)} />
                              )
                            }
                          </Flex>
                        </FormControl>
                      )}
                    </CardBody>
                  </Card>
                )}
                {getTemplateMessageBody()}
                {watchChannel === CommunicationChannelEnum.WHATSAPP &&
                  !watchIsLimitedOffer && (
                    <Card>
                      <CardBody>
                        <FormControl>
                          <FormLabel>Rodapé</FormLabel>
                          <Input
                            placeholder="Ex: Para sair envie STOP"
                            {...register("footer")}
                            isInvalid={!!errors.footer}
                          />
                          <Text color={colors.danger} fontSize="xs">
                            {errors.footer?.message}
                          </Text>
                        </FormControl>
                      </CardBody>
                    </Card>
                  )}
                <Card>
                  <CardBody display={"flex"} flexDir="column" gap={4}>
                    {!watchIsLimitedOffer && (
                      <FormControl>
                        <FormLabel>Botões & Links</FormLabel>
                        <Select
                          placeholder="Nenhum"
                          value={buttonType || undefined}
                          onChange={(event) => {
                            const selectedButtonType = event.target
                              .value as ButtonType;
                            setButtonType(selectedButtonType);
                            if (!!selectedButtonType) {
                              removeButtonText();
                              if (selectedButtonType === ButtonType.COPY_CODE) {
                                addCopyCodeButtons();
                                return;
                              }
                              appendButtonText({
                                type: selectedButtonType,
                                text: "",
                              });
                            }
                          }}
                        >
                          {[
                            {
                              id: ButtonType.QUICK_REPLY,
                              name: "Resposta rápida",
                            },
                            {
                              id: ButtonType.URL,
                              name: "Abrir URL",
                            },
                            {
                              id: ButtonType.COPY_CODE,
                              name: "Copiar Código",
                            },
                          ]
                            .filter(
                              (el) =>
                                !(
                                  watchChannel ===
                                    CommunicationChannelEnum.SMS &&
                                  el.id === ButtonType.QUICK_REPLY
                                )
                            )
                            .map((templateType) => (
                              <option
                                key={templateType.id}
                                value={templateType.id}
                              >
                                {templateType.name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                    {buttonType === ButtonType.QUICK_REPLY ? (
                      buttonTextFields.map((field, index) => (
                        <Flex display={"flex"} alignItems={"center"}>
                          <FormControl
                            w="500px"
                            display={"flex"}
                            alignItems={"center"}
                            gap={2}
                          >
                            <FormLabel width={"80px"}>
                              Botão {index + 1}
                            </FormLabel>
                            <Input
                              key={field.id}
                              placeholder="Título do botão"
                              {...register(`buttons.${index}.text`)}
                              isInvalid={!!errors?.buttons?.[index]?.text}
                            />
                            <Text color={colors.danger} fontSize="xs">
                              {errors?.buttons?.[index]?.text?.message}
                            </Text>
                          </FormControl>
                          {buttonTextFields.length > 1 && (
                            <ButtonIcon
                              icon={
                                <FaTrash
                                  fontSize="20px"
                                  color={colors.danger}
                                />
                              }
                              onClick={() => removeButtonText(index)}
                            />
                          )}
                        </Flex>
                      ))
                    ) : buttonType === ButtonType.URL ? (
                      <UrlButtonField
                        buttonTitleFormRegister={register(`buttons.0.text`)}
                        buttonUrlFormRegister={register(`buttons.0.url`)}
                        isTitleInvalid={!!errors?.buttons?.[0]?.text}
                        invalidTitleMessage={
                          errors?.buttons?.[0]?.text?.message
                        }
                        isUrlInvalid={!!errors?.buttons?.[0]?.url}
                        invalidUrlMessage={errors?.buttons?.[0]?.url?.message}
                      />
                    ) : buttonType === ButtonType.COPY_CODE ? (
                      <>
                        <Flex gap={2}>
                          <FormControl>
                            <FormLabel>Código da Oferta</FormLabel>
                            <Input
                              placeholder="Código da Oferta"
                              maxLength={offerCodeSize}
                              {...register(`buttons.0.offerCode`)}
                              isInvalid={!!errors?.buttons?.[0]?.offerCode}
                            />
                            <Text color={colors.danger} fontSize="xs">
                              {errors?.buttons?.[0]?.offerCode?.message}
                            </Text>
                          </FormControl>
                        </Flex>

                        <UrlButtonField
                          buttonTitleFormRegister={register(`buttons.1.text`)}
                          buttonUrlFormRegister={register(`buttons.1.url`)}
                          isTitleInvalid={!!errors?.buttons?.[1]?.text}
                          invalidTitleMessage={
                            errors?.buttons?.[1]?.text?.message
                          }
                          isUrlInvalid={!!errors?.buttons?.[1]?.url}
                          invalidUrlMessage={errors?.buttons?.[1]?.url?.message}
                        />
                      </>
                    ) : null}
                    {buttonType === ButtonType.QUICK_REPLY &&
                      buttonTextFields.length < 10 && (
                        <Button
                          onClick={() => {
                            appendButtonText({
                              type: buttonType,
                              text: "",
                            });
                          }}
                          width={"fit-content"}
                        >
                          + Adicionar botão
                        </Button>
                      )}
                  </CardBody>
                </Card>
              </>
            )}
            <Flex justify={"flex-end"}>
              <Button
                width="30%"
                isLoading={createMessageTemplate.isLoading}
                color={colors.white}
                bgColor={colors.primary}
                type="submit"
              >
                Criar
              </Button>
            </Flex>
          </Stack>
        </form>
      </GridItem>
      <GridItem position={"sticky"} top={0} alignSelf="start">
        <WhatsappTemplatePreview
          message={watchContent}
          footer={watchFooter}
          buttons={watchButtons}
          file={
            watchTemplateBodyType !== TemplateBodyTypeEnum.CAROUSEL &&
            selectedFiles.length > 0
              ? selectedFiles[0]
              : null
          }
          limitedOfferText={watchLimitedOfferText}
          limitedOfferExpirationDate={watchLimitedOfferExpirationDate}
          messageTemplateCards={(watchCards || []).map(
            (card: any, index: number): WhatsappMessageItemMessageCard => ({
              body: card.body,
              buttons: card.buttons,
              mediaUrl: "",
              file: selectedFiles[index],
            })
          )}
        />
      </GridItem>
      <GenerateMessageTemplateModal
        isOpen={isOpenGenerateMessageTemplateModal}
        onClose={onCloseGenerateMessageTemplateModal}
        onGenerateMessageTemplate={handleGenerateTemplate}
      />
      <RegenerateMessageTemplateModal
        isOpen={isOpenRegenerateMessageTemplateModal}
        onClose={onCloseRegenerateMessageTemplateModal}
        onRegenerateMessageTemplate={handleRegenerateTemplate}
        promptThreadId={promptThreadId}
      />
    </Grid>
  );
};

export default CreateMessageTemplatePage;
