import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { BsCheckAll } from "react-icons/bs";
import { MdOutlineCampaign } from "react-icons/md";
import { TbFileImport } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { appPaths } from "../../constants/app-paths";
import {
  finishCampaignCreation,
  setFilterCriteria,
} from "../../state/campaignCreationSlice";
import { RootState } from "../../state/store";
import FilterSidebar from "./components/FilterSidebar";
import TableCustomersPaginated from "./components/TableCustomersPaginated";
import { UploadFileModal } from "./components/UploadFileModal";

const CustomersPage = () => {
  const { showSelectCustomerRows, selectedCustomerRows } = useSelector(
    (state: RootState) => state.campaignCreation
  );
  const dispatch = useDispatch();
  const uploadFileModal = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const shouldFinishCampaignCreationOnUnmountRef = useRef(true);
  const toast = useToast();

  useEffect(() => {
    if (searchParams.toString()) {
      const paramsCount = searchParams.toString().split("&").length;
      toast({
        title: "Filtros aplicados",
        description: `${paramsCount} filtros aplicados`,
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [])

  function handleClickCancelCampaign() {
    setSearchParams("");
    dispatch(finishCampaignCreation());
    navigate(appPaths.customers.index());
  }

  function handleClickNewCampaign() {
    const newPath = `${appPaths.campaigns.create()}?${searchParams.toString()}`;
    navigate(newPath);
  }

  function handleClickConfirmCustomersSelection() {
    shouldFinishCampaignCreationOnUnmountRef.current = false;
    dispatch(setFilterCriteria(window.location.search.substring(1)));
    navigate(appPaths.campaigns.create());
  }

  useEffect(() => {
    shouldFinishCampaignCreationOnUnmountRef.current = true;
  }, []);

  useEffect(() => {
    return () => {
      if (shouldFinishCampaignCreationOnUnmountRef.current) {
        dispatch(finishCampaignCreation());
      }
    };
  }, [shouldFinishCampaignCreationOnUnmountRef, dispatch]);

  return (
    <Flex height="100%" flexDir={"column"}>
      <Flex padding="25px">
        <Heading>Clientes</Heading>

        {showSelectCustomerRows ? (
          <Flex
            bottom={"20px"}
            gap={1}
            justifyContent={"space-around"}
            position="absolute"
            right="25px"
            top="25px">
            <Button variant="outline" onClick={handleClickCancelCampaign}>
              Cancelar
            </Button>
            <Button
              leftIcon={<BsCheckAll fontSize="24px" />}
              variant="primary"
              onClick={handleClickConfirmCustomersSelection}
              isDisabled={Object.values(selectedCustomerRows).filter(Boolean).length === 0}
            >
              Confirmar
            </Button>
          </Flex>
        ) : (
          <Flex
            bottom={"20px"}
            gap={1}
            justifyContent={"space-around"}
            position="absolute"
            right="25px"
            top="25px">
            <Button
              leftIcon={<TbFileImport size="24px" />}
              variant="outline"
              onClick={uploadFileModal.onOpen}>
              Importar arquivo
            </Button>
            <Button
              variant="primary"
              leftIcon={<MdOutlineCampaign size="24px" />}
              onClick={handleClickNewCampaign}>
              Nova Campanha
            </Button>
          </Flex>
        )}
      </Flex>
      <Grid
        templateColumns={"300px auto"}
        templateAreas={'"filter-sidebar table"'}
        flexGrow={1}>
        <GridItem area="filter-sidebar" boxShadow={"lg"}>
          <FilterSidebar />
        </GridItem>
        <GridItem area="table">
          <TableCustomersPaginated />
        </GridItem>
      </Grid>
      <UploadFileModal
        isOpen={uploadFileModal.isOpen}
        onClose={uploadFileModal.onClose}
      />
    </Flex>
  );
};

export default CustomersPage;
