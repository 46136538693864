import { Avatar, Box, Checkbox, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { differenceInMinutes } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../../constants/colors";
import { isUnreadConversation, markConversationAsUnread } from "../../../../state/inboxSlice";
import { RootState } from "../../../../state/store";
import { Message } from "../../../../types/Message";
import { screenSizes } from "../../../../constants/screen-sizes";
import { User } from "../../../../types/Prisma";
import { BsPersonFill } from "react-icons/bs";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ConversationsService } from "../../../../services/conversations.service";
import { apiRoutes } from "../../../../constants/api-routes";

interface ConversationItemProps {
  name: string;
  isActive: boolean;
  message?: Pick<Message, "createdAt" | "fromSystem" | "text" | "status">;
  onClick: () => void;
  conversationId: string;
  companyAgents: User[];
  isSelected?: boolean;
  onSelect?: (selected: boolean) => void;
  isSelectable?: boolean;
}

const ConversationItem = ({
  name,
  isActive,
  message,
  onClick,
  conversationId,
  companyAgents,
  isSelected = false,
  onSelect,
  isSelectable = true,
}: ConversationItemProps) => {
  const contextMenu = useDisclosure();
  const [position, setPosition] = useState({x: 0, y: 0});
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const markAsUnreadMutation = useMutation(
    async () => {
      const response = await ConversationsService.markConversationAs(conversationId, 'delivered');
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data) {
          dispatch(markConversationAsUnread({ conversationId }));
          queryClient.invalidateQueries(apiRoutes.showConversation(conversationId));
        }
      },
    }
  );

  const isNotRead = useSelector((state: RootState) =>
    isUnreadConversation(state, conversationId)
  );
  const agentId = useSelector(
    (state: RootState) =>
      state.inbox.conversations.find(
        (conversation) => conversation.id === conversationId
      )?.ticketAgentId
  );
  const agent = companyAgents.find((agent) => agent.id === agentId);
  const minutesSinceStart = message
    ? differenceInMinutes(new Date(), new Date(message.createdAt))
    : 0;
  const hoursSinceStart = Math.floor(minutesSinceStart / 60);
  const daysSinceStart = Math.floor(hoursSinceStart / 24);

  const isMobile = useMediaQuery(screenSizes.mobile)[0];

  function getColor(minutesSinceStart: number) {
    if (minutesSinceStart < 30) {
      return "green";
    }
    if (minutesSinceStart < 6 * 60) {
      return "orange";
    }
    if (minutesSinceStart < 23 * 60) {
      return "red";
    }
    return "gray";
  }
  
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => 
    onSelect?.(event.target.checked);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setPosition({x: event.pageX, y: event.pageY});
    contextMenu.onOpen();
  };

  const handleSetNotReadContextItemClick = async () => {
    await markAsUnreadMutation.mutateAsync();
  };

  return (
    <>
      {contextMenu.isOpen && (
        <Menu isOpen={true} onClose={() => contextMenu.onClose()}>
          <MenuButton
            as={Box}
            position="absolute"
            left={`${position.x}px`}
            top={`${position.y}px`}
          />
          <MenuList>
            <MenuItem onClick={handleSetNotReadContextItemClick} isDisabled={isNotRead}>
              Marcar como não lida
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    <Flex
      role="group"
      position="relative"
      onContextMenu={handleContextMenu}
      padding={3}
      justifyContent="space-between"
      borderRadius={5}
      bgColor={isActive ? colors.lightGrey : "transparent"}
      color={isNotRead ? colors.black : colors.darkGrey}
      fontWeight={isNotRead ? "medium" : "normal"}
      onClick={onClick}
      gap={2}
      _hover={{
        bgColor: colors.lightGrey,
        cursor: "pointer",
      }}
    >
      <Flex 
        position='absolute'
        display={'flex'}
        opacity={Number(isSelectable || isSelected)}
        top={1}
        right={1}
        bgColor={colors.white}
        borderRadius={4}
        padding={1}
        transition={'all 0.3s'}
        _groupHover={{ opacity: 1 }}
      >
          <Checkbox size="sm" onChange={handleCheckboxChange} isChecked={!!isSelected} />
      </Flex>
      <Flex gap={2} flex={1}>
        <Avatar name={name} size={"sm"} />
        <Box>
          <Text textOverflow={"ellipsis"} noOfLines={1}>
            {name}
          </Text>
          <Text
            fontSize="sm"
            textOverflow={"ellipsis"}
            noOfLines={1}
            >
            {message?.fromSystem ? "Você: " : null}
            {message?.text}
          </Text>
        </Box>
      </Flex>
      <Flex direction="column" justifyContent="flex-end" alignItems={"end"} gap={1}>
        { isMobile && (
          <Text fontSize={12} display={"flex"} gap={1} alignItems={"center"} whiteSpace={"nowrap"}>
            { agent?.name && (
              <>
                <BsPersonFill />
                {agent.name}
              </>
            )}
          </Text>
        )}
        <Text fontSize="sm" color={getColor(minutesSinceStart)} whiteSpace={"nowrap"}>
          {minutesSinceStart === 0 ? 'agora' : minutesSinceStart < 60
            ? `${minutesSinceStart} min`
            : hoursSinceStart < 24
            ? `${hoursSinceStart} h`
            : `${daysSinceStart} d`}
        </Text>
      </Flex>
    </Flex>
    </>
  );
};

export default ConversationItem;
