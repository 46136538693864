import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { QuickRepliesService } from "../../../../services/quick-replies.service";
import ButtonIcon from "../../../../components/ButtonIcon";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { colors } from "../../../../constants/colors";
import { useCrudQuickReplyModal } from "../../../../hooks/useCrudQuickReplyModal";
import { useState } from "react";
import { QuickReply } from "../../../../types/Prisma";
import { QuickReplyDetailsModal } from "./QuickRepliesTable/components/QuickReplyDetailsModal";
import { TextTd, Th } from "../../../../components/CustomTable";

const TableQuickReplies = () => {
  const { data: quickReplies } = useQuery(
    apiRoutes.listQuickReplies(),
    async () => {
      const { data } = await QuickRepliesService.listQuickReplies();
      return data;
    }
  );
  const { openEditModal, openDeleteAlert } = useCrudQuickReplyModal();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedQuickReply, setSelectedQuickReply] = useState<QuickReply | null>(null);

  const handleOpenModal = (quickReply: QuickReply) => {
    setSelectedQuickReply(quickReply);
    onOpen();
  }

  return (
    <>
      <TableContainer>
        <Table variant="simple" style={{tableLayout: 'fixed'}}>
          <Thead>
            <Tr display={"flex"} width={"100%"}>
              <Th width={"30%"} textForTooltip="Apenas usado como atalho para encontrar a resposta rápida.">Título</Th>
              <Th width={"50%"} textForTooltip="Resposta a ser enviada.">Resposta</Th>
              <Th width={"20%"} display={"flex"} justifyContent={"center"}>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {quickReplies?.map((quickReply) => {
              return (
                <Tr display={"flex"} width={"100%"} key={quickReply.id}>
                  <TextTd width={"30%"} textForTooltip={quickReply.title}>{quickReply.title}</TextTd>
                  <TextTd width={"50%"} textForTooltip={quickReply.text}>{quickReply.text}</TextTd>
                  <Td
                    width={"20%"}
                    display="flex"
                    align="center"
                    justifyContent={"center"}>
                    <ButtonIcon
                      icon={<FaEye fontSize="20px" color={colors.primary} />}
                      onClick={() => handleOpenModal({
                        ...quickReply,
                        createdAt: new Date(quickReply.createdAt),
                        updatedAt: new Date(quickReply.updatedAt)
                      })}
                    />
                    <ButtonIcon
                      icon={<FaEdit fontSize="20px" color={colors.primary} />}
                      onClick={() => openEditModal(quickReply.id)}
                    />
                    <ButtonIcon
                      icon={<FaTrash fontSize="20px" color={colors.danger} />}
                      onClick={() => openDeleteAlert(quickReply.id)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <QuickReplyDetailsModal isOpen={isOpen} onClose={onClose} selectedQuickReply={selectedQuickReply} />
    </>
  )
}

export default TableQuickReplies;
