import { useSearchParams } from "react-router-dom";
import { CustomerFiltersEnum } from "../types/CustomerFiltersEnum";

function parseStringWithWhiteSpaces(value: string) {
  return value.replace(/\+/g, " ");
}

export function useCustomerSearchParams() {
  const [searchParams] = useSearchParams();

  const selectedEngagementTemplateIds =
    searchParams.get(CustomerFiltersEnum.SELECTED_ENGAGEMENT_TEMPLATE_IDS) ||
    "";

  const searchQuery = searchParams.get(CustomerFiltersEnum.SEARCH_QUERY) || "";
  const minTotalPurchases =
    searchParams.get(CustomerFiltersEnum.MIN_TOTAL_PURCHASES) || "";
  const maxTotalPurchases =
    searchParams.get(CustomerFiltersEnum.MAX_TOTAL_PURCHASES) || "";
  const minAverageOrderValue =
    searchParams.get(CustomerFiltersEnum.MIN_AVERAGE_ORDER_VALUE) || "";
  const maxAverageOrderValue =
    searchParams.get(CustomerFiltersEnum.MAX_AVERAGE_ORDER_VALUE) || "";
  const minTotalOrders =
    searchParams.get(CustomerFiltersEnum.MIN_TOTAL_ORDERS) || "";
  const maxTotalOrders =
    searchParams.get(CustomerFiltersEnum.MAX_TOTAL_ORDERS) || "";
  const selectedEngagementActionTypes =
    searchParams.get(CustomerFiltersEnum.SELECTED_ENGAGEMENT_ACTION_TYPES) ||
    "";
  const startOrdersCreatedAt =
    searchParams.get(CustomerFiltersEnum.START_ORDERS_CREATED_AT) || "";
  const endOrdersCreatedAt =
    searchParams.get(CustomerFiltersEnum.END_ORDERS_CREATED_AT) || "";
  const minDaysSinceLastCampaign =
    searchParams.get(CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN) || "";
  const sortBy = searchParams.get(CustomerFiltersEnum.SORT_BY) || "";
  const minAverageItemValue =
    searchParams.get(CustomerFiltersEnum.MIN_AVERAGE_ITEM_VALUE) || "";
  const maxAverageItemValue =
    searchParams.get(CustomerFiltersEnum.MAX_AVERAGE_ITEM_VALUE) || "";
  const selectedTags =
    searchParams.get(CustomerFiltersEnum.SELECTED_TAGS) || "";
  const excludedTags =
    searchParams.get(CustomerFiltersEnum.EXCLUDED_TAGS) || "";
  const isRemarketing =
    searchParams.get(CustomerFiltersEnum.IS_REMARKETING) || "";
  const minDaysSinceLastPurchase =
    searchParams.get(CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE) || "";
  const maxDaysSinceLastPurchase =
    searchParams.get(CustomerFiltersEnum.MAX_DAYS_SINCE_LAST_PURCHASE) || "";
  const excludedTemplateIds =
    searchParams.get(CustomerFiltersEnum.EXCLUDED_TEMPLATE_IDS) || "";
  const selectedProductIds =
    searchParams.get(CustomerFiltersEnum.SELECTED_PRODUCT_IDS) || "";
  const excludedProductIds =
    searchParams.get(CustomerFiltersEnum.EXCLUDED_PRODUCT_IDS) || "";
  const minProductQuantity =
    searchParams.get(CustomerFiltersEnum.MIN_PRODUCT_QUANTITY) || "";
  const maxProductQuantity =
    searchParams.get(CustomerFiltersEnum.MAX_PRODUCT_QUANTITY) || "";
  const minDaysSinceLastProductPurchase =
    searchParams.get(
      CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PRODUCT_PURCHASE
    ) || "";
  const maxDaysSinceLastProductPurchase =
    searchParams.get(
      CustomerFiltersEnum.MAX_DAYS_SINCE_LAST_PRODUCT_PURCHASE
    ) || "";
  const productNameContains =
    searchParams.get(CustomerFiltersEnum.PRODUCT_NAME_CONTAINS) || "";
  const isLastProductPurchased =
    searchParams.get(CustomerFiltersEnum.IS_LAST_PRODUCT_PURCHASED) || "";
  const customFieldId1 =
    searchParams.get(CustomerFiltersEnum.CUSTOM_FIELD_ID_1) || "";
  const customFieldValue1 =
    searchParams.get(CustomerFiltersEnum.CUSTOM_FIELD_VALUE_1) || "";
  const isScheduledCampaignsVisible =
    searchParams.get(CustomerFiltersEnum.IS_SCHEDULED_CAMPAIGNS_VISIBLE) || "";
  const platformOrderSource = searchParams.get(
    CustomerFiltersEnum.PLATFORM_ORDER_SOURCE
  );
  const selectedStates = parseStringWithWhiteSpaces(searchParams.get(CustomerFiltersEnum.SELECTED_STATES) || "");

  return {
    selectedEngagementTemplateIds,
    searchQuery,
    minTotalPurchases,
    maxTotalPurchases,
    minAverageOrderValue,
    maxAverageOrderValue,
    minTotalOrders,
    maxTotalOrders,
    selectedEngagementActionTypes,
    startOrdersCreatedAt,
    endOrdersCreatedAt,
    minDaysSinceLastCampaign,
    sortBy,
    minAverageItemValue,
    maxAverageItemValue,
    selectedTags,
    excludedTags,
    isRemarketing,
    minDaysSinceLastPurchase,
    maxDaysSinceLastPurchase,
    excludedTemplateIds,
    selectedProductIds,
    excludedProductIds,
    minProductQuantity,
    maxProductQuantity,
    minDaysSinceLastProductPurchase,
    maxDaysSinceLastProductPurchase,
    productNameContains,
    isLastProductPurchased,
    customFieldId1,
    customFieldValue1,
    isScheduledCampaignsVisible,
    platformOrderSource,
    selectedStates,
  };
}
