import { Box, Center, Image, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { pulsing } from "../../animations/pulsing";

interface LoadingScreenProps {
  isLoading: boolean;
  children: ReactNode;
}

const LoadingScreen = ({ isLoading, children }: LoadingScreenProps) => {
  return (
    <div>
      {isLoading ? (
        <Center
          height="100vh"
          width="100vw"
          boxSizing="border-box"
          position='absolute'
          top={0}
          left={0}
          bgColor='rgba(255,255,255,0.8)'
          flexDir={"column"}
          zIndex={1000}
          >
          <Image
            src="/favicon.ico"
            alt="loading"
            animation={`${pulsing} infinite 1.5s linear`}
          />
          <Text>Carregando</Text>
        </Center>
      ) : (
        children
      )}
    </div>
  );
};

export default LoadingScreen;
