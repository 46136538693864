import {useContext} from "react";
import {
  EmojiDataSetContext,
  EmojiDataSetContextData,
} from "../contexts/EmojiDataSetContext";

export const useEmojiDataSet = (): EmojiDataSetContextData => {
  const context = useContext(EmojiDataSetContext);

  if (!context)
    throw new Error("useDashboard must be used within a EmojiDataSetProvider");

  return context;
};
