import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputNumber, { InputNumberProps } from "../InputNumber";

interface InputRangeNumberProps
  extends Omit<InputNumberProps, "onChange" | "value"> {
  onChangeMinValue: (value: number) => void;
  onChangeMaxValue: (value: number) => void;
  minValue: number;
  maxValue: number;
}

const InputRangeNumber = ({
  size,
  leftAddon,
  rightAddon,
  onChangeMaxValue,
  onChangeMinValue,
  minValue,
  maxValue,
}: InputRangeNumberProps) => {
  return (
    <Flex justifyContent={"space-between"} alignItems="center" gap={2}>
      <Box>
        <InputNumber
          onChange={onChangeMinValue}
          value={minValue}
          size={size}
          leftAddon={leftAddon}
          rightAddon={rightAddon}
        />
        <Text textAlign='center' fontSize='9px'>min</Text>
      </Box>
      <Text fontSize={"25px"}>-</Text>
      <Box>
        <InputNumber
          onChange={onChangeMaxValue}
          value={maxValue}
          size={size}
          leftAddon={leftAddon}
          rightAddon={rightAddon}
          />
        <Text textAlign='center' fontSize='9px'>max</Text>
      </Box>
    </Flex>
  );
};

export default InputRangeNumber;
