import { Grid, GridItem } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import SidebarSecondary from "../../components/SidebarSecondary";
import { appPaths } from "../../constants/app-paths";

interface AutomationsLayoutProps {
  children: ReactNode;
}

const SIDEBAR_OPTIONS = [
  {
    title: "Fluxos de mensagem",
    path: appPaths.automations.messageFlows.index(),
  },
  {
    title: "Respostas automáticas",
    path: appPaths.automations.autoReplies(),
  },
  {
    title: "Configurar chatbot",
    path: appPaths.automations.autoSorting(),
  },
  {
    title: "Envios automatizados",
    path: appPaths.automations.backgroundAutomations.index(),
  },
];

const AutomationsLayout = ({ children }: AutomationsLayoutProps) => {
  return (
    <Grid
      height={"100vh"}
      templateColumns="250px auto"
      templateAreas={'"sidebar page"'}>
      <GridItem area="sidebar">
        <SidebarSecondary title="" options={SIDEBAR_OPTIONS} />
      </GridItem>
      <GridItem area="page" maxH={"100vh"} height={"100vh"} overflow="auto">
        {children}
      </GridItem>
    </Grid>
  );
};

export default AutomationsLayout;
