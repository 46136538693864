export function convertObjectToQueryString(
  object: Record<string, any> | undefined
): string {
  if (!object) {
    return "";
  }
  return Object.keys(object)
    .map((key) => {
      if (object[key]) {
        return `${key}=${encodeURIComponent(object[key])}`;
      }
      return "";
    })
    .join("&");
}

export function convertQueryStringToObject(queryString: string) {
  const keyValuePairs = queryString.split("&");
  const resultObject: Record<string, any> = {};

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    if (key && value) {
      resultObject[decodeURIComponent(key)] = decodeURIComponent(value);
    }
  });

  return resultObject;
}

export function isValidUrl(urlVal: string): boolean {
  const urlPattern =
    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w\-\.~!$&'\(\)*+,;=:@/?#%]*)*(\?([\w\-\.~!$&'\(\)*+,;=:@/?#%]*=[\w\-\.~!$&'\(\)*+,;=:@/?#%]*)&?)?$/;
  return urlPattern.test(urlVal);
}

export const UrlUtils = {
  convertObjectToQueryString: (object: any) =>
    convertObjectToQueryString(object),
  convertQueryStringToObject: (queryString: string) =>
    convertQueryStringToObject(queryString),
  isValidUrl: (urlVal: string) => isValidUrl(urlVal),
};
