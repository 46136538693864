import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export interface SelectOption {
  value: string;
  label: string;
}

export interface InputSelectProps {
  options: SelectOption[];
  isMulti?: boolean;
  defaultValue?: SelectOption[];
  onChange?: (value: any) => void;
  value?: SelectOption[];
  disabled?: boolean;
  placeholder?: string;
  onCreateOption?: (value: any) => void;
  onMenuClose?: () => void;
}

const InputSelect = ({
  options,
  isMulti,
  defaultValue,
  onChange,
  value,
  disabled,
  placeholder,
  onCreateOption,
  onMenuClose,
}: InputSelectProps) => {
  const SelectComponent = onCreateOption ? CreatableSelect : Select;

  return React.createElement(SelectComponent, {
    className: "sectionTest",
    styles: {
      control: (styles) => ({
        ...styles,
        minHeight: "36px",
        fontSize: "0.875rem", // chakra-sm
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    },
    menuPortalTarget: document.body,
    placeholder,
    isDisabled: disabled,
    options,
    isMulti,
    defaultValue,
    onChange,
    onCreateOption,
    value,
    menuPosition: "fixed",
    isClearable: true,
    closeMenuOnSelect: !isMulti,
    onMenuClose: onCreateOption ? onMenuClose : undefined,
  });
};

export default InputSelect;
