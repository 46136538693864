import { DrawerBody } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

export interface EndWhatsappConversationNodeEditorProps {
  formId: string;
  onSaveNode: (data: any) => void;
}
const EndWhatsappConversationNodeEditor = ({
  formId,
  onSaveNode,
}: EndWhatsappConversationNodeEditorProps) => {
  const { handleSubmit } = useForm({} as any);

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(onSaveNode)} id={formId}></form>
    </DrawerBody>
  );
};

export default EndWhatsappConversationNodeEditor;
