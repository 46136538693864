import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  useId,
  useToast,
} from "@chakra-ui/react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import CrudModal from "../components/CrudModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../constants/colors";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AutoSortingOptionsService,
  CreateAutoSortingOptionDto,
  UpdateAutoSortingOptionDto,
} from "../services/auto-sorting-options.service";
import { apiRoutes } from "../constants/api-routes";
import { AutomaticSortingOption } from "../types/AutomaticSortingOption";
import AlertDialogBase from "../components/AlertDialog";
import { FileValidationUtils } from "../utils/file-validation.utils";
import { ConversationCategory } from "../types/ConversationCategory";
import useFileValidation from "./useFileValidation";
import useDownloadFile from "./useDownloadFile";
import ButtonIcon from "../components/ButtonIcon";
import { FaRegTimesCircle } from "react-icons/fa";
import { MixpanelService } from "../services/mixpanel.service";
import useGetCrudText from "./useGetCrudText";
import { ConversationCategoriesService } from "../services/conversation-categories.service";

interface CrudAutoSortingOptionModalContextData {
  openCreateModal: () => void;
  openEditModal: (autoSortingOptionId: string) => void;
  handleClickDelete: (autoSortingOptionId: string) => void;
  handleChangeIsActive: (
    autoSortingOptionId: string,
    isActive: boolean
  ) => void;
}

interface CrudAutoSortingProps {
  children?: ReactNode;
}

const CrudAutoSortingOptionModalContext = createContext(
  {} as CrudAutoSortingOptionModalContextData
);

const schema = yup
  .object({
    conversationCategoryId: yup.string(),
    firstMessage: yup.string(),
    isActive: yup.boolean(),
  })
  .required();

export function CrudAutoSortingModalProvider({
  children,
}: CrudAutoSortingProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [autoSortingOptionId, setAutoSortingOptionId] = useState<string | null>(
    null
  );
  const { crudActionText, crudTitle } = useGetCrudText(!autoSortingOptionId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const formId = useId();
  const toast = useToast();
  const { data: conversationCategories = [] } = useQuery(
    apiRoutes.listConversationCategories(),
    async () => {
      const { data } =
        await ConversationCategoriesService.listConversationCategories();
      return data;
    }
  );
  const [availableConversationCategories, setAvailableConversationCategories] =
    useState<ConversationCategory[]>([]);
  const {
    isOpen: isAlertOpen,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure();
  const [currentAutoSortingOption, setCurrentAutoSortingOption] =
    useState<AutomaticSortingOption | null>(null);
  const { downloadWhatsappMedia } = useDownloadFile();
  const { validateFile } = useFileValidation();
  const queryClient = useQueryClient();
  const createAutoSortingOption = useMutation(
    (newAutoSortingOption: CreateAutoSortingOptionDto) =>
      AutoSortingOptionsService.createAutoSortingOption(newAutoSortingOption),
    {
      onSuccess: (res) => {
        MixpanelService.track("create-auto-sorting-option");
        queryClient.setQueryData(
          apiRoutes.listAutoSortingOptions(),
          (old: any) => [...old, res.data]
        );
      },
    }
  );

  const updateAutoSortingOption = useMutation(
    (updatedAutoSortingOption: UpdateAutoSortingOptionDto) =>
      AutoSortingOptionsService.updateAutoSortingOption(
        updatedAutoSortingOption
      ),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          apiRoutes.listAutoSortingOptions(),
          (old: any) => {
            return old.map((option: AutomaticSortingOption) =>
              option.id === res.data.id ? res.data : option
            );
          }
        );
      },
    }
  );

  const deleteAutoSortingOption = useMutation(
    (optionId: string) =>
      AutoSortingOptionsService.deleteAutoSortingOption(optionId),
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          apiRoutes.listAutoSortingOptions(),
          (old: any) =>
            old.filter(
              (option: AutomaticSortingOption) => option.id !== res.data.id
            )
        );
      },
    }
  );

  useEffect(() => {
    const cachedAutoSortingOptions = queryClient.getQueryData<
      AutomaticSortingOption[]
    >(apiRoutes.listAutoSortingOptions());
    const currentOption = cachedAutoSortingOptions?.find(
      (option) => option.id === autoSortingOptionId
    );

    const usedCategoryIds = cachedAutoSortingOptions?.map(
      (option) => option.conversationCategoryId
    );
    const filteredOptions = conversationCategories.filter(
      (category) =>
        !usedCategoryIds?.includes(category.id) ||
        category.id === currentOption?.conversationCategoryId
    );
    setAvailableConversationCategories(filteredOptions);
  }, [
    autoSortingOptionId,
    JSON.stringify(conversationCategories),
    queryClient,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isOpen,
  ]);

  useEffect(() => {
    const cachedAutoSortingOptions = queryClient.getQueryData<
      AutomaticSortingOption[]
    >(apiRoutes.listAutoSortingOptions());
    const currentOption = cachedAutoSortingOptions?.find(
      (option) => option.id === autoSortingOptionId
    );

    if (currentOption) {
      setCurrentAutoSortingOption(currentOption);
      setValue("conversationCategoryId", currentOption.conversationCategoryId);
      setValue("firstMessage", currentOption.firstMessage);
      setValue("isActive", currentOption.isActive);
    } else {
      setValue("isActive", true);
    }
  }, [
    autoSortingOptionId,
    conversationCategories,
    queryClient,
    setValue,
    availableConversationCategories,
  ]);

  async function onSubmit(data: any) {
    try {
      const { files, ...createData } = data;
      let file = files[0];
      if (!autoSortingOptionId) {
        await createAutoSortingOption.mutateAsync({ ...createData, file });
      } else {
        const isFileRemoved = currentAutoSortingOption?.file === null;

        await updateAutoSortingOption.mutateAsync({
          ...data,
          autoSortingOptionId,
          file: file || (isFileRemoved ? null : undefined),
        });
      }
      toast({
        title: "Opção salva com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      resetState();
    } catch (err) {}
  }

  function openEditModal(autoSortingOptionId: string) {
    setAutoSortingOptionId(autoSortingOptionId);
    onOpen();
  }

  function handleCloseModal() {
    resetState();
  }

  function openCreateModal() {
    setAutoSortingOptionId(null);
    onOpen();
  }

  function resetState() {
    onClose();
    setCurrentAutoSortingOption(null);
    setAvailableConversationCategories([]);
    setAutoSortingOptionId(null);
    setValue("conversationCategoryId", "");
    setValue("firstMessage", "");
    setValue("isActive", false);
    setValue("files", []);
  }

  async function handleClickDelete(optionId: string) {
    deleteAutoSortingOption.mutate(optionId);
    onClose();
  }

  async function handleChangeIsActive(
    autoSortingOptionId: string,
    isActive: boolean
  ) {
    updateAutoSortingOption.mutate({
      isActive,
      autoSortingOptionId,
    });
  }

  function handleFileChange(event: any) {
    const file = event.target.files && event.target.files[0];

    const isValidFile = validateFile(file);

    if (!isValidFile) {
      event.target.value = null;
    }
  }

  return (
    <CrudAutoSortingOptionModalContext.Provider
      value={{
        openCreateModal,
        openEditModal,
        handleChangeIsActive,
        handleClickDelete: (optionId: string) => {
          setAutoSortingOptionId(optionId);
          onOpenAlert();
        },
      }}>
      <AlertDialogBase
        isOpen={isAlertOpen}
        onClose={onCloseAlert}
        title="Deletar opção de atendimento"
        onConfirm={() => {
          handleClickDelete(autoSortingOptionId!);
          onCloseAlert();
        }}>
        Tem certeza que deseja deletar esta opção?
      </AlertDialogBase>
      <CrudModal
        isLoading={
          updateAutoSortingOption.isLoading || createAutoSortingOption.isLoading
        }
        actionButtonText={crudActionText}
        isOpen={isOpen}
        onClose={handleCloseModal}
        title={`${crudTitle} opção de atendimento`}
        onClickDelete={onOpenAlert}
        canDelete={Boolean(autoSortingOptionId)}
        formId={formId}>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Stack spacing={5}>
            <FormControl>
              <FormLabel>Categoria de destino</FormLabel>
              <Select
                placeholder="Selecione uma categoria"
                {...register("conversationCategoryId")}>
                {availableConversationCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.conversationCategoryId?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Mensagem automática</FormLabel>
              <Textarea
                placeholder="Mensagem automática"
                {...register("firstMessage")}
                isInvalid={errors.name?.message}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.firstMessage?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Arquivo para envio</FormLabel>
              {currentAutoSortingOption?.file ? (
                <Flex align={"center"} gap={3}>
                  <Link
                    onClick={() =>
                      downloadWhatsappMedia({
                        mediaName: currentAutoSortingOption.file!.name,
                        fileKey: currentAutoSortingOption.file!.key,
                      })
                    }>
                    {currentAutoSortingOption.file.name}
                  </Link>
                  <ButtonIcon
                    icon={<FaRegTimesCircle color="red" />}
                    onClick={() =>
                      setCurrentAutoSortingOption((prevState) => {
                        return prevState
                          ? {
                              ...prevState,
                              file: null,
                            }
                          : null;
                      })
                    }
                  />
                </Flex>
              ) : (
                <input
                  {...register("files")}
                  type="file"
                  onChange={handleFileChange}
                  accept={FileValidationUtils.supportedFileTypes.join(", ")}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Ativado?</FormLabel>
              <Switch {...register("isActive")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isActive?.message}
              </Text>
            </FormControl>
          </Stack>
        </form>
      </CrudModal>
      {children}
    </CrudAutoSortingOptionModalContext.Provider>
  );
}

export function useCrudAutoSortingOptionModal(): CrudAutoSortingOptionModalContextData {
  const context = useContext(CrudAutoSortingOptionModalContext);
  if (!context) {
    throw new Error(
      "useCrudAutoSortingOptionModal must be used within a CrudAutoSortingModal"
    );
  }
  return context;
}
