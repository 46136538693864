import {
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";
import { apiRoutes } from "../../../../../constants/api-routes";
import { colors } from "../../../../../constants/colors";
import { ListMessageTemplateItem, MessageTemplatesService } from "../../../../../services/message-templates.service";
import { MessageTemplateUtils } from "../../../../../utils/message-templates.utils";
import InputSwitchControlled from "../../../../../components/InputSwitchControlled";
import WhatsappTemplatePreview from "../../../../../components/WhatsappTemplatePreview";
import { AbandonedCartAutomationFormValues } from "../../EditBackgroundAutomationPage";
import { MessageTemplateTypeEnum } from "../../../../../types/MessageTemplateType";

const schema = yup
  .object({
    name: yup.string().required(),
    isActive: yup.boolean().required(),
    messageTemplateId: yup.string().required(),
  })
  .required();

interface AutomationFormProps {
  onSubmit: (data: any) => void;
  templateArgs: { [key: string]: string | undefined };
  setTemplateArgs: (args: { [key: string]: string | undefined }) => void;
  automation?: AbandonedCartAutomationFormValues;
}

const AbandonedCartAutomationForm = ({
  onSubmit,
  templateArgs,
  setTemplateArgs,
  automation,
}: AutomationFormProps) => {
  const defaultValues = {
    name: automation?.name || null,
    isActive: automation?.isActive || true as boolean,
    messageTemplateId: automation?.messageTemplateId || null,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const watchMessageTemplateId = watch("messageTemplateId");

  const { data: templates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: ListMessageTemplateItem) => {
            return template.status === "approved" && template.type === MessageTemplateTypeEnum.ABANDONED_CART;
          }
        );
      },
    }
  );

  function handleChangeTemplateParameter(parameter: string, value: string) {
    setTemplateArgs({
      ...templateArgs,
      [parameter]: value,
    });
  }

  const selectedTemplate = watchMessageTemplateId
    ? templates?.find((template) => template.id === watchMessageTemplateId)
    : null;

  const customParameters = MessageTemplateUtils.getCustomParametersInText(
    selectedTemplate?.templateText || ""
  );


  useEffect(() => {
    if (automation) {
      setValue("name", automation.name);
      setValue("isActive", automation.isActive);
      setValue("messageTemplateId", automation.messageTemplateId);
    }
  }, [automation, setValue]);

  return(
    <Grid
      height="auto"
      templateColumns="2fr 1fr"
      paddingTop="50px"
      paddingBottom="50px"
      paddingX="100px"
      alignItems="start"
      gap={4}>
      <GridItem>
        <Heading size="md" mb={5}>
          Nova automação
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel>Nome da automação</FormLabel>
              <Input placeholder="Nome" {...register("name")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Template para envio</FormLabel>
              <Select
                placeholder="Selecione um template"
                {...register("messageTemplateId")}>
                {templates?.map((template) => (
                  <option
                    key={template.id}
                    value={template.id}
                    title={template.templateText}>
                    {template.name}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.messageTemplateId?.message}
              </Text>
            </FormControl>
            {selectedTemplate && (
              <Container paddingX={"50px"}>
                {customParameters.map((param) => (
                  <FormControl isRequired key={param}>
                    <FormLabel fontSize="sm">
                      {param.replaceAll("[", "").replaceAll("]", "")}
                    </FormLabel>
                    <Input
                      size="sm"
                      onChange={(e) =>
                        handleChangeTemplateParameter(param, e.target.value)
                      }
                      value={templateArgs[param] || ""}
                      required
                    />
                  </FormControl>
                ))}
              </Container>
            )}
            <FormControl>
              <FormLabel>Ativo?</FormLabel>
              <InputSwitchControlled name="isActive" control={control} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isActive?.message}
              </Text>
            </FormControl>
            <Divider />
            <Flex justify={"flex-end"}>
              <Button
                width="30%"
                isLoading={false}
                color={colors.white}
                bgColor={colors.primary}
                type="submit">
                Salvar
              </Button>
            </Flex>
          </Stack>
        </form>
      </GridItem>
      <GridItem position={"sticky"} top={0} alignSelf="start">
        {selectedTemplate && (
          <WhatsappTemplatePreview
            message={selectedTemplate.templateText}
            footer={selectedTemplate.footerText}
            buttons={selectedTemplate.messageTemplateButtons}
            fileUrl={selectedTemplate.mediaUrl}
          />
        )}
      </GridItem>
    </Grid>
  )
};

export default AbandonedCartAutomationForm;
