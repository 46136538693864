import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { QuickReply } from "../types/QuickReply";

export interface CreateQuickReplyDto {
  title: string;
  text: string;
}

export interface UpdateQuickReplyDto {
  id: string;
  title: string;
  text: string;
}

const listQuickReplies = async (): Promise<AxiosResponse<QuickReply[]>> => {
  return request.get(apiRoutes.listQuickReplies());
}

const createQuickReply = async (createQuickReplyDto: CreateQuickReplyDto) => {
  return request.post(apiRoutes.createQuickReply(), createQuickReplyDto);
}

const updateQuickReply = async (updateQuickReplyDto: UpdateQuickReplyDto) => {
  const { id, ...updateData } = updateQuickReplyDto;
  return request.put(apiRoutes.updateQuickReply(id), updateData);
}

const deleteQuickReply = async (quickReplyId: string) => {
  return request.delete(apiRoutes.deleteQuickReply(quickReplyId));
}

export const QuickRepliesService = {
  listQuickReplies,
  createQuickReply,
  updateQuickReply,
  deleteQuickReply
}
