import { Box, Button, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { colors } from "../../../constants/colors";
import { useCrudCompanyDefinedFieldModal } from "../../../hooks/useCrudCompanyDefinedFieldModal";
import TableColumnSettings from "./components/TableColumnSettings";

const ColumnSettingPage = () => {
  const { openCreateModal } = useCrudCompanyDefinedFieldModal();
  
  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="flex-end">
        <Button
          variant='primary'
          onClick={openCreateModal}
        >
          + criar campo de cliente
        </Button>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableColumnSettings />
    </Box>
  );
};

export default ColumnSettingPage;
