import { Box, Button, Divider, Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../../constants/app-paths";
import TableAutomations from "./components/TableAutomations";

const BackgroundAutomationsPage = () => {
  const navigate = useNavigate();

  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="space-between">
        <Heading mb={5}>Envios automatizados</Heading>
        <Button
          variant="primary"
          onClick={() =>
            navigate(
              appPaths.automations.backgroundAutomations.createAutomation()
            )
          }>
          + criar automação
        </Button>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableAutomations />
    </Box>
  );
};

export default BackgroundAutomationsPage;
