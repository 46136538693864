import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineCampaign } from 'react-icons/md';
import { RiNodeTree } from 'react-icons/ri';
import { useQuery } from 'react-query';
import CardStatistic from '../../../../components/CardStatistic';
import { apiRoutes } from '../../../../constants/api-routes';
import { StatisticsService } from '../../../../services/statistics.service';
import { MoneyUtils } from '../../../../utils/money.utils'

const SectionReviRevenueSummary = () => {
  const { data: revenueSummary, isFetching: isFetchingRevenueSummary } =
    useQuery(
      apiRoutes.getReviRevenueSummary(),
      async () => {
        const { data } = await StatisticsService.getReviRevenueSummary();
        return data;
      },
      {
        staleTime: 1000 * 60 * 5,
      }
    );

  return (
    <Card flex={1}>
      <CardHeader>
        <Heading size="md">Receita influenciada pela Revi</Heading>
      </CardHeader>
      <CardBody display="flex" gap={4}>
        {[
          {
            title: "Total",
            value: MoneyUtils.formatCurrency(revenueSummary?.total || 0, 0),
            icon: "R$",
            tooltip: "Receita total influenciada pela Revi",
          },
          {
            title: "Marketing",
            value: MoneyUtils.formatCurrency(
              revenueSummary?.marketing || 0,
              0
            ),
            icon: <MdOutlineCampaign size="24px" />,
            tooltip:
              "Receita influenciada por campanhas de marketing da Revi",
          },
          {
            title: "Automações",
            value: MoneyUtils.formatCurrency(
              revenueSummary?.automation || 0,
              0
            ),
            icon: <RiNodeTree size="24px" />,
            tooltip: "Receita influenciada por automações da Revi",
          },
        ].map((stats) => (
          <CardStatistic
            icon={stats.icon}
            title={stats.title}
            value={stats.value}
            key={stats.title}
            size="sm"
            tooltip={stats.tooltip}
            isLoading={isFetchingRevenueSummary}
          />
        ))}
      </CardBody>
    </Card>
  )
}

export default SectionReviRevenueSummary