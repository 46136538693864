import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { WinningMetric } from "../types/CampaignExperiment";

interface Variant {
  templateId: string;
  templateArgs?: {
    [key: string]: string | undefined;
  };
}

export interface StartOrScheduleCampaignExperimentDto {
  customerIds: string[];
  filterCriteria?: string;
  scheduledExecutionTime?: string | null;
  variants: Variant[];
  winningMetric: WinningMetric;
  testSizePercentage: number;
  durationInMinutes: number;
  name: string;
}

const startOrScheduleCampaignExperiment = async (
  startOrScheduleCampaignExperimentDto: StartOrScheduleCampaignExperimentDto
) => {
  return request.post(
    apiRoutes.startOrScheduleCampaignExperiment(),
    startOrScheduleCampaignExperimentDto
  );
};

export const CampaignExperimentsService = {
  startOrScheduleCampaignExperiment,
};
