import { MdKeyboardVoice, MdSend, MdStop } from "react-icons/md";
import { colors } from "../../../../constants/colors";
import "./styles/audio-recorder.css";
import useOpusMediaRecorder from "../../../../hooks/useOpusMediaRecorder";
import { ButtonGroup } from "@chakra-ui/react";
import { IoTrash } from "react-icons/io5";

interface InputButtonsProps {
  currentText: string;
  onSend: (file: File) => void;
  isDesktop: boolean;
}

const InputButtons = ({ currentText, onSend, isDesktop }: InputButtonsProps) => {
  const oppusMediaRecorder = useOpusMediaRecorder({
    mimeType: "audio/ogg; codecs=opus",
  });

  const startRecording = () => {
    oppusMediaRecorder.startRecording();
  };

  const stopRecording = () => {
    if (oppusMediaRecorder.recorderState === "recording") {
      oppusMediaRecorder.stopRecording();
    }
  };

  async function createFileFromUrl(url: string, fileName: string) {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Erro ao buscar o URL: ${response.statusText}`);
    }
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  }

  const resetRecording = () => {
    oppusMediaRecorder.resetRecorder();
  };

  function handleSendAudio() {
    if (!oppusMediaRecorder.audioUrl) {
      return;
    }

    createFileFromUrl(oppusMediaRecorder.audioUrl, "audio.ogg")
      .then((file) => {
        onSend(file);
        resetRecording();
      })
      .catch((error) => {
        console.error("Erro:", error);
      });
  }

  return (
    <ButtonGroup
      justifyContent="center"
      alignItems="center"
      size="sm"
      style={{ marginRight: 12 }}
    >
      <div
        style={{
          height: "20px",
        }}
      >
        {isDesktop ? (
          oppusMediaRecorder.audioUrl ? (
            <div className="audio-container">
              <audio controls src={oppusMediaRecorder.audioUrl}></audio>
              <button onClick={resetRecording}>
                <IoTrash fontSize={16} color={colors.danger} />
              </button>
              <button onClick={handleSendAudio}>
                <MdSend fontSize={16} />
              </button>
            </div>
          ) : oppusMediaRecorder.recorderState === "recording" ? (
            <ButtonGroup justifyContent="center" alignItems="center" size="sm">
              <button onClick={resetRecording}>
                <IoTrash fontSize={16} color={colors.danger} />
              </button>

              <div className="button-stop-container">
                <div className="circle"></div>
                <button onClick={stopRecording} className="button-stop-recording">
                  {<MdStop fontSize={20} color={colors.darkGrey} />}
                </button>
              </div>
            </ButtonGroup>
          ) : currentText.length > 0 ? (
            <button type="submit">
              <MdSend fontSize={16} color={colors.darkGrey} />
            </button>
          ) : (
            <button onClick={startRecording}>
              {<MdKeyboardVoice fontSize={20} color={colors.darkGrey} />}
            </button>
          )
        ) : currentText.length > 0 ? (
          <button type="submit">
            <MdSend fontSize={16} color={colors.darkGrey} />
          </button>
        ) : null}
      </div>
    </ButtonGroup>
  );
};

export default InputButtons;
