import { AudienceRecommendationClassSlug } from "./Prisma";

export enum AudienceRecommendationTypeEnum {
  PRODUCT_LAUNCH = 'product-launch',
  PROMOTION = 'promotion',
  INACTIVE_CUSTOMERS = 'inactive-customers',
  NEW_CUSTOMERS = 'new-customers',
}

export interface AudienceRecommendation {
  type: AudienceRecommendationTypeEnum;
  audienceClass: AudienceRecommendationClassSlug;
  filterCriteria: string;
}
