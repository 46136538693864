import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AiOutlineMessage } from "react-icons/ai";
import { Handle, NodeProps, Position } from "reactflow";
import { SendWhatsappMessageNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";

const SendWhatsappMessageNode = (props: NodeProps<SendWhatsappMessageNodeData>) => {
  const { t } = useTranslation();
  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<AiOutlineMessage />}
      color="#33b64b"
    >
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content">
        {props.data.text}
      </Box>
      <Flex flexDir="column" gap={"5px"} mt="20px">
        {props.data.buttons?.map((button, index) => (
          <Box key={button.id}>
            {!button.url && (
              <Handle
              id={button.id}
              type="source"
              position={Position.Right}
              style={{
                top: "auto",
                bottom: 30 + (props.data.buttons!.length - index - 1) * 45,
                height: 10,
                width: 10,
              }}
            />
            )}
            <Flex
              bgColor="#EAF4FF"
              width="100%"
              borderRadius={"5px"}
              height="40px"
              justifyContent="center"
              alignItems={"center"}>
              {button.text}
            </Flex>
          </Box>
        ))}
      </Flex>
    </NodeLayout>
  );
};

export default SendWhatsappMessageNode;
