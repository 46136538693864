import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { Recommendation } from "../types/Recommendation";

const listCompanyRecommendations = async (): Promise<
  AxiosResponse<
  Recommendation[]
  >
> => {
  return request.get(apiRoutes.listCompanyRecommendations());
};

export const RecommendationsService = {
  listCompanyRecommendations,
};
