import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../../constants/app-paths";

interface BreadcrumbBaseItem {
  path: string;
  name: string;
}

interface BreadcrumbBaseProps {
  items: BreadcrumbBaseItem[];
}

const BreadcrumbBase = ({ items }: BreadcrumbBaseProps) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb mb={5} separator={<ChevronRightIcon color="gray.500" />}>
      {items.map((item) => (
        <BreadcrumbItem key={item.path}>
          <BreadcrumbLink onClick={() => navigate(item.path)}>{item.name}</BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbBase;
