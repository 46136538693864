import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import FilePreview from '../FilePreview';

interface WhatsappFilePreviewProps {
  file: File;
  showName?: boolean;
}

const WhatsappFilePreview: React.FC<WhatsappFilePreviewProps> = ({ file, showName }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={2}
      bg="white"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      maxW="200px"
    >
      <FilePreview file={file} /> 
      {showName && (
        <div>
          <Text fontSize="sm" fontWeight="bold" color="gray.700" maxWidth={'100%'}>
            {file.name}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {(file.size / 1024).toFixed(2)} KB
          </Text>
        </div>
      )}
    </Box>
  );
};

export default WhatsappFilePreview;