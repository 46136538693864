import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAuthenticated as isAuthenticatedSelector } from "../state/authSlice";
import { resetInboxState } from "../state/inboxSlice";
import { AppDispatch } from "../state/store";
import AppRoutes from "./app.routes";
import AuthRoutes from "./auth.routes";

const Routes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isAuthenticated) {
      // reset query cache
      queryClient.clear();
      
      // reset inbox state
      dispatch(resetInboxState());
    }
  }, [dispatch, isAuthenticated]);

  return isAuthenticated ? <AppRoutes /> : <AuthRoutes />;
};

export default Routes;
