import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import {
  Conversation,
  ConversationWithIncludes,
  ListConversationCustomerItem,
  ListConversationDetailedItem,
  ListConversationItem,
} from "../types/Conversation";
import { ConversationTicketStatus } from "../types/ConversationTicket";
import { PaginatedResponse } from "../types/PaginatedResponse";
import { MessageStatus } from "../types/Message";

export interface ListConversationsParams {
  ticketStatus: ConversationTicketStatus;
  daysSinceLastMessage: number;
}
const listConversations = async ({
  ticketStatus,
  daysSinceLastMessage,
}: ListConversationsParams): Promise<AxiosResponse<ListConversationItem[]>> => {
  return request.get(
    apiRoutes.listConversations({
      ticketStatus,
      daysSinceLastMessage,
    })
  );
};

export interface ListConversationsDetailedParams {
  ticketStatus: ConversationTicketStatus;
  daysSinceLastMessage: number;
  conversationCategoryId?: string | null;
  hasUnreadMessages?: boolean | null;
  perPage?: number;
  page?: number;
  ticketAgentId?: string | null;
  byAgentId?: string | null;
  hasNoAgentAssigned?: boolean | null;
}
const listConversationsDetailed = async ({
  ticketStatus,
  daysSinceLastMessage,
  conversationCategoryId,
  hasUnreadMessages,
  page,
  perPage,
  byAgentId,
  hasNoAgentAssigned,
}: ListConversationsDetailedParams): Promise<
  AxiosResponse<PaginatedResponse<ListConversationDetailedItem>>
> => {
  return request.get(
    apiRoutes.listConversationsDetailed({
      ticketStatus,
      daysSinceLastMessage,
      conversationCategoryId,
      hasUnreadMessages,
      page,
      perPage,
      byAgentId,
      hasNoAgentAssigned,
    })
  );
};

const listConversationsCustomers = async (): Promise<
  AxiosResponse<ListConversationCustomerItem[]>
> => {
  return request.get(apiRoutes.listConversationsCustomers());
};

const showConversation = async (
  conversationId: string
): Promise<AxiosResponse<ConversationWithIncludes>> => {
  return request.get(apiRoutes.showConversation(conversationId));
};

const closeAllConversationTickets = async (
  conversationId: string
): Promise<AxiosResponse<Conversation>> => {
  return request.post(apiRoutes.closeAllConversationTickets(conversationId));
};

const closeAllTicketsFromManyConversations = async (
  conversationIds: string[]
): Promise<AxiosResponse<Conversation[]>> => {
  return request.post(apiRoutes.closeAllTicketsFromManyConversations(conversationIds));
};

export interface UpdateConversationDto {
  conversationId: string;
  conversation: {
    categoryId?: string | null;
    recipientName: string;
  };
  customer?: {
    tagIds: string[];
    email?: string;
    notes?: string;
    isOptedOut?: boolean;
  };
}

const updateConversation = async (
  updateConversationDto: UpdateConversationDto
): Promise<AxiosResponse<ConversationWithIncludes>> => {
  const { conversationId, ...data } = updateConversationDto;
  return request.put(apiRoutes.updateConversation(conversationId), {
    ...data,
    conversation: {
      ...data.conversation,
      categoryId: data.conversation.categoryId,
    },
  });
};

export interface CreateConversationDto {
  recipientPhoneNumberId: string;
  recipientName?: string;
}

const createConverstation = async (
  createConversationDto: CreateConversationDto
): Promise<AxiosResponse<ConversationWithIncludes>> => {
  return request.post(apiRoutes.createConversation(), {
    ...createConversationDto,
  });
};

const readConversation = async (
  conversationId: string
): Promise<AxiosResponse<boolean>> => {
  return request.post(apiRoutes.readConversation(conversationId));
};

export interface ListConversationSummaryParams {
  daysSinceLastMessage: number;
}
const listConversationSummary = async (
  params: ListConversationSummaryParams
): Promise<
  AxiosResponse<
    {
      id: string;
      categoryId: string;
    }[]
  >
> => {
  return request.get(apiRoutes.listConversationSummary(params));
};
const getConversationIdsWithOpenTickets = async (
  params: ListConversationSummaryParams
): Promise<
  AxiosResponse<
    {
      id: string;
      agentId: string | null;
    }[]
  >
> => {
  return request.get(apiRoutes.getConversationIdsWithOpenTickets(params));
};
const getConversationIdsWithUnreadMessages = async (
  params: ListConversationSummaryParams
): Promise<
  AxiosResponse<
    {
      id: string;
    }[]
  >
> => {
  return request.get(apiRoutes.getConversationIdsWithUnreadMessages(params));
};

const markConversationAs = async (conversationId: string, state: MessageStatus): Promise<AxiosResponse<boolean>> => {
  return request.post(apiRoutes.markConversationAs(conversationId, state));
};

export const ConversationsService = {
  listConversations,
  updateConversation,
  createConverstation,
  showConversation,
  readConversation,
  listConversationsCustomers,
  closeAllConversationTickets,
  closeAllTicketsFromManyConversations,
  listConversationsDetailed,
  getConversationIdsWithOpenTickets,
  getConversationIdsWithUnreadMessages,
  listConversationSummary,
  markConversationAs,
};
