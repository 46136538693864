import {
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useQuery } from "react-query";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { apiRoutes } from "../../../../../constants/api-routes";
import { colors } from "../../../../../constants/colors";
import { useCrudAutoReplyModal } from "../../../../../hooks/useCrudAutoReplyModal";
import useDownloadFile from "../../../../../hooks/useDownloadFile";
import { AutoRepliesService } from "../../../../../services/auto-replies.service";
import { TextTd, Th } from "../../../../../components/CustomTable";

const TableAutoReplies = () => {
  const { t } = useTranslation();
  const { data: autoReplies } = useQuery(
    apiRoutes.listAutoReplies(),
    async () => {
      const { data } = await AutoRepliesService.listAutoReplies();
      return data;
    }
  );
  const { openEditModal, handleClickDelete } = useCrudAutoReplyModal();
  const { downloadWhatsappMedia } = useDownloadFile();

  function getTranslatedCondition(condition: string): string {
    const translations: {
      [key: string]: string;
    } = {
      contains: "contém",
      equals: "é igual",
    };

    return translations[condition] as any;
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Se a mensagem</Th>
            <Th maxWidth={"12rem"}>Palavra-chave</Th>
            <Th maxWidth={"12rem"}>Resposta automática</Th>
            <Th>Mídia</Th>
            <Th>Mover para categoria</Th>
            <Th>Mudar status para</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {autoReplies?.map((autoReply) => {
            return (
              <Tr key={autoReply.id}>
                <TextTd>{getTranslatedCondition(autoReply.condition)}</TextTd>
                <TextTd maxWidth={"12rem"} textForTooltip={autoReply.keyword}>{autoReply.keyword}</TextTd>
                <TextTd maxWidth={"12rem"} textForTooltip={autoReply.message}>{autoReply.message}</TextTd>
                <Td>
                  {autoReply.file && (
                    <Link
                      onClick={() =>
                        downloadWhatsappMedia({
                          mediaName: autoReply.file!.name,
                          fileKey: autoReply.file!.key,
                        })
                      }>
                      {autoReply.file.name}
                    </Link>
                  )}
                </Td>
                <TextTd>{autoReply.conversationCategory?.name}</TextTd>
                <TextTd>
                  {t(`enums.ConversationTicketStatus.${autoReply.conversationTicketStatus}`)}
                </TextTd>
                <Td
                  display="flex"
                  align="center"
                  justifyContent={"space-around"}>
                  <ButtonIcon
                    icon={<FaEdit fontSize="20px" color={colors.primary} />}
                    onClick={() => openEditModal(autoReply.id)}
                  />
                  <ButtonIcon
                    icon={<FaTrash fontSize="20px" color={colors.danger} />}
                    onClick={() => handleClickDelete(autoReply.id)}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableAutoReplies;
