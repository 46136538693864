import { AxiosResponse } from "axios";
import { apiRoutes } from "../constants/api-routes";
import { request } from "../constants/request";
import { AudienceRecommendation } from "../types/AudienceRecommendation";



const listAudienceRecommendations = async (): Promise<
  AxiosResponse<AudienceRecommendation[]>
> => {
  return request.get(apiRoutes.listAudienceRecommendations());
};

export const AudienceRecommendationsService = {
  listAudienceRecommendations,
};
