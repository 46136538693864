import { useQuery } from "react-query";
import CardStatistic, { CardStatisticProp } from "../CardStatistic";
import { request } from "../../constants/request";

interface CardStatisticContainerProps
  extends Omit<CardStatisticProp, "value" | "valueColor" | "bgIconColor"> {
  requestRoute: string;
  valueColor?: ((value: any) => string) | string;
  valueFormatter?: (value: any) => string;
  bgIconColor?: ((value: any) => string) | string;
}

const CardStatisticContainer = ({
  icon,
  requestRoute,
  title,
  valueFormatter,
  bgIconColor,
  tooltip,
  valueColor,
}: CardStatisticContainerProps) => {
  const { data, isFetching } = useQuery(
    requestRoute,
    async () => {
      const res = await request.get(requestRoute);
      return res.data;
    },
    {}
  );

  return (
    <CardStatistic
      icon={icon}
      title={title}
      value={valueFormatter ? valueFormatter(data) : data}
      isLoading={isFetching}
      bgIconColor={
        bgIconColor && typeof bgIconColor === "function"
          ? bgIconColor(data)
          : bgIconColor
      }
      tooltip={tooltip}
      valueColor={
        valueColor && typeof valueColor === "function"
          ? valueColor(data)
          : valueColor
      }
    />
  );
};

export default CardStatisticContainer;
