import { Grid, GridItem } from "@chakra-ui/react";
import { ReactNode } from "react";
import { appPaths } from "../../../constants/app-paths";
import SidebarSecondary from "../../../components/SidebarSecondary";

interface ReportsLayoutProps {
  children: ReactNode;
}

const SIDEBAR_OPTIONS = [
  {
    title: "Overview",
    path: appPaths.reports.index(),
  },
];

const ReportsLayout = ({ children }: ReportsLayoutProps) => {
  return (
    <Grid
      height={"100vh"}
      templateColumns="250px auto"
      templateAreas={'"sidebar page"'}>
      <GridItem area="sidebar">
        <SidebarSecondary title="" options={SIDEBAR_OPTIONS} />
      </GridItem>
      <GridItem area="page" maxH={"100vh"} height={"100vh"} overflow="auto">
        {children}
      </GridItem>
    </Grid>
  );
};

export default ReportsLayout;
