import {
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";
import TableFilters from "../../../../../components/TableFilters";
import { apiRoutes } from "../../../../../constants/api-routes";
import { colors } from "../../../../../constants/colors";
import { FiltersService } from "../../../../../services/filters.service";
import { ListMessageTemplateItem, MessageTemplatesService } from "../../../../../services/message-templates.service";
import { CustomerFilterUtils } from "../../../../../utils/customer-filter.utils";
import { MessageTemplateUtils } from "../../../../../utils/message-templates.utils";
import InputCheckboxGroupControlled from "../../../../../components/InputCheckboxGroupControlled";
import InputSwitchControlled from "../../../../../components/InputSwitchControlled";
import WhatsappTemplatePreview from "../../../../../components/WhatsappTemplatePreview";
import { AutomationFormValues } from "../../../../../services/automations.service";

const monthDayOptions = Array.from({ length: 31 }, (_, i) => String(i + 1)).map(
  (day) => ({
    label: day,
    value: day,
  })
);

const wdayOptions: {
  label: string;
  value: string;
}[] = [
  { label: "Segunda-feira", value: "1" },
  { label: "Terça-feira", value: "2" },
  { label: "Quarta-feira", value: "3" },
  { label: "Quinta-feira", value: "4" },
  { label: "Sexta-feira", value: "5" },
  { label: "Sábado", value: "6" },
  { label: "Domingo", value: "0" },
];

const timeOptions = [
  480, 510, 540, 570, 600, 630, 660, 690, 720, 750, 780, 810, 840, 870, 900,
  930, 960, 990, 1020, 1050, 1080, 1110, 1140, 1170, 1200, 1230, 1260,
];

const schema = yup
  .object({
    name: yup.string().required(),
    isActive: yup.boolean().required(),
    messageTemplateId: yup.string().required(),
    filterId: yup.string().required(),
    dailyMessageLimitOnWhatsapp: yup.number().required().positive().integer(),
    frequency: yup.string().oneOf(["daily", "weekly", "monthly"]),
    minutesOfTheDay: yup.string().required(),
    daysOfWeek: yup.array().when("frequency", {
      is: "weekly",
      then: yup.array().min(1).required(),
    }),
    daysOfMonth: yup.array().when("frequency", {
      is: "monthly",
      then: yup.array().min(1).required(),
    }),
    isAutomationRepetitionAllowed: yup.boolean().required(),
    minDaysSinceLastCampaign: yup
      .number()
      .transform((value, originalValue) => {
        return typeof originalValue === "string" && originalValue.trim() === ""
          ? null
          : value;
        })
      .required("Este é um campo obrigatório")
      .positive()
      .integer(),
  })
  .required();

interface AutomationFormProps {
  onSubmit: (data: any) => void;
  templateArgs: { [key: string]: string | undefined };
  setTemplateArgs: (args: { [key: string]: string | undefined }) => void;
  automation?: AutomationFormValues;
}

const AutomationForm = ({
  onSubmit,
  templateArgs,
  setTemplateArgs,
  automation,
}: AutomationFormProps) => {
  const defaultValues = {
    name: automation?.name || null,
    isActive: automation?.isActive || true as boolean,
    messageTemplateId: automation?.messageTemplateId || null,
    filterId: automation?.filterId || null,
    dailyMessageLimitOnWhatsapp: automation?.dailyMessageLimitOnWhatsapp || 0,
    frequency: automation?.frequency || "daily",
    minutesOfTheDay: automation?.minutes || null,
    daysOfWeek: automation?.daysOfWeek || [],
    daysOfMonth: automation?.daysOfMonth || [],
    isAutomationRepetitionAllowed: automation?.isAutomationRepetitionAllowed || false,
    minDaysSinceLastCampaign: automation?.minDaysSinceLastCampaign || 0,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });


  const watchMessageTemplateId = watch("messageTemplateId");
  const watchFrequency = watch("frequency");
  const watchFilterId = watch("filterId");

  const { data: filters } = useQuery(apiRoutes.listFilters(), async () => {
    const { data } = await FiltersService.listFilters();
    return data;
  });
  const { data: templates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      select(data) {
        return data.filter(
          (template: ListMessageTemplateItem) => template.status === "approved"
        );
      },
    }
  );

  function handleChangeTemplateParameter(parameter: string, value: string) {
    setTemplateArgs({
      ...templateArgs,
      [parameter]: value,
    });
  }

  const selectedTemplate = watchMessageTemplateId
    ? templates?.find((template) => template.id === watchMessageTemplateId)
    : null;
  const selectedFilter = watchFilterId
    ? filters?.find((filter) => filter.id === watchFilterId)
    : null;

  const customParameters = MessageTemplateUtils.getCustomParametersInText(
    selectedTemplate?.templateText || ""
  );

  useEffect(() => {
    if (watchFrequency !== "weekly") { setValue("daysOfWeek", []) }
    if (watchFrequency !== "monthly") { setValue("daysOfMonth", []) }
  }, [setValue, watchFrequency]);

  useEffect(() => {
    if (automation) {
      const minutesOfTheDay = automation.hours * 60 + automation.minutes;

      setValue("name", automation.name);
      setValue("isActive", automation.isActive);
      setValue("messageTemplateId", automation.messageTemplateId);
      setValue("filterId", automation.filterId);
      setValue("dailyMessageLimitOnWhatsapp", automation.dailyMessageLimitOnWhatsapp);
      setValue("frequency", automation.frequency);
      setValue("minutesOfTheDay", minutesOfTheDay);
      setValue("daysOfWeek", automation.daysOfWeek);
      setValue("daysOfMonth", automation.daysOfMonth);
      setValue("isAutomationRepetitionAllowed", automation.isAutomationRepetitionAllowed);
      setValue("minDaysSinceLastCampaign", automation.minDaysSinceLastCampaign);
    }
  }, [automation, setValue]);

  return(
    <Grid
      height="auto"
      templateColumns="2fr 1fr"
      paddingTop="50px"
      paddingBottom="50px"
      paddingX="100px"
      alignItems="start"
      gap={4}>
      <GridItem>
        <Heading size="md" mb={5}>
          Nova automação
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <FormControl isRequired>
              <FormLabel>Nome da automação</FormLabel>
              <Input placeholder="Nome" {...register("name")} />
              <Text color={colors.danger} fontSize="xs">
                {errors.name?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Template para envio</FormLabel>
              <Select
                placeholder="Selecione um template"
                {...register("messageTemplateId")}>
                {templates?.map((template) => (
                  <option
                    key={template.id}
                    value={template.id}
                    title={template.templateText}>
                    {template.name}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.messageTemplateId?.message}
              </Text>
            </FormControl>
            {selectedTemplate && (
              <Container paddingX={"50px"}>
                {customParameters.map((param) => (
                  <FormControl isRequired key={param}>
                    <FormLabel fontSize="sm">
                      {param.replaceAll("[", "").replaceAll("]", "")}
                    </FormLabel>
                    <Input
                      size="sm"
                      onChange={(e) =>
                        handleChangeTemplateParameter(param, e.target.value)
                      }
                      value={templateArgs[param] || ""}
                      required
                    />
                  </FormControl>
                ))}
              </Container>
            )}
            <FormControl isRequired>
              <FormLabel>Filtro a ser utilizado</FormLabel>
              <Select
                placeholder="Selecione um filtro"
                {...register("filterId")}>
                {filters?.map((filter) => (
                  <option
                    key={filter.id}
                    value={filter.id}
                    title={CustomerFilterUtils.parseFilterCriteria(filter.criteria)
                      .map((criteria) => `${criteria.title}: ${criteria.value}`)
                      .join("\n")}>
                    {filter.name} -{" "}
                    {CustomerFilterUtils.parseFilterCriteria(filter.criteria)
                      .map((criteria) => `${criteria.title}: ${criteria.value}`)
                      .join(", ")}
                  </option>
                ))}
              </Select>
              <Text color={colors.danger} fontSize="xs">
                {errors.filterId?.message}
              </Text>
              {selectedFilter && (
                <TableFilters
                  filterCriteria={selectedFilter.criteria || null}
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Frequência de envio</FormLabel>
              <Stack spacing={4}>
                <Select
                  placeholder="Selecione a frequência"
                  {...register("frequency")}>
                  <option value="daily">Diário</option>
                  <option value="weekly">Semanal</option>
                  <option value="monthly">Mensal</option>
                </Select>
                {watchFrequency === "weekly" && (
                  <Flex
                    flexDir={"column"}
                    flexWrap={"wrap"}
                    gap={2}
                    height="100px">
                    <InputCheckboxGroupControlled
                      options={wdayOptions}
                      name="daysOfWeek"
                      control={control}
                    />
                  </Flex>
                )}
                {watchFrequency === "monthly" && (
                  <Flex
                    flexDir={"row"}
                    flexWrap={"wrap"}
                    rowGap={"20px"}
                    columnGap={"50px"}>
                    <InputCheckboxGroupControlled
                      options={monthDayOptions}
                      name="daysOfMonth"
                      control={control}
                      itemWidth="20px"
                    />
                  </Flex>
                )}
                {!!watchFrequency && (
                  <Select
                    placeholder="Selecione a hora"
                    {...register("minutesOfTheDay")}>
                    {timeOptions.map((minutesOfTheDay) => {
                      const hour = Math.floor(minutesOfTheDay / 60);
                      const minutes = minutesOfTheDay % 60;
                      return (
                        <option key={minutesOfTheDay} value={minutesOfTheDay}>
                          {`${String(hour).padStart(2, "0")}:${String(
                            minutes
                          ).padStart(2, "0")}`}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </Stack>
              <Text color={colors.danger} fontSize="xs">
                {errors.minutesOfTheDay?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Limite diário de envio</FormLabel>
              <Input
                type="number"
                placeholder="Limite diário"
                {...register("dailyMessageLimitOnWhatsapp")}
              />
              <Text color={colors.danger} fontSize="xs">
                {errors.dailyMessageLimitOnWhatsapp?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Ocultar clientes que receberam uma campanhas nos últimos</FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="Dias"
                    {...register("minDaysSinceLastCampaign")}
                  />
                  <InputRightAddon>dias</InputRightAddon>
                </InputGroup>
              <Text color={colors.danger} fontSize="xs">
                {errors.minDaysSinceLastCampaign?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Cliente pode receber mais de uma vez a mesma automação</FormLabel>
              <InputSwitchControlled name="isAutomationRepetitionAllowed" control={control} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isAutomationRepetitionAllowed?.message}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel>Ativo?</FormLabel>
              <InputSwitchControlled name="isActive" control={control} />
              <Text color={colors.danger} fontSize="xs">
                {errors.isActive?.message}
              </Text>
            </FormControl>
            <Divider />
            <Flex justify={"flex-end"}>
              <Button
                width="30%"
                isLoading={false}
                color={colors.white}
                bgColor={colors.primary}
                type="submit">
                Salvar
              </Button>
            </Flex>
          </Stack>
        </form>
      </GridItem>
      <GridItem position={"sticky"} top={0} alignSelf="start">
        {selectedTemplate && (
          <WhatsappTemplatePreview
            message={selectedTemplate.templateText}
            footer={selectedTemplate.footerText}
            buttons={selectedTemplate.messageTemplateButtons}
            fileUrl={selectedTemplate.mediaUrl}
          />
        )}
      </GridItem>
    </Grid>
  )
};

export default AutomationForm;
