import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { ReactNode, useRef } from "react";

interface AlertDialogBaseProps {
  onConfirm: () => void;
  title: string;
  children: ReactNode;
  buttonConfirmColor?: "red" | "green" | "blue";
  isOpen: boolean;
  onClose: () => void;
  onReject?: () => void;
  buttonConfirmText?: string;
  buttonRejectText?: string;
  isConfirmLoading?: boolean;
}

const AlertDialogBase = ({
  onConfirm,
  children,
  buttonRejectText = "Cancelar",
  buttonConfirmColor = "red",
  title,
  isOpen,
  buttonConfirmText = "Confirmar",
  onClose,
  onReject,
  isConfirmLoading,
}: AlertDialogBaseProps) => {
  const cancelRef = useRef<any>();

  function handleClickReject() {
    if (onReject) {
      onReject();
    }
    onClose();
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={handleClickReject}>
              {buttonRejectText}
            </Button>
            <Button
              colorScheme={buttonConfirmColor}
              onClick={onConfirm}
              ml={3}
              isLoading={isConfirmLoading}>
              {buttonConfirmText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogBase;
