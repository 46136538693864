export enum CustomerFiltersEnum {
  SEARCH_QUERY = 'searchQuery',
  SELECTED_ENGAGEMENT_ACTION_TYPES = 'selectedEngagementActionTypes',
  MAX_TOTAL_PURCHASES = 'maxTotalPurchases',
  MIN_TOTAL_PURCHASES = 'minTotalPurchases',
  MIN_TOTAL_ORDERS = 'minTotalOrders',
  MAX_TOTAL_ORDERS = 'maxTotalOrders',
  SELECTED_ENGAGEMENT_TEMPLATE_IDS = 'selectedEngagementTemplateIds',
  MIN_AVERAGE_ORDER_VALUE = 'minAverageOrderValue',
  MAX_AVERAGE_ORDER_VALUE = 'maxAverageOrderValue',
  START_ORDERS_CREATED_AT = 'startOrdersCreatedAt',
  END_ORDERS_CREATED_AT = 'endOrdersCreatedAt',
  MIN_DAYS_SINCE_LAST_CAMPAIGN = 'minDaysSinceLastCampaign',
  MIN_AVERAGE_ITEM_VALUE = 'minAverageItemValue',
  MAX_AVERAGE_ITEM_VALUE = 'maxAverageItemValue',
  SELECTED_TAGS = 'selectedTags',
  MIN_DAYS_SINCE_LAST_PURCHASE = 'minDaysSinceLastPurchase',
  MAX_DAYS_SINCE_LAST_PURCHASE = 'maxDaysSinceLastPurchase',
  EXCLUDED_TAGS = 'excludedTags',
  IS_REMARKETING = 'isRemarketing',
  EXCLUDED_TEMPLATE_IDS = 'excludedTemplateIds',
  SELECTED_PRODUCT_IDS = 'selectedProductIds',
  EXCLUDED_PRODUCT_IDS = 'excludedProductIds',
  MIN_PRODUCT_QUANTITY = 'minProductQuantity',
  MAX_PRODUCT_QUANTITY = 'maxProductQuantity',
  MIN_DAYS_SINCE_LAST_PRODUCT_PURCHASE = 'minDaysSinceLastProductPurchase',
  MAX_DAYS_SINCE_LAST_PRODUCT_PURCHASE = 'maxDaysSinceLastProductPurchase',
  PRODUCT_NAME_CONTAINS = 'productNameContains',
  IS_LAST_PRODUCT_PURCHASED = 'isLastProductPurchased',
  SELECTED_STATES = 'selectedStates',

  CUSTOM_FIELD_ID_1 = 'customFieldId1',
  CUSTOM_FIELD_VALUE_1 = 'customFieldValue1',

  // hidden filters
  IS_SCHEDULED_CAMPAIGNS_VISIBLE = 'isScheduledCampaignsVisible',
  RFM_GROUP = 'rfmGroup',
  PLATFORM_ORDER_SOURCE = 'platformOrderSource',

  // extra options
  SORT_BY = 'sortBy',
}
