import React, { useState } from "react";
import { InputSelectProps, SelectOption } from "../InputSelect";
import AsyncSelect from "react-select/async";

interface InputSelectAsyncProps
  extends Omit<
    InputSelectProps,
    "options" | "defaultValue" | "onCreateOptions"
  > {
  defaultOptions: SelectOption[];
  cacheOptions: boolean;
  loadOptions: (inputValue: string) => Promise<SelectOption[]>;
}

const InputSelectAsync = ({
  defaultOptions,
  isMulti,
  cacheOptions,
  loadOptions,
  value,
  onChange,
  disabled,
  placeholder,
  onMenuClose,
}: InputSelectAsyncProps) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  async function loadOptionsDebounced(inputValue: string) {
    if (timer) {
      clearTimeout(timer);
    }

    const DEBOUNCE_TIME = 500;
    return new Promise<SelectOption[]>((resolve) => {
      setTimer(
        setTimeout(
          () =>
            loadOptions(inputValue).then((options) => {
              resolve(options);
            }),
          DEBOUNCE_TIME
        )
      );
    });
    
  }


  return React.createElement(AsyncSelect, {
    className: "sectionTest",
    styles: {
      control: (styles) => ({
        ...styles,
        minHeight: "36px",
        fontSize: "0.875rem", // chakra-sm
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    },
    menuPortalTarget: document.body,
    placeholder,
    isDisabled: disabled,
    defaultOptions,
    isMulti,
    cacheOptions,
    loadOptions: loadOptionsDebounced,
    value,
    onChange,
    closeMenuOnSelect: !isMulti,
    menuPosition: "fixed",
    isClearable: true,
    onMenuClose,
  });
};

export default InputSelectAsync;
