import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FocusLock,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaEdit, FaMagic, FaTrash } from "react-icons/fa";
import ButtonIcon from "../../../../components/ButtonIcon";
import { colors } from "../../../../constants/colors";
import { ButtonType } from "../../../../types/ButtonTypeEnum";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { ImBold } from "react-icons/im";
import CustomEmojiPicker from "../../../../components/CustomEmojiPicker";
import { BsPlus } from "react-icons/bs";
import { GupshupTemplateType } from "../../../../types/GupshupTemplateType";
import RegenerateMessageTemplateModal from "./RegenerateMessageTemplateModal";
import GenerateMessageTemplateModal from "./GenerateMessageTemplateModal";
import { CARD_BODY_MAX_LENGTH } from "..";
import FileInputItem from "../../../../components/FileInputItem/FileInputItem";
import { MessageTemplateButton } from "../../../../types/MessageTemplateButton";

interface CardsProps {
  title: string;
  body: string;
  buttons: MessageTemplateButton[];
}

interface CarouselFormSectionProps {
  cardsField: Record<"id", any>[];
  files: File[];
  appendCard: any;
  cards: CardsProps[];
  templateParametersList: string[];
  fileError: boolean;
  errors: any;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  handleChangeFile: (index: number, event: any) => void;
  handleRemoveSelectedFile: (index: number) => void;
  getAcceptedMessageTemplateFileTypes: (
    templateMediaType: GupshupTemplateType
  ) => string;
  onFileRemove: (index: number) => void;
}

const CarouselFormSection = ({
  errors,
  cardsField,
  cards,
  fileError,
  files,
  appendCard,
  templateParametersList,
  register,
  setValue,
  handleChangeFile,
  handleRemoveSelectedFile,
  getAcceptedMessageTemplateFileTypes,
  onFileRemove,
}: CarouselFormSectionProps) => {
  const toast = useToast();
  const [mediaType, setMediaType] = useState<GupshupTemplateType>(
    GupshupTemplateType.IMAGE
  );
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [promptThreadId, setPromptThreadId] = useState<Record<number, string>>(
    {}
  );
  const [buttonType, setButtonType] = useState<Record<number, ButtonType>>({});
  const newParameterInputRef = useRef<any>(null);
  const {
    isOpen: isOpenNewParameter,
    onOpen: onOpenNewParameter,
    onClose: onCloseNewParameter,
  } = useDisclosure();
  const {
    isOpen: isOpenGenerateMessageTemplateModal,
    onOpen: onOpenGenerateMessageTemplateModal,
    onClose: onCloseGenerateMessageTemplateModal,
  } = useDisclosure();
  const {
    isOpen: isOpenRegenerateMessageTemplateModal,
    onOpen: onOpenRegenerateMessageTemplateModal,
    onClose: onCloseRegenerateMessageTemplateModal,
  } = useDisclosure();

  useEffect(() => { 
    if (cards?.length) {
      const cardsButtonType: Record<number, ButtonType> = {};
      cards.forEach((card, index) => {
        if (!card.buttons?.length) return;
        cardsButtonType[index] = card.buttons[0].type as ButtonType
      })
      setButtonType(cardsButtonType);
    }
  // Este useEffect deve rodar apenas na primeira renderização, não atualize as dependências
  }, [])

  function handleAddNewCard(newCard: any) {
    const cardsLength = cards.length;
    if (cardsLength > 0) {
      if (files.length < cardsLength) {
        toast({
          title:
            "É necessário adicionar uma imagem/vídeo antes de adicionar um novo card",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (
        !cards[0].buttons ||
        cards[0].buttons?.length === 0 ||
        cards[0].buttons.length !== cards[cardsLength - 1].buttons.length
      ) {
        toast({
          title:
            "É necessário adicionar a mesma quantidade de botões para todos os cards",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    appendCard(newCard);
    setTimeout(() => {
      handleSetCurrentTabIndex(cardsLength);
    }, 100);
  }

  function handleCloseNewParameter() {
    newParameterInputRef.current!.value = null;
    onCloseNewParameter();
  }

  async function handleClickBold(index: number) {
    const textarea = document.querySelector(
      `textarea[name="cards.${index}.body"]`
    ) as HTMLTextAreaElement;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      "*" +
      currentValue.substring(startPosition, endPosition) +
      "*" +
      currentValue.substring(endPosition);

    setValue(`cards.${index}.body`, newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + 1,
      startPosition + 1 + (endPosition - startPosition)
    );
  }

  function handleClickParameter(index: number, parameter: any) {
    const textarea = document.querySelector(
      `textarea[name="cards.${index}.body"]`
    ) as HTMLTextAreaElement;
    const startPosition = textarea.selectionStart;
    const endPosition = textarea.selectionEnd;
    const currentValue = textarea.value;

    const newValue =
      currentValue.substring(0, startPosition) +
      parameter +
      currentValue.substring(endPosition);

    setValue(`cards.${index}.body`, newValue);
    textarea.focus();
    textarea.setSelectionRange(
      startPosition + parameter.length + 1,
      startPosition + parameter.length + 1
    );
  }
  function handleAddNewParameter(index: number) {
    let newParameter = newParameterInputRef.current?.value;
    newParameter = `[${newParameter.replace(/(\[|\])/g, "")}]`;

    if (!newParameter) {
      return;
    }
    if (
      newParameter.normalize("NFD").replace(/[\u0300-\u036f]/g, "") !==
      newParameter
    ) {
      toast({
        title: "O parâmetro não pode conter acentos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (templateParametersList.includes(newParameter)) {
      toast({
        title: "Esse nome de parâmetro já existe",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    handleClickParameter(index, newParameter);
    handleCloseNewParameter();
  }

  function handleRemoveCard(index: number) {
    let updatedCards = [...cards];
    updatedCards.splice(index, 1);

    setValue(`cards`, updatedCards);
    handleSetCurrentTabIndex();
    handleRemoveSelectedFile(index);
  }

  function removeButtonText(index: number, btnIndex?: number) {
    let updatedButtons: any[] = [];
    if (btnIndex != null) {
      updatedButtons = [...cards[index].buttons];
      updatedButtons.splice(btnIndex, 1);
    }

    setValue(`cards.${index}.buttons`, updatedButtons);
  }

  function handleSetCurrentTabIndex(value?: number) {
    let newValue = value;
    if (!newValue) {
      newValue =
        currentTabIndex >= cards.length && currentTabIndex !== 0
          ? cards.length - 1
          : 0;
    }
    setCurrentTabIndex(newValue);
  }

  function appendButtonText(index: number, newButton: any) {
    const currentButtons = cards[index].buttons || [];
    setValue(`cards.${index}.buttons`, [...currentButtons, newButton]);
  }
  function handleGenerateTemplate(
    content: string,
    promptThreadIdValue: string
  ) {
    setValue(`cards.${currentTabIndex}.body`, content);
    setPromptThreadId({ [currentTabIndex]: promptThreadIdValue });
  }

  function handleRegenerateTemplate(content: string) {
    setValue(`cards.${currentTabIndex}.body`, content);
  }

  function getRemainCharactersForCardCount(index: number) {
    return CARD_BODY_MAX_LENGTH - (cards[index].body?.length || 0);
  }

  function getCardsTabs() {
    const tabs = [];
    const panels = [];

    for (let index = 0; index < cardsField.length; index++) {
      tabs.push(
        <Tab>
          <>
            Card {index + 1}
            <ButtonIcon
              icon={<FaTrash fontSize="18px" color={colors.danger} />}
              onClick={() => handleRemoveCard(index)}
            />
          </>
        </Tab>
      );
      panels.push(
        <TabPanel>
          <Stack spacing={5}>
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>Cabeçalho</FormLabel>
                  <Select
                    disabled={files.length > 0}
                    value={mediaType}
                    onChange={(event) => {
                      const selectedMediaType = event.target
                        .value as GupshupTemplateType;
                      setMediaType(selectedMediaType);
                    }}
                  >
                    {[
                      {
                        id: GupshupTemplateType.IMAGE,
                        name: "Imagem",
                      },
                      {
                        id: GupshupTemplateType.VIDEO,
                        name: "Vídeo",
                      },
                    ].map((templateType) => (
                      <option key={templateType.id} value={templateType.id}>
                        {templateType.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Arquivo</FormLabel>
                  <input
                    type="file"
                    onChange={(event) => handleChangeFile(index, event)}
                    accept={getAcceptedMessageTemplateFileTypes(mediaType)}
                  />
                  {fileError && (
                    <Text color={colors.danger} fontSize="xs">
                      {"Arquivo é obrigatório"}
                    </Text>
                  )}

                  {files?.[index] && (
                    <FileInputItem file={files[index]} onRemove={() => onFileRemove(index)} />
                  )}
                </FormControl>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <FormControl>
                  <Flex alignItems={"center"} justifyContent="space-between">
                    <FormLabel>Corpo do Card</FormLabel>
                  </Flex>
                  <Box>
                    <Textarea
                      isDisabled={!!promptThreadId[index]}
                      maxLength={CARD_BODY_MAX_LENGTH}
                      placeholder="Escreva o texto da mensagem"
                      {...register(`cards.${index}.body`)}
                      isInvalid={!!errors?.cards?.[index]?.body}
                    />
                    <Flex justifyContent={'space-between'}>
                      <Text color={colors.danger} fontSize="xs">
                        {errors?.cards?.[index]?.body?.message}
                      </Text>
                      <Text fontSize="xs" color="gray.500" textAlign="right">
                        {cards[index].body?.length || 0}/{CARD_BODY_MAX_LENGTH} caracteres
                      </Text>
                    </Flex>

                    <Flex
                      justifyContent={"space-between"}
                      width="100%"
                      mt={1}
                      position={"relative"}
                    >
                      {!!promptThreadId[index] ? (
                        <Flex gap={3}>
                          <Button
                            onClick={() =>
                              setPromptThreadId({
                                ...promptThreadId,
                                [index]: "",
                              })
                            }
                            leftIcon={<FaEdit />}
                          >
                            Editar
                          </Button>
                          <Button
                            onClick={onOpenRegenerateMessageTemplateModal}
                            leftIcon={<FaArrowRotateLeft />}
                          >
                            Regenerar
                          </Button>
                        </Flex>
                      ) : (
                        <Button
                          onClick={onOpenGenerateMessageTemplateModal}
                          leftIcon={<FaMagic />}
                        >
                          Gerador da Revi
                        </Button>
                      )}
                      <Box>
                        <ButtonIcon
                          icon={<ImBold />}
                          onClick={() => handleClickBold(index)}
                        />
                        <CustomEmojiPicker
                          onEmojiSelection={(value) =>
                            handleClickParameter(index, value)
                          }
                        />
                      </Box>
                    </Flex>
                  </Box>
                </FormControl>
                <Box mt={3}>
                  <Text>Adicionar parâmetro</Text>
                  <Flex
                    gap={2}
                    alignItems="center"
                    flexWrap={"wrap"}
                    maxW="100%"
                  >
                    {templateParametersList.map((parameter) => (
                      <Button
                        key={parameter}
                        fontSize="xs"
                        color="gray.500"
                        mt={2}
                        mb={2}
                        variant="outline"
                        onClick={() =>
                          getRemainCharactersForCardCount(index) >=
                          parameter.length
                            ? handleClickParameter(index, parameter)
                            : null
                        }
                      >
                        {parameter}
                      </Button>
                    ))}
                    <Popover
                      isOpen={isOpenNewParameter}
                      onOpen={onOpenNewParameter}
                      onClose={handleCloseNewParameter}
                      initialFocusRef={newParameterInputRef}
                      placement="right"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Novo parâmetro"
                          icon={<BsPlus />}
                        />
                      </PopoverTrigger>
                      <PopoverContent p={5}>
                        <FocusLock persistentFocus={false}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <Stack spacing={4}>
                            <FormControl>
                              <FormLabel>Nome do parâmetro</FormLabel>
                              <Input
                                ref={newParameterInputRef}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleAddNewParameter(index);
                                  }
                                }}
                                placeholder="Ex: codigo do cupom, vencimento"
                              />
                            </FormControl>
                            <ButtonGroup
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="outline"
                                onClick={handleCloseNewParameter}
                              >
                                Cancelar
                              </Button>
                              <Button
                                bgColor={colors.lightGrey}
                                onClick={() => handleAddNewParameter(index)}
                              >
                                Adicionar
                              </Button>
                            </ButtonGroup>
                          </Stack>
                        </FocusLock>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                </Box>
              </CardBody>
            </Card>
            <Card>
              <CardBody display={"flex"} flexDir="column" gap={4}>
                <FormControl>
                  <FormLabel>Botões & Links</FormLabel>
                  <Select
                    placeholder="Nenhum"
                    onChange={(event) => {
                      const selectedButtonType = event.target
                        .value as ButtonType;
                      setButtonType({
                        ...buttonType,
                        [index]: selectedButtonType,
                      });
                      if (!!selectedButtonType) {
                        removeButtonText(index);
                        appendButtonText(index, {
                          type: selectedButtonType,
                          text: "",
                        });
                      }
                    }}
                    value={ButtonType[buttonType[index]]}
                  >
                    {[
                      {
                        id: ButtonType.QUICK_REPLY,
                        name: "Resposta rápida",
                      },
                      {
                        id: ButtonType.URL,
                        name: "Abrir URL",
                      },
                    ].map((templateType) => (
                      <option key={templateType.id} value={templateType.id}>
                        {templateType.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {buttonType[index] === ButtonType.QUICK_REPLY &&
                cards[index].buttons != null ? (
                  cards[index].buttons.map((field, btnIndex) => (
                    <Flex display={"flex"} alignItems={"center"}>
                      <FormControl
                        w="500px"
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <FormLabel width={"80px"}>
                          Botão {btnIndex + 1}
                        </FormLabel>
                        <Input
                          key={field.id}
                          placeholder="Título do botão"
                          {...register(
                            `cards.${index}.buttons.${btnIndex}.text`
                          )}
                        />
                      </FormControl>
                      {cards[index].buttons.length > 0 && (
                        <ButtonIcon
                          icon={
                            <FaTrash fontSize="20px" color={colors.danger} />
                          }
                          onClick={() => removeButtonText(index, btnIndex)}
                        />
                      )}
                    </Flex>
                  ))
                ) : buttonType[index] === ButtonType.URL ? (
                  <Flex gap={2}>
                    <FormControl w="250px">
                      <FormLabel>Botão</FormLabel>
                      <Input
                        placeholder="Título do botão"
                        {...register(`cards.${index}.buttons.0.text`)}
                        isInvalid={!!errors?.cards?.[index]?.buttons?.[0]?.text}
                      />
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel>URL</FormLabel>
                      <Input
                        placeholder="URL do botão"
                        {...register(`cards.${index}.buttons.0.url`)}
                        isInvalid={!!errors?.cards?.[index]?.buttons?.[0]?.url}
                      />
                      <Text color={colors.danger} fontSize="xs">
                        {errors?.cards?.[index]?.buttons?.[0]?.url?.message}
                      </Text>
                    </FormControl>
                  </Flex>
                ) : null}
                {buttonType[index] === ButtonType.QUICK_REPLY &&
                  cards[index].buttons?.length < 2 && (
                    <Button
                      onClick={() => {
                        appendButtonText(index, {
                          type: buttonType[index],
                          text: "",
                        });
                      }}
                      width={"fit-content"}
                    >
                      + Adicionar botão
                    </Button>
                  )}
              </CardBody>
            </Card>
          </Stack>
        </TabPanel>
      );
    }
    return (
        <Tabs
          onChange={handleSetCurrentTabIndex}
          variant="enclosed"
          isManual
          index={currentTabIndex}
        >
          <TabList>{tabs}</TabList>
          <TabPanels>{panels}</TabPanels>
        </Tabs>
    );
  }
  return (
    <Card>
      <CardBody display={"flex"} flexDir="column" gap={4}>
        <FormControl>
          <FormLabel>Cards do Carossel</FormLabel>
        </FormControl>
        {cardsField && cardsField.length < 10 && (
          <Button
            onClick={() => {
              handleAddNewCard({
                body: "",
                buttons: [],
              });
            }}
            width={"fit-content"}
          >
            + Adicionar Novo Card
          </Button>
        )}
        {getCardsTabs()}
      </CardBody>

      <GenerateMessageTemplateModal
        isOpen={isOpenGenerateMessageTemplateModal}
        onClose={onCloseGenerateMessageTemplateModal}
        onGenerateMessageTemplate={handleGenerateTemplate}
      />
      <RegenerateMessageTemplateModal
        isOpen={isOpenRegenerateMessageTemplateModal}
        onClose={onCloseRegenerateMessageTemplateModal}
        onRegenerateMessageTemplate={handleRegenerateTemplate}
        promptThreadId={promptThreadId[currentTabIndex]}
      />
    </Card>
  );
};

export default CarouselFormSection;
