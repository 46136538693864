import { useTranslation } from "react-i18next";
import { NodeProps } from "reactflow";
import { SaveCustomerResponseNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";
import { colors } from "../../../../../../constants/colors";
import { Box } from "@chakra-ui/react";
import { CompaniesService } from "../../../../../../services/companies.service";
import { CompanyDefinedFieldTableEnum } from "../../../../../../types/CompanyDefinedField";
import { useQuery } from "react-query";
import { apiRoutes } from "../../../../../../constants/api-routes";
import { TbMessageCirclePlus } from "react-icons/tb";

const SaveCustomerResponseNode = (
  props: NodeProps<SaveCustomerResponseNodeData>
) => {
  const { t } = useTranslation();
  const { data: companyDefinedFields } = useQuery(
    apiRoutes.listCompanyDefinedFields(
      CompanyDefinedFieldTableEnum.CUSTOMERS
    ),
    async () => {
      const { data } = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS
      );
      return data;
    },
    {
      staleTime: 1000 * 15,
    }
  );

  const companyDefinedField = companyDefinedFields?.find(
    (field) => field.id === props.data.companyDefinedFieldId
  );

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<TbMessageCirclePlus />}
      color={colors.primaryLight}
    >
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content">
        {companyDefinedField?.name || ""}
      </Box>
    </NodeLayout>
  );
};

export default SaveCustomerResponseNode;
