export enum TemplateParametersEnum {
  CUSTOMER_NAME = '[nome do consumidor]',
  COMPANY_NAME = '[nome da empresa]',
  REPRESENTATIVE_NAME = '[nome do atendente]',
  CUSTOM_TEXT = '[texto personalizado]',
  TRACKING_CODE = '[codigo de rastreio]',
  ABANDONED_CART_PRODUCTS = '[produtos do carrinho abandonado]',

  // CTA_LINK = '[cta link]',
}

export const TemplateParametersList = Object.values(TemplateParametersEnum);