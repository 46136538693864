import {
  Table,
  TableContainer,
  Tbody,
  Td, Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { apiRoutes } from "../../../../../constants/api-routes";
import { CompaniesService } from "../../../../../services/companies.service";
import {
  CompanyDefinedFieldDataTypeEnum,
  CompanyDefinedFieldTableEnum
} from "../../../../../types/CompanyDefinedField";

const TableColumnSettings = () => {
  const { data: companyDefinedFields } = useQuery(
    apiRoutes.listCompanyDefinedFields(CompanyDefinedFieldTableEnum.CUSTOMERS),
    async () => {
      const { data } = await CompaniesService.listCompanyDefinedFields(
        CompanyDefinedFieldTableEnum.CUSTOMERS
      );
      return data;
    }
  );

  function getTranslatedDataType(
    dataType: CompanyDefinedFieldDataTypeEnum
  ): string {
    const translations: Record<CompanyDefinedFieldDataTypeEnum, string> = {
      string: "TEXTO",
      number: "NÚMERO",
      boolean: "BOOLEANO",
      date: "DATA",
    };

    return translations[dataType] as any;
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nome do campo</Th>
            <Th>Tipo</Th>
          </Tr>
        </Thead>
        <Tbody>
          {companyDefinedFields?.map((companyDefinedField) => {
            return (
              <Tr key={companyDefinedField.id}>
                <Td fontWeight={"bold"}>{companyDefinedField.name}</Td>
                <Td>{getTranslatedDataType(companyDefinedField.dataType)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableColumnSettings;
