import React, { useEffect, useState } from "react";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

interface CarouselProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  itemWidthInPx: number;
  isScrollable?: boolean;
}

const Carousel = <T,>({
  items,
  renderItem,
  itemWidthInPx,
  isScrollable = false,
}: CarouselProps<T>) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemWidth = `${itemWidthInPx}px`;

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % items.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
  };

  useEffect(() => {
    const newIndex = items.length - 1;
    if (newIndex >= 0) {
      setCurrentIndex(newIndex);
    }
  }, [items.length]);

  return (
    <Flex
      direction="column"
      marginTop="10px"
      width={isScrollable ? `${itemWidthInPx + 30}px` : itemWidthInPx}
      overflow='auto'
    >
      <Flex
        position="relative"
        width={itemWidth}
        height="auto"
        overflow={isScrollable ? 'overflow-x' : "hidden"}>
        <Flex
          transform={
            isScrollable
              ? undefined
              : `translateX(${-currentIndex * itemWidthInPx}px)`
          }
          transition={isScrollable ? undefined : "transform 0.3s ease-in-out"}
          width={`${items.length * 100}%`}>
          {items.map((item, index) => (
            <Box key={index} flex="0 0 auto" width={itemWidth}>
              {renderItem(item, index)}
            </Box>
          ))}
        </Flex>
      </Flex>

      {isScrollable ? null : (
        <Flex gap={2} marginTop="10px" width='100%' justifyContent={'center'}>
          <IconButton
            aria-label="Previous"
            icon={<ArrowBackIcon />}
            onClick={handlePrev}
            isDisabled={items.length <= 1}
          />
          <IconButton
            aria-label="Next"
            icon={<ArrowForwardIcon />}
            onClick={handleNext}
            isDisabled={items.length <= 1}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Carousel;
