import { UseToastOptions } from '@chakra-ui/react';
import { createSlice } from '@reduxjs/toolkit';

interface GlobalState {
  showToast: boolean;
  toastOptions: UseToastOptions | null;
}

const initialState: GlobalState = {
  showToast: false,
  toastOptions: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.showToast = true;
      state.toastOptions = action.payload;
    },
    hideToast: (state) => {
      state.showToast = false;
      state.toastOptions = null;
    },
  },
});

export const { showToast, hideToast } = globalSlice.actions;
export default globalSlice.reducer;
