import { QuestionIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  Tooltip,
} from "@chakra-ui/react";
import { ReactNode } from "react";

interface FormLabelProps extends ChakraFormLabelProps {
  children?: ReactNode;
  tooltip?: string;
}
const FormLabel = ({
  children,
  tooltip,
  size,
  marginBottom,
  ...rest
}: FormLabelProps) => {
  return (
    <ChakraFormLabel {...rest}>
      <Flex align="center" justifyContent={"space-between"} fontSize={size}>
        {children}
        {tooltip && (
          <Tooltip label={tooltip}>
            <QuestionIcon boxSize={3} />
          </Tooltip>
        )}
      </Flex>
    </ChakraFormLabel>
  );
};

export default FormLabel;
