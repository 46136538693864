import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import { colors } from "../../../constants/colors";
import { useCrudAutoSortingOptionModal } from "../../../hooks/useCrudAutoSortingModal";
import TableAutoSortings from "./components/TableAutoSortings";

const AutomaticSortingPage = () => {
  const { openCreateModal } = useCrudAutoSortingOptionModal();

  return (
    <Box padding="20px">
      <Flex width="100%" justifyContent="flex-end">
        <Button
          variant='primary'
          onClick={openCreateModal}>
          + criar opção de atendimento
        </Button>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableAutoSortings />
    </Box>
  );
};

export default AutomaticSortingPage;
