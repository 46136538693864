import { CopyIcon } from "@chakra-ui/icons";
import { Box, Divider, Link, Flex } from "@chakra-ui/react";
import { colors } from "../../constants/colors";

function OfferCodeButtonPreview() {
  return (
    <Box bgColor={"#FFFFFF"} borderBottomRadius="5px">
      <Divider />
      <Flex
        flexDir="column"
        justifyContent={"center"}
        alignItems="center"
        padding={2}
      >
        <Link color={colors.hyperlink}>
          <CopyIcon mx="3px" color={colors.hyperlink} />Copiar código da oferta
        </Link>
      </Flex>
    </Box>
  );
}
export default OfferCodeButtonPreview;
