import { Box } from "@chakra-ui/react";
import React from "react";
import WhatsappMessageItem, {
  WhatsappMessageItemProps,
} from "../WhatsappMessageItem";

interface WhatsappTemplatePreviewProps extends WhatsappMessageItemProps {
  height?: string;
}

const WhatsappTemplatePreview = ({
  message,
  file,
  buttons,
  footer,
  fileUrl,
  height = "100vh",
  messageTemplateCards,
  limitedOfferText,
  limitedOfferExpirationDate,
}: WhatsappTemplatePreviewProps) => {
  return (
    <Box
      height={height}
      minWidth="361px"
      backgroundImage={"/template-preview-bg.png"}
      padding={3}
      overflowX="visible"
      overflowY="scroll"
    >
      <WhatsappMessageItem
        message={message}
        limitedOfferText={limitedOfferText}
        limitedOfferExpirationDate={limitedOfferExpirationDate}
        file={file}
        buttons={buttons}
        footer={footer}
        fileUrl={fileUrl}
        messageTemplateCards={messageTemplateCards}
      />
    </Box>
  );
};

export default WhatsappTemplatePreview;
