import { Card, CardBody, CardHeader, Input, Radio, RadioGroup, Stack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { DeliveryType } from ".";
import { hoverCardStyles } from "../../styles/hover.styles";
import { CommunicationChannelEnum } from "../../types/CommunicationChannelEnum";
import { MessageTemplate } from "../../types/MessageTemplate";

interface TemplateSelectionProps {
  filteredTemplates: MessageTemplate[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  handleSelectTemplate: (template: MessageTemplate) => void;
  deliveryType: DeliveryType | null;
}

export function TemplateSelection({
  deliveryType,
  filteredTemplates,
  searchQuery,
  setSearchQuery,
  handleSelectTemplate,
}: TemplateSelectionProps) {
  const [communicationChannel, setCommunicationChannel] = useState<CommunicationChannelEnum>(CommunicationChannelEnum.WHATSAPP);
  
  return (
    <VStack>
      <RadioGroup
        value={communicationChannel}
        onChange={(value: any) => setCommunicationChannel(value)}
      >
        {deliveryType === 'campaign' && (
          <Stack direction='row'>
            <Radio value={CommunicationChannelEnum.WHATSAPP}>WhatsApp</Radio>
            <Radio value={CommunicationChannelEnum.SMS}>SMS</Radio>
          </Stack>
        )}
      </RadioGroup>
      <Input
        placeholder="Pesquisar por nome"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />
      {filteredTemplates.filter(el => el.communicationChannel === communicationChannel).map((template) => (
        <Card
          width={"100%"}
          key={template.id}
          boxShadow={"2xl"}
          _hover={hoverCardStyles}
          onClick={() => handleSelectTemplate(template)}>
          <CardHeader pb="1">
            <Text fontWeight={"bold"}>{template.name}</Text>
          </CardHeader>
          <CardBody pt="1">{template.templateText}</CardBody>
        </Card>
      ))}
    </VStack>
  );
}