import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import ButtonIcon from "../ButtonIcon";

interface PaginationProps {
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  totalRows: number;
  rowsPerPage: number;
  initialPage?: number;
}

const Pagination = ({
  onChangePage,
  onChangeRowsPerPage,
  totalRows,
  rowsPerPage,
  initialPage = 1,
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    onChangePage(currentPage);
  }, [currentPage, onChangePage]);

  function onPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function onNextPage() {
    if (currentPage < totalRows / rowsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function getCanPreviousPage() {
    return currentPage > 1;
  }

  function getCanNextPage() {
    return currentPage < totalRows / rowsPerPage;
  }

  return (
    <Flex align={"center"} gap={5}>
      <Flex align={"center"}>
        <ButtonIcon
          icon={<FaAngleDoubleLeft />}
          onClick={() => setCurrentPage(1)}
          disabled={!getCanPreviousPage()}
        />
        <ButtonIcon
          icon={<FaAngleLeft />}
          onClick={() => onPreviousPage()}
          disabled={!getCanPreviousPage()}
        />
        <ButtonIcon
          icon={<FaAngleRight />}
          onClick={() => onNextPage()}
          disabled={!getCanNextPage()}
        />
        <ButtonIcon
          icon={<FaAngleDoubleRight />}
          onClick={() => setCurrentPage(Math.ceil(totalRows / rowsPerPage))}
          disabled={!getCanNextPage()}
        />
      </Flex>
      <Text>
        {(currentPage - 1) * rowsPerPage}-{currentPage * rowsPerPage} de{" "}
        <strong>{totalRows}</strong> itens
      </Text>
      <select
        value={rowsPerPage}
        onChange={(e) => {
          onChangeRowsPerPage(Number(e.target.value));
          setCurrentPage(1);
        }}>
        {[20, 100, 500, 1000, 5000].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Mostrar {pageSize}
          </option>
        ))}
      </select>
    </Flex>
  );
};

export default Pagination;
