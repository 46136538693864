import { Badge, Td, Tr, useBoolean } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { BiTestTube } from "react-icons/bi";
import { CiTrophy } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import ButtonIcon from "../../../../../components/ButtonIcon";
import { colors } from "../../../../../constants/colors";
import { ExperimentStatus } from "../../../../../types/CampaignExperiment";
import {
  CampaignType,
  WhatsappCampaignStatsData
} from "../../../../../types/WhatsappCampaignsData";
import RowCampaign, { RowCampaignProps } from "./RowCampaign";

interface ExperimentData {
  campaign_experiment_id: string;
  created_at: Date;
  total_recipients: number;
  sent: number;
  read: number;
  clicks: number;
  replies: number;
  failed: number;
  experiment_status: ExperimentStatus;
}

interface RowExperimentProps
  extends Omit<RowCampaignProps, "whatsappCampaign"> {
  whatsappCampaigns: WhatsappCampaignStatsData[];
}

const RowExperiment = ({
  whatsappCampaigns,
  onClickEngagementAction,
  onClickCancelCampaign,
  onClickCampaignResults,
  onClickInterruptCampaign,
}: RowExperimentProps) => {
  const [isExpanded, setIsExpanded] = useBoolean();
  const { t } = useTranslation();

  function getExperimentData(
    campaigns: WhatsappCampaignStatsData[]
  ): ExperimentData {
    return campaigns.reduce(
      (acc, campaign) => {
        acc.total_recipients += campaign.total_recipients;
        acc.sent += campaign.sent;
        acc.read += campaign.read;
        acc.clicks += campaign.clicks;
        acc.replies += campaign.replies;
        acc.failed += campaign.failed;
        return acc;
      },
      {
        campaign_experiment_id: campaigns[0].campaign_experiment_id!,
        created_at: new Date(
          campaigns[0].scheduled_execution_time || campaigns[0].created_at
        ),
        total_recipients: 0,
        sent: 0,
        read: 0,
        clicks: 0,
        replies: 0,
        failed: 0,
        experiment_status: campaigns[0].experiment_status!,
      }
    );
  }

  function getColorScheme(status: ExperimentStatus): string {
    const statusData: Record<ExperimentStatus, string> = {
      in_progress: colors.status.inProgress,
      completed: colors.status.completed,
      scheduled: colors.status.scheduled,
      canceled: colors.status.canceled,
    };
    return statusData[status];
  }

  const winningVariant = whatsappCampaigns.find(
    (campaign) => campaign.type === CampaignType.EXPERIMENT_WINNING_GROUP
  );

  function getCampaignIcon(whatsappCampaign: WhatsappCampaignStatsData): ReactNode {
    const { type: campaignType } = whatsappCampaign;
    
    const color = whatsappCampaign.template_id === winningVariant?.template_id ? "green" : "gray";
    return campaignType === CampaignType.STANDARD ? null : campaignType ===
      CampaignType.EXPERIMENT_TEST_GROUP ? (
      <BiTestTube style={{ marginLeft: "0.5rem" }} size={13} color={color} />
    ) : campaignType === CampaignType.EXPERIMENT_WINNING_GROUP ? (
      <CiTrophy style={{ marginLeft: "0.5rem" }} size={20} color={color} />
    ) : null;
  }

  const experiment = getExperimentData(whatsappCampaigns);

  return (
    <React.Fragment>
      <Tr key={experiment.campaign_experiment_id} fontWeight="light">
        <Td>
          <ButtonIcon
            icon={isExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
            onClick={() => setIsExpanded.toggle()}
          />
        </Td>
        <Td>{format(experiment.created_at, "dd/MM/yyyy HH:mm")}</Td>
        <Td display="inline-flex" alignItems="center">
          Teste A/B
        </Td>
        <Td textAlign={"center"}>{experiment.total_recipients}</Td>
        <Td textAlign={"center"}>{experiment.sent}</Td>
        <Td textAlign={"center"}>{experiment.read}</Td>
        <Td textAlign={"center"}>{experiment.clicks}</Td>
        <Td textAlign={"center"}>{experiment.replies}</Td>
        <Td textAlign={"center"}>{experiment.failed}</Td>
        <Td
          display={"flex"}
          width={"100px"}
          flexDir="column"
          justifyContent={"center"}
          alignItems="center">
          <Badge colorScheme={getColorScheme(experiment.experiment_status)}>
            {t(`enums.ExperimentStatus.${experiment.experiment_status}`)}
          </Badge>
        </Td>
        <Td></Td>
      </Tr>
      {isExpanded &&
        whatsappCampaigns.map((whatsappCampaign) => (
          <RowCampaign
            key={whatsappCampaign.id}
            bgColor={"gray.50"}
            paddingLeft={"40px"}
            whatsappCampaign={whatsappCampaign}
            onClickEngagementAction={onClickEngagementAction}
            onClickCancelCampaign={onClickCancelCampaign}
            onClickCampaignResults={onClickCampaignResults}
            onClickInterruptCampaign={onClickInterruptCampaign}
            campaignIcon={getCampaignIcon(whatsappCampaign)}
          />
        ))}
    </React.Fragment>
  );
};

export default RowExperiment;
