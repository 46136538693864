import { Box, Button, Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import TableQuickReplies from "./components";
import { useCrudQuickReplyModal } from "../../../hooks/useCrudQuickReplyModal";

const QuickRepliesPage = () => {
  const { openCreateModal } = useCrudQuickReplyModal();

  return (
    <Box padding="20px 40px">
      <Flex width="100%" justifyContent="space-between" paddingBottom={"16px"}>
        <Heading>Respostas Rápidas</Heading>
        <Button
          variant='primary'
          onClick={openCreateModal}
        >
          + resposta rápida
        </Button>
      </Flex>
      <Divider orientation="horizontal" mt={2} />
      <TableQuickReplies />
    </Box>
  );
};

export default QuickRepliesPage;
